import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import NavLogo from '../../../../components/NavLogo';
import Logo from '../../../../components/Logo';
import ChildHeaderBanner from '../../../../layouts/ChildHeaderBanner';
import * as app_consts from '../../../../utils/constants';
import CustomTabs from '../../../../layouts/CustomTabs';
import { retrieveSpecificProducerReset, retrieve_exchange_producer_details } from '../../../../utils/actions';
import { useDispatch, useSelector } from 'react-redux';
import TableComp from '../../../../components/table/TableComp';
import AssignBrokerToProducerModal from '../../../../components/modals/AssignBrokerToProducerModal';

const ExchangeProducerDetails = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { entity_id, producer_id, entity_mid } = useParams();
    const [activeTab, setActiveTab] = useState('Producer Information');
    const [isDataFetched, setIsDataFetched] = useState(false);
    const [page_data, setPagedata] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [openAssignModal, setOpenAssignModal] = useState(false);

    const [openDropDown, setOpenDropdown] = useState(false);

    const account = JSON.parse(localStorage.getItem('account'));

    const exchange_profile = account?.profiles?.find((profile) => profile.type === "exchange");

    // Handle Logout
    const handleLogout = () => {
        localStorage.clear();
        navigate("/");

    };

    // Code to Run When Page Opens
    useEffect(() => {
        dispatch(retrieve_exchange_producer_details(producer_id));
    }, []);

    // Listen to State
    const retrieveSpecificProducer = useSelector((state) => state.retrieveSpecificProducer);
    // 
    useEffect(() => {
        if (retrieveSpecificProducer.status !== app_consts.IDLE)
        {
            if (retrieveSpecificProducer.isLoading)
            {
                setLoading(true);
            }
            else
            {
                if (retrieveSpecificProducer.error)
                {
                    setError(retrieveSpecificProducer.error);
                }
                else if (retrieveSpecificProducer.data)
                {
                    setIsDataFetched(true);
                    setPagedata(retrieveSpecificProducer.data);
                }
                dispatch(retrieveSpecificProducerReset());
                setLoading(false);
            }
        }
    }, [retrieveSpecificProducer]);

    const tabs = [
        { title: 'Producer Information', disabled: !isDataFetched },
        { title: 'Warehouse Receipts', disabled: !isDataFetched },
        { title: 'Brokers', disabled: !isDataFetched },
    ];

    const successAssignBroker = (data) => {
        setPagedata(data);
        setOpenAssignModal(false);
    };

    return (
        <>
            <button data-drawer-target="logo-sidebar" data-drawer-toggle="logo-sidebar" aria-controls="logo-sidebar" type="button" className="inline-flex items-center p-2 mt-2 ml-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
                <span className="sr-only">Open sidebar</span>
                <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path clip-rule="evenodd" fill-rule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
                </svg>
                <NavLogo />
            </button>

            <aside
                id='logo-sidebar'
                className='fixed top-0 left-0 z-40 w-64 h-screen transition-transform -translate-x-full sm:translate-x-0'
                aria-label='Sidebar'
            >
                <div className='h-full px-3 py-4 overflow-y-auto bg-gray-50 dark:bg-gray-800'>
                    <a href='/' className='flex items-center pl-2.5 mb-5'>
                        <Logo />
                    </a>

                    <ul className='space-y-2 font-medium'>
                        <li>
                            <a href="/home" className="flex items-center p-2 text-gray-600 hover:text-green-600 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                                <svg class="w-6 h-6 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6.143 1H1.857A.857.857 0 0 0 1 1.857v4.286c0 .473.384.857.857.857h4.286A.857.857 0 0 0 7 6.143V1.857A.857.857 0 0 0 6.143 1Zm10 0h-4.286a.857.857 0 0 0-.857.857v4.286c0 .473.384.857.857.857h4.286A.857.857 0 0 0 17 6.143V1.857A.857.857 0 0 0 16.143 1Zm-10 10H1.857a.857.857 0 0 0-.857.857v4.286c0 .473.384.857.857.857h4.286A.857.857 0 0 0 7 16.143v-4.286A.857.857 0 0 0 6.143 11Zm10 0h-4.286a.857.857 0 0 0-.857.857v4.286c0 .473.384.857.857.857h4.286a.857.857 0 0 0 .857-.857v-4.286a.857.857 0 0 0-.857-.857Z" />
                                </svg>
                                <span className="ml-3">My Dashboards</span>
                            </a>
                        </li>
                        <li>
                            <a href={`/exchange/${entity_id}/${entity_mid}`} className="flex items-center p-2 text-gray-600 hover:text-green-600 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 dark:text-white">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M7.5 21 3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5" />
                                </svg>

                                <span className="ml-3">Exchange Dashboard</span>
                            </a>
                        </li>
                        <li>
                            <a
                                href={`/exchange/${entity_id}/${entity_mid}/membership`}
                                className="flex items-center p-2 text-gray-600 hover:text-green-600 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class='w-6 h-6'>
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Z" />
                                </svg>
                                <span className='ml-3'>Membership Management</span>
                            </a>
                        </li>
                        <li>
                            <a href={`/exchange/${entity_id}/${entity_mid}/warehouse_management`} className='flex items-center p-2 text-gray-600 hover:text-green-600 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0012 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75z" />
                                </svg>


                                <span className="ml-3">Warehouse Operator Management</span>
                            </a>
                        </li>
                        <li>
                            <a href={`/exchange/${entity_id}/${entity_mid}/broker_management`} className="flex items-center p-2 text-gray-600 hover:text-green-600 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z" />
                                </svg>
                                <span className="ml-3">Broker Management</span>
                            </a>
                        </li>
                        <li>
                            <a href={`/exchange/${entity_id}/${entity_mid}/cooperative_management`}
                                className="flex items-center p-2 text-gray-600 hover:text-green-600 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-6 h-6">
                                    <circle cx="5.5" cy="12.5" r="2.5" />
                                    <circle cx="12" cy="5.5" r="2.5" />
                                    <circle cx="18.5" cy="12.5" r="2.5" />
                                    <path d="M7 12.5a5 5 0 005 5m5-5a5 5 0 00-5-5m0 0a5 5 0 00-5 5m5-5v10" />
                                </svg>
                                <span className="ml-3">Cooperative Management</span>
                            </a>
                        </li>
                        <li>
                            <a href={`/exchange/${entity_id}/${entity_mid}/user_management`}
                                className="flex items-center p-2 text-gray-600 hover:text-green-600 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
                                </svg>

                                <span className="ml-3">User Management</span>
                            </a>
                        </li>
                        <li>
                            <a href={`/exchange/${entity_id}/${entity_mid}/producer_management`}
                                className='flex items-center p-2 text-green-600 rounded-lg dark:text-white hover:text-green-600 dark:hover:bg-gray-700 group'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z" />
                                </svg>

                                <span className="ml-3">Producer Management</span>
                            </a>
                        </li>

                        <li className='my-2 fixed bottom-12 w-11/12'>
                            <a
                                onClick={handleLogout}
                                className='cursor-pointer flex items-center p-2 text-gray-600 hover:text-green-600 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group'
                            >
                                <svg
                                    class='w-6 h-6 dark:text-white'
                                    aria-hidden='true'
                                    xmlns='http://www.w3.org/2000/svg'
                                    fill='none'
                                    viewBox='0 0 16 16'
                                >
                                    <path
                                        stroke='currentColor'
                                        stroke-linecap='round'
                                        stroke-linejoin='round'
                                        stroke-width='2'
                                        d='M4 8h11m0 0-4-4m4 4-4 4m-5 3H3a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h3'
                                    />
                                </svg>
                                <span className='flex-1 ml-3 whitespace-nowrap'>Sign Out</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </aside>


            <div className="p-4 sm:ml-64 mb-8">
                <div className="p-4 rounded-lg dark:border-gray-700">
                    <ChildHeaderBanner title="Producer Details" type={app_consts.SPECIFIC_EXCHANGE_PRODUCERS} navInfo={{ entity_id, producer_id, entity_mid }} home={`home`} />


                    <div className="relative ">
                        <CustomTabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
                        <br />
                        {
                            loading ?
                                <>
                                    <div className="text-center mt-4 h-10">
                                        <div role="status">
                                            <svg
                                                aria-hidden="true"
                                                className="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-green-600"
                                                viewBox="0 0 100 101"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                    fill="currentColor"
                                                />
                                                <path
                                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                    fill="currentFill"
                                                />
                                            </svg>
                                            <span className="sr-only">Please wait...</span>
                                        </div>
                                    </div>
                                </>
                                :
                                <>
                                    {
                                        error && error?.length > 0 ?
                                            <>
                                                <div className='text-center mt-8 h-10'>
                                                    <div
                                                        role='status'
                                                        className='text-red-600 flex flex-col items-center justify-center mb-4 leading-4'
                                                    >
                                                        <svg
                                                            xmlns='http://www.w3.org/2000/svg'
                                                            fill='none'
                                                            viewBox='0 0 24 24'
                                                            strokeWidth={1.5}
                                                            stroke='currentColor'
                                                            className='w-8 h-8'
                                                        >
                                                            <path
                                                                strokeLinecap='round'
                                                                strokeLinejoin='round'
                                                                d='M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
                                                            />
                                                        </svg>
                                                    </div>
                                                    <span
                                                        colSpan='8'
                                                        className='text-gray-700 py-4 my-6 tracking-wider'
                                                    >
                                                        Could Not Retrieve Producer Details.
                                                    </span>
                                                </div>
                                            </>
                                            :
                                            <>
                                                {activeTab === 'Producer Information' &&
                                                    <>
                                                        <div>
                                                            <div class="relative overflow-hidden bg-white dark:bg-gray-800 sm:rounded-lg">
                                                                <div class="flex-row items-center justify-between p-4 space-y-3 sm:flex sm:space-y-0 sm:space-x-4">
                                                                    <div>
                                                                        <h5 class="mr-3 font-semibold dark:text-white">Producer Information</h5>
                                                                        <p class="text-gray-600 font-medium dark:text-gray-400 capitalize">{page_data?.producer?.producer_id ? page_data?.producer?.producer_id : "---"} - {page_data?.producer?.type ? <>{page_data?.producer?.type !== app_consts.INDIVIDUAL.toLowerCase() ? page_data?.producer_entity?.producer_entity_name : page_data?.producer?.contact_name}</> : ""}</p>
                                                                    </div>
                                                                </div>
                                                                <dl className="grid grid-cols-2 gap-4 mb-4">
                                                                    <div className="col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600">
                                                                        <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Producer ID</dt>
                                                                        <dd className="flex items-center text-gray-500 dark:text-gray-400">
                                                                            {page_data?.producer?.producer_id ? page_data?.producer?.producer_id : "---"}
                                                                        </dd>
                                                                    </div>
                                                                    <div className="col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600">
                                                                        <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Producer Name</dt>
                                                                        <dd className="flex items-center text-gray-500 dark:text-gray-400">
                                                                            {page_data?.producer?.type ? <>{page_data?.producer?.type !== app_consts.INDIVIDUAL.toLowerCase() ? page_data?.producer_entity?.producer_entity_name : page_data?.producer?.contact_name}</> : ""}
                                                                        </dd>
                                                                    </div>
                                                                    <div className="col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600">
                                                                        <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Producer Email Address</dt>
                                                                        <dd className="flex items-center text-gray-500 dark:text-gray-400">
                                                                            {page_data?.producer?.type ? <>{page_data?.producer?.type !== app_consts.INDIVIDUAL.toLowerCase() ? <>{page_data?.producer_entity?.producer_entity_email ? page_data?.producer_entity?.producer_entity_email : page_data?.producer?.contact_email}</> : page_data?.producer?.contact_email}</> : ""}
                                                                        </dd>
                                                                    </div>
                                                                    <div className="col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600">
                                                                        <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Producer Mobile</dt>
                                                                        <dd className="flex items-center text-gray-500 dark:text-gray-400">
                                                                            {page_data?.producer?.type ? <>{page_data?.producer?.type !== app_consts.INDIVIDUAL.toLowerCase() ? <>{page_data?.producer_entity?.producer_entity_phone ? page_data?.producer_entity?.producer_entity_phone : page_data?.producer?.contact_phone}</> : page_data?.producer?.contact_phone}</> : ""}
                                                                        </dd>
                                                                    </div>
                                                                    <div className="col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600">
                                                                        <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Producer Address</dt>
                                                                        <dd className="flex items-center text-gray-500 dark:text-gray-400">
                                                                            {page_data?.producer?.type ? <>{page_data?.producer?.type !== app_consts.INDIVIDUAL.toLowerCase() ? <>{page_data?.producer_entity?.producer_entity_physical_address ? page_data?.producer_entity?.producer_entity_physical_address : page_data?.producer?.contact_address}</> : page_data?.producer?.contact_address}</> : ""}
                                                                        </dd>
                                                                    </div>
                                                                    <div className="col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600">
                                                                        <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Producer Province</dt>
                                                                        <dd className="flex items-center text-gray-500 dark:text-gray-400">
                                                                            {page_data?.producer?.type ? <>{page_data?.producer?.type !== app_consts.INDIVIDUAL.toLowerCase() ? <>{page_data?.producer_entity?.producer_entity_province ? page_data?.producer_entity?.producer_entity_province : page_data?.producer?.province}</> : page_data?.producer?.province}</> : ""}
                                                                        </dd>
                                                                    </div>
                                                                    <div className="col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600">
                                                                        <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Producer Country</dt>
                                                                        <dd className="flex items-center text-gray-500 dark:text-gray-400">
                                                                            {page_data?.producer?.type ? <>{page_data?.producer?.type !== app_consts.INDIVIDUAL.toLowerCase() ? <>{page_data?.producer_entity?.producer_entity_country ? page_data?.producer_entity?.producer_entity_country : page_data?.producer?.country}</> : page_data?.producer?.country}</> : ""}
                                                                        </dd>
                                                                    </div>
                                                                </dl>
                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                                {
                                                    activeTab === 'Warehouse Receipts' &&
                                                    <>
                                                        <div>
                                                            <div class="relative overflow-hidden bg-white dark:bg-gray-800 sm:rounded-lg">
                                                                <div class="flex-row items-center justify-between p-4 space-y-3 sm:flex sm:space-y-0 sm:space-x-4">
                                                                    <div>
                                                                        <h5 class="mr-3 font-semibold dark:text-white">Warehouse Receipts</h5>
                                                                        <p class="text-gray-600 font-medium dark:text-gray-400">{page_data?.producer?.producer_id ? page_data?.producer?.producer_id : "---"} - {page_data?.producer?.type ? <>{page_data?.producer?.type !== app_consts.INDIVIDUAL.toLowerCase() ? page_data?.producer_entity?.producer_entity_name : page_data?.producer?.contact_name}</> : ""}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <TableComp tableData={page_data?.warehouse_receipts} tableType={app_consts.SPECIFIC_PRODUCER_WHR_TABLE} />
                                                        </div>
                                                    </>
                                                }
                                                {
                                                    activeTab === 'Brokers' &&
                                                    <>
                                                        <div>
                                                            <div class="relative overflow-hidden bg-white dark:bg-gray-800 sm:rounded-lg">
                                                                <div class="flex-row items-center justify-between p-4 space-y-3 sm:flex sm:space-y-0 sm:space-x-4">
                                                                    <div>
                                                                        <h5 class="mr-3 font-semibold dark:text-white">Producer Brokers</h5>
                                                                        <p class="text-gray-600 font-medium dark:text-gray-400">{page_data?.producer?.producer_id ? page_data?.producer?.producer_id : "---"} - {page_data?.producer?.type ? <>{page_data?.producer?.type !== app_consts.INDIVIDUAL.toLowerCase() ? page_data?.producer_entity?.producer_entity_name : page_data?.producer?.contact_name}</> : ""}</p>
                                                                    </div>
                                                                    {
                                                                        (exchange_profile?.roles?.includes("administrator")) && (
                                                                            <>
                                                                                <div className='relative flex flex-column mr-4'>
                                                                                    <button className="flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800" type="button" onClick={() => setOpenDropdown(!openDropDown)}>
                                                                                        <svg
                                                                                            className='-ml-1 mr-1.5 w-5 h-5'
                                                                                            fill='currentColor'
                                                                                            viewBox='0 0 20 20'
                                                                                            xmlns='http://www.w3.org/2000/svg'
                                                                                            aria-hidden='true'
                                                                                        >
                                                                                            <path
                                                                                                clipRule='evenodd'
                                                                                                fillRule='evenodd'
                                                                                                d='M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z'
                                                                                            />
                                                                                        </svg>
                                                                                        Actions
                                                                                    </button>

                                                                                    <div className={`z-10 ${openDropDown ? "fixed right-0 origin-top-right mt-12 right-6" : "hidden"} w-48 bg-gray-100 divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 border-1 border-gray-300`}>
                                                                                        <ul className="border-1 font-bold border-gray-200 rounded-lg text-sm text-gray-800 dark:text-gray-200" aria-labelledby="multiLevelDropdownButton">

                                                                                            <li>
                                                                                                <span onClick={() => setOpenAssignModal(true)} className="hover:bg-gray-300 cursor-pointer block px-4 py-4 dark:hover:bg-gray-600 dark:hover:text-white">Assign to Broker</span>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                </div>
                                                            </div>
                                                            <TableComp tableData={page_data?.producer_brokers} tableType={app_consts.EXCHANGE_PRODUCER_BROKERS} />
                                                        </div>
                                                        {
                                                            page_data &&
                                                            (
                                                                <>
                                                                    <AssignBrokerToProducerModal isOpen={openAssignModal} setIsOpen={setOpenAssignModal} producer_id={producer_id} producer_name={page_data?.producer?.type ? page_data?.producer?.type !== app_consts.INDIVIDUAL.toLowerCase() ? page_data?.producer_entity?.producer_entity_name : page_data?.producer?.contact_name : ""} successFunction={successAssignBroker} />
                                                                </>
                                                            )
                                                        }
                                                    </>
                                                }
                                            </>
                                    }
                                </>
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default ExchangeProducerDetails;