import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Logo from '../../components/Logo';
import NavLogo from '../../components/NavLogo';
import ChildHeaderBanner from '../../layouts/ChildHeaderBanner';
import CustomTabs from '../../layouts/CustomTabs';
import { useDispatch, useSelector } from 'react-redux';
import { getDirectorsList, getEntity, getEntityProfile, getEntityProfileList, uploadMemberBoardResolution, uploadMemberProfileDocument, uploadMemberRegistrationDocument, uploadMemberTaxRegistrationDocument, activateMembershipType, uploadMemberPacraPrintoutDocument, verifyMembership, upload_bank_account_documents, uploadBankAccountDocumentsReset, retrieveEntityDetailsReset, getFullEntityDetails, verifyMembershiptReset, uploadDocumentReset, uploadEntityDocuments, exchange_upload_bank_account_documents } from '../../utils/actions';
import DocumentViewer from '../../layouts/DocumentViewer';
import ProfileCard from '../../layouts/ProfileCard';
import AddEntityManagementInformationModal from '../../components/modals/AddEntityManagementInformationModal';
import AddBankAccountDetailsModal from '../../components/modals/AddBankAccountDetailsModal';
import MembershipTypeModal from '../../components/modals/MembershipTypeModal';
import ActivateMembershipModal from '../../components/modals/ActivateMembershipModal';
import AdvancedDocumentViewer from '../../layouts/AdvancedDocumentViewer';
import * as app_consts from '../../utils/constants';
import YesNoModal from '../../components/modals/YesNoModal';
import TradingAuthorityModal from '../../components/modals/TradingAuthorityModal';
import TableComp from '../../components/table/TableComp';

const EntityAccountDetails = () => {

    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState('Registration Details');
    const [financialActiveTab, setFinancialActiveTab] = useState('Bank Accounts');
    const [isDataFetched, setIsDataFetched] = useState(false);
    const [page_data, setPageData] = useState(false);
    const [entityProfiles, setEntityProfiles] = useState([]);
    const [directorList, setDirectorList] = useState([]);
    const [bank_accounts, setBankAccounts] = useState([]);
    const [main_access_account, setMainAccessAccount] = useState([]);
    const [user_profiles, setUserProfiles] = useState([]);

    const [upload_loading, setUploadLoading] = useState(false);
    const [upload_error, setUploadError] = useState("");


    const tabs = [
        { title: 'Registration Details', disabled: !isDataFetched },
        { title: 'Registration Documents', disabled: !isDataFetched },
        { title: 'Management Details', disabled: !isDataFetched },
        { title: 'Financial Details', disabled: !isDataFetched },
        { title: 'User Profiles', disabled: !isDataFetched },
        { title: 'Transactions', disabled: true },
    ];

    const financical_details_tabs = [
        { title: 'Bank Accounts', disabled: !isDataFetched },
        { title: 'Financial Documents', disabled: !isDataFetched },
    ];

    const { entity_id, entity_mid, organization_id, id, name } = useParams();
    const dispatch = useDispatch();
    const entityprofile = useSelector((state) => state.getEntityProfile.data);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [documentName, setDocumentName] = useState('');

    const [errors, setErrors] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);

    const bankDetails = bank_accounts ? bank_accounts[0] : null;

    // For Verification
    const [verifyModal, setVerifyModal] = useState(false);
    const [verify_loading, setVerifyLoading] = useState(false);
    const [verify_error, setVerifyError] = useState("");


    const account = JSON.parse(localStorage.getItem('account'));
    const exchange_profile = account?.profiles?.find((profile) => profile.type === "exchange");

    // First Code to Run When Page Opens
    useEffect(() => {
        dispatch(getFullEntityDetails(organization_id));
    }, []);

    // Listen to Reducer State Changes
    const entity_details_listener = useSelector((state) => state.retriveEntityDetails);
    // 
    useEffect(() => {
        if (entity_details_listener.status !== app_consts.IDLE)
        {
            if (entity_details_listener.isLoading)
            {
                setLoading(true);
            }
            else
            {
                if (entity_details_listener.error)
                {
                    setError(entity_details_listener.message);
                }
                else
                {
                    setIsDataFetched(true);
                    setPageData(entity_details_listener.data);
                    setEntityProfiles(entity_details_listener.data?.entity_profiles);
                    setDirectorList(entity_details_listener.data?.directors);
                    setBankAccounts(entity_details_listener.data?.bank_details);
                    setUserProfiles(entity_details_listener.data?.user_profiles);
                    // Find Main Access Account
                    const mainAccount = entity_details_listener.data?.user_profiles?.find((account) => account.account_id === entity_details_listener.data?.account_id);
                    setMainAccessAccount(mainAccount);
                }
                setLoading(false);
                dispatch(retrieveEntityDetailsReset());
            }
        }
    }, [entity_details_listener]);

    const [yesorNoModalOpen, setYesorNoModalOpen] = useState(false);
    const [actionStatus, setActionStatus] = useState(null);

    const handleBrokerAuthorityActions = async (status) => {
        // Open the modal to confirm the action
        setActionStatus(status);
        setYesorNoModalOpen(true);
    };

    const closeYesOrNoModal = () => {
        setYesorNoModalOpen(false);
        setVerifyModal(false);
    };

    const confirmAction = async () => {
        setTradingModal({ tradingAuthorityModalModal: true });
        closeYesOrNoModal();
    };

    const [modal, setModal] = useState({
        membershipTypeModal: false,
    });

    const closeMembershipTypeModal = () => {
        setModal({ ...modal, membershipTypeModal: false });
    };

    const openSelectedModal = (modalToBeOpened) => {
        setModal({ ...modal, [modalToBeOpened]: true });
    };

    const [activateModal, setActivateModal] = useState({
        activateMmbershipModal: false,
    });

    const closeActivateMembershipModal = () => {
        setActivateModal({ ...modal, activateMmbershipModal: false });
    };

    const openSelectedACtivateModal = (openActivateMmbershipModal) => {
        setActivateModal({ ...activateModal, [openActivateMmbershipModal]: true });
    };

    const [tradingModal, setTradingModal] = useState({
        tradingAuthorityModalModal: false,
    });

    const closeTradingAuthorityModal = () => {
        setTradingModal({ ...modal, activateMmbershipModal: false });
    };

    const openTradingAuthorityModal = (openActivateMmbershipModal) => {
        setTradingModal({ ...tradingModal, [openActivateMmbershipModal]: true });
    };

    const uploadDocument = async (documentName) => {
        setUploadError("");

        if (!selectedFile)
        {
            setErrors({ file: "Please select a file to upload." });
            return;
        }
        if (documentName === "Financial Audit" || documentName === "Current Management Accounts")
        {
            if (bank_accounts)
            {
                if (bank_accounts && bank_accounts?.length > 0)
                {
                    const upload_params = () => {
                        if (documentName === "Financial Audit")
                        {
                            return ({ financial_audit_doc: selectedFile, id: bankDetails?.id });
                        }
                        else if (documentName === "Current Management Accounts")
                        {
                            return ({ current_management_accounts_doc: selectedFile, id: bankDetails?.id });
                        }
                    };
                    dispatch(exchange_upload_bank_account_documents(upload_params()));
                }
            }
        }
        else
        {
            dispatch(uploadEntityDocuments({ doc_type: documentName?.replace(/ /g, '_')?.toLowerCase(), document: selectedFile, organization_id: organization_id }));
        }
    };

    // Upload Document Listener
    const upload_document_listner = useSelector((state) => state.updateDocument);
    // 
    useEffect(() => {
        if (upload_document_listner.status !== app_consts.IDLE)
        {
            if (upload_document_listner.isLoading)
            {
                setUploadLoading(true);
            }
            else
            {
                setUploadLoading(false);
                if (upload_document_listner.error)
                {
                    setUploadError(upload_document_listner.message);
                }
                else if (upload_document_listner.data)
                {
                    setIsModalUploadOpen(false);
                    dispatch(getFullEntityDetails(organization_id));
                }
                dispatch(uploadDocumentReset());
            }
        }
    }, [upload_document_listner]);


    // Listen to Bank Accounts Details Upload
    const upload_bank_account_document = useSelector((state) => state.updateBankAccountDocuments);
    // 
    useEffect(() => {
        if (upload_bank_account_document.status !== app_consts.IDLE)
        {
            if (upload_bank_account_document.isLoading)
            {
                setLoading(true);
            }
            else
            {
                setLoading(false);
                if (upload_bank_account_document.error)
                {
                    setErrors(upload_bank_account_document.message);
                }
                else if (upload_bank_account_document.data)
                {
                    closeUploadModal();
                    successFunction();
                }
                dispatch(uploadBankAccountDocumentsReset());
            }
        }
    }, [upload_bank_account_document]);

    const [isManagentModalOpen, setIsManagentModalOpen] = useState(false);

    const openManagentModal = () => {
        setIsManagentModalOpen(true);
    };

    const closeManagentModal = () => {
        setIsManagentModalOpen(false);
    };

    const [isBankModalOpen, setIsBankModalOpen] = useState(false);

    const opeBankModal = () => {
        setIsBankModalOpen(true);
    };

    const closeBankModal = () => {
        setIsBankModalOpen(false);
    };



    // Upload Modal state and functions
    const [isModalUploadOpen, setIsModalUploadOpen] = useState(false);

    const openUploadModal = (documentName) => {
        setIsModalUploadOpen(true);
        setDocumentName(documentName); // Add this line to set the document name in state
        setSelectedFile(null);
        setFilePreview(null);
        setUploadError("");
    };


    const closeUploadModal = () => {
        setIsModalUploadOpen(false);
    };

    const [filePreview, setFilePreview] = useState(null);

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        const maxSizeInMB = 20;
        const maxSizeInBytes = maxSizeInMB * 1024 * 1024;

        if (selectedFile)
        {
            if (selectedFile.size > maxSizeInBytes)
            {
                setSelectedFile(null);
                setFilePreview(null);
                setUploadError("File size exceeds 20MB.");
                return;
            }
            if (selectedFile.type.startsWith('image/') || selectedFile.type === 'application/pdf')
            {
                setUploadError("");
                setSelectedFile(selectedFile);

                const reader = new FileReader();
                reader.onload = (event) => {
                    setFilePreview(event.target.result);
                };
                reader.readAsDataURL(selectedFile);
            } else
            {
                setSelectedFile(null);
                setFilePreview(null);
                setUploadError("Please select a valid image or PDF file (PNG, JPEG, or PDF).");
            }
        }
    };

    const successFunction = () => {
        dispatch(getFullEntityDetails(organization_id));
    };

    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleVerifyMembership = async () => {
        setVerifyError("");
        await dispatch(verifyMembership({
            account_id: main_access_account?.account_id,
            entity_id: page_data?.id
        }));
    };

    // Listen to Verification State
    const verify_membership_listener = useSelector((state) => state.verifyMembership);
    // 
    useEffect(() => {
        if (verify_membership_listener.status !== app_consts.IDLE)
        {
            if (verify_membership_listener.isLoading)
            {
                setVerifyLoading(true);
            }
            else
            {
                if (verify_membership_listener.error)
                {
                    setVerifyError(verify_membership_listener.error);
                }
                else
                {
                    closeYesOrNoModal();
                    dispatch(getFullEntityDetails(organization_id));
                }
                setVerifyLoading(false);
                dispatch(verifyMembershiptReset());
            }
        }
    }, [verify_membership_listener]);

    // Handle Logout
    const handleLogout = () => {
        localStorage.clear();
        navigate("/");
    };

    return (
        <div>

            <button data-drawer-target="logo-sidebar" data-drawer-toggle="logo-sidebar" aria-controls="logo-sidebar" type="button" className="inline-flex items-center p-2 mt-2 ml-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
                <span className="sr-only">Open sidebar</span>
                <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path clip-rule="evenodd" fill-rule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
                </svg>
                <NavLogo />
            </button>


            <aside
                id='logo-sidebar'
                className='fixed top-0 left-0 z-40 w-64 h-screen transition-transform -translate-x-full sm:translate-x-0'
                aria-label='Sidebar'
            >
                <div className='h-full px-3 py-4 overflow-y-auto bg-gray-50 dark:bg-gray-800'>
                    <a href='/' className='flex items-center pl-2.5 mb-5'>
                        <Logo />
                    </a>

                    <ul className='space-y-2 font-medium'>
                        <li>
                            <a href="/home" className="flex items-center p-2 text-gray-600 hover:text-green-600 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                                <svg class="w-6 h-6 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6.143 1H1.857A.857.857 0 0 0 1 1.857v4.286c0 .473.384.857.857.857h4.286A.857.857 0 0 0 7 6.143V1.857A.857.857 0 0 0 6.143 1Zm10 0h-4.286a.857.857 0 0 0-.857.857v4.286c0 .473.384.857.857.857h4.286A.857.857 0 0 0 17 6.143V1.857A.857.857 0 0 0 16.143 1Zm-10 10H1.857a.857.857 0 0 0-.857.857v4.286c0 .473.384.857.857.857h4.286A.857.857 0 0 0 7 16.143v-4.286A.857.857 0 0 0 6.143 11Zm10 0h-4.286a.857.857 0 0 0-.857.857v4.286c0 .473.384.857.857.857h4.286a.857.857 0 0 0 .857-.857v-4.286a.857.857 0 0 0-.857-.857Z" />
                                </svg>
                                <span className="ml-3">My Dashboards</span>
                            </a>
                        </li>
                        <li>
                            <a href={`/exchange/${entity_id}/${entity_mid}`} className="flex items-center p-2 text-gray-600 hover:text-green-600 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 dark:text-white">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M7.5 21 3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5" />
                                </svg>

                                <span className="ml-3">Exchange Dashboard</span>
                            </a>
                        </li>
                        <li>
                            <a
                                href={`/exchange/${entity_id}/${entity_mid}/membership`}
                                className='flex items-center p-2 text-green-600 rounded-lg dark:text-white hover:text-green-600 dark:hover:bg-gray-700 group'
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class='w-6 h-6'>
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Z" />
                                </svg>
                                <span className='ml-3'>Membership Management</span>
                            </a>
                        </li>
                        <li>
                            <a href={`/exchange/${entity_id}/${entity_mid}/warehouse_management`} className="flex items-center p-2 text-gray-600 hover:text-green-600 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0012 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75z" />
                                </svg>


                                <span className="ml-3">Warehouse Operator Management</span>
                            </a>
                        </li>
                        <li>
                            <a href={`/exchange/${entity_id}/${entity_mid}/broker_management`}
                                className="flex items-center p-2 text-gray-600 hover:text-green-600 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z" />
                                </svg>
                                <span className="ml-3">Broker Management</span>
                            </a>
                        </li>
                        <li>
                            <a href={`/exchange/${entity_id}/${entity_mid}/cooperative_management`}
                                className="flex items-center p-2 text-gray-600 hover:text-green-600 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-6 h-6">
                                    <circle cx="5.5" cy="12.5" r="2.5" />
                                    <circle cx="12" cy="5.5" r="2.5" />
                                    <circle cx="18.5" cy="12.5" r="2.5" />
                                    <path d="M7 12.5a5 5 0 005 5m5-5a5 5 0 00-5-5m0 0a5 5 0 00-5 5m5-5v10" />
                                </svg>
                                <span className="ml-3">Cooperative Management</span>
                            </a>
                        </li>
                        <li>
                            <a href={`/exchange/${entity_id}/${entity_mid}/user_management`}
                                className="flex items-center p-2 text-gray-600 hover:text-green-600 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
                                </svg>

                                <span className="ml-3">User Management</span>
                            </a>
                        </li>
                        <li>
                            <a href={`/exchange/${entity_id}/${entity_mid}/producer_management`}
                                className="flex items-center p-2 text-gray-600 hover:text-green-600 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z" />
                                </svg>

                                <span className="ml-3">Producer Management</span>
                            </a>
                        </li>

                        <li className='my-2 fixed bottom-12 w-11/12'>
                            <a
                                onClick={handleLogout}
                                className='cursor-pointer flex items-center p-2 text-gray-600 hover:text-green-600 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group'
                            >
                                <svg
                                    class='w-6 h-6 dark:text-white'
                                    aria-hidden='true'
                                    xmlns='http://www.w3.org/2000/svg'
                                    fill='none'
                                    viewBox='0 0 16 16'
                                >
                                    <path
                                        stroke='currentColor'
                                        stroke-linecap='round'
                                        stroke-linejoin='round'
                                        stroke-width='2'
                                        d='M4 8h11m0 0-4-4m4 4-4 4m-5 3H3a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h3'
                                    />
                                </svg>
                                <span className='flex-1 ml-3 whitespace-nowrap'>Sign Out</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </aside>

            <div className="p-4 sm:ml-64">
                <div className="p-4 rounded-lg dark:border-gray-700">
                    <ChildHeaderBanner title="Member Details" header_addition={page_data && page_data.entity_name ? page_data.entity_name : ""} type={app_consts.MEMBERSHIP_DETAILS} navInfo={{ entity_id, entity_mid, organization_id }} home={`home`} />

                    <div className="relative">
                        <CustomTabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
                        <br />
                        {
                            loading ?
                                <>
                                    <div className="text-center mt-4 h-10">
                                        <div role="status">
                                            <svg
                                                aria-hidden="true"
                                                className="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-green-600"
                                                viewBox="0 0 100 101"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                    fill="currentColor"
                                                />
                                                <path
                                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                    fill="currentFill"
                                                />
                                            </svg>
                                            <span className="sr-only">Please wait...</span>
                                        </div>
                                    </div>
                                </>
                                :
                                <>
                                    {
                                        error ?
                                            <>
                                                <tr className='flex items-center w-full justify-center'>
                                                    <td colSpan="8" className="w-full text-center py-4">
                                                        <div role="status" className='text-red-600 flex flex-col items-center justify-center mb-4 leading-4'>
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                            </svg>
                                                        </div>
                                                        <span colSpan="8" className="text-gray-700 py-4 my-6 tracking-wider">
                                                            {error}
                                                        </span>
                                                    </td>
                                                </tr>
                                            </>
                                            :
                                            <>
                                                {activeTab === 'Registration Details' &&
                                                    <div>
                                                        <div class="relative overflow-hidden bg-white dark:bg-gray-800 sm:rounded-lg">
                                                            <div class="flex-row items-center justify-between p-4 space-y-3 sm:flex sm:space-y-0 sm:space-x-4">
                                                                <div>
                                                                    <h5 class="text-xl mr-3 font-semibold dark:text-white">{page_data.entity_name} | Registration Details </h5>
                                                                </div>
                                                                <div class="flex flex-col items-stretch justify-end flex-shrink-0 w-full space-y-2 md:w-auto md:flex-row md:space-y-0 md:items-center md:space-x-3">
                                                                    {
                                                                        page_data && (
                                                                            <>
                                                                                {
                                                                                    page_data?.verified === 1 && exchange_profile?.roles?.includes("administrator") && (
                                                                                        <>
                                                                                            <button
                                                                                                type="button"
                                                                                                onClick={() => setActivateModal({ activateMmbershipModal: true })}
                                                                                                className="flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
                                                                                            >
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                                                                                                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clipRule="evenodd" />
                                                                                                </svg>
                                                                                                Activate Membership Type
                                                                                            </button>
                                                                                        </>
                                                                                    )
                                                                                }
                                                                                {
                                                                                    page_data?.verified === 0 && (
                                                                                        <>
                                                                                            <button
                                                                                                type="button"
                                                                                                onClick={() => setVerifyModal(true)}
                                                                                                className="flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
                                                                                            >
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                                                                                                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clipRule="evenodd" />
                                                                                                </svg>
                                                                                                <div className='ml-2'>
                                                                                                    Verify Entity
                                                                                                </div>
                                                                                            </button>
                                                                                        </>
                                                                                    )
                                                                                }
                                                                            </>
                                                                        )
                                                                    }
                                                                    {
                                                                        page_data?.verified === 1 && exchange_profile?.roles?.includes("administrator") && (
                                                                            <>
                                                                                <div className="relative inline-block text-left">
                                                                                    <div>
                                                                                        <button
                                                                                            type="button"
                                                                                            onClick={toggleDropdown}
                                                                                            className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg md:w-auto focus:outline-none hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                                                                                            id="actionsDropdownButton"
                                                                                        >
                                                                                            <svg
                                                                                                className="-ml-1 mr-1.5 w-5 h-5"
                                                                                                fill="currentColor"
                                                                                                viewBox="0 0 20 20"
                                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                                aria-hidden="true"
                                                                                            >
                                                                                                <path
                                                                                                    clipRule="evenodd"
                                                                                                    fillRule="evenodd"
                                                                                                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                                                                                />
                                                                                            </svg>
                                                                                            Actions
                                                                                        </button>
                                                                                    </div>
                                                                                    {isOpen && (
                                                                                        <div
                                                                                            className="origin-top-right absolute right-0 mt-2 w-44 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-gray-700 dark:ring-gray-600 z-1000"
                                                                                            role="menu"
                                                                                            aria-orientation="vertical"
                                                                                            aria-labelledby="actionsDropdownButton"
                                                                                        >
                                                                                            {exchange_profile?.roles?.includes("administrator") && entityProfiles && page_data?.verified === 1 && page_data?.active === 0 && page_data?.status !== "active" ?
                                                                                                <div className="py-1" role="none">
                                                                                                    <button
                                                                                                        onClick={() => setModal({ membershipTypeModal: true })}
                                                                                                        type='submit'

                                                                                                        className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
                                                                                                        role="menuitem"
                                                                                                    >
                                                                                                        Update Membership
                                                                                                    </button>
                                                                                                </div>
                                                                                                :
                                                                                                null}
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            </>
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                            <dl className="grid grid-cols-2 gap-4 mb-4">
                                                                <div className="col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600">
                                                                    <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Official Registration Name</dt>
                                                                    <dd className="flex items-center text-gray-500 dark:text-gray-400">

                                                                        {page_data?.entity_name}
                                                                    </dd>
                                                                </div>
                                                                <div className="p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 dark:border-gray-600"><dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Initial Membership Type</dt><dd className="text-gray-500 dark:text-gray-400">{page_data?.entity_category ? page_data?.entity_category?.replace(/_/g, ' ').toUpperCase() : "---"}</dd></div>
                                                                <div className="col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600">
                                                                    <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Physical Address</dt><dd className="flex items-center text-gray-500 dark:text-gray-400">
                                                                        {page_data?.physical_address ? <>{page_data?.physical_address}, {page_data?.province}, {page_data?.country}</> : "---"}
                                                                    </dd>
                                                                </div>
                                                                <div className="p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 dark:border-gray-600"><dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">PACRA Registration No.</dt><dd className="flex items-center text-gray-500 dark:text-gray-400">{page_data?.entity_reg_no || "---"}</dd></div>
                                                                <div className="p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 dark:border-gray-600"><dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">ZRA TPIN Number</dt><dd className="text-gray-500 dark:text-gray-400">{page_data?.entity_tax_no || "---"}</dd></div>
                                                                <div className="p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 dark:border-gray-600"><dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Organzation Type</dt><dd className="text-gray-500 dark:text-gray-400">{page_data?.entity_type ? page_data?.entity_type?.replace(/_/g, ' ').toUpperCase() : "---"}</dd></div>
                                                                <div className="p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 dark:border-gray-600"><dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Contact Email</dt><dd className="text-gray-500 dark:text-gray-400">{page_data?.entity_email || "---"}</dd></div>
                                                                <div className="p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 dark:border-gray-600"><dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Contact Telephone No.</dt><dd className="text-gray-500 dark:text-gray-400">{page_data?.entity_phone || "---"}</dd></div>

                                                                <div className="col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600">
                                                                    <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Membership Status</dt>
                                                                    <dd className="flex items-center text-gray-500 dark:text-gray-400">
                                                                        <span className='bg-yellow-100 text-yellow-800'>{page_data?.status ? page_data.status.toUpperCase() : "------"}</span>
                                                                    </dd>
                                                                </div>
                                                            </dl>
                                                            <div className="mt-4">
                                                                <h5 className="text-xl font-bold dark:text-white">Membership Types</h5>
                                                            </div>
                                                            <br />
                                                            <TableComp tableData={entityProfiles} tableType={app_consts.ENTITY_PROFILES_LIST_EXCHANGE_VIEW} tableHeading={"Membership Types"} />
                                                        </div>
                                                        <MembershipTypeModal
                                                            showModal={modal.membershipTypeModal}
                                                            closeModal={closeMembershipTypeModal}
                                                            openSelectedModal={openSelectedModal}
                                                            main_access_account={main_access_account}
                                                            successFunction={successFunction}
                                                            current_membersip_type={page_data?.entity_category}
                                                        />

                                                        <ActivateMembershipModal
                                                            showModal={activateModal.activateMmbershipModal}
                                                            closeModal={closeActivateMembershipModal}
                                                            openSelectedModal={openSelectedACtivateModal}
                                                            main_access_account={main_access_account}
                                                            tradingAction={handleBrokerAuthorityActions}
                                                            successFunction={successFunction}
                                                            user_profiles={user_profiles}
                                                        />

                                                        <YesNoModal
                                                            isOpen={yesorNoModalOpen}
                                                            closeFunction={closeYesOrNoModal}
                                                            modalTitle="Confirmation"
                                                            modalMessage={`Do you want to Activate and set Brokership Type?`}
                                                            cancelButtonLabel="No"
                                                            agreeButtonLabel="Yes"
                                                            agreeClickFunction={confirmAction}
                                                        />

                                                        <YesNoModal
                                                            isOpen={verifyModal}
                                                            closeFunction={closeYesOrNoModal}
                                                            modalTitle="Verify Entity"
                                                            modalMessage={`Are you Sure that you want to Verify this Entity?`}
                                                            cancelButtonLabel="No"
                                                            agreeButtonLabel="Yes"
                                                            agreeClickFunction={handleVerifyMembership}
                                                            modalLoading={verify_loading}
                                                            modalError={verify_error}
                                                        />

                                                        <TradingAuthorityModal
                                                            showModal={tradingModal.tradingAuthorityModalModal}
                                                            closeModal={closeTradingAuthorityModal}
                                                            openSelectedModal={openTradingAuthorityModal}
                                                            successFunction={successFunction}
                                                            entityprofile={entityProfiles}
                                                            main_access_account={main_access_account}
                                                        />
                                                    </div>

                                                }
                                                {activeTab === 'Registration Documents' && (
                                                    <div>
                                                        <div className='mb-6'>
                                                            <h5 class="text-xl mr-3 font-semibold dark:text-white">{page_data.entity_name} | Registration Document Uploads</h5>
                                                        </div>

                                                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                                                            <DocumentViewer
                                                                name="Registration Certificate"
                                                                url={page_data?.entity_reg_cert_url || ""}
                                                                onEditClick={() => openUploadModal('Registration Certificate')}
                                                            />
                                                            <DocumentViewer
                                                                name="Tax Certificate"
                                                                url={page_data?.entity_tax_cert_url || ""}
                                                                onEditClick={() => openUploadModal('Tax Certificate')}
                                                            />
                                                            <DocumentViewer
                                                                name="Board Resolution"
                                                                url={page_data?.board_resolution_url || ""}
                                                                onEditClick={() => openUploadModal('Board Resolution')}
                                                            />
                                                            <DocumentViewer
                                                                name="Profile Document"
                                                                url={page_data?.entity_profile_doc_url || ""}
                                                                onEditClick={() => openUploadModal('Profile Document')}
                                                            />
                                                            <DocumentViewer
                                                                name="Pacra Printout"
                                                                url={page_data?.entity_pacra_printout_url || ""}
                                                                onEditClick={() => openUploadModal('Pacra Printout')}
                                                            />

                                                        </div>

                                                    </div>
                                                )}
                                                {activeTab === 'Management Details' &&
                                                    <div>
                                                        <div className="flex items-center justify-between">
                                                            <h5 className="text-xl font-bold dark:text-white">
                                                                {page_data.entity_name} | Directors & Management
                                                            </h5>
                                                            {page_data && page_data && page_data?.entity_name ? (
                                                                <button
                                                                    type="button"
                                                                    onClick={openManagentModal}
                                                                    className="flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
                                                                >
                                                                    Add Management
                                                                </button>
                                                            ) : null}
                                                        </div>
                                                        {
                                                            directorList && directorList?.length > 0 ?
                                                                <>
                                                                    <div className="flex flex-wrap p-8">
                                                                        {directorList?.map((director) => (
                                                                            <div key={director?.id} className="w-full md:w-1/2 lg:w-1/3 p-4">
                                                                                <ProfileCard data={director} />
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </>
                                                                :
                                                                <>
                                                                    <div className="text-center mt-8 h-10 mb-16">
                                                                        <div role="status" className='flex flex-col items-center justify-center'>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" className='text-redd-700' viewBox="0 0 24 24" stroke-width="1.5" stroke="gray" class="w-10 h-10">
                                                                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
                                                                            </svg>
                                                                            <span className="my-2 text-gray-600 font-meduim">No Directors have been added to Entity.</span>
                                                                            <button
                                                                                type="button"
                                                                                onClick={openManagentModal}
                                                                                className="flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
                                                                            >
                                                                                Add Management
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                        }


                                                    </div>
                                                }
                                                {activeTab === 'Financial Details' &&

                                                    <div>
                                                        <div className="flex items-center justify-between">
                                                            <h5 className="text-xl font-bold dark:text-white">
                                                                {page_data.entity_name} | Financial Details
                                                            </h5>
                                                            {page_data && page_data ? (
                                                                <button
                                                                    type="button"
                                                                    onClick={opeBankModal}
                                                                    className="flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
                                                                >
                                                                    Add Financial Details
                                                                </button>
                                                            ) : null}
                                                        </div>
                                                        <CustomTabs tabs={financical_details_tabs} activeTab={financialActiveTab} setActiveTab={setFinancialActiveTab} />
                                                        <br />
                                                        {
                                                            financialActiveTab === "Bank Accounts" && (
                                                                <>
                                                                    <TableComp tableData={bank_accounts} tableType={app_consts.BANK_ACCOUNTS} tableHeading={"Bank Accounts"} />
                                                                </>
                                                            )
                                                        }
                                                        {
                                                            financialActiveTab === "Financial Documents" && (
                                                                <>
                                                                    {
                                                                        bankDetails ? (
                                                                            <>
                                                                                <div className='grid grid-cols-1 md:grid-cols-2 gap-4 mb-4'>
                                                                                    <AdvancedDocumentViewer
                                                                                        name="Financial Audit"
                                                                                        uploadButton={true}
                                                                                        url={bankDetails?.financial_audit_doc}
                                                                                        noDocumentMessage='Financial Audit Document has not Been Uploaded.'
                                                                                        onEditClick={() => openUploadModal('Financial Audit')}
                                                                                    />
                                                                                    <AdvancedDocumentViewer
                                                                                        name='Current Management Accounts'
                                                                                        uploadButton={true}
                                                                                        noDocumentMessage='Current Management Accounts Document has not Been Uploaded.'
                                                                                        url={bankDetails?.current_management_accounts_doc}
                                                                                        onEditClick={() => openUploadModal('Current Management Accounts')}
                                                                                    />
                                                                                </div>
                                                                            </>
                                                                        )
                                                                            :
                                                                            <>
                                                                                <div className="text-center mt-8 h-10 mb-16">
                                                                                    <div role="status" className='flex flex-col items-center justify-center'>
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" className='text-redd-700' viewBox="0 0 24 24" stroke-width="1.5" stroke="gray" class="w-10 h-10">
                                                                                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
                                                                                        </svg>
                                                                                        <span className="mb-4 my-2 text-gray-600 font-meduim">Financial Documents can be viewed after adding Financial Details.</span>
                                                                                        <button
                                                                                            type="button"
                                                                                            onClick={opeBankModal}
                                                                                            className="flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
                                                                                        >
                                                                                            Add Financial Details
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                    }
                                                                </>
                                                            )
                                                        }

                                                    </div>

                                                }
                                                {activeTab === 'User Profiles' &&

                                                    <div>
                                                        <div className="flex items-center justify-between">
                                                            <h5 className="text-xl font-bold dark:text-white">
                                                                {page_data.entity_name} | User Profiles
                                                            </h5>
                                                        </div>
                                                        <br />
                                                        <dl className="grid grid-cols-2 gap-4 mb-4">
                                                            <div className="col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600">
                                                                <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Main Access Account User Name</dt>
                                                                <dd className="flex items-center text-gray-500 dark:text-gray-400">
                                                                    {main_access_account?.user_first_name ? `${main_access_account?.user_first_name} ${main_access_account?.user_last_name}` : "--"}
                                                                </dd>
                                                            </div>
                                                            <div className="col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600">
                                                                <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Main Access Account Mobile</dt>
                                                                <dd className="flex items-center text-gray-500 dark:text-gray-400">
                                                                    {main_access_account?.account_mobile ? main_access_account?.account_mobile : "--"}
                                                                </dd>
                                                            </div>
                                                        </dl>
                                                        {
                                                            <TableComp tableData={user_profiles} tableType={app_consts.USER_PROFILES_TABLE} tableHeading={"User Profiles"} />
                                                        }
                                                    </div>

                                                }
                                                {activeTab === 'Transactions' && <div>Transactions Content</div>}
                                            </>
                                    }
                                </>
                        }
                    </div>
                </div>
            </div>

            {/* Upload Modal */}
            {isModalUploadOpen && (
                <div className="fixed inset-4 flex items-center justify-center z-50 overflow-y-auto">
                    <div className="fixed inset-0 bg-black opacity-50"></div>
                    {/* Modal content goes here */}
                    <div className="relative p-8 bg-white rounded-lg shadow dark:bg-gray-800 max-h-screen overflow-y-auto w-full max-w-screen-lg">
                        {/* Add your modal content here */}
                        <div class="flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5 dark:border-gray-600">
                            <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                                Upload {documentName}
                            </h3>
                            <button type="button" onClick={closeUploadModal} class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="updateProductModal">
                                <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                <span class="sr-only">Close modal</span>
                            </button>
                        </div>
                        <div class="flex items-center justify-center w-full">
                            <label for="dropzone-file" class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                                {filePreview ? (
                                    selectedFile.type.startsWith('image/') ? (
                                        <img src={filePreview} alt="File Preview" className="max-h-full max-w-full" />
                                    ) : (
                                        <object
                                            data={filePreview}
                                            type="application/pdf"
                                            width="100%"
                                            height="100%"
                                            aria-label="File Preview"
                                        />
                                    )
                                ) : (

                                    <div>
                                        <div class="flex flex-col items-center justify-center pt-5 pb-6">
                                            <svg class="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                                            </svg>
                                            <p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold">Click to upload</span> or drag and drop</p>
                                            <p class="text-xs text-gray-500 dark:text-gray-400">PNG, JPG or PDF</p>
                                        </div>
                                        <input
                                            id="dropzone-file"
                                            type="file"
                                            accept=".png, .jpg, .pdf, .jpeg"
                                            className="hidden"
                                            onChange={handleFileChange}
                                        />
                                    </div>
                                )}

                            </label>
                        </div>
                        <br />
                        {upload_error && upload_error.length > 0 && (
                            <div className="p-4 my-2 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
                                <span className="font-medium">Error: </span>
                                {upload_error}
                            </div>
                        )}
                        <div className="flex justify-between space-x-4">
                            {/* Close button */}
                            <button
                                type="button"
                                onClick={closeUploadModal}
                                className="text-gray-900 bg-white hover:bg-gray-100 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 mr-2 mb-2"
                            >
                                Close Modal
                            </button>

                            <button
                                type="submit"
                                disabled={upload_loading}
                                onClick={() => uploadDocument(documentName)}
                                className="text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-primary-800 mr-2 mb-2"
                            >
                                {upload_loading ? "Uploading Document..." : "Upload Document"}
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {/* Add Directores & Management Modal */}
            {isManagentModalOpen && (
                <div className="fixed inset-4 flex items-center justify-center z-50 overflow-y-auto">
                    <div className="fixed inset-0 bg-black opacity-50"></div>
                    {/* Modal content goes here */}
                    <div className="relative p-8 bg-white rounded-lg shadow dark:bg-gray-800 max-h-screen overflow-y-auto w-full max-w-6xl">
                        {/* Add your modal content here */}
                        <br />
                        <p className="text-xl font-semibold text-green-600/100 dark:text-green-500/100">Details of {page_data?.entity_name}'s Directors & Executive</p>
                        {/* <br /> */}
                        <div className="flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5 dark:border-gray-600">
                            <p className="tracking-tighter text-gray-500 md:text-lg dark:text-gray-400">Please provide personal details of {page_data?.entity_name}'s Directors and Executives as listed on {page_data?.entity_name} official incorporation documentation.</p>

                            <button
                                type="button"
                                onClick={closeManagentModal}
                                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                data-modal-toggle="updateProductModal"
                            >
                                <svg
                                    aria-hidden="true"
                                    className="w-5 h-5"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                        clipRule="evenodd"
                                    ></path>
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>
                        <AddEntityManagementInformationModal main_access_account={main_access_account} directors={directorList} successFunction={successFunction} closeModal={setIsManagentModalOpen} />
                        <br />
                    </div>
                </div>

            )}
            {/* Add Bank Details Modal */}
            {isBankModalOpen && (
                <div className="fixed inset-4 flex items-center justify-center z-50 overflow-y-auto">
                    <div className="fixed inset-0 bg-black opacity-50"></div>
                    {/* Modal content goes here */}
                    <div className="relative p-8 bg-white rounded-lg shadow dark:bg-gray-800 max-h-screen overflow-y-auto w-full max-w-6xl">
                        {/* Add your modal content here */}
                        <br />
                        <p className="text-xl font-semibold text-green-600/100 dark:text-green-500/100">Details of {page_data?.entity_name}'s Bank Account</p>
                        <div className="flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5 dark:border-gray-600">
                            <p class="tracking-tighter text-gray-500 md:text-lg dark:text-gray-400">Provide details of the primary bank account {page_data?.entity_name} will use to transaction with ZAMACE.</p>


                            <button
                                type="button"
                                onClick={closeBankModal}
                                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                data-modal-toggle="updateProductModal"
                            >
                                <svg
                                    aria-hidden="true"
                                    className="w-5 h-5"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                        clipRule="evenodd"
                                    ></path>
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>
                        <AddBankAccountDetailsModal main_access_account={main_access_account} successFunction={successFunction} closeModal={setIsBankModalOpen} />
                    </div>
                </div>

            )}
        </div >
    );
};

export default EntityAccountDetails;



