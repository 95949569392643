import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { BuildingOfficeIcon, InformationCircleIcon } from '@heroicons/react/24/outline';
import 'react-phone-input-2/lib/style.css';
import NavHeader from '../../layouts/NavHeader';
import Footer from '../../layouts/Footer';
import { getCurrentAccount, uploadMemberPacraPrintoutDocument, uploadRegistrationDocument } from '../../utils/actions';


const UploadPacraPrintout = ({ entity }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileSizePreviewable, setFileSizePreviewable] = useState(false);

    const account = JSON.parse(localStorage.getItem('account'));
    const profile = account.profiles.find((profile) => profile.account_id === account.id);

    const profileName = profile.name;
    const profileType = profile.type;
    const profileStatus = profile.status;
    const profileEntityId = profile.entity_id;


    const [filePreview, setFilePreview] = useState(null);
    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile && (selectedFile.type.startsWith('image/') || selectedFile.type === 'application/pdf'))
        {
            // Check if File Size is Greater than 20mbs
            if (selectedFile.size > 20 * 1024 * 1024)
            {
                setErrors({ file: "File Size too Large. File Limit: 20mbs" });
            }
            else
            {
                setFileSizePreviewable(!(selectedFile.size > 1024 * 1024));
                setErrors({});
                setSelectedFile(selectedFile);

                const reader = new FileReader();
                reader.onload = (event) => {
                    setFilePreview(event.target.result);
                };
                reader.readAsDataURL(selectedFile);
            }
        } else
        {
            setSelectedFile(null);
            setFilePreview(null);
            setErrors({ file: "Please select a valid image or PDF file (PNG, JPEG, or PDF)." });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!selectedFile)
        {
            setErrors({ file: "Please select a file to upload." });
            return;
        }

        try
        {
            setLoading(true);
            await dispatch(uploadMemberPacraPrintoutDocument(profileEntityId, selectedFile));
            await dispatch(getCurrentAccount());
            setLoading(false);
            navigate('/registration_tax_certificate');
        } catch (error)
        {
            if (error.response && error.response.data && error.response.data.errors)
            {
                setErrors(error.response.data.errors);
            } else
            {
                console.error(error);
            }

            setLoading(false);
        }
    };

    return (
        <>
            <NavHeader />
            <section className="flex h-screen ">
                <div className="w-full lg:px-9">
                    <div className="relative overflow-hidden bg-white dark:bg-gray-800 sm:rounded-lg">
                        <div className="grid w-full place-items-center bg-white p-6 sm:p-16 lg:p-8">
                            <ol className="items-center w-full space-y-4 sm:flex sm:space-x-8 sm:space-y-0">
                                <li className="flex items-center text-gray-600 dark:text-gray-500 space-x-2.5">
                                    <span className="flex items-center justify-center w-8 h-8 border border-gray-600 rounded-full shrink-0 dark:border-gray-500">
                                        2
                                    </span>
                                    <span>
                                        <h3 className="font-medium leading-tight">Verify your registration</h3>
                                        <p className="text-sm">Use OTP for verification</p>
                                    </span>
                                </li>
                                <li className="flex items-center text-gray-500 dark:text-gray-400 space-x-2.5">
                                    <span className="flex items-center justify-center w-8 h-8 border border-gray-500 rounded-full shrink-0 dark:border-gray-400">
                                        3
                                    </span>
                                    <span>
                                        <h3 className="font-medium leading-tight">Membership Type</h3>
                                        <p className="text-sm">Set membership type</p>
                                    </span>
                                </li>
                                <li className="flex items-center text-green-600 dark:text-green-500 space-x-2.5">
                                    <span className="flex items-center justify-center w-8 h-8 border border-green-600 rounded-full shrink-0 dark:border-green-500">
                                        4
                                    </span>
                                    <span>
                                        <h3 className="font-medium leading-tight">Organization Information</h3>
                                        <p className="text-sm">Provide organization details</p>
                                    </span>
                                </li>
                                <li className="flex items-center text-gray-600 dark:text-gray-500 space-x-2.5">
                                    <span className="flex items-center justify-center w-8 h-8 border border-gray-600 rounded-full shrink-0 dark:border-gray-500">
                                        5
                                    </span>
                                    <span>
                                        <h3 className="font-medium leading-tight">{profileType == 'cooperative' ? 'Executive Members' : 'Directors & Management'}</h3>
                                        <p className="text-sm">Provide KYC information</p>
                                    </span>
                                </li>
                                <li className="flex items-center text-gray-600 dark:text-gray-500 space-x-2.5">
                                    <span className="flex items-center justify-center w-8 h-8 border border-gray-600 rounded-full shrink-0 dark:border-gray-500">
                                        6
                                    </span>
                                    <span>
                                        <h3 className="font-medium leading-tight">Add Financial Details</h3>
                                        <p className="text-sm">Provide Financial Details</p>
                                    </span>
                                </li>
                                <li className="flex items-center text-gray-600 dark:text-gray-500 space-x-2.5">
                                    <span className="flex items-center justify-center w-8 h-8 border border-gray-600 rounded-full shrink-0 dark:border-gray-500">
                                        7
                                    </span>
                                    <span>
                                        <h3 className="font-medium leading-tight">Submit Registration</h3>
                                        <p className="text-sm">Submit registration for verification</p>
                                    </span>
                                </li>
                            </ol>

                            <form onSubmit={handleSubmit}>
                                <br />

                                <div className="flex flex-col items-stretch justify-start border-t border-gray-100">
                                    <h3 className="text-2xl font-bold leading-7 text-green-800 sm:truncate sm:text-2xl sm:tracking-tight">
                                        Registrant Name: {profileName}
                                    </h3>
                                    <br />
                                    <div className="flex flex-row space-x-6">
                                        <div className="flex items-center text-sm text-green-800">
                                            <BuildingOfficeIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-green-800" aria-hidden="true" />
                                            <span className="font-bold uppercase">{profileType}</span>
                                        </div>
                                        <div className="flex items-center text-sm text-red-800">
                                            <InformationCircleIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-red-800" aria-hidden="true" />
                                            <span className="font-bold uppercase">{profileStatus}</span>
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <ol class="flex items-center w-full text-sm font-medium text-center text-gray-500 dark:text-gray-400 sm:text-base">
                                    <li class="flex md:w-full items-center after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-200 after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10 dark:after:border-gray-700">
                                        <span class="flex items-center after:content-['/'] sm:after:hidden after:mx-2 after:text-gray-200 dark:after:text-gray-500">
                                            <span class="mr-2">1</span>
                                            Contact <span class="hidden sm:inline-flex sm:ml-2">Info</span>
                                        </span>
                                    </li>
                                    <li class="flex md:w-full items-center after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-200 after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10 dark:after:border-gray-700">
                                        <span class="flex items-center after:content-['/'] sm:after:hidden after:mx-2 after:text-gray-200 dark:after:text-gray-500">
                                            <span class="mr-2">2</span>
                                            Registration <span class="hidden sm:inline-flex sm:ml-2">Certificate</span>
                                        </span>
                                    </li>
                                    <li class="flex md:w-full items-center text-green-600 dark:text-green-500 sm:after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-200 after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10 dark:after:border-gray-700">
                                        <span class="flex items-center after:content-['/'] sm:after:hidden after:mx-2 after:text-gray-200 dark:after:text-gray-500">
                                            <svg class="w-3.5 h-3.5 sm:w-4 sm:h-4 mr-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                            </svg>
                                            Registration <span class="hidden sm:inline-flex sm:ml-2">Printout</span>
                                        </span>
                                    </li>
                                    <li class="flex md:w-full items-center after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-200 after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10 dark:after:border-gray-700">
                                        <span class="flex items-center after:content-['/'] sm:after:hidden after:mx-2 after:text-gray-200 dark:after:text-gray-500">
                                            <span class="mr-2">4</span>
                                            Tax <span class="hidden sm:inline-flex sm:ml-2">Certificate</span>
                                        </span>
                                    </li>
                                    <li class="flex md:w-full items-center after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-200 after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10 dark:after:border-gray-700">
                                        <span class="flex items-center after:content-['/'] sm:after:hidden after:mx-2 after:text-gray-200 dark:after:text-gray-500">
                                            <span class="mr-2">5</span>
                                            Company <span class="hidden sm:inline-flex sm:ml-2">Profile</span>
                                        </span>
                                    </li>
                                    <li class="flex items-center">
                                        <span class="mr-2">6</span>
                                        Board <span class="hidden sm:inline-flex sm:ml-2">Resolution</span>
                                    </li>
                                </ol>
                                <br />
                                <p class="text-xl font-semibold text-green-600/100 dark:text-green-500/100">Upload the official registration Printout for {profileName}.</p>
                                <p class="tracking-tighter text-gray-500 md:text-lg dark:text-gray-400">A company or organization registration printout is a deatiled legal document issued by a government authority or relevant regulatory body to confirm the existence and legitimacy of the organization or business entity. If you are domiciled in Zambia upload your PACRA Printout of Registration.</p>
                                <br />

                                <div class="flex items-center justify-center w-full">
                                    <label for="dropzone-file" class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                                        {
                                            filePreview ? (
                                                <>
                                                    {
                                                        fileSizePreviewable ?
                                                            (
                                                                selectedFile.type.startsWith('image/') ? (
                                                                    <img src={filePreview} alt="File Preview" className="max-h-full max-w-full" />
                                                                ) : (
                                                                    <object
                                                                        data={filePreview}
                                                                        type="application/pdf"
                                                                        width="100%"
                                                                        height="100%"
                                                                        aria-label="File Preview"
                                                                    />
                                                                )
                                                            )
                                                            :
                                                            <div>
                                                                <div class="flex flex-col items-center justify-center pt-5 pb-6">
                                                                    <p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold">Cannot Preview Documents Larger than 1mbs</span></p>
                                                                    <p class="text-xs text-gray-500 dark:text-gray-400">
                                                                        <p class="text-xs text-gray-500 dark:text-gray-400">Select <span class="font-semibold">'Upload {profileName}'s' Registration Printout'</span> to Continue.</p></p>
                                                                </div>
                                                            </div>
                                                    }
                                                </>
                                            )
                                                : (
                                                    // Render the upload label
                                                    <div>
                                                        <div class="flex flex-col items-center justify-center pt-5 pb-6">
                                                            <svg class="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                                                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                                                            </svg>
                                                            <p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold">Click to upload</span> or drag and drop</p>
                                                            <p class="text-xs text-gray-500 dark:text-gray-400">SVG, PNG, JPG or PDF (MAX. 800x400px)</p>
                                                        </div>
                                                        <input
                                                            id="dropzone-file"
                                                            type="file"
                                                            accept=".png, .jpg, .pdf, .jpeg"
                                                            className="hidden"
                                                            onChange={handleFileChange}
                                                        />
                                                    </div>
                                                )}
                                    </label>
                                </div>

                                <br />

                                <button
                                    type="submit"
                                    className="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
                                >
                                    Upload {profileName}'s' Registration Printout
                                </button>
                                <br />
                                {Object.entries(errors).map(([field, messages]) => (
                                    <div
                                        key={field}
                                        className="p-4 mt-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
                                        role="alert"
                                    >
                                        {Array.isArray(messages) ? (
                                            messages.map((message, idx) => (
                                                <p className="font-medium" key={idx}>
                                                    {message}
                                                </p>
                                            ))
                                        ) : (
                                            <p className="font-medium">
                                                {messages}
                                            </p>
                                        )}
                                    </div>
                                ))}
                                {loading && (
                                    <div className="text-center">
                                        <div role="status">
                                            <svg
                                                aria-hidden="true"
                                                className="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-green-600"
                                                viewBox="0 0 100 101"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                    fill="currentColor"
                                                />
                                                <path
                                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                    fill="currentFill"
                                                />
                                            </svg>
                                            <span className="sr-only">Please wait...</span>
                                        </div>
                                    </div>
                                )}
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default UploadPacraPrintout;