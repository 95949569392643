import React, { useEffect, useState } from 'react';
import * as app_consts from '../../utils/constants';

const MultiDocumentUploadModal = ({ modalOpen, setModalOpen, acceptedFileTypes, upload_message, upload_messag_helper, image_loading, uploadImageFunc, images, previewImages, setImages, setPreviewImages, imageUploadCount, setImageUploadCount, loadingFin }) => {

    const [isOpen, setIsOpen] = useState(modalOpen);
    const [fileTypes, setFileTypes] = useState(acceptedFileTypes);
    const [error, setError] = useState("");
    const [filePreview, setFilePreview] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [viewImage, setViewImage] = useState({ index: "", caption: "", image: "" });
    const [loading, setLoading] = useState(image_loading);
    const [uploadCount, setUploadCount] = useState(imageUploadCount);
    const [finLoad, setFinLoad] = useState(false);

    useEffect(() => {
        if (images?.length > 0 && previewImages?.length > 0)
        {
            setSelectedFiles(images);
            setFilePreview(previewImages);
        }
    }, [images, previewImages]);


    useEffect(() => {
        setFinLoad(loadingFin);
    }, [loadingFin]);

    // UseEffect
    useEffect(() => {
        setUploadCount(imageUploadCount);
    }, [imageUploadCount]);

    // UseEffect
    useEffect(() => {
        setIsOpen(modalOpen);
        setFileTypes(acceptedFileTypes);
        if (modalOpen && !previewImages)
        {
            setFilePreview([]);
            setSelectedFiles([]);
            setError("");
        }
    }, [modalOpen, acceptedFileTypes]);

    useEffect(() => {
        setLoading(image_loading);
    }, [image_loading]);

    // Close Modal
    const closeModal = () => {
        setViewImage([]);
        setSelectedFiles([]);
        setFilePreview([]);
        setFinLoad(false);
        setImageUploadCount(0);
        setModalOpen(false);
        setError("");
        setModalOpen(false);
    };

    const handleFileChange = (e) => {
        const files = e.target.files;
        const allowedTypes = fileTypes;
        let selectedFilesArray = [];
        let error = "";

        for (let i = 0; i < files.length; i++)
        {
            const file = files[i];
            if (allowedTypes.includes(file.type))
            {
                const fileObject = {
                    index: selectedFilesArray.length,
                    caption: "",
                    image: file,
                    status: app_consts.IDLE
                };
                selectedFilesArray.push(fileObject);

                const reader = new FileReader();
                reader.onload = (event) => {
                    setFilePreview(prevPreview => [
                        ...prevPreview,
                        { index: fileObject.index, caption: fileObject.caption, image: event.target.result, status: app_consts.IDLE }
                    ]);
                    if (i === 0)
                    {
                        setViewImage({ index: fileObject.index, caption: fileObject.caption, image: event.target.result });
                    }
                };
                reader.readAsDataURL(file);
            } else
            {
                error += `File ${file.name} is not a valid image. `;
            }
        }

        if (error)
        {
            setError("Files that Are Not of the Accepted Type have been Excluded from the Entries.");
        } else
        {
            setError("");
        }
        setSelectedFiles(selectedFilesArray);
    };

    const handleCaptionChange = (index, caption) => {
        let updatedArray1 = selectedFiles?.map(obj =>
            obj.index === index ? { ...obj, caption: caption } : obj
        );
        let updatedArray2 = filePreview?.map(obj =>
            obj.index === index ? { ...obj, caption: caption } : obj
        );
        setViewImage({ ...viewImage, ["caption"]: caption });
        setSelectedFiles(updatedArray1);
        setFilePreview(updatedArray2);
    };

    const handleUpload = () => {
        if (finLoad) 
        {
            setViewImage([]);
            setSelectedFiles([]);
            setFilePreview([]);
            setFinLoad(false);
            setImageUploadCount(0);
            setModalOpen(false);
            setError("");
        }
        else
        {
            setError("");
            if (selectedFiles?.length === 0)
            {
                setError("No Images Have Been Selected");
            }
            else
            {
                // Check If All Captions Have Something in Them
                const checkCaptions = selectedFiles?.map(obj => obj.caption === "" ? "error" : "ok");

                if (checkCaptions.includes("error"))
                {
                    setError("Please Make Sure that Each Image has a Caption Attached to it.");
                }
                else
                {
                    uploadImageFunc(selectedFiles, filePreview);
                }
            }
        }
    };

    // Remove from Array
    const delete_image = (index) => {
        let updatedArray1 = selectedFiles?.filter(obj => { return obj.index !== index; }
        );
        let updatedArray2 = filePreview?.filter(obj => { return obj.index !== index; }
        );

        // Update Indexes
        for (let i = 0; i < updatedArray1.length; i++)
        {
            updatedArray1[i].index = i;
        }

        for (let i = 0; i < updatedArray2.length; i++)
        {
            updatedArray2[i].index = i;
        }

        setSelectedFiles(updatedArray1);
        setFilePreview(updatedArray2);
        setImages([]);
        setPreviewImages([]);
        if (updatedArray2?.length > 0)
        {
            setViewImage(updatedArray2[0]);
        }
    };

    return (
        <>
            {
                isOpen && (
                    <div className="fixed inset-4 flex items-center justify-center z-50 overflow-y-auto">
                        <div className="fixed inset-0 bg-black opacity-50"></div>
                        {/* Modal Goes Here */}

                        <div className="relative bg-white rounded-lg shadow dark:bg-gray-800 overflow-y-auto w-full  max-h-screen overflow-y-auto w-full max-w-screen-lg">
                            <>
                                <article aria-label="File Upload Modal" class="relative h-full flex flex-col bg-white shadow-xl rounded-md" ondrop="dropHandler(event);" ondragover="dragOverHandler(event);" ondragleave="dragLeaveHandler(event);" ondragenter="dragEnterHandler(event);">



                                    <div class="h-full overflow-auto p-8 w-full h-full flex flex-col">
                                        {
                                            filePreview?.length === 0 ?
                                                <>
                                                    <label for="dropzone-file" class="border-dashed border-2 border-gray-400 py-12 flex flex-col justify-center items-center cursor-pointer">
                                                        <p class="mb-3 font-semibold text-gray-900 flex flex-col items-center flex-wrap justify-center">
                                                            <span className="mb-2">{upload_message}</span>
                                                            <span className='text-gray-600 mt-1 text-sm'>{upload_messag_helper}</span>
                                                        </p>
                                                        <div class="mt-2 rounded-sm px-3 py-1 bg-gray-200 hover:bg-gray-300 focus:shadow-outline focus:outline-none">
                                                            Upload a file

                                                        </div>
                                                        <input
                                                            id="dropzone-file"
                                                            type="file"
                                                            className="hidden"
                                                            multiple
                                                            onChange={handleFileChange}
                                                        />
                                                    </label>
                                                </>
                                                :
                                                <>
                                                    {
                                                        !loading && (
                                                            <>
                                                                {
                                                                    !finLoad && (
                                                                        <>
                                                                            <div className='flex flex-col items-center'>
                                                                                <div class="border-dashed border-2 border-gray-400 flex flex-col justify-center items-center h-80 lg:w-7/12 w-full">
                                                                                    {
                                                                                        viewImage && (
                                                                                            <>
                                                                                                <img src={viewImage.image} alt="File Preview" className="max-h-full max-w-full" />
                                                                                            </>
                                                                                        )
                                                                                    }
                                                                                </div>
                                                                                <div className='flex items-start'>
                                                                                    <div className='my-4 w-6/12'>
                                                                                        <label
                                                                                            htmlFor='image_caption'
                                                                                            className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                                                                                        >
                                                                                            Image Caption
                                                                                        </label>
                                                                                        <input
                                                                                            type='text'
                                                                                            name='image_caption'
                                                                                            value={viewImage.caption}
                                                                                            onChange={(e) => handleCaptionChange(viewImage.index, e.target.value)}
                                                                                            className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500'
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    )
                                                                }
                                                            </>
                                                        )
                                                    }
                                                </>
                                        }
                                        {error && error.length > 0 && (
                                            <div className="p-4 my-2 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
                                                <span className="font-medium">Error: </span>
                                                {error}
                                            </div>
                                        )}
                                        <div>
                                            <h1 class="pt-4 pb-3 font-semibold sm:text-lg text-gray-900">
                                                To Upload
                                            </h1>
                                            <div className='grid grid-cols-4 gap-2 md:grid-cols-4'>
                                                {
                                                    filePreview && filePreview?.map((preview) => {
                                                        return (
                                                            <>
                                                                <div id="file-template">
                                                                    <li class="block p-1 w-full sm:w-full md:w-full lg:w-full xl:w-full h-24 mx-2">
                                                                        <article tabindex="0" class={`"border-rounded ${preview?.status === app_consts.FAIL ? "border-red-400" : preview?.status === app_consts.SUCCESS ? "border-green-400" : ""} border-2 group w-full h-full rounded-md focus:outline-none focus:shadow-outline elative bg-gray-100 cursor-pointer relative shadow-sm"`}>
                                                                            <img onClick={() => setViewImage(preview)} src={preview.image} class="img-preview  w-full h-full sticky object-cover rounded-md bg-fixed" />

                                                                            <section class="flex flex-col rounded-md text-xs break-words w-full h-full z-20 absolute top-0 py-2">
                                                                                <div onClick={() => setViewImage(preview)} className=' top-0 left-0 z-100 flex flex-row hover:text-white w-full h-full hover:bg-black opacity-80 '>
                                                                                    <h1 class="text-xl">{preview.caption}</h1>
                                                                                </div>
                                                                                <div class="flex">
                                                                                    <span class="p-1 text-blue-800">
                                                                                        <i>
                                                                                            {/* <svg class="fill-current w-4 h-4 ml-auto pt-1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                                                            <path d="M15 2v5h5v15h-16v-20h11zm1-2h-14v24h20v-18l-6-6z" />
                                                                                        </svg> */}
                                                                                        </i>
                                                                                    </span>
                                                                                    {
                                                                                        !loading && !finLoad && (
                                                                                            <>
                                                                                                <button onClick={() => delete_image(preview?.index)} class="delete ml-auto focus:outline-none hover:bg-gray-300 p-1 rounded-md text-gray-800">
                                                                                                    <svg class="pointer-events-none fill-current w-4 h-4 ml-auto" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                                                                        <path class="pointer-events-none" d="M3 6l3 18h12l3-18h-18zm19-4v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.316c0 .901.73 2 1.631 2h5.711z" />
                                                                                                    </svg>
                                                                                                </button>
                                                                                            </>
                                                                                        )
                                                                                    }
                                                                                </div>
                                                                            </section>
                                                                            {
                                                                                preview?.status !== app_consts.IDLE &&
                                                                                <>
                                                                                    <div className='flex flex-row justify-end items center my-2'>
                                                                                        {
                                                                                            preview?.status === app_consts.LOADING ?
                                                                                                <>
                                                                                                    <svg
                                                                                                        aria-hidden="true"
                                                                                                        className="inline w-5 h-5 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-green-600"
                                                                                                        viewBox="0 0 100 101"
                                                                                                        fill="none"
                                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                                    >
                                                                                                        <path
                                                                                                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                                                                            fill="currentColor"
                                                                                                        />
                                                                                                        <path
                                                                                                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                                                                            fill="currentFill"
                                                                                                        />
                                                                                                    </svg>
                                                                                                </>
                                                                                                :
                                                                                                preview?.status === app_consts.FAIL ?
                                                                                                    <>
                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="red" class="w-6 h-6">
                                                                                                            <path stroke-linecap="round" stroke-linejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                                                                                        </svg>
                                                                                                    </>
                                                                                                    :
                                                                                                    preview?.status === app_consts.SUCCESS ?
                                                                                                        <>
                                                                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="green" class="w-6 h-6">
                                                                                                                <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                                                                                            </svg>
                                                                                                        </>
                                                                                                        :
                                                                                                        ""
                                                                                        }
                                                                                    </div>
                                                                                </>
                                                                            }
                                                                        </article>
                                                                    </li>
                                                                </div>
                                                            </>
                                                        );
                                                    })
                                                }
                                            </div>
                                            {
                                                filePreview?.length === 0 && (
                                                    <>
                                                        <ul id="gallery" class="flex flex-1 flex-wrap -m-1">
                                                            <li id="empty" class="h-full w-full text-center flex flex-col items-center justify-center items-center">
                                                                <img class="mx-auto w-32" src="https://user-images.githubusercontent.com/507615/54591670-ac0a0180-4a65-11e9-846c-e55ffce0fe7b.png" alt="no data" />
                                                                <span class="text-small text-gray-500">No files selected</span>
                                                            </li>
                                                        </ul>
                                                    </>
                                                )
                                            }
                                        </div>
                                    </div>


                                    <footer class="flex justify-end px-8 pb-8 pt-4">
                                        <button disabled={loading === true} onClick={handleUpload} class="px-3 py-2 text-xs font-medium text-center inline-flex items-center text-white bg-green-700 rounded-lg hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800 ">
                                            {
                                                loading === true ? "Uploading" : !finLoad ? "Upload Now" : "Close"
                                            }
                                        </button>
                                        {
                                            !finLoad && (
                                                <>
                                                    <button disabled={loading} onClick={closeModal} id="cancel" class="px-3 py-2 text-xs font-medium text-center inline-flex items-center text-white bg-gray-400 rounded-lg hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800 ml-6 ">
                                                        Cancel
                                                    </button>
                                                </>
                                            )
                                        }
                                    </footer>
                                </article>
                            </>
                        </div>
                    </div>
                )
            }
        </>
    );
};

export default MultiDocumentUploadModal;