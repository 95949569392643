import React, { useEffect, useState } from 'react';
import * as app_consts from '../utils/constants';
// import { PDFViewer } from '@react-pdf/renderer';
import WarehouseReceiptTradingInvoicePDF from './generated_PDFs/WarehouseReceiptTradingInvoicePDF';
import WarehouseReceiptTradingContract from './generated_PDFs/WarehouseReceiptTradingContract';
import { PDFViewer } from '@react-pdf/renderer';

const AdvancedDocumentViewer = ({ name, url, onEditClick, uploadButton, doc_type, data, noDocumentMessage, downloadClick, setDownloadClick, downloadable }) => {

    const [docViewerData, setDocViewerData] = useState(data);
    const [showUploadButton, setUploadButton] = useState(true);
    const [noDoc, setNoDoc] = useState("No Document to Display");

    useEffect(() => {
        if (data?.length !== 0)
        {
            setDocViewerData(data);
            setUploadButton(uploadButton);
            setNoDoc(noDocumentMessage);

        }
    }, [data, uploadButton]);

    return (
        <div className="border border-gray-200">
            <div className="flex justify-between items-center p-4 bg-gray-100 border-b border-gray-300">
                <h2 className="text-xl font-bold dark:text-white">{name}</h2>
                {
                    showUploadButton && (
                        <>
                            <button onClick={onEditClick} type="button" class="px-3 py-2 text-xs font-medium text-center inline-flex items-center text-white bg-green-700 rounded-lg hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5 mr-2">
                                    <path d="M9.25 13.25a.75.75 0 001.5 0V4.636l2.955 3.129a.75.75 0 001.09-1.03l-4.25-4.5a.75.75 0 00-1.09 0l-4.25 4.5a.75.75 0 101.09 1.03L9.25 4.636v8.614z" />
                                    <path d="M3.5 12.75a.75.75 0 00-1.5 0v2.5A2.75 2.75 0 004.75 18h10.5A2.75 2.75 0 0018 15.25v-2.5a.75.75 0 00-1.5 0v2.5c0 .69-.56 1.25-1.25 1.25H4.75c-.69 0-1.25-.56-1.25-1.25v-2.5z" />
                                </svg>
                                Upload Document
                            </button>
                        </>
                    )
                }
                {
                    downloadable === true && (
                        <>
                            {/* <button onClick={() => setDownloadClick(true)} type="button" class="px-3 py-2 text-xs font-medium text-center inline-flex items-center text-white bg-green-700 rounded-lg hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3" />
                                </svg>
                                Download
                            </button> */}
                        </>
                    )
                }
            </div>
            {
                doc_type === app_consts.SYSTEM_INVOICE_GENERATED_PDF ?
                    <>
                        <div style={{ height: "500px", width: "100%", overflow: "scroll" }} className='border-2 border-text-gray-400'>
                            <PDFViewer height="100%" width="100%" showToolbar>
                                {
                                    docViewerData ? (
                                        <WarehouseReceiptTradingInvoicePDF data={docViewerData} downloadTradingInvoice={downloadClick} downloadable={downloadable} setDownloadTradingInvoice={setDownloadClick} />
                                    )
                                        :
                                        <>
                                            <div className='flex flex-row justify-center items-center bg-gray-300 w-full h-full'>
                                                <span className="text-gray-600">{noDoc}</span>
                                            </div>
                                        </>
                                }
                            </PDFViewer>
                        </div>
                    </>
                    :
                    doc_type === app_consts.SYSTEM_CONTRACT_GENERATED_PDF ?
                        <>
                            <div style={{ height: "500px", width: "100%", overflow: "scroll" }}>
                                <PDFViewer height="100%" width="100%" showToolbar>
                                    {
                                        docViewerData ? (
                                            <WarehouseReceiptTradingContract data={docViewerData} downloadTradingContract={downloadClick} downloadable={downloadable} setDownloadTradingContract={setDownloadClick} />
                                        )
                                            :
                                            <>
                                                <div className='flex flex-row justify-center items-center bg-gray-300 w-full h-full'>
                                                    <span className="text-gray-600">{noDoc}</span>
                                                </div>
                                            </>
                                    }
                                </PDFViewer>
                            </div>
                        </>
                        :
                        <>
                            {
                                url && url.length > 0 ?
                                    <>
                                        {url && (url.endsWith(".pdf") ? (
                                            <embed src={url} type="application/pdf" width="100%" height="500px" />
                                        ) : (
                                            <img src={url} alt={url} className="w-full" />
                                        ))}
                                    </>
                                    :
                                    <>
                                        <>
                                            <div className='flex flex-row justify-center items-center bg-gray-300 w-full h-full'>
                                                <span className="text-gray-600">{noDoc}</span>
                                            </div>
                                        </>
                                    </>
                            }
                        </>
            }
        </div>
    );
};

export default AdvancedDocumentViewer;
