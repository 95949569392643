import * as app_consts from "../../utils/constants";

export const wizard_step_guide_data = (type) => {
  let steps_obj = [];
  switch (type)
  {
    case app_consts.CREATE_WAREHOUSE_RECEIPT_WIZARD:
      steps_obj = [
        {
          index: 1,
          step: "Delivery Info",
        },
        {
          index: 2,
          step: "Commodity Grading Info",
        },
        {
          index: 3,
          step: "Storage Information",
        },
        {
          index: 4,
          step: "Inventory Information",
        },
        {
          index: 5,
          step: "Warehouse Receipt Information",
        },
      ];
      break;
    case app_consts.CREATE_BULK_WAREHOUSE_RECEIPT_WIZARD:
      steps_obj = [
        {
          index: 1,
          step: "Commodity and Storage Info",
        },
        {
          index: 2,
          step: "Inventory Information",
        },
        {
          index: 3,
          step: "Warehouse Receipt Information",
        },
      ];
      break;
    case app_consts.CREATE_GOODS_RECEIVED_NOTE_WIZARD:
      steps_obj = [
        {
          index: 1,
          step: "Deposit Information",
        },
        {
          index: 2,
          step: "Goods Received Note Info",
        },
        {
          index: 3,
          step: "Storage Info",
        },
        {
          index: 4,
          step: "Inventory Information",
        },
        {
          index: 5,
          step: "Warehouse Receipt Information",
        },
      ];
      break;
  }
  return steps_obj;
};