import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import countries from "../../utils/countries.json";
import provinces from '../../utils/provinces.json';
import districts from "../../utils/districts.json";
import { useDispatch, useSelector } from 'react-redux';
import { addWarehouse, addWarehouseReset } from '../../utils/actions';
import * as app_consts from '../../utils/constants';

const initialStorageType = { bunker: "", grain_dam: "", stack1bag: "", shed1warehouse: "", silo: "" };

const AddWarehouseModal = ({ isOpen, setIsOpen, successFunction }) => {

    const { entity_id, entity_mid } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [openModal, setOpenModal] = useState(isOpen);
    const [loading, setLoading] = useState("");
    const [storage_types, setStorageTypes] = useState([]);
    const [storage_capacity, setStorageCapacity] = useState([]);
    const [storage_capacity_data, setStorageCapacityData] = useState(initialStorageType);
    const [total_storage_capacity, setTotalStorageCapacity] = useState(0.00);
    const [error, setError] = useState("");

    useEffect(() => {
        setOpenModal(isOpen);
        formik.resetForm();
        setStorageTypes([]);
    }, [isOpen]);

    const addWarehouseSchema = yup.object().shape({
        name: yup.string().required('Warehouse Name is Required.'),
        address: yup.string().required('Warehouse Address is Required'),
        province: yup.string().required('Please select a Province.'),
        districts: yup.string().required('Please Select a District'),
        country: yup.string().required('Please select a Country.'),
        storage_capacity: yup.object().required('Please Set Values for Selected Storage Types'),
        storage_types: yup.array().min(1, 'Please Select at Least One Storage Type'),
    });

    const formik = useFormik({
        initialValues: {
            entity_id: entity_id,
            name: '',
            address: '',
            province: '',
            districts: '',
            country: '',
            storage_types: [],
            storage_capacity: "",
            status: "uncertified",
        },
        validationSchema: addWarehouseSchema,


        onSubmit: async (values) => {

            setError("");
            setLoading(true);

            try
            {
                const warehouseData = {
                    entity_id: entity_id,
                    name: values.name,
                    address: values.address,
                    province: values.province,
                    country: values.country,
                    storage_types: storage_types,
                    storage_capacity: values.storage_capacity,
                    status: values.status,
                    districts: values.districts
                };

                await dispatch(addWarehouse(warehouseData));

            } catch (error)
            {
                console.log(error.message);
            }

        },
    });

    // Listen to Add Warehouse State
    const add_warehouse_listner = useSelector((state) => state.addWarehouse);
    // 
    useEffect(() => {
        if (add_warehouse_listner.status !== app_consts.IDLE)
        {
            if (add_warehouse_listner.isLoading)
            {
                setLoading(true);
            }
            else
            {
                setLoading(false);
                if (add_warehouse_listner.error)
                {
                    setError(add_warehouse_listner.error);
                }
                else
                {
                    closeModal();
                    successFunction();
                }
                dispatch(addWarehouseReset());
            }
        }
    }, [add_warehouse_listner]);

    const handleCheckboxChange = (event) => {
        const { value, name } = event.target;
        // Check if Checkbox is Checked or Not

        // Check if the checkbox is already in the array;
        if (storage_types.includes(value))
        {
            // If it is, remove it
            const updatedArray = storage_types?.filter(item => item !== name);

            // If it is, remove it
            const current_object = updatedArray;
            delete current_object[value];
            // Redefine Storage Capacity
            formik.setFieldValue('storage_types', current_object);
            formik.setFieldValue('storage_capacity', redifine_storage_capactiy(current_object));
            setStorageTypes(storage_types.filter(storage_type => storage_type !== value));
            setStorageCapacityData({ ...storage_capacity_data, [name]: 0.00 });
        } else
        {
            // If it is not, add it to the array
            formik.setFieldValue('storage_types', [...storage_types, value]);
            formik.setFieldValue('storage_capacity', redifine_storage_capactiy(storage_types));
            setStorageTypes([...storage_types, value]);
        }
    };

    // // Create New Storage Capacity Object
    const redifine_storage_capactiy = (storage_capacity_array) => {
        // Return Map
        let return_map = {};

        storage_capacity_array?.map((storage_capacity) => {
            return_map[storage_capacity] = storage_capacity_data[storage_capacity];
            setStorageCapacityData({ ...storage_capacity_data, [return_map[storage_capacity]]: 0.00 });
        });

        return return_map;
    };


    // Handle Storage Capacity Input
    const handleStorageCapacityChange = (e) => {
        if (e.target.value === "")
        {
            setStorageCapacityData({ ...storage_capacity_data, [e.target.name]: "" });
            formik.setFieldValue('storage_capacity', { ...formik.values.storage_capacity, [e.target.name]: "" });
        }
        else 
        {
            setStorageCapacityData({ ...storage_capacity_data, [e.target.name]: e.target.value || 0 });
            formik.setFieldValue('storage_capacity', { ...formik.values.storage_capacity, [e.target.name]: e.target.value });
        }
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    // UseEffect for Total Figure
    useEffect(() => {
        setTotalStorageCapacity((parseFloat(storage_capacity_data.bunker || 0) + parseFloat(storage_capacity_data.grain_dam || 0) + parseFloat(storage_capacity_data.shed1warehouse || 0) + parseFloat(storage_capacity_data.silo || 0) + parseFloat(storage_capacity_data.stack1bag || 0)).toFixed(2));
    }, [storage_capacity_data]);

    // UseEffect for Listening to Formik Storage Type Changes
    useEffect(() => {
        formik.values?.storage_types?.map((storage_type) => {
            formik.setFieldValue('storage_capacity', { ...formik.values.storage_capacity, [storage_type]: storage_capacity_data[storage_type] });
        });
    }, [formik.values.storage_types]);

    const handleOnblur = (name, value) => {
        if (value)
        {
            formik.setFieldValue(name, parseFloat(value).toFixed(2));
            setStorageCapacityData({ ...storage_capacity_data, [name]: parseFloat(value).toFixed(2) });
        }
        else
        {
            setStorageCapacityData({ ...storage_capacity_data, [name]: "" });
            formik.setFieldValue('storage_capacity', { ...formik.values.storage_capacity, [name]: "" });
        }
    };

    return (
        <>
            {
                openModal && (
                    <>
                        <div className="fixed inset-0 flex items-center justify-center z-50 overflow-y-auto">
                            <div className="fixed inset-0 bg-black opacity-50"></div>
                            {/* Modal content goes here */}
                            <div className="my-8 relative p-4 bg-white lg:w-5/12 w-5/6 rounded-lg shadow dark:bg-gray-800 md:p-8 max-h-screen overflow-y-auto">
                                {/* Add your modal content here */}
                                <div class="flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5 dark:border-gray-600">
                                    <h3 class="text-lg font-semibold text-gray-900 dark:text-white">
                                        Add a Warehouse
                                    </h3>
                                    <button type="button" onClick={closeModal} class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="updateProductModal">
                                        <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                        <span class="sr-only">Close modal</span>
                                    </button>
                                </div>
                                <form className="space-y-4 md:space-y-6" onSubmit={formik.handleSubmit}>
                                    <div className="container grid grid-cols-2 gap-6 sm:grid-cols-2 lg:grid-cols-2">
                                        <div className="mb-6">
                                            <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Warehouse Name</label>
                                            <input
                                                type="text"
                                                name="name"
                                                value={formik.values.name}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                className="bg-gray-100 border border-gray-100 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                                            />
                                            {formik.touched.name && formik.errors.name && (
                                                <p className="text-red-500 text-sm mt-1">{formik.errors.name}</p>
                                            )}
                                        </div>
                                        <div className="mb-6">
                                            <label htmlFor="country" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                                Country
                                            </label>
                                            <select
                                                name="country"
                                                value={formik.values.country}
                                                onChange={formik.handleChange}
                                                required
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                                            >
                                                {countries.map((country, index) => (
                                                    <option key={index} value={country}>
                                                        {country}
                                                    </option>
                                                ))}
                                            </select>
                                            {formik.touched.country && formik.errors.country && (
                                                <p className="text-red-500 text-sm mt-1">{formik.errors.country}</p>
                                            )}
                                        </div>
                                        {/* Province/State Input or Dropdown based on country */}
                                        <div className="mb-6">
                                            <label htmlFor="province" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                                Province / State
                                            </label>
                                            {formik.values.country === 'Zambia' ? (
                                                <select
                                                    name="province"
                                                    value={formik.values.province}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                                                >
                                                    <option value="">Choose a province</option>
                                                    {provinces.Zambia.map((province, index) => (
                                                        <option key={index} value={province}>
                                                            {province}
                                                        </option>
                                                    ))}
                                                </select>
                                            ) : (
                                                <input
                                                    type="text"
                                                    name="province"
                                                    value={formik.values.province}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                                                />
                                            )}
                                            {formik.touched.province && formik.errors.province && (
                                                <p className="text-red-500 text-sm mt-1">{formik.errors.province}</p>
                                            )}
                                        </div>
                                        {/* District/County Input or Dropdown based on country */}
                                        <div className="mb-6">
                                            <label htmlFor="districts" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                                District / County
                                            </label>
                                            {formik.values.province && formik.values.country === 'Zambia' ? (
                                                <select
                                                    name="districts"
                                                    value={formik.values.districts}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                                                >
                                                    <option value="">Choose a District</option>
                                                    {districts?.[formik.values?.province]?.map((district, index) => (
                                                        <option key={index} value={district}>
                                                            {district}
                                                        </option>
                                                    ))}
                                                </select>
                                            ) : (
                                                <input
                                                    type="text"
                                                    name="districts"
                                                    value={formik.values.districts}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                                                />
                                            )}
                                            {formik.touched.districts && formik.errors.districts && (
                                                <p className="text-red-500 text-sm mt-1">{formik.errors.districts}</p>
                                            )}
                                        </div>
                                        <div className="mb-6">
                                            <label htmlFor="address" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Physical Address</label>
                                            <input
                                                type="text"
                                                name="address"
                                                value={formik.values.address}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                className="bg-gray-100 border border-gray-100 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                                            />
                                            {formik.touched.address && formik.errors.address && (
                                                <p className="text-red-500 text-sm mt-1">{formik.errors.address}</p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="">
                                        <span className="block mb-4 text-sm font-medium text-gray-900 dark:text-white">Storage Types</span>
                                        <div className='container grid grid-cols-2 gap-6 sm:grid-cols-2 lg:grid-cols-3 border border-2 rounded-lg border-gray-200 p-2'>
                                            <div className="flex items-start">
                                                <div className="flex items-center h-5">
                                                    <input
                                                        id="bunker"
                                                        name="bunker"
                                                        aria-describedby="bunker"
                                                        type="checkbox"
                                                        className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800"
                                                        required=""
                                                        value="bunker"
                                                        checked={storage_types.includes('bunker')}
                                                        onChange={handleCheckboxChange}
                                                    />
                                                </div>
                                                <div className="ml-3 text-sm">
                                                    <label htmlFor="bunker" className="text-gray-500 dark:text-gray-300">
                                                        Bunker
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="flex items-start">
                                                <div className="flex items-center h-5">
                                                    <input
                                                        id="grain_dam"
                                                        name="grain_dam"
                                                        aria-describedby="grain_dam"
                                                        type="checkbox"
                                                        className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800"
                                                        required=""
                                                        value="grain_dam"
                                                        checked={storage_types.includes('grain_dam')}
                                                        onChange={handleCheckboxChange}
                                                    />
                                                </div>
                                                <div className="ml-3 text-sm">
                                                    <label htmlFor="grain_dam" className="text-gray-500 dark:text-gray-300">
                                                        Grain Dam
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="flex items-start">
                                                <div className="flex items-center h-5">
                                                    <input
                                                        id="silo"
                                                        aria-describedby="silo"
                                                        type="checkbox"
                                                        className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800"
                                                        value="silo"
                                                        name="silo"
                                                        checked={storage_types.includes('silo')}
                                                        onChange={handleCheckboxChange}
                                                    />
                                                </div>
                                                <div className="ml-3 text-sm">
                                                    <label htmlFor="silo" className="text-gray-500 dark:text-gray-300">
                                                        Silo
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="flex items-start">
                                                <div className="flex items-center h-5">
                                                    <input
                                                        id="stack1bag"
                                                        aria-describedby="text"
                                                        type="checkbox"
                                                        name="stack1bag"
                                                        className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800"
                                                        value="stack1bag"
                                                        checked={storage_types.includes('stack1bag')}
                                                        onChange={handleCheckboxChange}
                                                    />
                                                </div>
                                                <div className="ml-3 text-sm">
                                                    <label htmlFor="stack" className="text-gray-500 dark:text-gray-300">
                                                        Stack / Bag
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="flex items-start">
                                                <div className="flex items-center h-5">
                                                    <input
                                                        id="shed1warehouse"
                                                        aria-describedby="shed1warehouse"
                                                        type="checkbox"
                                                        name="shed1warehouse"
                                                        value="shed1warehouse"
                                                        className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800"

                                                        checked={storage_types.includes('shed1warehouse')}
                                                        onChange={handleCheckboxChange}
                                                    />
                                                </div>
                                                <div className="ml-3 text-sm">
                                                    <label htmlFor="bag" className="text-gray-500 dark:text-gray-300">
                                                        Shed / Warehouse
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        {formik.touched.storage_types && formik.errors.storage_types && (
                                            <p className="text-red-500 text-sm mt-1">{formik.errors.storage_types}</p>
                                        )}
                                    </div>
                                    {
                                        storage_types.length > 0 && (
                                            <>
                                                <div className="mt-6">
                                                    <hr />
                                                    <div className="mt-4 container grid grid-cols-2 gap-6 sm:grid-cols-2 lg:grid-cols-2">
                                                        {
                                                            storage_types.includes("bunker") && (
                                                                <>
                                                                    <div className="mb-6">
                                                                        <label htmlFor="bunker" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Bunker Storage Capacity <span className='text-gray-600 text-xs'>(Metric Tonnes)</span></label>
                                                                        <input
                                                                            type="number"
                                                                            step="0.01"
                                                                            default="0"
                                                                            min="0.00"
                                                                            name="bunker"
                                                                            onChange={handleStorageCapacityChange}
                                                                            value={storage_capacity_data.bunker}
                                                                            onBlur={() => handleOnblur("bunker", storage_capacity_data.bunker)}
                                                                            className="bg-gray-100 border border-gray-100 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                                                                        />
                                                                    </div>
                                                                </>
                                                            )
                                                        }
                                                        {
                                                            storage_types.includes("grain_dam") && (
                                                                <>
                                                                    <div className="mb-6">
                                                                        <label htmlFor="grain_dam" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Grain Dam Storage Capacity <span className='text-gray-600 text-xs'>(Metric Tonnes)</span></label>
                                                                        <input
                                                                            type="number"
                                                                            step="0.01"
                                                                            default="0"
                                                                            min="0.00"
                                                                            name="grain_dam"
                                                                            onChange={handleStorageCapacityChange}
                                                                            value={storage_capacity_data.grain_dam}
                                                                            onBlur={() => handleOnblur("grain_dam", storage_capacity_data.grain_dam)}
                                                                            className="bg-gray-100 border border-gray-100 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                                                                        />
                                                                    </div>
                                                                </>
                                                            )
                                                        }{
                                                            storage_types.includes("silo") && (
                                                                <>
                                                                    <div className="mb-6">
                                                                        <label htmlFor="silo" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Silo Storage Capacity <span className='text-gray-600 text-xs'>(Metric Tonnes)</span></label>
                                                                        <input
                                                                            type="number"
                                                                            step="0.01"
                                                                            default="0"
                                                                            min="0.00"
                                                                            name="silo"
                                                                            onChange={handleStorageCapacityChange}
                                                                            value={storage_capacity_data.silo}
                                                                            onBlur={() => handleOnblur("silo", storage_capacity_data.silo)}
                                                                            className="bg-gray-100 border border-gray-100 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                                                                        />
                                                                    </div>
                                                                </>
                                                            )
                                                        }{
                                                            storage_types.includes("stack1bag") && (
                                                                <>
                                                                    <div className="mb-6">
                                                                        <label htmlFor="stack1bag" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Stack/Bag Storage Capacity <span className='text-gray-600 text-xs'>(Metric Tonnes)</span></label>
                                                                        <input
                                                                            type="number"
                                                                            step="0.01"
                                                                            default="0"
                                                                            min="0.00"
                                                                            name="stack1bag"
                                                                            onChange={handleStorageCapacityChange}
                                                                            value={storage_capacity_data.stack1bag}
                                                                            onBlur={() => handleOnblur("stack1bag", storage_capacity_data.stack1bag)}
                                                                            className="bg-gray-100 border border-gray-100 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                                                                        />
                                                                    </div>
                                                                </>
                                                            )
                                                        }{
                                                            storage_types.includes("shed1warehouse") && (
                                                                <>
                                                                    <div className="mb-6">
                                                                        <label htmlFor="shed1warehouse" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Shed/Warehouse Storage Capacity <span className='text-gray-600 text-xs'>(Metric Tonnes)</span></label>
                                                                        <input
                                                                            type="number"
                                                                            step="0.01"
                                                                            default="0"
                                                                            min="0.00"
                                                                            name="shed1warehouse"
                                                                            onChange={handleStorageCapacityChange}
                                                                            value={storage_capacity_data.shed1warehouse}
                                                                            onBlur={() => handleOnblur("shed1warehouse", storage_capacity_data.shed1warehouse)}
                                                                            className="bg-gray-100 border border-gray-100 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                                                                        />
                                                                    </div>
                                                                </>
                                                            )
                                                        }
                                                    </div>
                                                    {formik.touched.storage_capacity && formik.errors.storage_capacity && (
                                                        <p className="text-red-500 text-sm mt-1">{formik.errors.storage_capacity}</p>
                                                    )}
                                                </div>
                                                <div className="mb-6">
                                                    <label htmlFor="total_storage_capacity" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Total Storage Capacity  <span className='text-gray-600 text-xs'>(Metric Tonnes)</span></label>
                                                    <input
                                                        type="number"
                                                        default="0.00"
                                                        name="total_storage_capacity"
                                                        value={total_storage_capacity}
                                                        disabled
                                                        onBlur={formik.handleBlur}
                                                        className="bg-gray-50 border border-gray-100 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                                                    />
                                                </div>
                                            </>
                                        )
                                    }
                                    {error && error.length > 0 && (
                                        <div className="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
                                            <span className="font-medium">Error: </span>
                                            {error}
                                        </div>
                                    )}
                                    {/* Close button */}
                                    <div className="flex space-x-4">
                                        <button
                                            type="button"
                                            onClick={closeModal}
                                            className="text-gray-900 bg-white hover:bg-gray-100 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 mr-2 mb-2"
                                        >
                                            Close Modal
                                        </button>
                                        <button
                                            type="submit"
                                            disabled={loading}
                                            className="text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-primary-800 mr-2 mb-2"
                                        >

                                            {formik.isSubmitting ? 'Adding Warehouse...' : 'Add Warehouse'}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </>
                )
            }
        </>
    );
};

export default AddWarehouseModal;