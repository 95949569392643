import React, { useEffect, useState } from 'react';
import * as app_consts from '../../utils/constants';
import TableRowsData from './TableRowsData';
import { format, parseISO } from 'date-fns';
import { addThreeDots, capitalizeSentences, convertToTitleCase, formatEasilyReadableNumber, replace_string_with_forward_slash } from '../../utils/functionalUtils';
import { useParams } from 'react-router-dom';
import ToggleTextDetails from '../../layouts/text/ToggleTextDetails';
import { toggleViewWarehouseOnWebsiteReset, toggle_entity_profile_viewable_on_website, toggle_warehouse_viewable_on_website } from '../../utils/actions';
import { useDispatch, useSelector } from 'react-redux';

export const WarehouseReceiptHistoryTable = ({ data, currentItems, page_size, currentPage }) => {

    const [pageSelected, setPageSelected] = useState(currentPage);

    useEffect(() => {
        setPageSelected(currentPage);
    }, [currentPage]);

    return (
        <>
            {
                data?.length > 0 &&
                currentItems?.map((item, index) => {
                    return (
                        <>
                            <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                {/* Other Rows */}
                                <TableRowsData data={index + 1 + (page_size * (pageSelected - 1))} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.message || "---"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.timestamp ? format(parseISO(item.timestamp), "yyyy-MM-dd HH:mm:ss") : "---"} type={app_consts.TEXT_ROW} />
                            </tr>
                        </>
                    );
                }
                )
            }
        </>
    );
};

export const OfferHistoryTable = ({ data, currentItems, page_size, currentPage }) => {

    const [pageSelected, setPageSelected] = useState(currentPage);

    useEffect(() => {
        setPageSelected(currentPage);
    }, [currentPage]);

    return (
        <>
            {
                data?.length > 0 &&
                currentItems?.map((item, index) => {
                    return (
                        <>
                            <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                {/* Other Rows */}
                                <TableRowsData data={index + 1 + (page_size * (pageSelected - 1))} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item || "---"} type={app_consts.TEXT_ROW} />
                            </tr>
                        </>
                    );
                }
                )
            }
        </>
    );
};

export const BrokerOfferTradeTable = ({ data, currentItems, page_size, currentPage }) => {

    const [pageSelected, setPageSelected] = useState(currentPage);
    const { entity_id, entity_mid, broker_offer_id, view } = useParams();

    useEffect(() => {
        setPageSelected(currentPage);
    }, [currentPage]);


    // URL Prefixes
    const brokerUrlPrefix = () => {
        if (view)
        {
            return 'broker/view';
        }
        else
        {
            return 'broker';
        }
    };

    return (
        <>
            {
                data?.length > 0 &&
                currentItems?.map((item, index) => {
                    return (
                        <>
                            <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                {/* Other Rows */}
                                <TableRowsData data={index + 1 + (page_size * (pageSelected - 1))} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.amount ? `${parseFloat(item?.amount).toFixed(2)} ZMW` : "---"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.quantity ? `${parseFloat(item?.quantity).toFixed(2)} MT` : "---"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.status ? capitalizeSentences(item?.status) : "---"} type={app_consts.TEXT_STATUS} />
                                <TableRowsData data={item.trade_date ? format(parseISO(item.trade_date), "yyyy-MM-dd HH:mm:ss") : '-'} type={app_consts.TEXT_ROW} />
                                <TableRowsData type={app_consts.TEXT_VIEW_DETAILS} nav_link={`/${brokerUrlPrefix()}/${entity_id}/${entity_mid}/offer_management/${broker_offer_id}/trade/${item?.id}`} />
                            </tr>
                        </>
                    );
                }
                )
            }
        </>
    );
};

export const BidManagemenetTable = ({ data, currentItems, page_size, currentPage }) => {

    const [pageSelected, setPageSelected] = useState(currentPage);
    const { entity_id, entity_mid } = useParams();

    useEffect(() => {
        setPageSelected(currentPage);
    }, [currentPage]);

    return (
        <>
            {
                data?.length > 0 &&
                currentItems?.map((item, index) => {
                    return (
                        <>
                            <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                {/* Other Rows */}
                                <TableRowsData data={item?.broker_bid_id || "--"} type={app_consts.TEXT_CHECKBOX} />
                                <TableRowsData data={item?.broker_bid_reference_no || "---"} type={app_consts.TEXT_LINK} nav_link={`/broker/${entity_id}/${entity_mid}/bid_management/${item?.broker_bid_id}`} />
                                <TableRowsData data={item?.commodity_order_reference_no ? item?.commodity_order_reference_no : "---"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.commodity ? item?.commodity.replace(/_/g, ' ').toUpperCase() : "---"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.bid_volume ? `${parseFloat(item?.bid_volume).toFixed(2)} MT` : "---"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item.broker_bid_date ? format(parseISO(item.broker_bid_date), "yyyy-MM-dd") : '-'} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.bid_status ? capitalizeSentences(item?.bid_status) : "---"} type={app_consts.TEXT_STATUS} />
                                <TableRowsData type={app_consts.TEXT_VIEW_DETAILS} nav_link={`/broker/${entity_id}/${entity_mid}/bid_management/${item?.broker_bid_id}`} />
                            </tr>
                        </>
                    );
                }
                )
            }
        </>
    );
};

export const WarehouseReceiptListTable = ({ data, currentItems, page_size, currentPage }) => {

    const [pageSelected, setPageSelected] = useState(currentPage);
    const { entity_id, entity_mid, view } = useParams();

    useEffect(() => {
        setPageSelected(currentPage);
    }, [currentPage]);

    // URL Prefixes
    const warehouseUrlPrefix = () => {
        if (view)
        {
            return 'warehouse/view';
        }
        else
        {
            return 'warehouse';
        }
    };

    return (
        <>
            {
                data?.length > 0 &&
                currentItems?.map((item, index) => {
                    return (
                        <>
                            <tr key={item?.receipt_number} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                {/* Other Rows */}
                                {/* <TableRowsData data={item?.receipt_number || "--"} type={app_consts.TEXT_CHECKBOX} /> */}
                                <TableRowsData data={item?.receipt_number ? addThreeDots(item?.receipt_number, 20) : '-'} type={app_consts.TEXT_LINK} nav_link={`/${warehouseUrlPrefix()}/${entity_id}/${entity_mid}/receipts/${item?.receipt_number}`} />
                                <TableRowsData data={item?.commodity ? item?.commodity.replace(/_/g, ' ').toUpperCase() : "---"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.owner_name ? addThreeDots(item?.owner_name, 20) : '-'} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.quantity ? `${parseFloat(item?.quantity).toFixed(2)}` : "0.00"} type={app_consts.TEXT_ROW} textClassname="flex flex-row items-center justify-center" />
                                <TableRowsData data={item?.warehouse_receipt_origin ? item?.warehouse_receipt_origin.replace(/_/g, ' ').toUpperCase() : "---"} type={app_consts.TEXT_STATUS} textClassname="bg-green-100 text-green-800" textDarkClassName="dark:bg-green-900" />
                                <TableRowsData data={item?.status ? capitalizeSentences(item?.status) : "---"} type={app_consts.TEXT_STATUS} />
                                <TableRowsData type={app_consts.TEXT_VIEW_DETAILS} nav_link={`/${warehouseUrlPrefix()}/${entity_id}/${entity_mid}/receipts/${item?.receipt_number}`} />
                            </tr>
                        </>
                    );
                }
                )
            }
        </>
    );
};

export const TradeFulfillmentListTable = ({ data, currentItems, page_size, currentPage }) => {

    const [pageSelected, setPageSelected] = useState(currentPage);
    const { entity_id, entity_mid, view } = useParams();

    useEffect(() => {
        setPageSelected(currentPage);
    }, [currentPage]);

    // URL Prefixes
    const warehouseUrlPrefix = () => {
        if (view)
        {
            return 'warehouse/view';
        }
        else
        {
            return 'warehouse';
        }
    };

    return (
        <>
            {
                data?.length > 0 &&
                currentItems?.map((item, index) => {
                    return (
                        <>
                            <tr key={item?.receipt_number} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                {/* Other Rows */}
                                <TableRowsData data={item?.fulfillment?.id || "--"} type={app_consts.TEXT_CHECKBOX} />
                                <TableRowsData data={item?.contract?.contract_reference_no ? addThreeDots(item?.contract?.contract_reference_no) : '-'} type={app_consts.TEXT_LINK} nav_link={`/${warehouseUrlPrefix()}/${entity_id}/${entity_mid}/trade_fulfillments/${item?.contract?.contract_reference_no}`} />
                                <TableRowsData data={item?.fulfillment?.commodity ? item?.fulfillment?.commodity.replace(/_/g, ' ').toUpperCase() : "---"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.contract?.quantity ? `${parseFloat(item?.contract?.quantity).toFixed(2)}MT` : "0.00MT"} type={app_consts.TEXT_ROW} textClassname="flex flex-row items-center justify-center" />
                                <TableRowsData data={item?.trade_confirmation?.trade_date ? format(parseISO(item?.trade_confirmation?.trade_date), "yyyy-MM-dd HH:mm:ss") : "---"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.contract?.status ? item?.contract?.status.replace(/_/g, ' ').toUpperCase() : "---"} type={app_consts.TEXT_STATUS} textClassname="bg-green-100 text-green-800" textDarkClassName="dark:bg-green-900" />
                                <TableRowsData data={item?.fulfillment?.status ? item?.fulfillment?.status.replace(/_/g, ' ').toUpperCase() : "---"} type={app_consts.TEXT_STATUS} textClassname="bg-green-100 text-green-800" textDarkClassName="dark:bg-green-900" />
                                <TableRowsData data={item?.trade_confirmation?.status ? capitalizeSentences(item?.trade_confirmation?.status) : "---"} type={app_consts.TEXT_STATUS} />
                                <TableRowsData type={app_consts.TEXT_VIEW_DETAILS} nav_link={`/${warehouseUrlPrefix()}/${entity_id}/${entity_mid}/trade_fulfillments/${item?.contract?.contract_reference_no}`} />
                            </tr>
                        </>
                    );
                }
                )
            }
        </>
    );
};

export const SuperBidManagemenetTable = ({ data, currentItems, page_size, currentPage }) => {

    const [pageSelected, setPageSelected] = useState(currentPage);
    const { entity_id, entity_mid } = useParams();

    useEffect(() => {
        setPageSelected(currentPage);
    }, [currentPage]);

    return (
        <>
            {
                data?.length > 0 &&
                currentItems?.map((item, index) => {
                    return (
                        <>
                            <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                {/* Other Rows */}
                                <TableRowsData data={item?.broker_bid_id || "--"} type={app_consts.TEXT_CHECKBOX} />
                                <TableRowsData data={item?.date_to_be_placed_on_platform || "---"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.commodity ? item?.commodity.replace(/_/g, ' ').toUpperCase() : "---"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.volume ? `${parseFloat(item?.volume).toFixed(2)} MT` : "---"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.requested_unit_price ? `${parseFloat(item?.requested_unit_price)}` : "---"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.quality ? item?.quality.replace(/_/g, ' ').toUpperCase() : "---"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.order_type ? item?.order_type.replace(/_/g, ' ').toUpperCase() : "---"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.commodity_special_terms_and_conditions ? addThreeDots(item?.commodity_special_terms_and_conditions, 20) : "---"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.bid_status ? capitalizeSentences(item?.bid_status) : "---"} type={app_consts.TEXT_STATUS} />
                                <TableRowsData type={app_consts.TEXT_VIEW_DETAILS} nav_link={`/broker/${entity_id}/${entity_mid}/s_bid_management/${item?.broker_bid_id}`} />
                            </tr>
                        </>
                    );
                }
                )
            }
        </>
    );
};

export const EntityProfileUserProfilesTable = ({ data, currentItems, page_size, currentPage }) => {

    const [pageSelected, setPageSelected] = useState(currentPage);
    const { entity_id, entity_mid, view } = useParams();

    useEffect(() => {
        setPageSelected(currentPage);
    }, [currentPage]);

    // URL Prefixes
    const warehouseUrlPrefix = () => {
        if (view)
        {
            return 'warehouse/view';
        }
        else
        {
            return 'warehouse';
        }
    };

    return (
        <>
            {
                data?.length > 0 &&
                currentItems?.map((item, index) => {
                    return (
                        <>
                            <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                {/* Other Rows */}
                                <TableRowsData data={item?.profile_id || "--"} type={app_consts.TEXT_CHECKBOX} />
                                <TableRowsData data={item?.user_first_name || "---"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.user_last_name || "---"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.account_mobile || "---"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.account_email || "---"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.profile_created_on ? format(parseISO(item?.profile_created_on), "yyyy-MM-dd HH:mm:ss") : "---"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.profile_status ? capitalizeSentences(item?.profile_status) : "---"} type={app_consts.TEXT_STATUS} />
                                <TableRowsData type={app_consts.TEXT_VIEW_DETAILS} nav_link={`/${warehouseUrlPrefix()}/${entity_id}/${entity_mid}/profile/${item?.profile_id}`} />
                            </tr>
                        </>
                    );
                }
                )
            }
        </>
    );
};

export const ProvisionReceiptListTable = ({ data, currentItems, page_size, currentPage }) => {

    const [pageSelected, setPageSelected] = useState(currentPage);
    const { entity_id, entity_mid, view } = useParams();

    useEffect(() => {
        setPageSelected(currentPage);
    }, [currentPage]);


    return (
        <>
            {
                data?.length > 0 &&
                currentItems?.map((item, index) => {
                    return (
                        <>
                            <tr key={item?.receipt_number} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                {/* Other Rows */}
                                <TableRowsData data={item?.receipt_number || "--"} type={app_consts.TEXT_CHECKBOX} />
                                <TableRowsData data={item?.receipt_number ? addThreeDots(item?.receipt_number, 20) : '-'} type={app_consts.TEXT_LINK} nav_link={`/broker/${entity_id}/${entity_mid}/provision_receipts/${item?.receipt_number}`} />
                                <TableRowsData data={item?.commodity ? item?.commodity.replace(/_/g, ' ').toUpperCase() : "---"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.producer?.contact_name ? addThreeDots(item?.producer?.contact_name, 20) : '-'} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.volume ? `${parseFloat(item?.volume).toFixed(2)}` : "0.00"} type={app_consts.TEXT_ROW} textClassname="flex flex-row items-center justify-center" />
                                <TableRowsData data={item?.provision_receipt_origin ? "PROVISIONAL" : "---"} type={app_consts.TEXT_STATUS} textClassname="bg-green-100 text-green-800" textDarkClassName="dark:bg-green-900" />
                                <TableRowsData data={item?.status ? capitalizeSentences(item?.status) : "---"} type={app_consts.TEXT_STATUS} />
                                <TableRowsData type={app_consts.TEXT_VIEW_DETAILS} nav_link={`/broker/${entity_id}/${entity_mid}/provisional_receipts/${item?.receipt_number}`} />
                            </tr>
                        </>
                    );
                }
                )
            }
        </>
    );
};

// Warehouse Reports Table
export const WarehouseReportsTable = ({ data, currentItems, page_size, currentPage }) => {

    const [pageSelected, setPageSelected] = useState(currentPage);
    const { entity_id, entity_mid, view } = useParams();
    const [toggleClick, setToggleClick] = useState({ toggleClick: false, selectedIndex: "" });

    useEffect(() => {
        setPageSelected(currentPage);
    }, [currentPage]);

    const toggleClickFunc = (index) => {
        if (index !== toggleClick.selectedIndex)
        {
            setToggleClick({ ...toggleClick, ["toggleClick"]: true, ["selectedIndex"]: index });
        }
        else 
        {
            setToggleClick({ ...toggleClick, ["toggleClick"]: !toggleClick.toggleClick, ["selectedIndex"]: "" });
        }
    };

    return (
        <>
            {
                data?.length > 0 &&
                currentItems?.map((item, index) => {
                    return (
                        <>
                            <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                {/* Other Rows */}
                                <TableRowsData data={item?.warehouse?.id || "--"} type={app_consts.TEXT_CHEVRON_DOWN} toggleClickFunc={toggleClickFunc} toggleClick={toggleClick} />
                                <TableRowsData data={item?.warehouse?.warehouse_id || "---"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.warehouse?.warehouse_name || "---"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.warehouse?.total_warehouse_capacity ? formatEasilyReadableNumber(parseFloat(item?.warehouse?.total_warehouse_capacity).toFixed(2)) : "---"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.warehouse?.total_used_capacity ? formatEasilyReadableNumber(parseFloat(item?.warehouse?.total_used_capacity).toFixed(2)) : "---"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.warehouse?.available_capacity ? formatEasilyReadableNumber(parseFloat(item?.warehouse?.available_capacity).toFixed(2)) : "---"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.warehouse?.used_percentage ? `${parseFloat(item?.warehouse?.used_percentage).toFixed(2)}%` : "0.00%"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.warehouse?.total_warehouse_receipts ? item?.warehouse?.total_warehouse_receipts : "0"} type={app_consts.TEXT_ROW} textClassname="text-center" text />
                                <TableRowsData data={item?.warehouse?.status ? capitalizeSentences(item?.warehouse?.status) : "---"} type={app_consts.TEXT_STATUS} />
                            </tr>
                            {/* For Accordion */}

                            {
                                toggleClick.toggleClick && toggleClick.selectedIndex === item?.warehouse?.id ?
                                    <>
                                        {
                                            item?.storage?.map((storage, index) => {
                                                return (
                                                    <tr className='pl-4'>
                                                        <TableRowsData data="" type={app_consts.TEXT_ROW} />
                                                        <TableRowsData data="" type={app_consts.TEXT_ROW} />
                                                        <TableRowsData data={storage?.storage_type ? capitalizeSentences(replace_string_with_forward_slash(convertToTitleCase(storage?.storage_type))) : "---"} type={app_consts.TEXT_ROW} textClassname=" bg-gray-100" />
                                                        <TableRowsData data={storage?.storage_capacity ? formatEasilyReadableNumber(parseFloat(storage?.storage_capacity).toFixed(2)) : "0.00"} type={app_consts.TEXT_ROW} textClassname=" bg-gray-100" />
                                                        <TableRowsData data={storage?.used_capacity ? formatEasilyReadableNumber(parseFloat(storage?.used_capacity).toFixed(2)) : "0.00"} type={app_consts.TEXT_ROW} textClassname=" bg-gray-100" />
                                                        <TableRowsData data={storage?.available_space ? formatEasilyReadableNumber(parseFloat(storage?.available_space).toFixed(2)) : "0.00"} type={app_consts.TEXT_ROW} textClassname=" bg-gray-100" />
                                                        <TableRowsData data={storage?.used_percentage ? `${parseFloat(storage?.used_percentage).toFixed(2)} %` : "0.00%"} type={app_consts.TEXT_ROW} textClassname=" bg-gray-100" />
                                                        <TableRowsData data={storage?.total_stored_warehouse_receipts ? storage?.total_stored_warehouse_receipts : "0"} type={app_consts.TEXT_ROW} textClassname="text-center bg-gray-100" text />
                                                    </tr>
                                                );
                                            })
                                        }
                                    </>
                                    :
                                    <>
                                    </>
                            }
                        </>
                    );
                }
                )
            }
        </>
    );
};

// Warehouse Locations Table
export const WarehouseLocationsListTable = ({ data, currentItems, page_size, currentPage }) => {

    const [pageSelected, setPageSelected] = useState(currentPage);
    const { entity_id, entity_mid, view } = useParams();

    useEffect(() => {
        setPageSelected(currentPage);
    }, [currentPage]);

    // URL Prefixes
    const warehouseUrlPrefix = () => {
        if (view)
        {
            return 'warehouse/view';
        }
        else
        {
            return 'warehouse';
        }
    };

    return (
        <>
            {
                data?.length > 0 &&
                currentItems?.map((item, index) => {
                    return (
                        <>
                            <tr key={item?.receipt_number} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                {/* Other Rows */}
                                {/* <TableRowsData data={item?.receipt_number || "--"} type={app_consts.TEXT_CHECKBOX} /> */}
                                <TableRowsData data={item?.warehouse_id ? addThreeDots(item?.warehouse_id, 20) : '-'} type={app_consts.TEXT_LINK} nav_link={`/${warehouseUrlPrefix()}/${entity_id}/${entity_mid}/locations/${item?.warehouse_id}`} />
                                <TableRowsData data={item?.name ? addThreeDots(item?.name, 20) : '-'} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.address ? addThreeDots(item?.address, 20) : '-'} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.status ? capitalizeSentences(item?.status) : "---"} type={app_consts.TEXT_STATUS} />
                                <TableRowsData type={app_consts.TEXT_VIEW_DETAILS} nav_link={`/${warehouseUrlPrefix()}/${entity_id}/${entity_mid}/locations/${item?.warehouse_id}`} />
                            </tr>
                        </>
                    );
                }
                )
            }
        </>
    );
};

// Inbound Shipments Table
export const InboundShipmentsTable = ({ data, currentItems, page_size, currentPage }) => {

    const [pageSelected, setPageSelected] = useState(currentPage);
    const { entity_id, entity_mid, view } = useParams();

    useEffect(() => {
        setPageSelected(currentPage);
    }, [currentPage]);

    // URL Prefixes
    const warehouseUrlPrefix = () => {
        if (view)
        {
            return 'warehouse/view';
        }
        else
        {
            return 'warehouse';
        }
    };

    return (
        <>
            {
                data?.length > 0 &&
                currentItems?.map((item, index) => {
                    return (
                        <>
                            <tr key={item?.receipt_number} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                {/* Other Rows */}
                                {/* <TableRowsData data={item?.receipt_number || "--"} type={app_consts.TEXT_CHECKBOX} /> */}
                                <TableRowsData data={item.warehouse_id ? addThreeDots(item.warehouse_id, 20) : '-'} type={app_consts.TEXT_LINK} nav_link={`/${warehouseUrlPrefix()}/${entity_id}/${entity_mid}/inbound_shipments/${item.id}`} />
                                <TableRowsData data={item.commodity_name ? addThreeDots(item.commodity_name.replace(/_/g, ' ').toUpperCase(), 20) : '-'} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item.depositor_type ? <>{item.depositor_type !== app_consts.INDIVIDUAL.toLowerCase() ? addThreeDots(item?.producer_entity_name) : addThreeDots(item?.depositor_name)}</> : ""} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item.arrival_time ? format(parseISO(item.arrival_time), "yyyy-MM-dd HH:mm:ss") : '-'} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.status ? capitalizeSentences(item?.status.replace(/_/g, ' ').toUpperCase(), 20) : "---"} type={app_consts.TEXT_STATUS} />
                                <TableRowsData type={app_consts.TEXT_VIEW_DETAILS} nav_link={`/${warehouseUrlPrefix()}/${entity_id}/${entity_mid}/inbound_shipments/${item.id}`} />
                            </tr>
                        </>
                    );
                }
                )
            }
        </>
    );
};

// GRN Info Table
export const GRNInfoTable = ({ data, currentItems, page_size, currentPage }) => {

    const [pageSelected, setPageSelected] = useState(currentPage);
    const { entity_id, entity_mid, view } = useParams();

    useEffect(() => {
        setPageSelected(currentPage);
    }, [currentPage]);

    return (
        <>
            {
                data?.length > 0 &&
                currentItems?.map((item, index) => {
                    return (
                        <>
                            <tr key={item?.grn_no} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                {/* Other Rows */}
                                {/* <TableRowsData data={item?.receipt_number || "--"} type={app_consts.TEXT_CHECKBOX} /> */}
                                <TableRowsData data={item.grn_no ? item.grn_no : '-'} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item.truck_load_no ? item.truck_load_no : '-'} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item.no_of_bags ? item.no_of_bags : '-'} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item.quantity ? item.quantity : '-'} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item.date_received ? format(parseISO(item.date_received), "yyyy-MM-dd HH:mm:ss") : '-'} type={app_consts.TEXT_ROW} />
                            </tr>
                        </>
                    );
                }
                )
            }
        </>
    );
};

// Producer Warehouse Receipts Table
export const ProducerWarehouseReceiptsTable = ({ data, currentItems, page_size, currentPage }) => {

    const [pageSelected, setPageSelected] = useState(currentPage);
    const { entity_id, entity_mid, view } = useParams();

    useEffect(() => {
        setPageSelected(currentPage);
    }, [currentPage]);

    // URL Prefixes
    const warehouseUrlPrefix = () => {
        if (view)
        {
            return 'warehouse/view';
        }
        else
        {
            return 'warehouse';
        }
    };

    return (
        <>
            {
                data?.length > 0 &&
                currentItems?.map((item, index) => {
                    return (
                        <>
                            <tr key={item?.grn_no} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                {/* Other Rows */}
                                {/* <TableRowsData data={item?.receipt_number || "--"} type={app_consts.TEXT_CHECKBOX} /> */}
                                <TableRowsData data={item.producer_unique_id ? addThreeDots(item.producer_unique_id, 20) : '-'} type={app_consts.TEXT_LINK} nav_link={`/${warehouseUrlPrefix()}/${entity_id}/${entity_mid}/receipt_depositors/${item.producer_unique_id}`} />
                                <TableRowsData data={item.producer_type ? <>{item.producer_type !== app_consts.INDIVIDUAL.toLowerCase() ? addThreeDots(item?.producer_entity_name) : addThreeDots(item?.producer_contact_name)}</> : ""} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item.producer_type ? <>{item.producer_type !== app_consts.INDIVIDUAL.toLowerCase() ? <>{item?.producer_entity_email ? addThreeDots(item?.producer_entity_email) : addThreeDots(item?.producer_contact_email)}</> : addThreeDots(item?.producer_contact_email)}</> : ""} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item.producer_type ? <>{item.producer_type !== app_consts.INDIVIDUAL.toLowerCase() ? <>{item?.producer_entity_phone ? addThreeDots(item?.producer_entity_phone) : addThreeDots(item?.producer_contact_phone)}</> : addThreeDots(item?.producer_contact_phone)}</> : ""} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item.warehouse_receipt_count ? item.warehouse_receipt_count : '-'} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item.last_receipt_date ? format(parseISO(item.last_receipt_date), "yyyy-MM-dd HH:mm:ss") : '-'} type={app_consts.TEXT_ROW} />
                                <TableRowsData type={app_consts.TEXT_VIEW_DETAILS} nav_link={`/${warehouseUrlPrefix()}/${entity_id}/${entity_mid}/receipt_depositors/${item.producer_unique_id}`} />
                            </tr>
                        </>
                    );
                }
                )
            }
        </>
    );
};

// specific Producer Warehouse Receipts Table
export const SpecificProducerWarehouseReceiptsTable = ({ data, currentItems, page_size, currentPage }) => {

    const [pageSelected, setPageSelected] = useState(currentPage);
    const { entity_id, entity_mid, view } = useParams();

    useEffect(() => {
        setPageSelected(currentPage);
    }, [currentPage]);

    // URL Prefixes
    const warehouseUrlPrefix = () => {
        if (view)
        {
            return 'warehouse/view';
        }
        else
        {
            return 'warehouse';
        }
    };

    return (
        <>
            {
                data?.length > 0 &&
                currentItems?.map((item, index) => {
                    return (
                        <>
                            <tr key={item?.receipt_number} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                {/* Other Rows */}
                                {/* <TableRowsData data={item?.receipt_number || "--"} type={app_consts.TEXT_CHECKBOX} /> */}
                                <TableRowsData data={item?.receipt_number ? addThreeDots(item?.receipt_number, 20) : '-'} type={app_consts.TEXT_LINK} nav_link={`/${warehouseUrlPrefix()}/${entity_id}/${entity_mid}/receipts/${item?.receipt_number}`} />
                                <TableRowsData data={item?.commodity ? item?.commodity.replace(/_/g, ' ').toUpperCase() : "---"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.quantity ? `${parseFloat(item?.quantity).toFixed(2)}` : "0.00"} type={app_consts.TEXT_ROW} textClassname="flex flex-row items-center justify-center" />
                                <TableRowsData data={item?.warehouse_receipt_origin ? item?.warehouse_receipt_origin.replace(/_/g, ' ').toUpperCase() : "---"} type={app_consts.TEXT_STATUS} textClassname="bg-green-100 text-green-800" textDarkClassName="dark:bg-green-900" />
                                <TableRowsData data={item?.status ? capitalizeSentences(item?.status) : "---"} type={app_consts.TEXT_STATUS} />
                                <TableRowsData type={app_consts.TEXT_VIEW_DETAILS} nav_link={`/${warehouseUrlPrefix()}/${entity_id}/${entity_mid}/receipts/${item?.receipt_number}`} />
                            </tr>
                        </>
                    );
                }
                )
            }
        </>
    );
};

// Specific Warehouse Receipts Depositor GRNs Table
export const SpecificWarehouseReceiptsDepositorsGRNTable = ({ data, currentItems, page_size, currentPage }) => {

    const [pageSelected, setPageSelected] = useState(currentPage);
    const { entity_id, entity_mid, view } = useParams();
    const [toggleClick, setToggleClick] = useState({ toggleClick: false, selectedIndex: "" });

    useEffect(() => {
        setPageSelected(currentPage);
    }, [currentPage]);

    const toggleClickFunc = (index) => {
        if (index !== toggleClick.selectedIndex)
        {
            setToggleClick({ ...toggleClick, ["toggleClick"]: true, ["selectedIndex"]: index });
        }
        else 
        {
            setToggleClick({ ...toggleClick, ["toggleClick"]: !toggleClick.toggleClick, ["selectedIndex"]: "" });
        }
    };

    const getSortedDeliveryNotes = (deliveryNotes) => {
        if (!deliveryNotes) return [];

        return deliveryNotes.sort((a, b) => {
            const dateA = new Date(a.date_received);
            const dateB = new Date(b.date_received);
            return dateB - dateA; // Descending order
        });
    };

    return (
        <>
            {
                data?.length > 0 &&
                currentItems?.map((item, index) => {
                    return (
                        <>
                            <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                {/* Other Rows */}
                                {
                                    <TableRowsData data={item?.receipt_number || "--"} type={item?.delivery_notes?.length > 0 ? app_consts.TEXT_CHEVRON_DOWN : app_consts.TEXT_MINUS} toggleClickFunc={toggleClickFunc} toggleClick={toggleClick} />
                                }
                                <TableRowsData data={item?.receipt_number || "---"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.commodity ? item?.commodity.replace(/_/g, ' ').toUpperCase() : "---"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.quantity ? parseFloat(item?.quantity).toFixed(2) : "---"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item.date_created ? format(parseISO(item.date_created), "yyyy-MM-dd HH:mm:ss") : '-'} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.status ? capitalizeSentences(item?.status) : "---"} type={app_consts.TEXT_STATUS} />
                            </tr>
                            {/* For Accordion */}
                            {
                                toggleClick.toggleClick && toggleClick.selectedIndex === item?.receipt_number ?
                                    <>
                                        <tr key={item?.grn_no} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                            <th scope="col" className="px-6 py-3">
                                            </th>
                                            <th scope="col" className="flex justify-between items-center px-6 py-3">
                                                <span>GRN NO.</span>
                                                <span>TRUCK LOAD NO.</span>
                                                <span>NO. OF BAGS</span>
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                <span>QUANTITY (METRIC TONNES)</span>
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                <span>DATE RECEIVED</span>
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                            </th>
                                        </tr>
                                        {
                                            item?.delivery_notes && getSortedDeliveryNotes(item.delivery_notes).map((delivery, index) => {
                                                return (
                                                    <tr key={item?.grn_no} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                                        {/* Other Rows */}
                                                        {/* <TableRowsData data={item?.receipt_number || "--"} type={app_consts.TEXT_CHECKBOX} /> */}
                                                        <td>

                                                        </td>
                                                        <td className="flex justify-between items-center bg-gray-100">
                                                            <div className="w-4/12">
                                                                <TableRowsData data={delivery.grn_no ? delivery.grn_no : '-'} type={app_consts.TEXT_ROW} textClassname=" bg-gray-100 text-center" />
                                                            </div>
                                                            <div className="w-4/12">
                                                                <TableRowsData data={delivery.truck_load_no ? delivery.truck_load_no : '-'} type={app_consts.TEXT_ROW} textClassname=" bg-gray-100 text-center" />
                                                            </div>
                                                            <div className="w-4/12">
                                                                <TableRowsData data={delivery.no_of_bags ? delivery.no_of_bags : '-'} type={app_consts.TEXT_ROW} textClassname=" bg-gray-100 text-center" />
                                                            </div>
                                                        </td>
                                                        <TableRowsData data={delivery.quantity ? delivery.quantity : '-'} type={app_consts.TEXT_ROW} textClassname=" bg-gray-100" />
                                                        <TableRowsData data={delivery.date_received ? format(parseISO(delivery.date_received), "yyyy-MM-dd HH:mm:ss") : '-'} type={app_consts.TEXT_ROW} textClassname=" bg-gray-100" />
                                                    </tr>
                                                );
                                            })
                                        }
                                    </>
                                    :
                                    <>
                                    </>
                            }
                        </>
                    );
                }
                )
            }
        </>
    );
};

// specific Producer Warehouse Receipts Table
export const ReportsAuditTrailsTable = ({ data, currentItems, page_size, currentPage }) => {

    const [pageSelected, setPageSelected] = useState(currentPage);
    const { entity_id, entity_mid, view } = useParams();

    useEffect(() => {
        setPageSelected(currentPage);
    }, [currentPage]);

    // URL Prefixes
    const warehouseUrlPrefix = () => {
        if (view)
        {
            return 'warehouse/view';
        }
        else
        {
            return 'warehouse';
        }
    };

    return (
        <>
            {
                data?.length > 0 &&
                currentItems?.map((item, index) => {
                    return (
                        <>
                            <tr key={item?.receipt_number} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                {/* Other Rows */}
                                {/* <TableRowsData data={item?.receipt_number || "--"} type={app_consts.TEXT_CHECKBOX} /> */}
                                <TableRowsData data={item?.audit_trail?.timestamp ? format(parseISO(item?.audit_trail?.timestamp), "yyyy-MM-dd HH:mm:ss") : '-'} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.audit_trail?.message ? item?.audit_trail?.message : "---"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.user?.first_name ? addThreeDots(`${item?.user?.first_name} ${item?.user?.last_name}`, 20) : '-'} type={app_consts.TEXT_LINK} nav_link={`/${warehouseUrlPrefix()}/${entity_id}/${entity_mid}/profile/${item?.profile?.id}`} textClassname="no-underline hover:underline" />
                            </tr>
                        </>
                    );
                }
                )
            }
        </>
    );
};

// Exchange Producer List Table
export const ExchangeProducerListTable = ({ data, currentItems, page_size, currentPage }) => {

    const [pageSelected, setPageSelected] = useState(currentPage);
    const { entity_id, entity_mid, view } = useParams();

    useEffect(() => {
        setPageSelected(currentPage);
    }, [currentPage]);

    return (
        <>
            {
                data?.length > 0 &&
                currentItems?.map((item, index) => {
                    return (
                        <>
                            <tr key={item?.grn_no} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                {/* Other Rows */}
                                {/* <TableRowsData data={item?.receipt_number || "--"} type={app_consts.TEXT_CHECKBOX} /> */}
                                <TableRowsData data={item?.producer?.producer_id ? addThreeDots(item?.producer?.producer_id, 20) : '-'} type={app_consts.TEXT_LINK} nav_link={`/exchange/${entity_id}/${entity_mid}/producer_management/${item?.producer?.producer_id}`} />
                                <TableRowsData data={item?.producer?.type ? <>{item?.producer?.type !== app_consts.INDIVIDUAL.toLowerCase() ? addThreeDots(item?.producer_entity?.producer_entity_name) : addThreeDots(item?.producer?.contact_name)}</> : "-"} type={app_consts.TEXT_ROW} textClassname={"capitalize"} />
                                <TableRowsData data={item?.producer?.type ? convertToTitleCase(item?.producer?.type) : "-"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.producer?.type ? <>{item?.producer?.type !== app_consts.INDIVIDUAL.toLowerCase() ? <>{item?.producer_entity?.producer_entity_phone ? addThreeDots(item?.producer_entity_phone || "-") : addThreeDots(item?.producer?.contact_phone || "-")}</> : addThreeDots(item?.producer?.contact_phone || "-")}</> : "-"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.producer?.type ? <>{item?.producer?.type !== app_consts.INDIVIDUAL.toLowerCase() ? <>{item?.producer_entity?.producer_entity_email ? addThreeDots(item?.producer_entity_email || "-") : addThreeDots(item?.producer?.contact_email || "-")}</> : addThreeDots(item?.producer?.contact_email || "-")}</> : "-"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.broker_number ? item?.broker_number : 0} type={app_consts.TEXT_ROW} />
                                <TableRowsData type={app_consts.TEXT_VIEW_DETAILS} nav_link={`/exchange/${entity_id}/${entity_mid}/producer_management/${item?.producer?.producer_id}`} />
                            </tr>
                        </>
                    );
                }
                )
            }
        </>
    );
};

// Exchange Producer Brokers Table
export const ExchangeProducerBrokersTable = ({ data, currentItems, page_size, currentPage }) => {

    const [pageSelected, setPageSelected] = useState(currentPage);
    const { entity_id, entity_mid, view } = useParams();

    useEffect(() => {
        setPageSelected(currentPage);
    }, [currentPage]);

    return (
        <>
            {
                data?.length > 0 &&
                currentItems?.map((item, index) => {
                    return (
                        <>
                            <tr key={item?.organization_id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                {/* Other Rows */}
                                {/* <TableRowsData data={item?.receipt_number || "--"} type={app_consts.TEXT_CHECKBOX} /> */}
                                <TableRowsData data={item?.organization_id ? addThreeDots(item?.organization_id, 20) : '-'} type={app_consts.TEXT_LINK} nav_link={`/exchange/${entity_id}/${entity_mid}/membership/${item?.organization_id}`} />
                                <TableRowsData data={item?.entity_name ? item?.entity_name : ""} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.entity_email ? item?.entity_email : ""} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.entity_phone ? item?.entity_phone : ""} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.status ? item?.status : ""} type={app_consts.TEXT_STATUS} />
                                <TableRowsData type={app_consts.TEXT_VIEW_DETAILS} nav_link={`/exchange/${entity_id}/${entity_mid}/membership/${item?.organization_id}`} />
                            </tr>
                        </>
                    );
                }
                )
            }
        </>
    );
};

// Broker Producers Table
export const BrokerProducersTable = ({ data, currentItems, page_size, currentPage }) => {

    const [pageSelected, setPageSelected] = useState(currentPage);
    const { entity_id, entity_mid, view } = useParams();

    useEffect(() => {
        setPageSelected(currentPage);
    }, [currentPage]);

    return (
        <>
            {
                data?.length > 0 &&
                currentItems?.map((item, index) => {
                    return (
                        <>
                            <tr key={item?.producer.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                {/* Other Rows */}
                                <TableRowsData data={item?.producer?.producer_id ? addThreeDots(item?.producer?.producer_id, 20) : '-'} type={app_consts.TEXT_LINK} nav_link={`/broker/${entity_id}/${entity_mid}/customer_details/${item?.producer.id}`} />
                                <TableRowsData data={item?.producer?.type?.replace(/_/g, ' ')?.toUpperCase() || '-'} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.producer?.type !== app_consts.INDIVIDUAL.toLowerCase() ? addThreeDots(item?.producer_entity?.producer_entity_name, 20) : ""} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.producer.contact_email ? (
                                    <a
                                        href={`mailto:${item?.producer.contact_email}`}
                                        className='hover:underline'
                                    >
                                        {item?.producer.contact_email}
                                    </a>
                                ) : (
                                    '-'
                                )} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.producer.contact_phone || '-'} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.receipt_count || '0'} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.producer.province || '-'} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.producer.country || '-'} type={app_consts.TEXT_ROW} />
                                <TableRowsData type={app_consts.TEXT_VIEW_DETAILS} nav_link={`/broker/${entity_id}/${entity_mid}/customer_details/${item?.producer.id}`} />
                            </tr>
                        </>
                    );
                }
                )
            }
        </>
    );
};

// Ats Management Table
export const AtsManagementTable = ({ data, currentItems, page_size, currentPage }) => {

    const [pageSelected, setPageSelected] = useState(currentPage);
    const { entity_id, entity_mid, view } = useParams();

    useEffect(() => {
        setPageSelected(currentPage);
    }, [currentPage]);

    // Filter Super Offers
    const super_offers = currentItems?.filter((item) => item?.commodity_order_type === app_consts.SUPER_OFFER);
    const super_bids = currentItems?.filter((item) => item?.commodity_order_type === app_consts.SUPER_BID);

    const numberFormat = new Intl.NumberFormat('en-ZM', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });


    return (
        <>
            {/* Map Super Offers */}
            {data?.length > 0 &&
                super_offers?.map((item, index) => {
                    return (
                        <>
                            <tr key={item?.commodity_order_reference_no} className="border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700 font-bold">
                                <TableRowsData data={item?.commodity_order_reference_no ? addThreeDots(item?.commodity_order_reference_no, 30) : '-'} type={app_consts.TEXT_LINK_CUSTOM_CLASS} nav_link={`/broker/${entity_id}/${entity_mid}/live_market/${item?.id}`} textClassname="px-2 py-4 font-sm text-gray-900 whitespace-nowrap dark:text-white" />
                                <TableRowsData data={item?.date_to_be_placed_on_platform || '-'} type={app_consts.TEXT_ROW_CUSTOM_CLASS} textClassname="px-2 py-4 font-sm text-gray-900 whitespace-nowrap dark:text-white" />
                                <TableRowsData data={item?.commodity.replace(/_/g, ' ').toUpperCase() || '-'} type={app_consts.TEXT_ROW_CUSTOM_CLASS} textClassname="px-2 py-4 font-sm text-gray-900 whitespace-nowrap dark:text-white" />
                                <TableRowsData data={"OFFER"} type={app_consts.TEXT_ROW_CUSTOM_CLASS} textClassname="px-2 py-4 font-sm text-gray-900 whitespace-nowrap dark:text-white" />
                                <TableRowsData data={item?.requested_unit_price ? `${numberFormat.format(item?.requested_unit_price)} ZMW` : ""} type={app_consts.TEXT_ROW_CUSTOM_CLASS} textClassname="px-2 py-4 font-sm text-gray-900 whitespace-nowrap dark:text-white " />
                                <TableRowsData data={item?.volume_on_offer || '0'} type={app_consts.TEXT_ROW_CUSTOM_CLASS} textClassname="px-2 py-4 font-sm text-gray-900 whitespace-nowrap dark:text-white text-center" />
                                <TableRowsData data={item?.delivery || '-'} type={app_consts.TEXT_ROW_CUSTOM_CLASS} textClassname="px-2 py-4 font-sm text-gray-900 whitespace-nowrap dark:text-white" />
                                <TableRowsData data={item?.commodity_order_status || '-'} type={app_consts.TEXT_STATUS_CUSTOM_CLASS} textClassname="bg-yellow-100 text-yellow-800 text-xs uppercase font-medium px-2 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300" parentClassname="px-6 py-4 text-normalfont-medium" />
                                <td className='px-2 py-4 font-sm text-gray-900 whitespace-nowrap dark:text-white'>
                                    <a
                                        href={`/broker/${entity_id}/${entity_mid}/live_market/${item?.id}`}
                                    >
                                        <button
                                            type='button'
                                            class='text-gray-900 bg-[#F7BE38] hover:bg-[#F7BE38]/90 focus:ring-4 focus:outline-none focus:ring-[#F7BE38]/50 font-medium rounded-lg text-sm px-5 py-2 text-center inline-flex items-center dark:focus:ring-[#F7BE38]/50 mr-2 mb-2'
                                        >
                                            {
                                                item?.status.toUpperCase() !== app_consts.SOLD ? "Trade" : "View"
                                            }
                                        </button>
                                    </a>
                                </td>
                            </tr>
                            {
                                item?.bids && item?.bids.length > 0 && item?.bids.map((bid_item, bidIndex) => {
                                    return (
                                        <>
                                            <tr key={bidIndex} className="mx-4 border-b bg-gray-100 dark:bg-gray-700 dark:border-gray-700 font-normal">
                                                <TableRowsData data={bid_item?.broker_bid_reference_no || '-'} type={app_consts.TEXT_ROW_CUSTOM_CLASS} textClassname="px-2 py-4 font-sm text-gray-900 whitespace-nowrap dark:text-white" />
                                                <TableRowsData data={bid_item?.broker_bid_date || '-'} type={app_consts.TEXT_ROW_CUSTOM_CLASS} textClassname="px-2 py-4 font-sm text-gray-900 whitespace-nowrap dark:text-white" />
                                                <TableRowsData data={bid_item?.commodity.replace(/_/g, ' ').toUpperCase() || '-'} type={app_consts.TEXT_ROW_CUSTOM_CLASS} textClassname="px-2 py-4 font-sm text-gray-900 whitespace-nowrap dark:text-white" />
                                                <TableRowsData data="BID" type={app_consts.TEXT_ROW_CUSTOM_CLASS} textClassname="px-2 py-4 font-sm text-gray-900 whitespace-nowrap dark:text-white" />
                                                <TableRowsData data={bid_item?.bid ? `${numberFormat.format(bid_item?.bid)} ZMW` : ""} type={app_consts.TEXT_ROW_CUSTOM_CLASS} textClassname="px-2 py-4 font-sm text-gray-900 whitespace-nowrap dark:text-white" />

                                                <TableRowsData data={bid_item?.bid_volume || '-'} type={app_consts.TEXT_ROW_CUSTOM_CLASS} textClassname="px-2 py-4 font-sm text-gray-900 whitespace-nowrap dark:text-white text-center" />
                                                <TableRowsData data={bid_item?.delivery || '-'} type={app_consts.TEXT_ROW_CUSTOM_CLASS} textClassname="px-2 py-4 font-sm text-gray-900 whitespace-nowrap dark:text-white" />
                                                <TableRowsData data={bid_item?.status || '-'} type={app_consts.TEXT_STATUS_CUSTOM_CLASS} textClassname="bg-yellow-100 text-yellow-800 text-xs uppercase font-medium px-2 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300" parentClassname="px-6 py-4 text-normalfont-medium" />
                                                <TableRowsData data="" type={app_consts.TEXT_ROW_CUSTOM_CLASS} textClassname="px-2 py-4 font-sm text-gray-900 whitespace-nowrap dark:text-white" />
                                            </tr>
                                        </>
                                    );
                                })
                            }
                        </>
                    );
                })
            }

            {/* Map Super Bids */}
            {data?.length > 0 &&
                super_bids?.map((item, index) => {
                    return (
                        <>
                            <tr key={item?.commodity_order_reference_no} className="border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700 font-bold">
                                <TableRowsData data={item?.commodity_order_reference_no ? addThreeDots(item?.commodity_order_reference_no, 30) : '-'} type={app_consts.TEXT_LINK_CUSTOM_CLASS} nav_link={`/broker/${entity_id}/${entity_mid}/bid/live_market/${item.id}`} textClassname="px-2 py-4 font-sm text-gray-900 whitespace-nowrap dark:text-white" />
                                <TableRowsData data={item?.date_to_be_placed_on_platform || '-'} type={app_consts.TEXT_ROW_CUSTOM_CLASS} textClassname="px-2 py-4 font-sm text-gray-900 whitespace-nowrap dark:text-white" />
                                <TableRowsData data={item?.commodity.replace(/_/g, ' ').toUpperCase() || '-'} type={app_consts.TEXT_ROW_CUSTOM_CLASS} textClassname="px-2 py-4 font-sm text-gray-900 whitespace-nowrap dark:text-white" />
                                <TableRowsData data='BID' type={app_consts.TEXT_ROW_CUSTOM_CLASS} textClassname="px-2 py-4 font-sm text-gray-900 whitespace-nowrap dark:text-white" />
                                <TableRowsData data={item?.requested_unit_price ? `${numberFormat.format(item?.requested_unit_price)} ZMW` : ""} type={app_consts.TEXT_ROW_CUSTOM_CLASS} textClassname="px-2 py-4 font-sm text-gray-900 whitespace-nowrap dark:text-white" />
                                <TableRowsData data={item?.volume || '-'} type={app_consts.TEXT_ROW_CUSTOM_CLASS} textClassname="px-2 py-4 font-sm text-gray-900 whitespace-nowrap dark:text-white text-center" />
                                <TableRowsData data={item?.delivery || '-'} type={app_consts.TEXT_ROW_CUSTOM_CLASS} textClassname="px-2 py-4 font-sm text-gray-900 whitespace-nowrap dark:text-white" />
                                <TableRowsData data={item?.commodity_order_status || '-'} type={app_consts.TEXT_STATUS_CUSTOM_CLASS} textClassname="bg-yellow-100 text-yellow-800 text-xs uppercase font-medium px-2 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300" parentClassname="px-6 py-4 text-normalfont-medium" />

                                <td className='px-2 py-4 font-sm text-gray-900 whitespace-nowrap dark:text-white'>
                                    <a
                                        href={`/broker/${entity_id}/${entity_mid}/bid/live_market/${item.id}`}
                                    >
                                        <button
                                            type='button'
                                            class='text-gray-900 bg-[#F7BE38] hover:bg-[#F7BE38]/90 focus:ring-4 focus:outline-none focus:ring-[#F7BE38]/50 font-medium rounded-lg text-sm px-5 py-2 text-center inline-flex items-center dark:focus:ring-[#F7BE38]/50 mr-2 mb-2'
                                        >
                                            {
                                                item?.status.toUpperCase() !== app_consts.SOLD ? "Trade" : "View"
                                            }
                                        </button>
                                    </a>
                                </td>
                            </tr>


                            {
                                item?.broker_offers && item?.broker_offers.length > 0 && item?.broker_offers.map((brokerOffer, brokerOfferIndex) => {
                                    return (
                                        <>
                                            <tr key={brokerOfferIndex} className="mx-4 border-b bg-gray-100 dark:bg-gray-700 dark:border-gray-700 font-normal">
                                                <TableRowsData data={brokerOffer?.broker_offer_reference_no || '-'} type={app_consts.TEXT_ROW_CUSTOM_CLASS} textClassname="px-2 py-4 font-sm text-gray-900 whitespace-nowrap dark:text-white" />
                                                <TableRowsData data={brokerOffer?.date_to_be_placed_on_platform || '-'} type={app_consts.TEXT_ROW_CUSTOM_CLASS} textClassname="px-2 py-4 font-sm text-gray-900 whitespace-nowrap dark:text-white" />
                                                <TableRowsData data={brokerOffer?.commodity.replace(/_/g, ' ').toUpperCase() || '-'} type={app_consts.TEXT_ROW_CUSTOM_CLASS} textClassname="px-2 py-4 font-sm text-gray-900 whitespace-nowrap dark:text-white" />
                                                <TableRowsData data="OFFER" type={app_consts.TEXT_ROW_CUSTOM_CLASS} textClassname="px-2 py-4 font-sm text-gray-900 whitespace-nowrap dark:text-white" />
                                                <TableRowsData data={brokerOffer?.offer ? `${numberFormat.format(brokerOffer?.offer)} ZMW` : ""} type={app_consts.TEXT_ROW_CUSTOM_CLASS} textClassname="px-2 py-4 font-sm text-gray-900 whitespace-nowrap dark:text-white" />

                                                <TableRowsData data={brokerOffer?.offer_volume || '-'} type={app_consts.TEXT_ROW_CUSTOM_CLASS} textClassname="px-2 py-4 font-sm text-gray-900 whitespace-nowrap dark:text-white text-center" />
                                                <TableRowsData data={brokerOffer?.delivery || '-'} type={app_consts.TEXT_ROW_CUSTOM_CLASS} textClassname="px-2 py-4 font-sm text-gray-900 whitespace-nowrap dark:text-white" />
                                                <TableRowsData data={brokerOffer?.status || '-'} type={app_consts.TEXT_STATUS_CUSTOM_CLASS} textClassname="bg-yellow-100 text-yellow-800 text-xs uppercase font-medium px-2 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300" parentClassname="px-6 py-4 text-normalfont-medium" />
                                                <TableRowsData data="" type={app_consts.TEXT_ROW_CUSTOM_CLASS} textClassname="px-2 py-4 font-sm text-gray-900 whitespace-nowrap dark:text-white" />
                                            </tr>
                                        </>
                                    );
                                })
                            }
                        </>
                    );
                })
            }
        </>
    );
};

// Membership Management
export const MemberShipManagement = ({ data, currentItems, page_size, currentPage }) => {

    const [pageSelected, setPageSelected] = useState(currentPage);
    const { entity_id, entity_mid } = useParams();
    const [toggleClick, setToggleClick] = useState({ toggleClick: false, selectedIndex: "" });

    useEffect(() => {
        setPageSelected(currentPage);
    }, [currentPage]);

    const toggleClickFunc = (index) => {
        if (index !== toggleClick.selectedIndex)
        {
            setToggleClick({ ...toggleClick, ["toggleClick"]: true, ["selectedIndex"]: index });
        }
        else 
        {
            setToggleClick({ ...toggleClick, ["toggleClick"]: !toggleClick.toggleClick, ["selectedIndex"]: "" });
        }
    };

    return (
        <>
            {
                data?.length > 0 &&
                currentItems?.map((item, index) => {
                    return (
                        <>
                            <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                {/* Other Rows */}
                                {
                                    item?.entity_profiles && item?.entity_profiles?.length > 0 ?
                                        <>
                                            <TableRowsData data={item?.organization_id || "---"} type={app_consts.TEXT_CHEVRON_DOWN} toggleClickFunc={toggleClickFunc} toggleClick={toggleClick} />
                                        </>
                                        :
                                        <>
                                            <TableRowsData type={app_consts.TEXT_MINUS} toggleClickFunc={toggleClickFunc} toggleClick={toggleClick} />
                                        </>
                                }
                                <TableRowsData data={item?.organization_id || "---"} type={app_consts.TEXT_LINK} nav_link={`/exchange/${entity_id}/${entity_mid}/membership/${item?.organization_id}`} />
                                <TableRowsData data={item?.entity_name ? addThreeDots(item?.entity_name, 20) : '-'} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.entity_category ? addThreeDots(item?.entity_category?.replace(/_/g, ' ').toUpperCase(), 20) : '-'} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={
                                    <>
                                        {
                                            item?.entity_email ? (
                                                <a
                                                    href={`mailto:${item?.entity_email}`}
                                                    className='hover:underline'
                                                >
                                                    {addThreeDots(item?.entity_email, 20)}
                                                </a>
                                            ) : (
                                                '-'
                                            )
                                        }
                                    </>
                                } type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.entity_phone || "-"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.inserted_at ? format(parseISO(item?.inserted_at), "yyyy-MM-dd HH:mm:ss") : '-'} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.status ? capitalizeSentences(item?.status) : "---"} type={app_consts.TEXT_STATUS} />
                                <TableRowsData type={app_consts.TEXT_VIEW_DETAILS} nav_link={`/exchange/${entity_id}/${entity_mid}/membership/${item?.organization_id}`} />
                            </tr>
                            {/* For Accordion */}
                            {
                                toggleClick.toggleClick && toggleClick.selectedIndex === item?.organization_id ?
                                    <>
                                        <tr key={item?.organization_id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                            <th scope="col" className="px-6 py-3">
                                            </th>
                                            {/* <th scope="col" className="flex justify-between items-center px-6 py-3">
                                                    <span>Member Type ID</span>
                                                </th> */}
                                            <th scope="col" className="px-6 py-3">
                                                <span>Member ID</span>
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                <span>Type</span>
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                <span>Status</span>
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                <span>Created Date</span>
                                            </th>
                                        </tr>
                                        {
                                            item?.entity_profiles?.map((entity_profile, index) => {
                                                return (
                                                    <tr key={item?.member_type_id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                                        {/* Other Rows */}
                                                        {/* <TableRowsData data={item?.receipt_number || "--"} type={app_consts.TEXT_CHECKBOX} /> */}
                                                        <td className=" bg-gray-100 text-center" >

                                                        </td>
                                                        {/* <td className="flex justify-between items-center bg-gray-100"> */}
                                                        <TableRowsData data={entity_profile.member_type_id ? entity_profile.member_type_id : '-'} type={app_consts.TEXT_ROW} textClassname=" bg-gray-100 text-center" />
                                                        <td className=" bg-gray-100 text-center">

                                                        </td>
                                                        <TableRowsData data={entity_profile?.type ? addThreeDots(entity_profile?.type?.replace(/_/g, ' ').toUpperCase(), 20) : '-'} type={app_consts.TEXT_ROW} textClassname=" bg-gray-100" />
                                                        <td className=" bg-gray-100 text-center">

                                                        </td>
                                                        <TableRowsData data={entity_profile?.status || '-'} type={app_consts.TEXT_STATUS_CUSTOM_CLASS} textClassname="bg-yellow-100 text-yellow-800 text-xs uppercase font-medium px-2 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300" parentClassname="bg-gray-100 px-6 py-4 text-normalfont-medium" />
                                                        <td className=" bg-gray-100 text-center">

                                                        </td>
                                                        <TableRowsData data={entity_profile.inserted_at ? format(parseISO(entity_profile.inserted_at), "yyyy-MM-dd HH:mm:ss") : '-'} type={app_consts.TEXT_ROW} textClassname=" bg-gray-100" />
                                                        <td className=" bg-gray-100 text-center">

                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        }
                                    </>
                                    :
                                    <>
                                    </>
                            }
                        </>
                    );
                }
                )
            }
        </>
    );
};

// Entity Profiles View From Exchange
export const EntityProfilesExchangeTable = ({ data, currentItems, page_size, currentPage }) => {

    const [pageSelected, setPageSelected] = useState(currentPage);
    const { entity_id, entity_mid, view } = useParams();

    useEffect(() => {
        setPageSelected(currentPage);
    }, [currentPage]);

    return (
        <>
            {
                data?.length > 0 &&
                currentItems?.map((item, index) => {
                    return (
                        <>
                            <tr key={item?.member_type_id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                {
                                    item?.type === app_consts.WAREHOUSE_OPERATOR?.toLowerCase() ?
                                        <>
                                            <TableRowsData data={item.member_type_id ? item.member_type_id : '-'} type={app_consts.TEXT_LINK_NEW_PAGE} nav_link={`/warehouse/view/${item?.entity_id}/${item?.member_type_id}`} />
                                        </>
                                        :
                                        item?.type === app_consts.BROKER?.toLowerCase() ?
                                            <>
                                                <TableRowsData data={item.member_type_id ? item.member_type_id : '-'} type={app_consts.TEXT_LINK_NEW_PAGE} nav_link={`/broker/view/${item?.entity_id}/${item?.member_type_id}`} />
                                            </>
                                            :
                                            <>
                                                <TableRowsData data={item.member_type_id ? item.member_type_id : '-'} type={app_consts.TEXT_LINK} nav_link={``} />
                                            </>
                                }
                                <TableRowsData data={item?.type ? addThreeDots(item?.type?.replace(/_/g, ' ').toUpperCase(), 20) : '-'} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.status || '-'} type={app_consts.TEXT_STATUS_CUSTOM_CLASS} textClassname="bg-yellow-100 text-yellow-800 text-xs uppercase font-medium px-2 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300" parentClassname="px-6 py-4 text-normalfont-medium" />
                                <TableRowsData data={item.inserted_at ? format(parseISO(item.inserted_at), "yyyy-MM-dd HH:mm:ss") : '-'} type={app_consts.TEXT_ROW} />
                                {
                                    item?.type === app_consts.WAREHOUSE_OPERATOR?.toLowerCase() ?
                                        <>
                                            <TableRowsData data={"View Dashboard"} type={app_consts.TEXT_VIEW_DETAILS_CUSTOM_CLASS_NEW_PAGE} nav_link={`/warehouse/view/${item?.entity_id}/${item?.member_type_id}`} textClassname={"px-3 py-2 text-xs font-medium text-center inline-flex items-center text-white bg-green-700 rounded-lg hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800 min-w-[110px]"} />
                                        </>
                                        :
                                        item?.type === app_consts.BROKER?.toLowerCase() ?
                                            <>
                                                <TableRowsData data={"View Dashboard"} type={app_consts.TEXT_VIEW_DETAILS_CUSTOM_CLASS_NEW_PAGE} nav_link={`/${item?.type}/view/${item?.entity_id}/${item?.member_type_id}`} textClassname={"px-3 py-2 text-xs font-medium text-center inline-flex items-center text-white bg-green-700 rounded-lg hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800 min-w-[110px]"} />
                                            </>
                                            :
                                            <>
                                                <TableRowsData data={"View Dashboard"} type={app_consts.TEXT_VIEW_DETAILS_CUSTOM_CLASS_NEW_PAGE} nav_link={""} textClassname={"px-3 py-2 text-xs font-medium text-center inline-flex items-center text-white bg-gray-400 rounded-lg hover:bg-gray-500 focus:ring-4 focus:outline-none focus:ring-gray-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800 min-w-[110px]"} />
                                            </>
                                }
                            </tr>
                        </>
                    );
                }
                )
            }
        </>
    );
};

// Bank Accounts Table
export const BankAccountsTable = ({ data, currentItems, page_size, currentPage }) => {

    const [pageSelected, setPageSelected] = useState(currentPage);
    const { entity_id, entity_mid, view } = useParams();

    useEffect(() => {
        setPageSelected(currentPage);
    }, [currentPage]);

    return (
        <>
            {
                data?.length > 0 &&
                currentItems?.map((item, index) => {
                    return (
                        <>
                            <tr key={item?.grn_no} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                {/* Other Rows */}
                                {/* <TableRowsData data={item?.receipt_number || "--"} type={app_consts.TEXT_CHECKBOX} /> */}
                                <TableRowsData data={item?.bank_account_name ? addThreeDots(item?.bank_account_name, 20) : '-'} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.bank_name ? addThreeDots(item?.bank_name, 20) : '-'} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.bank_account_number ? addThreeDots(item?.bank_account_number, 10) : '-'} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.branch_code ? item?.branch_code : "---"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.swift_code ? item?.swift_code : "---"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.status ? item?.status : ""} type={app_consts.TEXT_STATUS} />
                                <TableRowsData type={app_consts.TEXT_VIEW_DETAILS} />
                            </tr>
                        </>
                    );
                }
                )
            }
        </>
    );
};

// User Profiles Table
export const UserProfilesTable = ({ data, currentItems, page_size, currentPage }) => {

    const [pageSelected, setPageSelected] = useState(currentPage);
    const { entity_id, entity_mid, view } = useParams();

    useEffect(() => {
        setPageSelected(currentPage);
    }, [currentPage]);

    return (
        <>
            {
                data?.length > 0 &&
                currentItems?.map((item, index) => {
                    return (
                        <>
                            <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                {/* Other Rows */}
                                <TableRowsData data={item?.user_first_name || "---"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.user_last_name || "---"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.type?.replace(/_/g, ' ')?.toUpperCase() || "---"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.account_mobile || "---"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.account_email || "---"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.profile_created_on ? format(parseISO(item?.profile_created_on), "yyyy-MM-dd HH:mm:ss") : "---"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.profile_status ? capitalizeSentences(item?.profile_status) : "---"} type={app_consts.TEXT_STATUS} />
                                <TableRowsData type={app_consts.TEXT_VIEW_DETAILS} nav_link={`/exchange/${entity_id}/${entity_mid}/user_management/${item?.account_id}`} />
                            </tr>
                        </>
                    );
                }
                )
            }
        </>
    );
};

// Warehouse Operator Table
export const ExchangeWarehouseOperatorTable = ({ data, currentItems, page_size, currentPage }) => {

    const [pageSelected, setPageSelected] = useState(currentPage);

    useEffect(() => {
        setPageSelected(currentPage);
    }, [currentPage]);

    return (
        <>
            {
                data?.length > 0 &&
                currentItems?.map((item, index) => {
                    return (
                        <>
                            <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                {/* Other Rows */}
                                <TableRowsData data={item?.entity_profile_member_type_id || "---"} type={app_consts.TEXT_LINK} nav_link={`/warehouse/view/${item?.entity_id}/${item?.entity_profile_member_type_id}/profile`} />
                                <TableRowsData data={item?.entity_name || "---"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.total_warehouses || "---"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.total_warehouses_storage_capacity ? parseFloat(item?.total_warehouses_storage_capacity?.toFixed(2)) : "---"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.total_warehouse_receipts || "---"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.entity_profile_created_on ? format(parseISO(item?.entity_profile_created_on), "yyyy-MM-dd HH:mm:ss") : "---"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.entity_profile_status ? capitalizeSentences(item?.entity_profile_status) : "---"} type={app_consts.TEXT_STATUS} />
                                <TableRowsData type={app_consts.TEXT_VIEW_DETAILS} nav_link={`/warehouse/view/${item?.entity_id}/${item?.entity_profile_member_type_id}/profile`} />
                            </tr>
                        </>
                    );
                }
                )
            }
        </>
    );
};

// Exchange Warehouse Locations Table
export const ExchangeWarehouseLocationsListTable = ({ data, currentItems, page_size, currentPage }) => {

    const [pageSelected, setPageSelected] = useState(currentPage);
    const [toggleClick, setToggleClick] = useState({ toggleClick: false, selectedIndex: "" });
    const [toggle_error, setToggleError] = useState("");
    const dispatch = useDispatch();
    const [row_data, setRowData] = useState(currentItems);

    useEffect(() => {
        setRowData(currentItems);
    }, [currentItems]);

    useEffect(() => {
        setPageSelected(currentPage);
    }, [currentPage]);

    const toggleClickFunc = (index) => {
        dispatch(toggle_warehouse_viewable_on_website({ warehouse_id: index }));
        setToggleClick({ ...toggleClick, ["toggleClick"]: true, ["selectedIndex"]: index });
    };

    // Listen to Reducer for Role Amendment
    const toggle_view_website_listener = useSelector((state) => state.toggleViewWarehouseOnWebsite);
    // 
    useEffect(() => {
        if (toggle_view_website_listener.status !== app_consts.IDLE)
        {
            if (toggle_view_website_listener.isLoading)
            {
                setToggleClick({ ...toggleClick, ["toggleClick"]: true, ["selectedIndex"]: toggleClick.selectedIndex });
            }
            else
            {
                setToggleClick({ ...toggleClick, ["toggleClick"]: false });
                if (toggle_view_website_listener.error)
                {
                    setToggleError(toggle_view_website_listener.error);
                }
                else
                {
                    setToggleError("");
                    setToggleClick({ ...toggleClick, ["toggleClick"]: !toggleClick.toggleClick, ["selectedIndex"]: "" });
                    // Find Warehouse and Update
                    const updated_list = currentItems?.map((item) => {
                        if (item.warehouse_id === toggleClick.selectedIndex) 
                        {
                            item.warehouse_viewable = toggle_view_website_listener?.data?.data?.warehouse_viewable;
                            return {
                                ...item,
                                warehouse_viewable: toggle_view_website_listener?.data?.data?.warehouse_viewable
                            };
                        }
                        else
                        {
                            return item;
                        }
                    });
                    setRowData(updated_list);
                }
                dispatch(toggleViewWarehouseOnWebsiteReset());
            }
        }
    }, [toggle_view_website_listener]);

    return (
        <>
            {
                data?.length > 0 &&
                row_data?.map((item, index) => {
                    return (
                        <>
                            <tr key={item?.warehouse_id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                {/* Other Rows */}
                                {/* <TableRowsData data={item?.receipt_number || "--"} type={app_consts.TEXT_CHECKBOX} /> */}
                                <TableRowsData data={item?.warehouse_id ? addThreeDots(item?.warehouse_id, 20) : '-'} type={app_consts.TEXT_LINK} nav_link={`/warehouse/view/${item?.entity_id}/${item?.member_type_id}/locations/${item?.warehouse_id}`} />
                                <TableRowsData data={item?.entity_name ? addThreeDots(item?.entity_name, 20) : '-'} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.name ? addThreeDots(item?.name, 20) : '-'} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.address ? addThreeDots(item?.address, 20) : '-'} type={app_consts.TEXT_ROW} />
                                <TableRowsData toggleClickFunc={toggleClickFunc} toggleClick={toggleClick} data={item?.warehouse_id} check_value={item?.warehouse_viewable} type={app_consts.TABLE_TOGGLE} />
                                <TableRowsData data={item?.status ? capitalizeSentences(item?.status) : "---"} type={app_consts.TEXT_STATUS} />
                                <TableRowsData type={app_consts.TEXT_VIEW_DETAILS} nav_link={`/warehouse/view/${item?.entity_id}/${item?.member_type_id}/locations/${item?.warehouse_id}`} />
                            </tr>
                            {
                                toggle_error && toggleClick.selectedIndex === item?.warehouse_id && (
                                    <>
                                        <tr key={item?.id} className=" bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                            <TableRowsData type={app_consts.TEXT_ROW} />
                                            <TableRowsData type={app_consts.TEXT_ROW} />
                                            <TableRowsData type={app_consts.TEXT_ROW} />
                                            <TableRowsData type={app_consts.TEXT_ROW} />
                                            <TableRowsData textClassname={"px-6 py-4 text-red-600"} data={toggle_error} type={app_consts.TEXT_ROW_CUSTOM_CLASS} />
                                        </tr>
                                    </>
                                )
                            }
                        </>
                    );
                }
                )
            }
        </>
    );
};

// Exchange Brokers Table
export const ExchangeBrokersTable = ({ data, currentItems, page_size, currentPage }) => {

    const [pageSelected, setPageSelected] = useState(currentPage);
    const { entity_id, entity_mid, view } = useParams();
    const [toggleClick, setToggleClick] = useState({ toggleClick: false, selectedIndex: "" });
    const [toggle_error, setToggleError] = useState("");
    const dispatch = useDispatch();
    const [row_data, setRowData] = useState(currentItems);


    useEffect(() => {
        setRowData(currentItems);
    }, [currentItems]);

    useEffect(() => {
        setPageSelected(currentPage);
    }, [currentPage]);

    const toggleClickFunc = (index) => {
        dispatch(toggle_entity_profile_viewable_on_website({ member_type_id: index }));
        setToggleClick({ ...toggleClick, ["toggleClick"]: true, ["selectedIndex"]: index });
    };

    // Listen to Reducer for Role Amendment
    const toggle_view_website_listener = useSelector((state) => state.toggleViewWarehouseOnWebsite);
    // 
    useEffect(() => {
        if (toggle_view_website_listener.status !== app_consts.IDLE)
        {
            if (toggle_view_website_listener.isLoading)
            {
                setToggleClick({ ...toggleClick, ["toggleClick"]: true, ["selectedIndex"]: toggleClick.selectedIndex });
            }
            else
            {
                setToggleClick({ ...toggleClick, ["toggleClick"]: false });
                if (toggle_view_website_listener.error)
                {
                    setToggleError(toggle_view_website_listener.error);
                }
                else
                {
                    setToggleError("");
                    setToggleClick({ ...toggleClick, ["toggleClick"]: !toggleClick.toggleClick, ["selectedIndex"]: "" });
                    // Find Warehouse and Update
                    const updated_list = currentItems?.map((item) => {
                        if (item.entity_mid === toggleClick.selectedIndex) 
                        {
                            item.entity_profile_viewable = toggle_view_website_listener?.data?.entity_profile_viewable;
                            return {
                                ...item,
                                warehouse_viewable: toggle_view_website_listener?.data?.entity_profile_viewable
                            };
                        }
                        else
                        {
                            return item;
                        }
                    });
                    setRowData(updated_list);
                }
                dispatch(toggleViewWarehouseOnWebsiteReset());
            }
        }
    }, [toggle_view_website_listener]);

    return (
        <>
            {
                data?.length > 0 &&
                row_data?.map((item, index) => {
                    return (
                        <>
                            <tr key={item?.entity_mid} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                {/* Other Rows */}
                                <TableRowsData data={item?.entity_mid ? addThreeDots(item?.entity_mid, 20) : '-'} type={app_consts.TEXT_LINK} nav_link={`/exchange/${entity_id}/${entity_mid}/broker_management/${item?.entity_mid}`} />
                                <TableRowsData data={item?.entity_name ? addThreeDots(item?.entity_name, 20) : '-'} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.entity_extension?.type ? addThreeDots(item?.entity_extension?.type?.replace(/_/g, ' ')?.toUpperCase(), 20) : '-'} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.member_count || '-'} type={app_consts.TEXT_ROW} textClassname="text-center" />
                                <TableRowsData data={item?.entity_extension?.offering_and_bidding_authority ? addThreeDots(item?.entity_extension?.offering_and_bidding_authority?.replace(/_/g, ' ')?.toUpperCase(), 20) : '-'} type={app_consts.TEXT_ROW} />
                                <TableRowsData toggleClickFunc={toggleClickFunc} toggleClick={toggleClick} data={item?.entity_mid} check_value={item?.entity_profile_viewable} type={app_consts.TABLE_TOGGLE} />
                                <TableRowsData data={item?.entity_extension?.status ? addThreeDots(item?.entity_extension?.status?.replace(/_/g, ' ')?.toUpperCase(), 20) : 'NOT SET'} type={app_consts.TEXT_STATUS} />
                                <TableRowsData type={app_consts.TEXT_VIEW_DETAILS} nav_link={`/exchange/${entity_id}/${entity_mid}/broker_management/${item?.entity_mid}`} />
                            </tr>
                            {
                                toggle_error && toggleClick.selectedIndex === item?.entity_mid && (
                                    <>
                                        <tr key={item?.id} className=" bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                            <TableRowsData type={app_consts.TEXT_ROW} />
                                            <TableRowsData type={app_consts.TEXT_ROW} />
                                            <TableRowsData type={app_consts.TEXT_ROW} />
                                            <TableRowsData type={app_consts.TEXT_ROW} />
                                            <TableRowsData type={app_consts.TEXT_ROW} />
                                            <TableRowsData textClassname={"px-6 py-4 text-red-600"} data={toggle_error} type={app_consts.TEXT_ROW_CUSTOM_CLASS} />
                                        </tr>
                                    </>
                                )
                            }
                        </>
                    );
                }
                )
            }
        </>
    );
};

// Exchange Broker Details Super Offer Table
export const ExchangeBrokerDetailsSuperOffersTable = ({ data, currentItems, page_size, currentPage }) => {

    const [pageSelected, setPageSelected] = useState(currentPage);
    const { entity_id, entity_mid, view } = useParams();

    useEffect(() => {
        setPageSelected(currentPage);
    }, [currentPage]);

    return (
        <>
            {
                data?.length > 0 &&
                currentItems?.map((item, index) => {
                    return (
                        <>
                            <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                {/* Other Rows */}
                                <TableRowsData data={item?.broker_offer?.inserted_at ? format(parseISO(item?.broker_offer?.inserted_at), "yyyy-MM-dd HH:mm:ss") : "---"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.broker_offer?.commodity?.replace(/_/g, ' ').toUpperCase() || "---"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.broker_offer?.volume_on_offer?.toFixed(2) || "---"} type={app_consts.TEXT_ROW} textClassname={"text-center"} />
                                <TableRowsData data={item?.broker_offer?.requested_unit_price?.toFixed(2) || "---"} type={app_consts.TEXT_ROW} textClassname={"text-center"} />
                                <TableRowsData data={item?.broker_offer?.quality?.replace(/_/g, ' ').toUpperCase() || "---"} type={app_consts.TEXT_ROW} textClassname={"text-center"} />
                                <TableRowsData data={item?.commodity_order?.status ? capitalizeSentences(item?.commodity_order?.status) : "---"} type={app_consts.TEXT_STATUS} />
                                <TableRowsData type={app_consts.TEXT_VIEW_DETAILS} nav_link={`/markets/offer_details/${item?.broker_offer?.id}`} />
                            </tr>
                        </>
                    );
                }
                )
            }
        </>
    );
};

// User Profiles Table
export const SpecificUserProfilesTable = ({ data, currentItems, page_size, currentPage }) => {

    const [pageSelected, setPageSelected] = useState(currentPage);
    const { entity_id, entity_mid, view } = useParams();

    useEffect(() => {
        setPageSelected(currentPage);
    }, [currentPage]);

    return (
        <>
            {
                data?.length > 0 &&
                currentItems?.map((item, index) => {
                    return (
                        <>
                            <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                {/* Other Rows */}
                                <TableRowsData data={item?.user_first_name || "---"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.user_last_name || "---"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.account_mobile || "---"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.account_email || "---"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.profile_created_on ? format(parseISO(item?.profile_created_on), "yyyy-MM-dd HH:mm:ss") : "---"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.profile_status ? capitalizeSentences(item?.profile_status) : "---"} type={app_consts.TEXT_STATUS} />
                                {/* <TableRowsData type={app_consts.TEXT_VIEW_DETAILS} /> */}
                            </tr>
                        </>
                    );
                }
                )
            }
        </>
    );
};

// Exchange Broker Details Super Offer Table
export const ExchangeBrokerDetailsSuperBidsTable = ({ data, currentItems, page_size, currentPage }) => {

    const [pageSelected, setPageSelected] = useState(currentPage);
    const { entity_id, entity_mid, view } = useParams();

    useEffect(() => {
        setPageSelected(currentPage);
    }, [currentPage]);

    return (
        <>
            {
                data?.length > 0 &&
                currentItems?.map((item, index) => {
                    return (
                        <>
                            <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                {/* Other Rows */}
                                <TableRowsData data={item?.broker_bid?.inserted_at ? format(parseISO(item?.broker_bid?.inserted_at), "yyyy-MM-dd HH:mm:ss") : "---"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.broker_bid?.commodity?.replace(/_/g, ' ').toUpperCase() || "---"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.broker_bid?.volume?.toFixed(2) || "---"} type={app_consts.TEXT_ROW} textClassname={"text-center"} />
                                <TableRowsData data={item?.broker_bid?.requested_unit_price?.toFixed(2) || "---"} type={app_consts.TEXT_ROW} textClassname={"text-center"} />
                                <TableRowsData data={item?.broker_bid?.quality?.replace(/_/g, ' ').toUpperCase() || "---"} type={app_consts.TEXT_ROW} textClassname={"text-center"} />
                                <TableRowsData data={item?.commodity_order?.status ? capitalizeSentences(item?.commodity_order?.status) : "---"} type={app_consts.TEXT_STATUS} />
                                <TableRowsData type={app_consts.TEXT_VIEW_DETAILS} nav_link={`/markets/bid_details/${item?.broker_bid?.id}`} />
                            </tr>
                        </>
                    );
                }
                )
            }
        </>
    );
};

// Exchange Brokers Table
export const ExchangeCooperativesTable = ({ data, currentItems, page_size, currentPage }) => {

    const [pageSelected, setPageSelected] = useState(currentPage);
    const { entity_id, entity_mid, view } = useParams();
    const [toggleClick, setToggleClick] = useState({ toggleClick: false, selectedIndex: "" });
    const [toggle_error, setToggleError] = useState("");
    const dispatch = useDispatch();
    const [row_data, setRowData] = useState(currentItems);


    useEffect(() => {
        setRowData(currentItems);
    }, [currentItems]);

    useEffect(() => {
        setPageSelected(currentPage);
    }, [currentPage]);

    const toggleClickFunc = (index) => {
        dispatch(toggle_entity_profile_viewable_on_website({ member_type_id: index }));
        setToggleClick({ ...toggleClick, ["toggleClick"]: true, ["selectedIndex"]: index });
    };

    // Listen to Reducer for Role Amendment
    const toggle_view_website_listener = useSelector((state) => state.toggleViewWarehouseOnWebsite);
    // 
    useEffect(() => {
        if (toggle_view_website_listener.status !== app_consts.IDLE)
        {
            if (toggle_view_website_listener.isLoading)
            {
                setToggleClick({ ...toggleClick, ["toggleClick"]: true, ["selectedIndex"]: toggleClick.selectedIndex });
            }
            else
            {
                setToggleClick({ ...toggleClick, ["toggleClick"]: false });
                if (toggle_view_website_listener.error)
                {
                    setToggleError(toggle_view_website_listener.error);
                }
                else
                {
                    setToggleError("");
                    setToggleClick({ ...toggleClick, ["toggleClick"]: !toggleClick.toggleClick, ["selectedIndex"]: "" });
                    // Find Warehouse and Update
                    const updated_list = currentItems?.map((item) => {
                        if (item.entity_mid === toggleClick.selectedIndex) 
                        {
                            item.entity_profile_viewable = toggle_view_website_listener?.data?.entity_profile_viewable;
                            return {
                                ...item,
                                warehouse_viewable: toggle_view_website_listener?.data?.entity_profile_viewable
                            };
                        }
                        else
                        {
                            return item;
                        }
                    });
                    setRowData(updated_list);
                }
                dispatch(toggleViewWarehouseOnWebsiteReset());
            }
        }
    }, [toggle_view_website_listener]);

    return (
        <>
            {
                data?.length > 0 &&
                row_data?.map((item, index) => {
                    return (
                        <>
                            <tr key={item?.entity_mid} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                {/* Other Rows */}
                                <TableRowsData data={item?.entity_mid ? addThreeDots(item?.entity_mid, 20) : '-'} type={app_consts.TEXT_LINK} nav_link={`/exchange/${entity_id}/${entity_mid}/cooperative_management/${item?.entity_mid}`} />
                                <TableRowsData data={item?.entity_name ? addThreeDots(item?.entity_name, 20) : '-'} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.entity_sector ? addThreeDots(item?.entity_sector?.replace(/_/g, ' ')?.toUpperCase(), 20) : '-'} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.entity_province ? addThreeDots(item?.entity_province, 20) : '-'} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.member_count || '0'} type={app_consts.TEXT_ROW} textClassname="text-center" />
                                <TableRowsData toggleClickFunc={toggleClickFunc} toggleClick={toggleClick} data={item?.entity_mid} check_value={item?.entity_profile_viewable} type={app_consts.TABLE_TOGGLE} />
                                <TableRowsData data={item?.entity_profile_status ? addThreeDots(item?.entity_profile_status?.replace(/_/g, ' ')?.toUpperCase(), 20) : '-'} type={app_consts.TEXT_STATUS} />
                                <TableRowsData type={app_consts.TEXT_VIEW_DETAILS} nav_link={`/exchange/${entity_id}/${entity_mid}/cooperative_management/${item?.entity_mid}`} />
                            </tr>
                            {
                                toggle_error && toggleClick.selectedIndex === item?.entity_mid && (
                                    <>
                                        <tr key={item?.id} className=" bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                            <TableRowsData type={app_consts.TEXT_ROW} />
                                            <TableRowsData type={app_consts.TEXT_ROW} />
                                            <TableRowsData type={app_consts.TEXT_ROW} />
                                            <TableRowsData type={app_consts.TEXT_ROW} />
                                            <TableRowsData type={app_consts.TEXT_ROW} />
                                            <TableRowsData textClassname={"px-6 py-4 text-red-600"} data={toggle_error} type={app_consts.TEXT_ROW_CUSTOM_CLASS} />
                                        </tr>
                                    </>
                                )
                            }
                        </>
                    );
                }
                )
            }
        </>
    );
};

// Exchange Entity Producers Table
export const ExchangeEntityProducersTable = ({ data, currentItems, page_size, currentPage }) => {

    const [pageSelected, setPageSelected] = useState(currentPage);
    const { entity_id, entity_mid, view } = useParams();

    useEffect(() => {
        setPageSelected(currentPage);
    }, [currentPage]);

    return (
        <>
            {
                data?.length > 0 &&
                currentItems?.map((item, index) => {
                    return (
                        <>
                            <tr key={item?.producer.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                {/* Other Rows */}
                                <TableRowsData data={item?.producer?.producer_id ? addThreeDots(item?.producer?.producer_id, 20) : '-'} type={app_consts.TEXT_LINK} nav_link={`/exchange/${entity_id}/${entity_mid}/producer_management/${item?.producer?.producer_id}`} />
                                <TableRowsData data={item?.producer?.type?.replace(/_/g, ' ')?.toUpperCase() || '-'} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.producer?.type !== app_consts.INDIVIDUAL.toLowerCase() ? addThreeDots(item?.producer_entity?.producer_entity_name, 20) : ""} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.producer.contact_email ? (
                                    <a
                                        href={`mailto:${item?.producer.contact_email}`}
                                        className='hover:underline'
                                    >
                                        {item?.producer.contact_email}
                                    </a>
                                ) : (
                                    '-'
                                )} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.producer.c || '-'} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.receipt_count || '0'} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.producer.province || '-'} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.producer.country || '-'} type={app_consts.TEXT_ROW} />
                                <TableRowsData type={app_consts.TEXT_VIEW_DETAILS} nav_link={`/exchange/${entity_id}/${entity_mid}/producer_management/${item?.producer?.producer_id}`} />
                            </tr>
                        </>
                    );
                }
                )
            }
        </>
    );
};

// Users Table
export const UsersTable = ({ data, currentItems, page_size, currentPage }) => {

    const [pageSelected, setPageSelected] = useState(currentPage);
    const { entity_id, entity_mid, view } = useParams();

    useEffect(() => {
        setPageSelected(currentPage);
    }, [currentPage]);

    return (
        <>
            {
                data?.length > 0 &&
                currentItems?.map((item, index) => {
                    return (
                        <>
                            <tr key={item?.account_id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                {/* Other Rows */}
                                <TableRowsData data={item?.user_first_name ? addThreeDots(item?.user_first_name, 20) : "---"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.user_last_name ? addThreeDots(item?.user_last_name, 20) : "--"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.account_mobile ? addThreeDots(item?.account_mobile, 20) : "---"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.account_email ? addThreeDots(item?.account_email, 20) : "---"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.account_created_on ? format(parseISO(item?.account_created_on), "yyyy-MM-dd HH:mm:ss") : "---"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.account_verified ? addThreeDots("Verified", 20) : 'Not Verified'} type={app_consts.TEXT_STATUS} />
                                <TableRowsData data={item?.account_verified ? addThreeDots("Active", 20) : 'Not Active'} type={app_consts.TEXT_STATUS} />
                                <TableRowsData type={app_consts.TEXT_VIEW_DETAILS} nav_link={`/exchange/${entity_id}/${entity_mid}/user_management/${item?.account_id}`} />
                            </tr>
                        </>
                    );
                }
                )
            }
        </>
    );
};

// User Account Profile Table
export const UserAccountProfileTable = ({ data, currentItems, page_size, currentPage }) => {

    const [pageSelected, setPageSelected] = useState(currentPage);
    const { entity_id, entity_mid } = useParams();
    const [toggleClick, setToggleClick] = useState({ toggleClick: false, selectedIndex: "" });

    useEffect(() => {
        setPageSelected(currentPage);
    }, [currentPage]);

    const toggleClickFunc = (index) => {
        if (index !== toggleClick.selectedIndex)
        {
            setToggleClick({ ...toggleClick, ["toggleClick"]: true, ["selectedIndex"]: index });
        }
        else 
        {
            setToggleClick({ ...toggleClick, ["toggleClick"]: !toggleClick.toggleClick, ["selectedIndex"]: "" });
        }
    };

    return (
        <>
            {
                data?.length > 0 &&
                currentItems?.map((item, index) => {
                    return (
                        <>
                            <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                {/* Other Rows */}
                                {
                                    item?.profiles && item?.profiles?.length > 0 ?
                                        <>
                                            <TableRowsData data={item?.organization_id || "---"} type={app_consts.TEXT_CHEVRON_DOWN} toggleClickFunc={toggleClickFunc} toggleClick={toggleClick} />
                                        </>
                                        :
                                        <>
                                            <TableRowsData type={app_consts.TEXT_MINUS} toggleClickFunc={toggleClickFunc} toggleClick={toggleClick} />
                                        </>
                                }
                                <TableRowsData data={item?.organization_id || "---"} type={app_consts.TEXT_LINK} nav_link={`/exchange/${entity_id}/${entity_mid}/membership/${item?.organization_id}`} />
                                <TableRowsData data={item?.entity_name ? addThreeDots(item?.entity_name, 20) : '-'} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={
                                    <>
                                        {
                                            item?.entity_email ? (
                                                <a
                                                    href={`mailto:${item?.entity_email}`}
                                                    className='hover:underline'
                                                >
                                                    {addThreeDots(item?.entity_email, 20)}
                                                </a>
                                            ) : (
                                                '-'
                                            )
                                        }
                                    </>
                                } type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.entity_phone || "-"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.profiles?.length || 0} type={app_consts.TEXT_ROW} textClassname={"text-center"} />
                                <TableRowsData data={item?.status ? capitalizeSentences(item?.status) : "---"} type={app_consts.TEXT_STATUS} />
                                <TableRowsData type={app_consts.TEXT_VIEW_DETAILS} nav_link={`/exchange/${entity_id}/${entity_mid}/membership/${item?.organization_id}`} />
                            </tr>
                            {/* For Accordion */}
                            {
                                toggleClick.toggleClick && toggleClick.selectedIndex === item?.organization_id ?
                                    <>
                                        <tr key={item?.organization_id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                            <th scope="col" className="px-6 py-3">
                                            </th>
                                            {/* <th scope="col" className="flex justify-between items-center px-6 py-3">
                                                    <span>Member Type ID</span>
                                                </th> */}
                                            <th scope="col" className="px-6 py-3">
                                                <span>Member ID</span>
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                <span>Type</span>
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                <span>Status</span>
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                <span>Created Date</span>
                                            </th>
                                        </tr>
                                        {
                                            item?.profiles?.map((profile, index) => {
                                                return (
                                                    <tr key={item?.member_type_id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                                        {/* Other Rows */}
                                                        {/* <TableRowsData data={item?.receipt_number || "--"} type={app_consts.TEXT_CHECKBOX} /> */}
                                                        <td className=" bg-gray-100 text-center" >

                                                        </td>
                                                        {/* <td className="flex justify-between items-center bg-gray-100"> */}
                                                        <TableRowsData data={profile.member_type_id ? profile.member_type_id : '-'} type={app_consts.TEXT_ROW} textClassname=" bg-gray-100 text-center" />
                                                        <td className=" bg-gray-100 text-center">

                                                        </td>
                                                        <TableRowsData data={profile?.profile_type ? addThreeDots(profile?.profile_type?.replace(/_/g, ' ').toUpperCase(), 20) : '-'} type={app_consts.TEXT_ROW} textClassname=" bg-gray-100" />
                                                        <td className=" bg-gray-100 text-center">

                                                        </td>
                                                        <TableRowsData data={profile?.profile_status || '-'} type={app_consts.TEXT_STATUS_CUSTOM_CLASS} textClassname="bg-yellow-100 text-yellow-800 text-xs uppercase font-medium px-2 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300" parentClassname="bg-gray-100 px-6 py-4 text-normalfont-medium" />
                                                        <td className=" bg-gray-100 text-center">

                                                        </td>
                                                        <TableRowsData data={profile.inserted_at ? format(parseISO(profile.inserted_at), "yyyy-MM-dd HH:mm:ss") : '-'} type={app_consts.TEXT_ROW} textClassname=" bg-gray-100" />
                                                        <td className=" bg-gray-100 text-center">

                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        }
                                    </>
                                    :
                                    <>
                                    </>
                            }
                        </>
                    );
                }
                )
            }
        </>
    );
};

// User Account Brokers and Cooperatives Table
export const UserAccountBrokersCooperatives = ({ data, currentItems, page_size, currentPage }) => {

    const [pageSelected, setPageSelected] = useState(currentPage);
    const { entity_id, entity_mid } = useParams();
    const [toggleClick, setToggleClick] = useState({ toggleClick: false, selectedIndex: "" });

    useEffect(() => {
        setPageSelected(currentPage);
    }, [currentPage]);

    const toggleClickFunc = (index) => {
        if (index !== toggleClick.selectedIndex)
        {
            setToggleClick({ ...toggleClick, ["toggleClick"]: true, ["selectedIndex"]: index });
        }
        else 
        {
            setToggleClick({ ...toggleClick, ["toggleClick"]: !toggleClick.toggleClick, ["selectedIndex"]: "" });
        }
    };

    return (
        <>
            {
                data?.length > 0 &&
                currentItems?.map((item, index) => {
                    return (
                        <>
                            <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                {/* Other Rows */}
                                <TableRowsData data={item?.entity_profile_member_type_id || "---"} type={app_consts.TEXT_LINK} nav_link={`/exchange/${entity_id}/${entity_mid}/membership/${item?.entity_profile_member_type_id}`} />
                                <TableRowsData data={item?.entity_name ? addThreeDots(item?.entity_name, 20) : '-'} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.entity_profile_type ? addThreeDots(item?.entity_profile_type?.replace(/_/g, ' ').toUpperCase(), 20) : '-'} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={
                                    <>
                                        {
                                            item?.entity_email ? (
                                                <a
                                                    href={`mailto:${item?.entity_email}`}
                                                    className='hover:underline'
                                                >
                                                    {addThreeDots(item?.entity_email, 20)}
                                                </a>
                                            ) : (
                                                '-'
                                            )
                                        }
                                    </>
                                } type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.entity_phone || "-"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.entity_profile_status ? capitalizeSentences(item?.entity_profile_status) : "---"} type={app_consts.TEXT_STATUS} />
                                <TableRowsData type={app_consts.TEXT_VIEW_DETAILS} nav_link={item?.entity_profile_type === app_consts.BROKER.toLowerCase() ? `/exchange/${entity_id}/${entity_mid}/broker_management/${item?.entity_profile_member_type_id}` : `/exchange/${entity_id}/${entity_mid}/cooperative_management/${item?.entity_profile_member_type_id}`} />
                            </tr>
                        </>
                    );
                }
                )
            }
        </>
    );
};

// Invalid Cooperative Entity CSV Data Table
export const InvalidCooperativeEntityCSVDataTable = ({ data, currentItems, page_size, currentPage }) => {

    const [pageSelected, setPageSelected] = useState(currentPage);
    const { entity_id, entity_mid, view } = useParams();
    const [toggleClick, setToggleClick] = useState({ toggleClick: false, selectedIndex: "" });

    useEffect(() => {
        setPageSelected(currentPage);
    }, [currentPage]);

    const toggleClickFunc = (index) => {
        if (index !== toggleClick.selectedIndex)
        {
            setToggleClick({ ...toggleClick, ["toggleClick"]: true, ["selectedIndex"]: index });
        }
        else 
        {
            setToggleClick({ ...toggleClick, ["toggleClick"]: !toggleClick.toggleClick, ["selectedIndex"]: "" });
        }
    };

    return (
        <>
            {
                data?.length > 0 &&
                currentItems?.map((item, index) => {
                    return (
                        <>
                            <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                {/* Other Rows */}
                                {
                                    <TableRowsData data={index} type={item?.errors?.length > 0 ? app_consts.TEXT_CHEVRON_DOWN : app_consts.TEXT_MINUS} toggleClickFunc={toggleClickFunc} toggleClick={toggleClick} />
                                }
                                <TableRowsData data={item?.contact_name ? addThreeDots(item?.contact_name, 20) : '-'} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.contact_number ? addThreeDots(item?.contact_number, 20) : '-'} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.contact_email ? addThreeDots(item?.contact_email, 20) : '-'} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.entity_name ? addThreeDots(item?.entity_name, 20) : '-'} textClassname={"capitalize"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.entity_email ? addThreeDots(item?.entity_email, 20) : '-'} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.entity_mobile ? addThreeDots(item?.entity_mobile, 20) : '-'} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.area ? addThreeDots(item?.area, 20) : '-'} textClassname={"capitalize"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.district ? addThreeDots(item?.district, 20) : '-'} textClassname={"capitalize"} type={app_consts.TEXT_ROW} />
                            </tr>
                            {/* For Accordion */}
                            {
                                toggleClick.toggleClick && toggleClick.selectedIndex === index ?
                                    <>
                                        <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                            <th scope="col" className="px-6 py-3">
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                <span className='text-red-600 font-semibold'>Errors</span>
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                            </th>
                                        </tr>
                                        {
                                            item?.errors && item?.errors?.map((error, index) => {
                                                return (
                                                    <>
                                                        <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                                            {/* Other Rows */}
                                                            <TableRowsData data={index + 1} textClassname={"text-center"} type={app_consts.TEXT_ROW} />
                                                            <TableRowsData data={error || '-'} type={app_consts.TEXT_ROW} textClassname=" bg-gray-100" />
                                                            <td className=" bg-gray-100 text-center" >

                                                            </td>
                                                            <td className=" bg-gray-100 text-center">

                                                            </td>
                                                            <td className=" bg-gray-100 text-center">

                                                            </td>
                                                            <td className=" bg-gray-100 text-center">

                                                            </td>
                                                            <td className=" bg-gray-100 text-center">

                                                            </td>
                                                        </tr>
                                                        <tr key={index} className='pl-4'>
                                                        </tr>
                                                    </>
                                                );
                                            })
                                        }
                                    </>
                                    :
                                    <>
                                    </>
                            }
                        </>
                    );
                }
                )
            }
        </>
    );
};

// Valid Cooperative Entity CSV Data
export const ValidCooperativeEntityCSVDataTable = ({ data, currentItems, page_size, currentPage }) => {

    const [pageSelected, setPageSelected] = useState(currentPage);
    const { entity_id, entity_mid, view } = useParams();

    useEffect(() => {
        setPageSelected(currentPage);
    }, [currentPage]);

    return (
        <>
            {
                data?.length > 0 &&
                currentItems?.map((item, index) => {
                    return (
                        <>
                            <tr key={item?.account_id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                {/* Other Rows */}
                                <TableRowsData data={item?.first_name ? addThreeDots(item?.first_name, 20) : "---"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.middle_name ? addThreeDots(item?.middle_name, 20) : "--"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.last_name ? addThreeDots(item?.last_name, 20) : "--"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.contact_number ? addThreeDots(item?.contact_number, 20) : "---"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.contact_email ? addThreeDots(item?.contact_email, 20) : "---"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.entity_name ? addThreeDots(item?.entity_name, 20) : "---"} textClassname={"capitalize"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.entity_mobile ? addThreeDots(item?.entity_mobile, 20) : "---"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.entity_email ? addThreeDots(item?.entity_email, 20) : "---"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.area ? addThreeDots(item?.area, 20) : "---"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.district ? addThreeDots(item?.district, 20) : "---"} textClassname={"capitalize"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.province ? addThreeDots(item?.province, 20) : "---"} type={app_consts.TEXT_ROW} />
                            </tr>
                        </>
                    );
                }
                )
            }
        </>
    );
};

// Invalid Cooperative Entity CSV Data Table
export const InvalidCooperativeMemberCSVDataTable = ({ data, currentItems, page_size, currentPage }) => {

    const [pageSelected, setPageSelected] = useState(currentPage);
    const { entity_id, entity_mid, view } = useParams();
    const [toggleClick, setToggleClick] = useState({ toggleClick: false, selectedIndex: "" });

    useEffect(() => {
        setPageSelected(currentPage);
    }, [currentPage]);

    const toggleClickFunc = (index) => {
        if (index !== toggleClick.selectedIndex)
        {
            setToggleClick({ ...toggleClick, ["toggleClick"]: true, ["selectedIndex"]: index });
        }
        else 
        {
            setToggleClick({ ...toggleClick, ["toggleClick"]: !toggleClick.toggleClick, ["selectedIndex"]: "" });
        }
    };

    return (
        <>
            {
                data?.length > 0 &&
                currentItems?.map((item, index) => {
                    return (
                        <>
                            <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                {/* Other Rows */}
                                {
                                    <TableRowsData data={index} type={item?.errors?.length > 0 ? app_consts.TEXT_CHEVRON_DOWN : app_consts.TEXT_MINUS} toggleClickFunc={toggleClickFunc} toggleClick={toggleClick} />
                                }
                                <TableRowsData data={item?.name ? addThreeDots(item?.name, 20) : '-'} textClassname={"capitalize"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.gender ? addThreeDots(item?.gender, 20) : '-'} textClassname={"capitalize"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.phone_number ? addThreeDots(item?.phone_number, 20) : '-'} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.nrc_number ? addThreeDots(item?.nrc_number, 20) : '-'} textClassname={"capitalize"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.email ? addThreeDots(item?.email, 20) : '-'} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.member_type_id ? addThreeDots(item?.member_type_id, 20) : '-'} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.commodities_array && item?.commodities_array?.length > 0 ?
                                    <>
                                        <div className='grid grid-cols-1 md:grid-cols-2'>
                                            {
                                                item?.commodities_array?.map((commodity, index) => {
                                                    return (
                                                        <div className='w-auto m-2'>
                                                            <span className='text-xs w-auto p-2 mt-2 bg-gray-300 w-auto border rounded-lg'>{commodity.replace(/_/g, ' ').toUpperCase()} </span>
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                    </> : '-'} textClassname={"capitalize"} type={app_consts.TEXT_ROW} />
                            </tr>
                            {/* For Accordion */}
                            {
                                toggleClick.toggleClick && toggleClick.selectedIndex === index ?
                                    <>
                                        <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                            <th scope="col" className="px-6 py-3">
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                <span className='text-red-600 font-semibold'>Errors</span>
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                            </th>
                                        </tr>
                                        {
                                            item?.errors && item?.errors?.map((error, index) => {
                                                return (
                                                    <>
                                                        <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                                            {/* Other Rows */}
                                                            <TableRowsData data={index + 1} textClassname={"text-center"} type={app_consts.TEXT_ROW} />
                                                            <TableRowsData data={error || '-'} type={app_consts.TEXT_ROW} textClassname=" bg-gray-100" />
                                                            <td className=" bg-gray-100 text-center" >

                                                            </td>
                                                            <td className=" bg-gray-100 text-center">

                                                            </td>
                                                            <td className=" bg-gray-100 text-center">

                                                            </td>
                                                            <td className=" bg-gray-100 text-center">

                                                            </td>
                                                            <td className=" bg-gray-100 text-center">

                                                            </td>
                                                        </tr>
                                                        <tr key={index} className='pl-4'>
                                                        </tr>
                                                    </>
                                                );
                                            })
                                        }
                                    </>
                                    :
                                    <>
                                    </>
                            }
                        </>
                    );
                }
                )
            }
        </>
    );
};

// Valid Cooperative Member CSV Data
export const ValidCooperativeMemberCSVDataTable = ({ data, currentItems, page_size, currentPage }) => {

    const [pageSelected, setPageSelected] = useState(currentPage);
    const { entity_id, entity_mid, view } = useParams();

    useEffect(() => {
        setPageSelected(currentPage);
    }, [currentPage]);

    return (
        <>
            {
                data?.length > 0 &&
                currentItems?.map((item, index) => {
                    return (
                        <>
                            <tr key={item?.account_id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                {/* Other Rows */}
                                <TableRowsData data={item?.first_name ? addThreeDots(item?.first_name, 20) : "---"} textClassname={"capitalize"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.middle_name ? addThreeDots(item?.middle_name, 20) : "--"} textClassname={"capitalize"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.last_name ? addThreeDots(item?.last_name, 20) : "--"} textClassname={"capitalize"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.gender ? addThreeDots(item?.gender, 20) : "---"} textClassname={"capitalize"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.phone_number ? addThreeDots(item?.phone_number, 20) : "---"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.nrc_number ? addThreeDots(item?.nrc_number, 20) : "---"} textClassname={"capitalize"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.email ? addThreeDots(item?.email, 20) : "---"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.member_type_id ? addThreeDots(item?.member_type_id, 20) : "---"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.commodities_array && item?.commodities_array?.length > 0 ?
                                    <>
                                        <div className='grid grid-cols-1 md:grid-cols-2'>
                                            {
                                                item?.commodities_array?.map((commodity, index) => {
                                                    return (
                                                        <div className='w-auto m-2'>
                                                            <span className='text-xs w-auto p-2 mt-2 bg-gray-300 w-auto border rounded-lg'>{commodity.replace(/_/g, ' ').toUpperCase()} </span>
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                    </> : '-'} textClassname={"capitalize"} type={app_consts.TEXT_ROW} />
                            </tr>
                        </>
                    );
                }
                )
            }
        </>
    );
};