import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { register, getCurrentAccount } from '../../utils/actions';
import Logo from '../../components/Logo';
import { Link, useNavigate } from 'react-router-dom';

import PhoneInput from 'react-phone-input-2';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import 'react-phone-input-2/lib/style.css';

const RegisterSchema = yup.object().shape({
    first_name: yup.string().required('First name is required'),
    last_name: yup.string().required('Last name is required'),
    mobile: yup.string().required('Mobile number is required'),
    email: yup.string().email('Email is invalid').required('Email address is required'),
    password_hash: yup.string().min(8, 'Password must be at least 8 characters').required('Password is required'),
    confirmPassword: yup.string().oneOf([yup.ref('password_hash'), null], 'Passwords must match').required('Confirm password is required'),
});

const Register = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleConfirmPasswordChange = (event) => {
        formik.setFieldValue('confirmPassword', event.target.value);
    };

    const formik = useFormik({
        initialValues: {
            first_name: '',
            last_name: '',
            mobile: '',
            email: '',
            password_hash: '',
            confirmPassword: '',
            verified: 0,
            active: 0,
        },
        validationSchema: RegisterSchema,
        onSubmit: async (values) => {
            if (values.password_hash !== values.confirmPassword)
            {
                formik.setFieldError('confirmPassword', 'Passwords must match');
                return;
            }
            setLoading(true);

            try
            {
                localStorage.clear();
                await dispatch(register(values));
                await dispatch(getCurrentAccount());
                navigate('/verify_account');
            } catch (error)
            {
                if (error.response)
                {
                    if (error.response.status === 400)
                    {
                        setError(error.response.data.message);
                    }
                    else if (error.response.status === 500)
                    {
                        setError("Registration Failed.");
                    }
                }
                else
                {
                    setError("Registration Failed.");
                }
            } finally
            {
                setLoading(false);
            }
        },
    });

    const phoneNumber = parsePhoneNumberFromString(formik.values.mobile);

    if (phoneNumber && phoneNumber.isValid())
    {
        formik.values.mobile = phoneNumber.formatNational();
    }

    return (
        <section className="bg-gray-50 dark:bg-gray-900">
            <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
                <Logo />
                <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                    <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                        <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                            Create an access account
                        </h1>
                        <form className="space-y-4 md:space-y-6" onSubmit={formik.handleSubmit}>
                            <div className="flex flex-wrap -mx-3 mb-6">
                                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label htmlFor="first_name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                        First Name
                                    </label>
                                    <input
                                        type="text"
                                        name="first_name"
                                        id="first_name"
                                        value={formik.values.first_name}
                                        onChange={(e) => formik.handleChange(e.target.name)(e.target.value.toUpperCase())}
                                        onBlur={formik.handleBlur}
                                        className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                                        required
                                    />
                                    {formik.touched.first_name && formik.errors.first_name ? (
                                        <div className="p-2 mb-4 text-sm text-red-800 dark:text-red-400">{formik.errors.first_name}</div>
                                    ) : null}
                                </div>
                                <div className="w-full md:w-1/2 px-3">
                                    <label htmlFor="last_name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                        Last Name
                                    </label>
                                    <input
                                        type="text"
                                        name="last_name"
                                        id="last_name"
                                        value={formik.values.last_name}
                                        onChange={(e) => formik.handleChange(e.target.name)(e.target.value.toUpperCase())}
                                        onBlur={formik.handleBlur}
                                        className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                                        required
                                    />
                                    {formik.touched.last_name && formik.errors.last_name ? (
                                        <div className="p-2 mb-4 text-sm text-red-800 dark:text-red-400">{formik.errors.last_name}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div>
                                <label htmlFor="mobile" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                    Mobile Number
                                </label>
                                <PhoneInput
                                    country={'zm'}
                                    value={formik.values.mobile}
                                    onChange={(mobile) => formik.setFieldValue('mobile', mobile)}
                                    inputClass="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                                    containerClass="w-full"
                                    buttonClass="dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                                    required
                                />
                            </div>
                            {formik.touched.mobile && formik.errors.mobile ? (
                                <div className="p-2 mb-4 text-sm text-red-800 dark:text-red-400">{formik.errors.mobile}</div>
                            ) : null}

                            <div>
                                <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                    Email (corporate email)
                                </label>
                                <input
                                    type="email"
                                    name="email"
                                    id="email"
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                                    placeholder="Enter email address"
                                    required
                                />
                                {formik.touched.email && formik.errors.email ? (
                                    <div className="p-2 mb-4 text-sm text-red-800 dark:text-red-400">{formik.errors.email}</div>
                                ) : null}
                            </div>
                            <div className="flex flex-wrap -mx-3 mb-6">
                                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label htmlFor="password_hash" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                        Password
                                    </label>
                                    <input
                                        type="password"
                                        name="password_hash"
                                        id="password_hash"
                                        value={formik.values.password_hash}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        placeholder="••••••••"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                                        required
                                    />
                                    {formik.touched.password_hash && formik.errors.password_hash ? (
                                        <div className="p-2 mb-4 text-sm text-red-800 dark:text-red-400">{formik.errors.password_hash}</div>
                                    ) : null}
                                </div>
                                <div className="w-full md:w-1/2 px-3">
                                    <label htmlFor="confirmPassword" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                        Confirm password
                                    </label>
                                    <input
                                        type="password"
                                        name="confirmPassword"
                                        id="confirmPassword"
                                        value={formik.values.confirmPassword}
                                        onChange={handleConfirmPasswordChange}
                                        onBlur={formik.handleBlur}
                                        placeholder="••••••••"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                                        required
                                    />
                                    {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                                        <div className="p-2 mb-4 text-sm text-red-800 dark:text-red-400">{formik.errors.confirmPassword}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="flex items-start">
                                <div className="flex items-center h-5">
                                    <input
                                        id="terms"
                                        aria-describedby="terms"
                                        type="checkbox"
                                        className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800"
                                        required
                                    />
                                </div>
                                <div className="ml-3 text-sm">
                                    <label htmlFor="terms" className="font-light text-gray-500 dark:text-gray-300">
                                        I accept the{' '}
                                        <a className="font-medium text-primary-600 hover:underline dark:text-primary-500" href="#">
                                            Terms and Conditions
                                        </a>
                                    </label>
                                </div>
                            </div>

                            <button
                                type="submit"
                                disabled={!formik.isValid}
                                className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                            >
                                {formik.isSubmitting ? 'Creating Account...' : 'Create Access Account'}
                            </button>

                            {error && (
                                <div className="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
                                    <span className="font-medium">Error: </span>
                                    {error}
                                </div>
                            )}
                            {loading && (
                                <div className="text-center">
                                    <div role="status">
                                        <svg
                                            aria-hidden="true"
                                            className="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-green-600"
                                            viewBox="0 0 100 101"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                fill="currentColor"
                                            />
                                            <path
                                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                fill="currentFill"
                                            />
                                        </svg>
                                        <span className="sr-only">Please wait...</span>
                                    </div>
                                </div>
                            )}
                            <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                                Already have a ZAMACE access account?{' '}
                                <Link to="/" className="font-medium text-primary-600 hover:underline dark:text-primary-500">
                                    Go to Login
                                </Link>
                            </p>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Register;

