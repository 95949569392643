import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addEntityDirectorAndExecutive, exchange_add_director_to_entity, uploadDirectorReset } from '../../utils/actions';
import { useNavigate } from 'react-router-dom';
import 'react-phone-input-2/lib/style.css';
import countries from '../../utils/countries.json';
import FileInput from '../../layouts/FileInput';
// import { format } from 'libphonenumber-js';
import format from 'date-fns/format';
import * as app_consts from '../../utils/constants';

const AddEntityManagementInformationModal = ({ main_access_account, directors, successFunction, closeModal }) => {
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [error, setError] = useState("");

    const [selectedFiles, setSelectedFiles] = useState({
        id_front_image_url: null,
        id_back_image_url: null,
        profile_image_url: null,
    });

    const [entityData, setEntityData] = useState({
        first_name: '',
        identification: '',
        last_name: '',
        nationality: '',
        id_back_image_url: '',
        id_front_image_url: '',
        profile_image_url: '',
        history: [],
        type: ''
    });

    const validateInputs = (data) => {
        const errors = {};

        if (!data.first_name)
        {
            errors.first_name = 'Please enter first name';
        }

        if (!data.last_name)
        {
            errors.last_name = 'Please enter last name';
        }

        if (!data.identification)
        {
            errors.identification = 'Please enter NRC or passport number';
        }

        if (!data.nationality)
        {
            errors.nationality = 'Please select nationality';
        }

        if (!data.type)
        {
            errors.type = 'Please select title';
        }

        return errors;
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setEntityData((prevData) => ({ ...prevData, [name]: value }));
        setErrors((prevErrors) => ({ ...prevErrors, [name]: null }));
    };

    const MAX_FILE_SIZE_MB = 20;

    const handleFileChange = (e, fieldName) => {
        const selectedFile = e.target.files[0];

        if (selectedFile)
        {
            const fileSizeInMB = selectedFile.size / (1024 * 1024);
            if (fileSizeInMB > MAX_FILE_SIZE_MB)
            {
                setSelectedFiles((prevFiles) => ({ ...prevFiles, [fieldName]: null }));
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [fieldName]: `File size exceeds the ${MAX_FILE_SIZE_MB}MB limit.`,
                }));
                return;
            }

            const allowedFileTypes = [
                'image/jpeg',
                'image/png',
                'image/gif',
                'image/bmp',
                'image/tiff',
                'image/heif',
            ];

            if (allowedFileTypes.includes(selectedFile.type))
            {
                setSelectedFiles((prevFiles) => ({ ...prevFiles, [fieldName]: selectedFile }));
                setErrors((prevErrors) => ({ ...prevErrors, [fieldName]: null, file: null }));
            } else
            {
                setSelectedFiles((prevFiles) => ({ ...prevFiles, [fieldName]: null }));
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [fieldName]: 'Please select a valid image (JPEG, PNG, GIF, BMP, TIFF or HEIF).',
                }));
            }
        }
    };


    const handleCountryChange = (e) => {
        const selectedCountry = e.target.value;

        setEntityData((prevEntity) => ({
            ...prevEntity,
            nationality: selectedCountry,
        }));
    };

    const handleAddDirector = async (e) => {
        e.preventDefault();

        if (!selectedFiles.id_front_image_url || !selectedFiles.profile_image_url)
        {
            setErrors({ file: "Please select all required image files to upload." });
            return;
        }

        const inputErrors = validateInputs(entityData);
        if (Object.keys(inputErrors).length > 0)
        {
            setErrors(inputErrors);
            return;
        }

        const directorIdentification = entityData.identification;

        const isDirectorExist = directors.some((director) => director.identification === directorIdentification);

        if (isDirectorExist)
        {
            setErrors({ identification: 'You have already added a director with the same details' });
            return;
        }

        try
        {
            setError("");
            const timestamp = new Date();
            const historyStatement = `Added director on ${format(timestamp, 'MMMM dd, yyyy hh:mm a')}`;

            const directorData = {
                ...entityData,
                history: [...entityData.history, historyStatement],
                id_front_image_url: selectedFiles.id_front_image_url,
                id_back_image_url: selectedFiles.id_back_image_url,
                profile_image_url: selectedFiles.profile_image_url,

                account_id: main_access_account?.account_id,
                entity_id: main_access_account?.entity_id,
                profile_id: main_access_account?.profile_id,
            };

            await dispatch(exchange_add_director_to_entity(directorData));
        } catch (error)
        {
            console.log(error);
        }
    };

    // Listen to Add State
    const add_director_state = useSelector((state) => state.entity);
    // 
    useEffect(() => {
        if (add_director_state.status !== app_consts.IDLE)
        {
            if (add_director_state.loading)
            {
                setLoading(true);
            }
            else
            {
                if (add_director_state.error)
                {
                    setError(add_director_state.error);
                }
                else
                {
                    // Clear fields after successful addition
                    setEntityData({
                        ...entityData,
                        first_name: '',
                        identification: '',
                        last_name: '',
                        nationality: '',
                        id_back_image_url: '',
                        id_front_image_url: '',
                        profile_image_url: '',
                        history: [],
                        type: ''
                    });

                    setSelectedFiles({
                        id_front_image_url: null,
                        id_back_image_url: null,
                        profile_image_url: null,
                    });
                    closeModal(false);
                    successFunction();
                }
                setLoading(false);
                dispatch(uploadDirectorReset());
            }
        }
    }, [add_director_state]);


    return (
        <>
            <form onSubmit={handleAddDirector}>

                <div className="mt-8">
                    <p className="text-lg font-medium text-gray-900 dark:text-white">Add individual details one at a time.</p>
                </div>
                <div className="grid gap-6 mb-2 lg:grid-cols-4 p-6 sm:p-16 lg:p-8">
                    <div>
                        <label htmlFor="first_name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            First Name
                        </label>
                        <input
                            type="text"
                            name="first_name"
                            value={entityData.first_name}
                            onChange={handleInputChange}
                            required
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                        />
                        {errors.first_name && <p className="text-red-500 text-xs mt-1">{errors.first_name}</p>}
                    </div>
                    <div>
                        <label htmlFor="last_name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            Surname
                        </label>
                        <input
                            type="text"
                            name="last_name"
                            value={entityData.last_name}
                            onChange={handleInputChange}
                            required
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                        />
                        {errors.last_name && <p className="text-red-500 text-xs mt-1">{errors.last_name}</p>}
                    </div>
                    <div>
                        <label htmlFor="identification" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            NRC/Passport Number
                        </label>
                        <input
                            type="text"
                            name="identification"
                            value={entityData.identification}
                            onChange={handleInputChange}
                            required
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                        />
                        {errors.identification && <p className="text-red-500 text-xs mt-1">{errors.identification}</p>}
                    </div>
                    <div>
                        <label htmlFor="nationality" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            Select Nationality
                        </label>
                        <select
                            name="nationality"
                            value={entityData.nationality}
                            onChange={handleCountryChange}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                            required
                        >
                            {countries.map((country, index) => (
                                <option key={index} value={country}>
                                    {country}
                                </option>
                            ))}
                        </select>
                        {errors.nationality && (
                            <p className="text-red-500 text-xs mt-1">{errors.nationality[0]}</p>
                        )}
                    </div>
                    <div>
                        <label htmlFor="type" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            Select Title
                        </label>
                        <select
                            name="type"
                            value={entityData.type}
                            onChange={handleInputChange}
                            required
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                        >
                            <option value="">Choose a category</option>
                            <option value="ceo">CEO</option>
                            <option value="cfo">CFO/Finance Director</option>
                            <option value="director">Director</option>
                        </select>
                        {errors.type && (
                            <p className="text-red-500 text-xs mt-1">{errors.type[0]}</p>
                        )}
                    </div>
                    <div>
                        <FileInput
                            label="Upload Front NRC/Passport Image"
                            name="id_front_image_url"
                            onChange={(e) => handleFileChange(e, 'id_front_image_url')}
                        />
                        {errors.id_front_image_url && <p className="text-red-500 text-xs mt-1">{errors.id_front_image_url}</p>}
                    </div>
                    <div>
                        <FileInput
                            label="Upload Back NRC Image"
                            name="id_back_front_url"
                            onChange={(e) => handleFileChange(e, 'id_back_image_url')}
                        />
                        {errors.id_back_image_url && <p className="text-red-500 text-xs mt-1">{errors.id_back_image_url}</p>}
                    </div>
                    <div>
                        <FileInput
                            label="Upload Profile Image (full face ONLY)"
                            name="profile_image_url"
                            onChange={(e) => handleFileChange(e, 'profile_image_url')}
                        />
                        {errors.profile_image_url && <p className="text-red-500 text-xs mt-1">{errors.profile_image_url}</p>}
                    </div>
                </div>
                {error && (
                    <div
                        className='p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400'
                        role='alert'
                    >
                        <span className='font-medium'>Error: </span>
                        {error}
                    </div>
                )}
                <div className="flex justify-between items-center space-x-4">
                    {/* Close button */}
                    <button
                        type="button"
                        onClick={() => closeModal(false)}
                        className="text-gray-900 bg-white hover:bg-gray-100 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 mr-2 mb-2"
                    >
                        Close Modal
                    </button>
                    <button
                        loading={loading}
                        type="submit"
                        className="text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800"
                    >
                        {loading ? "Adding Director/Executive" : "Add Director/Executive"}
                    </button>
                </div>
            </form>
        </>
    );
};


export default AddEntityManagementInformationModal;
