import React, { useState } from 'react';
import Logo from '../../../components/Logo';
import NavLogo from '../../../components/NavLogo';
import WelcomeBanner from '../../../layouts/WelcomeBanner';
import { useNavigate, useParams } from 'react-router-dom';
// import CustomerTypeModal from '../../components/modals/CustomerTypeModal';

const MarketsDashboard = () => {
  // const [showModal, setShowModal] = useState(false);
  const { entity_id, entity_mid } = useParams();
  const currentUrl = window.location.href;
  const navigate = useNavigate();

  // const closeModal = () => {
  //   setShowModal(false);
  // };

  const account = JSON.parse(localStorage.getItem('account'));
  const firstName = account.user.first_name;
  const lastName = account.user.last_name;


  // Handle Logout
  const handleLogout = () => {
    localStorage.clear();
    navigate("/");
  };

  return (
    <>
      <div>
        <button
          data-drawer-target='logo-sidebar'
          data-drawer-toggle='logo-sidebar'
          aria-controls='logo-sidebar'
          type='button'
          className='inline-flex items-center p-2 mt-2 ml-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600'
        >
          <span className='sr-only'>Open sidebar</span>
          <svg
            className='w-6 h-6'
            aria-hidden='true'
            fill='currentColor'
            viewBox='0 0 20 20'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              clip-rule='evenodd'
              fill-rule='evenodd'
              d='M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z'
            ></path>
          </svg>
          <NavLogo />
        </button>

        <aside
          id='logo-sidebar'
          className='fixed top-0 left-0 z-40 w-64 h-screen transition-transform -translate-x-full sm:translate-x-0'
          aria-label='Sidebar'
        >
          <div className='h-full px-3 py-4 overflow-y-auto bg-gray-50 dark:bg-gray-800'>
            <a href='/' className='flex items-center pl-2.5 mb-5'>
              <Logo />
            </a>

            <ul className='space-y-2 font-medium'>
              <li>
                <a href="/home" className="flex items-center p-2 text-gray-600 hover:text-green-600 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                  <svg class="w-6 h-6  dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6.143 1H1.857A.857.857 0 0 0 1 1.857v4.286c0 .473.384.857.857.857h4.286A.857.857 0 0 0 7 6.143V1.857A.857.857 0 0 0 6.143 1Zm10 0h-4.286a.857.857 0 0 0-.857.857v4.286c0 .473.384.857.857.857h4.286A.857.857 0 0 0 17 6.143V1.857A.857.857 0 0 0 16.143 1Zm-10 10H1.857a.857.857 0 0 0-.857.857v4.286c0 .473.384.857.857.857h4.286A.857.857 0 0 0 7 16.143v-4.286A.857.857 0 0 0 6.143 11Zm10 0h-4.286a.857.857 0 0 0-.857.857v4.286c0 .473.384.857.857.857h4.286a.857.857 0 0 0 .857-.857v-4.286a.857.857 0 0 0-.857-.857Z" />
                  </svg>
                  <span className="ml-3">My Dashboards</span>
                </a>
              </li>
              <li>
                <a href={`/markets/${entity_id}/${entity_mid}/offer_management`} className="flex items-center p-2 text-green-600 hover:text-green-600 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m.75 12l3 3m0 0l3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                  </svg>
                  <span className="ml-3">Offer Management</span>
                </a>
              </li>

              <li>
                <a href={`/markets/${entity_id}/${entity_mid}/bid_management`} className="flex items-center p-2 text-green-600 hover:text-green-600 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m.75 12l3 3m0 0l3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                  </svg>
                  <span className="ml-3">Bid Management</span>
                </a>
              </li>

              <li className="my-2 fixed bottom-12 w-11/12">
                <a onClick={handleLogout} className="cursor-pointer flex items-center p-2 text-gray-600 hover:text-green-600 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                  <svg class="w-6 h-6 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 8h11m0 0-4-4m4 4-4 4m-5 3H3a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h3" />
                  </svg>
                  <span className="flex-1 ml-3 whitespace-nowrap">Sign Out</span>
                </a>
              </li>
            </ul>
          </div>
        </aside>

        <div className='p-4 sm:ml-64'>
          <div className='p-4 rounded-lg dark:border-gray-700'>
            <WelcomeBanner bannerTitle="Markets Dashboard" />

            <div className='grid md:grid-cols-2 gap-4 mb-4'>

              <a
                href={`/markets/${entity_id}/${entity_mid}/offer_management`}
                class='each flex hover:shadow-lg select-none p-6 rounded-md border-gray-300 border mb-3 hover:border-green-500 cursor-pointer'
              >
                <div><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m.75 12l3 3m0 0l3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                </svg>

                </div>
                <div class='flex flex-col ml-2'>
                  <span class='text-lg font-bold leading-5 text-green-600'>
                    Offer Management
                  </span>
                  <span class='text-sm text-gray-500 hidden md:block'>
                    Manage Markert Offers
                  </span>
                </div>
              </a>

              <a
                href={`/markets/${entity_id}/${entity_mid}/bid_management`}
                class='each flex hover:shadow-lg select-none p-6 rounded-md border-gray-300 border mb-3 hover:border-green-500 cursor-pointer'
              >
                <div><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m.75 12l3 3m0 0l3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                </svg>

                </div>
                <div class='flex flex-col ml-2'>
                  <span class='text-lg font-bold leading-5 text-green-600'>
                    Bid Management
                  </span>
                  <span class='text-sm text-gray-500 hidden md:block'>
                    Manage Markert Bids
                  </span>
                </div>
              </a>
              <a
                href={`/markets/${entity_id}/${entity_mid}/provision_receipt_management`}
                class='each flex hover:shadow-lg select-none p-6 rounded-md border-gray-300 border mb-3 hover:border-green-500 cursor-pointer'
              >
                <div><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m.75 12l3 3m0 0l3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                </svg>

                </div>
                <div class='flex flex-col ml-2'>
                  <span class='text-lg font-bold leading-5 text-green-600'>
                    Provisional Receipt Management
                  </span>
                  <span class='text-sm text-gray-500 hidden md:block'>
                    Manage Provisional Receipts
                  </span>
                </div>
              </a>

              <a
                href={`/markets/${entity_id}/${entity_mid}/ats_management`}
                className="each flex hover:shadow-lg select-none p-6 rounded-md border-gray-300 border mb-3 hover:border-green-500 cursor-pointer"
              >
                <div>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke-width='1.5'
                    stroke='currentColor'
                    class='w-6 h-6'
                  >
                    <path
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      d='M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z'
                    />
                  </svg>
                </div>
                <div class='flex flex-col ml-2'>
                  <span className='text-lg font-bold leading-5 text-green-600'>
                    Automated Trading System
                  </span>
                  <span class='text-sm text-gray-500 hidden md:block'>
                    View Trades on the live market
                  </span>
                </div>
              </a>

            </div>
          </div>
        </div>
      </div>

      {/* <CustomerTypeModal showModal={showModal} closeModal={closeModal} /> */}
    </>
  );
};

export default MarketsDashboard;
