import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import RSelect from 'react-select';
import { getFilteredEntityTypeActiveStatusVerified, getWarehouseListByEntityStatus, list_all_producers_with_entities, processBulkDeliveryReset, processGoodsReceivedNoteReset, process_bulk_delivery, process_goods_received_note, retreive_pending_entity_list_by_type, retrieveSpecificWarehouseStorageTypeReset, retrieve_specific_warehouse_storage_types } from '../../../utils/actions';
import { useDispatch, useSelector } from 'react-redux';
import { convertToTitleCase, rSelectMapFun, retreive_rSelectVariables } from '../../../utils/functionalUtils';
import * as app_consts from '../../../utils/constants';
import { useParams } from 'react-router-dom';
import { acceptable_grades } from '../../../utils/commodity_grading_scale';
import countries from "../../../utils/countries.json";
import { FileInput } from 'flowbite-react';
import * as yup from 'yup';

const DepositAndGRNInfoStep = ({ nextStep, setPrevData, prevData }) => {

    const dispatch = useDispatch();
    const { entity_id } = useParams();

    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [warehouseList, setWarehouseList] = useState([]);
    const [depositor_type, setDepositorType] = useState("");
    const [selectedDepositorType, setSelectedDepositorType] = useState([]);
    const [producerList, setProducerList] = useState([]);
    const [depositor_own_label, setDepositorOwnlabel] = useState("");
    const [permitted_commodities, setPermittedCommodities] = useState([]);
    const [grades, setGrades] = useState();
    const [errors, setErrors] = useState({});
    const [storage_list_loading, setStorageListLoading] = useState(false);
    const [storage_list, setStorageList] = useState([]);

    const [selectedFiles, setSelectedFiles] = useState({
        grade_cert_img: null,
    });

    // Retrieve Producers and Service Provider List On Page Load
    useEffect(() => {
        dispatch(list_all_producers_with_entities());

        // Fetch Warehouse List
        dispatch(getWarehouseListByEntityStatus({ entity_id: entity_id, status: app_consts.CERTIFIED }));
    }, []);

    // Yup Validation
    const deliveriesSchema = yup.object().shape({
        depositor_id: yup.string().required('Depositor is Required'),
        commodity: yup.string().required('Commodity is required'),
        origin: yup.string().required('Origin is required'),
        warehouse_id: yup.string().required('Warehouse is required'),
        truck_load_no: yup.string().required('Truck Load No is required'),
        no_of_bags: yup.string().required('No of Bags is required'),
        grn_no: yup.string().required('GRN No is required'),
        date_received: yup.string().required('Date Received is required'),
        quantity: yup.string().required('Quantity is required'),
    });

    const formik = useFormik({
        initialValues: {
            depositor_id: '',
            commodity: "",
            origin: "",
            warehouse_id: "",
            truck_load_no: "",
            no_of_bags: "",
            grn_no: "",
            date_received: "",
            quantity: "",
        },
        validationSchema: deliveriesSchema,
        onSubmit: async (values) => {
            setError("");
            setLoading(true);
            try
            {
                const goodsReceivedNoteParams = {
                    warehouse_id: values.warehouse_id,
                    depositor_id: values.depositor_id,
                    commodity: values.commodity,
                    origin: values.origin,
                    grn_params: {
                        truck_load_no: values.truck_load_no,
                        no_of_bags: values.no_of_bags,
                        grn_no: values.grn_no,
                        date_received: values.date_received,
                        quantity: values.quantity
                    }
                };

                await dispatch(process_goods_received_note(goodsReceivedNoteParams));

            } catch (error)
            {
                if (error.response)
                {
                    if (error.response.status === 400)
                    {
                        setError(error.response.data.message);
                    }
                    else if (error.response.status === 500)
                    {
                        setError("Failed to Process Goods Received Note.");
                    }
                }
                else
                {
                    setError("Failed to Process Goods Received Note.");
                }
            } finally
            {
                setLoading(false);
            }
        },
    });

    // Listen to Create Bulk Delivery Reducer
    const goods_received_note_data = useSelector((state) => state.processGoodsReceivedNote);
    // 
    useEffect(() => {
        if (goods_received_note_data.status !== app_consts.IDLE)
        {
            if (goods_received_note_data.isLoading)
            {
                setLoading(true);
            }
            else
            {
                setLoading(false);
                if (goods_received_note_data.error)
                {
                    setError(goods_received_note_data.message);
                }
                else if (goods_received_note_data.data)
                {
                    setPrevData({ ...prevData, ["goods_received"]: goods_received_note_data.data, ["delivery_id"]: goods_received_note_data?.data?.id, ["commodity"]: goods_received_note_data?.data?.commodity, ["commodity_id"]: goods_received_note_data?.data?.commodity_id, });
                    nextStep();
                }
                dispatch(processGoodsReceivedNoteReset());
            }
        }
    }, [goods_received_note_data]);

    // Listen to Changes When Fetching Warehouse List
    const warehouseListData = useSelector((state) => state.warehouseList);

    useEffect(() => {
        const fetchData = async () => {
            if (warehouseListData.data)
            {
                let mappedData = [];

                await Promise.all(
                    warehouseListData?.data?.data?.map(async (data_to_map) => {
                        const { label, value } = retreive_rSelectVariables(app_consts.WAREHOUSES_MAP, data_to_map);
                        const result = await rSelectMapFun(label, value);
                        mappedData.push(result);
                    })
                );

                setWarehouseList(mappedData);
            }
        };

        fetchData();
    }, [warehouseListData]);

    // Listen to Producers State
    const producersState = useSelector((state) => state.listAllProducersWithEntities);
    // 
    useEffect(() => {
        if (producersState.data)
        {
            setProducerList(producersState?.data);
        }
    }, [producersState]);

    // Handle Changes in React Select Field
    const handlRSelectChanges = (option, name) => {
        if (option)
        {
            formik.setFieldValue(name, option.value);
        }
        else
        {
            formik.setFieldValue(name, "");
        }
    };

    // Handle Changes in Depositor Type Selection
    const handleDepositorTypeChange = async (depositorType) => {
        if (depositorType)
        {
            setDepositorType(depositorType);
            setSelectedDepositorType(await filterDepositors(depositorType));
        }
        else
        {
            setSelectedDepositorType([]);
        }
        if (depositorType !== app_consts.WAREHOUSE_OPERATOR.toLowerCase())
        {
            formik.setFieldValue('depositor_id', "");
        }
    };

    const filterDepositors = async (depositorType) => {
        let filteredList;
        if (depositorType && depositorType === app_consts.WAREHOUSE_OPERATOR.toLowerCase())
        {
            filteredList = producerList?.filter((producer) => depositorType === producer.type && producer.entity_id === entity_id);
        }
        else
        {
            filteredList = producerList?.filter((producer) => depositorType === producer.type);
        }

        let mappedData = [];

        await Promise.all(
            filteredList?.map(async (data_to_map) => {
                const { label, value } = retreive_rSelectVariables(app_consts.PRODUCERS_LIST_MAP, data_to_map);
                const result = await rSelectMapFun(label, value);
                mappedData.push(result);
            })
        );
        if (depositorType && depositorType === app_consts.WAREHOUSE_OPERATOR.toLowerCase() && mappedData)
        {
            setDepositorOwnlabel(mappedData[0]?.label);
            formik.setFieldValue('depositor_id', mappedData[0]?.value);
            handleDepositorCommodities(mappedData[0]);
        }
        return mappedData;
    };

    // Handle Depositor Commodities
    const handleDepositorCommodities = (option) => {
        formik.setFieldValue("commodity", "");
        if (option)
        {
            // Fileter Out Producer Commodities
            const filteredList = producerList?.filter((producer) => option.value === producer.id);
            setPermittedCommodities(filteredList[0].commodities);
        }
        else
        {
            setPermittedCommodities([]);
        }
    };

    // Handle Acceptable Grades
    const handleAcceptableGrades = (commodity) => {
        formik.setFieldValue("grade", "");
        setGrades(acceptable_grades(commodity?.replace(/ /g, '_').toLowerCase()));
    };
    const MAX_FILE_SIZE_MB = 20;


    return (
        <>

            <form className='justify-center items-center' onSubmit={formik.handleSubmit}>
                <div className="px-4 flex flex-col items-stretch justify-start border-gray-100">
                    <h3 className="text-2xl font-bold leading-7 text-green-800 sm:truncate sm:text-2xl sm:tracking-tight">
                        Deposit Information
                    </h3>
                </div>
                <div className="grid gap-6 mb-2 lg:grid-cols-3 p-6 sm:p-16 lg:p-8">
                    <div>
                        <label htmlFor="depositor_type" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            Depositor Type
                        </label>
                        <select
                            name="depositor_type"
                            onChange={(e) => handleDepositorTypeChange(e.target.value)}
                            // required
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                        >
                            <option value=''>Choose Depositor type</option>
                            <option value='individual'>Individual</option>
                            <option value='commercial'>Commercial</option>
                            <option value='public_entity'>Public Entity</option>
                            <option value='broker'>Broker</option>
                            <option value='warehouse_operator'>Own</option>
                        </select>
                    </div>
                    <div>
                        <label
                            htmlFor='profileType'
                            className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                        >
                            Depositor
                        </label>
                        {
                            depositor_type === app_consts.WAREHOUSE_OPERATOR.toLowerCase() ?
                                <>
                                    <input
                                        type='text'
                                        name="depositor_id"
                                        disabled
                                        value={depositor_own_label || "Depositor Not Found"}
                                        className="bg-gray-200 border cursor-not-allowed border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                                    />
                                </>
                                :
                                <>
                                    <RSelect
                                        name='depositor_id'
                                        // value={formik.values.depositor_id}
                                        onChange={(e) => { handlRSelectChanges(e, "depositor_id"); handleDepositorCommodities(e); }}
                                        options={selectedDepositorType}
                                        isClearable={true}
                                    />
                                </>
                        }
                        {formik.touched.depositor_id && formik.errors.depositor_id && (
                            <p className="text-red-500 text-sm mt-1">{formik.errors.depositor_id}</p>
                        )}
                    </div>

                    <div>
                        <label htmlFor="commodity" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            Commodity
                        </label>
                        <select
                            name="commodity"
                            value={formik.values.commodity}
                            onChange={(e) => { formik.setFieldValue('commodity', e?.target?.value); handleAcceptableGrades(e?.target?.value); }}
                            // required
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                        >
                            <option value="">Choose Commodity</option>
                            {
                                permitted_commodities?.map((per_commod) => {
                                    return (
                                        <>
                                            <option value={per_commod}>{convertToTitleCase(per_commod)}</option>
                                        </>
                                    );
                                })
                            }
                        </select>
                        {formik.touched.commodity && formik.errors.commodity && (
                            <p className="text-red-500 text-sm mt-1">{formik.errors.commodity}</p>
                        )}
                    </div>

                    <div className="mb-6">
                        <label htmlFor="origin" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            Commodity Origin
                        </label>
                        <select
                            name="origin"
                            value={formik.values.origin}
                            onChange={formik.handleChange}
                            // required
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                        >
                            {countries.map((country, index) => (
                                <option key={index} value={country}>
                                    {country}
                                </option>
                            ))}
                        </select>
                        {formik.touched.origin && formik.errors.origin && (
                            <p className="text-red-500 text-sm mt-1">{formik.errors.origin}</p>
                        )}
                    </div>

                    <div>
                        <label
                            htmlFor='profileType'
                            className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                        >
                            Warehouse
                        </label>
                        <RSelect
                            name='warehouse_id'
                            // value={formik.values.warehouse_id}
                            onChange={(e) => { handlRSelectChanges(e, "warehouse_id"); if (e?.value) dispatch(retrieve_specific_warehouse_storage_types(e.value)); setStorageList([]); }}
                            options={warehouseList}
                            isClearable={true}
                        />
                        {formik.touched.warehouse_id && formik.errors.warehouse_id && (
                            <p className="text-red-500 text-sm mt-1">{formik.errors.warehouse_id}</p>
                        )}
                    </div>
                </div>
                <div className="px-4 flex flex-row items-stretch justify-between border-gray-100">
                    <h3 className="text-2xl font-bold leading-7 text-green-800 sm:truncate sm:text-2xl sm:tracking-tight">
                        First GRN Details
                    </h3>
                </div>

                <div className="grid gap-6 mb-2 lg:grid-cols-3 p-6 sm:p-16 lg:p-8">
                    <div>
                        <label htmlFor="truck_load_no" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            Truck Load No.
                        </label>
                        <input
                            type="text"
                            name="truck_load_no"
                            value={formik.values.truck_load_no}
                            onChange={formik.handleChange}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                        />
                        {formik.touched.truck_load_no && formik.errors.truck_load_no && (
                            <p className="text-red-500 text-sm mt-1">{formik.errors.truck_load_no}</p>
                        )}
                    </div>
                    <div>
                        <label htmlFor="no_of_bags" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            No. Of Bags
                        </label>
                        <input
                            type="text"
                            name="no_of_bags"
                            value={formik.values.no_of_bags}
                            onChange={formik.handleChange}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                        />
                        {formik.touched.no_of_bags && formik.errors.no_of_bags && (
                            <p className="text-red-500 text-sm mt-1">{formik.errors.no_of_bags}</p>
                        )}
                    </div>
                    <div>
                        <label htmlFor="quantity" className="flex flex-row items-center block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            Quantity <span className="block ml-2 text-xs font-medium text-gray-600 dark:text-white">(Metric Tonnes)</span>
                        </label>
                        <input
                            type="text"
                            name="quantity"
                            value={formik.values.quantity}
                            onChange={formik.handleChange}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                        />
                        {formik.touched.quantity && formik.errors.quantity && (
                            <p className="text-red-500 text-sm mt-1">{formik.errors.quantity}</p>
                        )}
                    </div>
                    <div>
                        <label htmlFor="grn_no" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            GRN No.
                        </label>
                        <input
                            type="text"
                            name="grn_no"
                            value={formik.values.grn_no}
                            onChange={formik.handleChange}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                        />
                        {formik.touched.grn_no && formik.errors.grn_no && (
                            <p className="text-red-500 text-sm mt-1">{formik.errors.grn_no}</p>
                        )}
                    </div>

                    <div>
                        <label htmlFor="date_received" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            Date Received
                        </label>
                        <input
                            type="datetime-local"
                            name="date_received"
                            value={formik.values.date_received}
                            onChange={formik.handleChange}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                        />
                        {formik.touched.date_received && formik.errors.date_received && (
                            <p className="text-red-500 text-sm mt-1">{formik.errors.date_received}</p>
                        )}
                    </div>
                </div>

                {error && error.length > 0 && (
                    <div className="my-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 px-6 py-4 sm:px-16 lg:px-8" role="alert">
                        <span className="font-medium">Error: </span>
                        {error}
                    </div>
                )}
                <div className='mb-4 px-6 flex flex-row justify-end items-center'>
                    {/* <button
                        onClick={prevStep}
                        type="submit"
                        className="text-white bg-gray-500 hover:bg-gray-600 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-gray-500 dark:hover:bg-gray-600 dark:focus:ring-gray-700"
                    >
                        Previous Step: Commodity Grading Information
                    </button> */}
                    <button
                        // onClick={nextStep}
                        type="submit"
                        disabled={loading}
                        className={`${loading ? "bg-gray-400" : "bg-green-700 hover:bg-green-800 dark:bg-green-600 dark:hover:bg-green-700"} text-white  focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:focus:ring-green-800`}
                    >
                        {loading ? "Processing..." : "Next Step: Add More GRNs"}
                    </button>
                </div>
            </form>
        </>
    );
};

export default DepositAndGRNInfoStep;