import React, { useEffect, useState } from 'react';
import * as app_consts from '../../../utils/constants';
import TableComp from '../../../components/table/TableComp';
import AddGoodsReceivedNoteModal from '../../../components/modals/AddGoodsReceivedNoteModal';
import { convertUnderscoreToTitleCase } from '../../../utils/functionalUtils';
import YesNoModal from '../../../components/modals/YesNoModal';
import { useDispatch, useSelector } from 'react-redux';
import { confirmGoodsReceivedDeliveryyReset, confirm_goods_received_delivery } from '../../../utils/actions';

const AddGRNStep = ({ nextStep, prevStep, prevData, setPrevData, successFunction }) => {

    const dispatch = useDispatch();
    const [previewData, setPreviewData] = useState(prevData);
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [goods_received_data, setGoodsReceivedData] = useState([]);
    const [totalQuantity, setTotalQuantity] = useState(0);
    const [openYesNoModal, setOpenYesNoModal] = useState(false);
    const [openYesNoModalError, setOpenYesNoModalError] = useState('');
    const [openYesNoModalLoading, setOpenYesNoModalLoading] = useState(false);

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const openModal = () => {
        setIsModalOpen(true);
    };


    useEffect(() => {
        setPreviewData(prevData);
        setGoodsReceivedData(prevData?.goods_received?.goods_received);
    }, [prevData]);

    // Goods Received UseEffect
    useEffect(() => {
        if (goods_received_data)
        {
            setTotalQuantity(calculateTotalQuantity());
        }
    }, [goods_received_data]);

    // Calculate Total Quantity
    const calculateTotalQuantity = () => {
        return goods_received_data.reduce((acc, obj) => acc + parseFloat(obj.quantity || 0), 0);
    };

    const addGrnSucces = (newGrn) => {
        setGoodsReceivedData([...goods_received_data, newGrn]);
        closeModal();
    };

    const openYesNoModalCloseFunction = () => {
        setOpenYesNoModal(false);
    };

    const complete_grn_entries = () => {
        setOpenYesNoModalError("");
        dispatch(confirm_goods_received_delivery({ delivery_id: previewData.delivery_id }));
    };
    // UseEffect and Listener
    const confirm_listener = useSelector((state) => state.confirmGoodsReceivedDelivery);
    // 
    useEffect(() => {
        if (confirm_listener.status !== app_consts.IDLE)
        {
            if (confirm_listener.isLoading)
            {
                setOpenYesNoModalLoading(true);
            }
            else
            {
                setOpenYesNoModalLoading(false);
                if (confirm_listener.error)
                {
                    setOpenYesNoModalError(confirm_listener.message);
                }
                else if (confirm_listener.data)
                {
                    setPrevData({ ...prevData, ["goods_received"]: confirm_listener.data, ["delivery_id"]: confirm_listener?.data?.id, ["commodity"]: confirm_listener?.data?.commodity, ["commodity_id"]: confirm_listener?.data?.commodity_id, });
                    nextStep();
                }
                dispatch(confirmGoodsReceivedDeliveryyReset());
            }
        }
    }, [confirm_listener]);

    return (
        <>
            <div className="px-4 flex flex-col items-stretch justify-start border-gray-100">
                <h3 className="text-2xl font-bold leading-7 text-green-800 sm:truncate sm:text-2xl sm:tracking-tight">
                    Goods Received Note Information {previewData.commodity ? <span className='font-medium text-xl'> - {convertUnderscoreToTitleCase(previewData.commodity)}</span> : ""}
                </h3>
                <div className='mb-4 px-6 flex flex-row justify-between items-center'>
                    <div className='flex flex-row justify-between items-center'>
                        <span className='text-gray-700 mr-2'>Total Quantity: </span>
                        <span>{totalQuantity ? `${totalQuantity?.toFixed(2)} MT` : ""}</span>
                    </div>
                    <button className="flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
                        type="button"
                        onClick={openModal}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            className="w-5 h-5 mr-2"
                        >
                            <path d="M5.433 13.917l1.262-3.155A4 4 0 017.58 9.42l6.92-6.918a2.121 2.121 0 013 3l-6.92 6.918c-.383.383-.84.685-1.343.886l-3.154 1.262a.5.5 0 01-.65-.65z" />
                            <path d="M3.5 5.75c0-.69.56-1.25 1.25-1.25H10A.75.75 0 0010 3H4.75A2.75 2.75 0 002 5.75v9.5A2.75 2.75 0 004.75 18h9.5A2.75 2.75 0 0017 15.25V10a.75.75 0 00-1.5 0v5.25c0 .69-.56 1.25-1.25 1.25h-9.5c-.69 0-1.25-.56-1.25-1.25v-9.5z" />
                        </svg>
                        Add GRN
                    </button>
                </div>
            </div>
            {/* Determine Which Grading Should Show */}
            <TableComp tableData={goods_received_data} tableType={app_consts.GOODS_RECEIVED_NOTE_TABLE} tableHeading="Goods Received Notes" />

            <div className='my-8 px-6 flex flex-row justify-end items-center'>
                {/* <button
                        onClick={prevStep}
                        type="submit"
                        className="text-white bg-gray-500 hover:bg-gray-600 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-gray-500 dark:hover:bg-gray-600 dark:focus:ring-gray-700"
                    >
                        Previous Step: Commodity Grading Information
                    </button> */}
                <button
                    onClick={() => setOpenYesNoModal(true)}
                    // type="submit"
                    className={`${loading ? "bg-gray-400" : "bg-green-700 hover:bg-green-800 dark:bg-green-600 dark:hover:bg-green-700"} text-white  focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:focus:ring-green-800`}
                >
                    {loading ? "Processing..." : "Next Step: Storage Info"}
                </button>
            </div>
            <AddGoodsReceivedNoteModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} successFunction={addGrnSucces} delivery_id={previewData.delivery_id} />
            {/*  */}
            <YesNoModal isOpen={openYesNoModal} setIsOpen={setOpenYesNoModal} closeFunction={openYesNoModalCloseFunction} modalTitle={`Confirm Good Received Notes.`} modalMessage={`Are you sure that all Good Received Notes for the Warehouse Receipt to be Generated have been Entered?`} cancelButtonLabel="Cancel" agreeButtonLabel="Confirm" agreeClickFunction={complete_grn_entries} modalError={openYesNoModalError} modalLoading={openYesNoModalLoading} messageHint={`Receipt to be Generated from Good Received Notes is suming up to ${totalQuantity?.toFixed(2)} Metric Tonnes (MT)`} />
        </>
    );
};

export default AddGRNStep;