import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import NavLogo from '../../../../components/NavLogo';
import DynamicNavLogo from '../../../../components/DynamicNavLogo';
import { bulkEntityCreationReset, bulk_cooperative_member_creation, retrieveAllProducersListReset, retrieveAllUsers, retrieveAllUsersReset, retrieveEntityProfileInfoReset, retrieve_entity_profile_cooperative_details, retrieve_exchange_producer_list } from '../../../../utils/actions';
import * as app_consts from '../../../../utils/constants';
import { download, generateCsv, mkConfig } from 'export-to-csv';
import { EMAIL_REGEX, EMPTYNULLREGEX, NRC_REGEX, ZAMIBAN_PHONE_NUMBER_REGEX } from '../../../../utils/functionalUtils';
import TableComp from '../../../../components/table/TableComp';

const CooperativeMemberCSVImport = () => {

  const { entity_id, entity_mid, member_type_id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page_data, setPageData] = useState([]);
  const [completed, setCompleted] = useState(false);
  const [completed_success, setCompletedSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingmessage, setLoadingMessage] = useState("");
  const [data_verification, setDataVerification] = useState("");
  const [error, setError] = useState("");
  const [successImports, setSuccessImports] = useState([]);
  const [failed_imports, setFailedImports] = useState([]);
  const [data_uploaded, setDataUploaded] = useState(false);
  const [imported_data, setImportedData] = useState("");
  const [imported_data_list, setImportedDataList] = useState("");
  const [producer_data, setProducerData] = useState([]);
  const [user_data, setUserData] = useState([]);
  const [coop_data, setCoopata] = useState([]);

  const [user_complete_load, setUserCompleteLoad] = useState([]);
  const [producer_complete_load, setProducerCompleteLoad] = useState([]);
  const [coop_complete_load, setCoopCompleteLoad] = useState([]);

  const csv_headers = ["name", "gender", "phone_number", "nrc_number", "email", "member_type_id", "commodities"];

  // Handle Logout
  const handleLogout = () => {
    localStorage.clear();
    navigate('/');
  };
  // First Code to Run When the Page Opens
  useEffect(() => {
    dispatch(retrieve_entity_profile_cooperative_details(member_type_id));
  }, []);

  // Listen to Entity Details Reducer
  const entity_profile_info_listner = useSelector((state) => state.retrieveEntityProfileInfo);
  // 
  useEffect(() => {
    if (entity_profile_info_listner.status !== app_consts.IDLE)
    {
      if (!entity_profile_info_listner.isLoading)
      {
        if (entity_profile_info_listner.error)
        {
        }
        else
        {
          setCoopCompleteLoad(true);
          setPageData(entity_profile_info_listner.data);
          setCoopata(entity_profile_info_listner.data?.members);
        }
        dispatch(retrieveEntityProfileInfoReset());
      }
    }
  }, [entity_profile_info_listner]);

  const export_to_csv = async (e) => {
    e.preventDefault();

    const options = mkConfig({
      fieldSeparator: ',',
      quoteStrings: '"',
      showLabels: true,
      showTitle: false,
      title: " ",
      useTextFile: false,
      useBom: true,
      filename: "zamace_cooperative_members_import_template",
      useKeysAsHeaders: false,
      columnHeaders: csv_headers
    });

    // // Export CSV
    const csv = generateCsv(options)([]);

    download(options)(csv);
  };

  // Handle File Change
  const handleFileChange = (event) => {
    setLoading(true);
    setLoadingMessage("Uploading CSV Document");
    setError("");
    setDataVerification("");
    setSuccessImports([]);
    setFailedImports([]);
    setDataUploaded(true);
    const file = event?.target?.files[0];
    const reader = new FileReader();
    setUserCompleteLoad(false);
    setProducerCompleteLoad(false);
    setCoopCompleteLoad(false);

    reader.onload = (e) => {
      const csv_data = e?.target?.result;

      convertToJson(csv_data);

    };

    reader?.readAsText(file);
  };

  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef?.current?.click();
  };

  // Handle Data Import and Upload
  const handleDataImport = () => {
    dispatch(bulk_cooperative_member_creation({ bulk_data: successImports, member_type_id: member_type_id }));
  };

  // Listen to Entity List Changes
  const bulk_entity_creation_listener = useSelector((state) => state.bulkEntityCreation);

  useEffect(() => {
    if (bulk_entity_creation_listener.status !== app_consts.IDLE)
    {
      if (bulk_entity_creation_listener.isLoading)
      {
        setLoading(true);
        setLoadingMessage("Submitting Data");
      } else
      {
        if (bulk_entity_creation_listener.error)
        {
          setError(bulk_entity_creation_listener.error);
        } else if (bulk_entity_creation_listener.data)
        {
          setCompleted(true);

          const succ_imports = bulk_entity_creation_listener?.data?.filter(
            (imp_data) => imp_data?.import_message === "success"
          );

          const fail_imports = bulk_entity_creation_listener?.data?.filter(
            (imp_data) => imp_data?.import_message === "fail"
          );

          setCompletedSuccess(
            `Data Import Completed with ${succ_imports?.length} Successful Imports and ${fail_imports?.length} Failed Imports.`
          );

          const updated_failed_imports = fail_imports?.map((import_item) => {
            return { ...import_item, errors: [import_item?.error_message] };
          });

          setFailedImports(updated_failed_imports);
        }

        setLoadingMessage("");
        setLoading(false);
        dispatch(bulkEntityCreationReset());
      }
    }
  }, [bulk_entity_creation_listener]);

  const convertToJson = (csv_data) => {
    const lines = csv_data.split("\n");

    setLoadingMessage("Verifying Headers");

    // Extract the Column Headers
    const headers = lines[0]?.split(",")?.map(header => header.replace(/"/g, '')?.trim());
    // Validate the headers
    const headersMatch = csv_headers.every((header, index) => header === headers[index]);

    if (!headersMatch)
    {
      setError("Invalid headers");
      setLoading(false);
      setLoadingMessage("");
      setDataUploaded(false);
    } else
    {
      // Fetch Data
      dispatch(retrieveAllUsers());
      dispatch(retrieve_exchange_producer_list());
      dispatch(retrieve_entity_profile_cooperative_details(member_type_id));
      setLoadingMessage("Verifying CSV Data");
      const result = [];

      const parseCSVLine = (line) => {
        const result = [];
        let currentField = '';
        let insideQuotes = false;

        for (let i = 0; i < line.length; i++)
        {
          const char = line[i];

          if (char === '"')
          {
            insideQuotes = !insideQuotes;
            continue;
          }

          if (char === ',' && !insideQuotes)
          {
            result.push(currentField.trim());
            currentField = '';
          } else
          {
            currentField += char;
          }
        }

        // Add the last field
        result.push(currentField.trim());

        return result;
      };

      // Loop Through Each Line of CSV Data (Excluding the Headers)
      for (let i = 1; i < lines?.length; i++)
      {
        const currentLine = parseCSVLine(lines[i]);

        // Check if the row is empty or contains only whitespace
        if (currentLine.every(field => field === ""))
        {
          continue;
        }

        const obj = {};
        // Loop Through Each Column and Match the Headers
        for (let j = 0; j < headers.length; j++)
        {
          if (headers[j] === "Commodities")
          { // Replace "FieldWithCommas" with the actual header name of your quoted field
            obj[headers[j]] = currentLine[j].split(',').map(field => field.trim());
          } else
          {
            obj[headers[j]] = currentLine[j];
          }
        }

        result.push(obj);
      }

      // Load Into State
      setImportedData(result);
      setImportedDataList(result);
    }
  };

  // Imported Data listener
  useEffect(() => {
    if (imported_data?.length > 0)
    {
      if (user_complete_load && coop_complete_load > 0 && producer_complete_load > 0 && error === "" && data_uploaded && data_verification === "")
      {
        verify_member_details(imported_data[0]);
        // Retrieve All Users and All Entities
      }
    } else
    {
      if (data_uploaded)
      {
        if (data_verification === app_consts.COMPLETE)
        {
          setLoadingMessage("");
          setLoading(false);
        } else if (data_verification === "")
        {
          setLoading(false);
          setError("Upload Failed: No Data in the CSV Document has been found.");
        }
      }
    }
  }, [imported_data, user_complete_load, coop_complete_load, producer_complete_load, error, data_verification]);

  const verify_member_details = async (member_details) => {

    let new_member_details = member_details;
    const errors = [];

    // Verify Member Type ID
    const valid_member_type_id = member_type_id?.trim() === member_details?.member_type_id?.trim();
    if (!valid_member_type_id)
    {
      errors.push(`Member Type ID Does not match with ${page_data?.entity?.entity_name}'s Member Type ID. (${member_type_id})`);
    }

    // Verify Member Name
    const valid_member_name = verify_member_name(new_member_details?.name);
    if (!valid_member_name?.status)
    {
      errors.push(valid_member_name?.error);
    }
    else
    {
      new_member_details = { ...new_member_details, first_name: valid_member_name?.first_name, middle_name: valid_member_name?.middle_name, last_name: valid_member_name?.last_name };
    }

    // Verify Member Gender
    const valid_member_gender = verify_member_gender(new_member_details?.gender);
    if (!valid_member_gender?.status)
    {
      errors.push(valid_member_gender?.error);
    }
    else
    {
      new_member_details = { ...new_member_details, gender: valid_member_gender?.gender };
    }

    // Verify Member Mobile
    const valid_mobile_number = verify_member_mobile(new_member_details?.phone_number);
    if (!valid_mobile_number?.status)
    {
      errors.push(valid_mobile_number?.error);
    }

    // Verify Email Address
    const valid_email_address = verify_member_email(new_member_details?.email);
    if (!valid_email_address?.status)
    {
      errors.push(valid_email_address?.error);
    }

    // Verify Commodities
    let commodities_array = [];
    let commodities_view_array = [];
    // Split the string by commas
    const commodities = new_member_details?.commodities?.split(',');

    // Process each item
    commodities?.map(item => {
      // Trim leading and trailing white spaces
      const trimmedItem = item?.trim();
      // Replace spaces with underscores
      commodities_array?.push(trimmedItem?.replace(/\s+/g, '_'));
      commodities_view_array?.push(trimmedItem?.replace(/\s+/g, '_'));
    });

    new_member_details = { ...new_member_details, commodities_array: commodities_array };

    // Check Existance
    const valid_existance = await verify_account_existance(new_member_details);
    if (!valid_existance?.status)
    {
      errors.push(valid_existance?.error);
    }
    // Check for Duplicates on None Nil Values
    const duplicate_1 = imported_data_list?.filter((data) => data?.phone_number === new_member_details?.phone_number && new_member_details?.phone_number !== app_consts.NIL);
    // Phone Number
    if (duplicate_1?.length > 1)
    {
      errors.push("Duplicate Member Mobile");
    }

    const duplicate_2 = imported_data_list?.filter((data) => data?.email === new_member_details?.email && new_member_details?.email !== app_consts.NIL);
    // Email
    if (duplicate_2?.length > 1)
    {
      errors.push("Duplicate Member Email");
    }

    const duplicate_3 = imported_data_list?.filter((data) => data?.nrc_number === new_member_details?.nrc_number && new_member_details?.nrc_number !== app_consts.NIL);
    // NRC
    if (duplicate_3?.length > 1)
    {
      errors.push("Duplicate Member Identification Number");
    }

    // Update Values that Might be NIL
    if (new_member_details?.nrc_number === app_consts.NIL)
    {
      new_member_details = { ...new_member_details, nrc_number: "" };
    }
    if (new_member_details?.phone_number === app_consts.NIL)
    {
      new_member_details = { ...new_member_details, phone_number: "" };
    }
    if (new_member_details?.email === app_consts.NIL)
    {
      new_member_details = { ...new_member_details, email: "" };
    }

    let processedData = 0;
    console.log(errors);
    if (errors?.length > 0)
    {
      // Add Errors to Failed Imports
      processedData = (failed_imports?.length + 1) + successImports?.length;
      new_member_details = { ...new_member_details, errors: errors };
      setFailedImports([...failed_imports, { ...new_member_details }]);
    }
    else
    {
      processedData = (successImports?.length + 1) + failed_imports?.length;
      setSuccessImports([...successImports, { ...new_member_details }]);
    }

    // Calculate Percentage progess
    const percentage = (processedData / imported_data_list?.length) * 100;
    setLoadingMessage(`Verifying CSV Data (${parseFloat(percentage?.toFixed(0))} %)`);

    // Update Import Data
    const updated_import_data = imported_data?.filter((detail, index) => index !== 0);

    if (processedData === imported_data_list?.length)
    {
      setDataVerification(app_consts.COMPLETE);
      setDataUploaded(true);
    }
    setImportedData(updated_import_data);

  };

  // Verify Member Name
  const verify_member_name = (member_name) => {
    let return_res;
    // Trim the name to remove any leading or trailing spaces
    const trimmedName = member_name.trim();

    // Split the name by spaces
    const nameParts = trimmedName.split(" ");

    // Check for empty name or invalid input
    if (nameParts.length === 0 || trimmedName === "")
    {
      return_res = { status: false, error: "Member Name is Required." };
    }
    else
    {
      // Extract the first, middle, and last names
      const first_name = nameParts[0];
      const last_name = nameParts.length > 1 ? nameParts[nameParts.length - 1] : null;
      const middle_name = nameParts.length > 2 ? nameParts.slice(1, -1).join(" ") : null;

      return_res = { status: true, first_name: first_name, last_name: last_name, middle_name: middle_name };
    }
    return return_res;
  };

  // Verify Member Gender
  const verify_member_gender = (member_gender) => {
    let return_res;
    // Trim the name to remove any leading or trailing spaces
    const trimmedName = member_gender.trim();
    if (trimmedName?.toLowerCase() === app_consts.FEMALE_ABBREV?.toLowerCase() || trimmedName?.toLowerCase() === app_consts.MALE_ABBREV?.toLowerCase())
    {
      if (trimmedName?.toLowerCase() === app_consts.FEMALE_ABBREV?.toLowerCase())
      {
        return_res = { status: true, gender: app_consts.FEMALE?.toLowerCase() };
      }
      else if (trimmedName?.toLowerCase() === app_consts.MALE_ABBREV?.toLowerCase())
      {
        return_res = { status: true, gender: app_consts.MALE?.toLowerCase() };
      }
    }
    else
    {
      return_res = { status: false, error: "Invalid Gender. Gender should be 'F' for Female and 'M' for Male." };
    }
    return return_res;
  };

  // Verify Member Mobile
  const verify_member_mobile = (member_mobile) => {
    // Check if Mobile is NIL
    const nil_mobile_check = EMPTYNULLREGEX.test(member_mobile);
    let return_res;

    if (nil_mobile_check)
    {
      return_res = { status: false, error: "Invalid Member Phone Number. Insert a Valid Zambian Phone Number. Phone Number must be 'NIL' if Not Available." };
    }
    else
    {
      // Check if NIL
      if (member_mobile === app_consts.NIL)
      {
        return_res = { status: true };
      }
      else
      {
        // REGEX Check
        const regex_phone_number = ZAMIBAN_PHONE_NUMBER_REGEX.test(member_mobile);
        if (regex_phone_number)
        {
          return_res = { status: true };
        }
        else
        {
          return_res = { status: false, error: "Invalid Member Phone Number. Insert a Valid Zambian Phone Number. Phone Number must be 'NIL' if Not Available." };
        }
      }
    }
    return return_res;
  };

  // Verify Member Email Addres
  const verify_member_email = (member_email) => {
    // Check if Email is NIL
    const nil_email_check = EMPTYNULLREGEX.test(member_email);
    let return_res;
    if (nil_email_check)
    {
      return_res = { status: false, error: "Invalid Member Email. Email must be 'NIL' if Not Available." };
    }
    else
    {
      // Check if NIL
      if (member_email === app_consts.NIL)
      {
        return_res = { status: true };
      }
      else
      {
        // REGEX Check
        const regex_email = EMAIL_REGEX.test(member_email);
        if (regex_email)
        {
          return_res = { status: true };
        }
        else
        {
          return_res = { status: false, error: "Invalid Member Email. Email must be 'NIL' if Not Available." };
        }
      }
    }
    return return_res;
  };

  // Verify Account Existance
  const verify_account_existance = async (member_details) => {
    let return_res;
    let user_existance;
    let new_member_details = member_details;
    const nil_nrc_check = EMPTYNULLREGEX.test(new_member_details?.nrc_number);


    if (return_res)
    {
      nil_nrc_check = { status: false, error: `Invalid NRC. NRC Should be 'NIL' if not Available.` };
    }
    else
    {
      // Check if NIL
      if (new_member_details?.nrc_number === app_consts.NIL)
      {
        return_res = { status: true };
      }
      else
      {
        // REGEX Check
        const regex_nrc = NRC_REGEX.test(new_member_details?.nrc_number);
        if (regex_nrc)
        {
          // Check Existance
          const nrc_exists = user_data?.filter((user) => user?.user_id_number?.toLowerCase() === new_member_details?.nrc_number.toLowerCase());
          const email_exists = user_data?.filter((user) => user?.account_email?.toLowerCase() === new_member_details?.email.toLowerCase());
          const phone_number_exists = user_data?.filter((user) => user?.account_mobile?.toLowerCase() === new_member_details?.phone_number.toLowerCase());

          // Check if Exists and Return Account with ID
          if (nrc_exists.length > 0)
          {
            if (nrc_exists?.length > 1)
            {
              return_res = { status: false, error: `More than one Identification Numbers with '${new_member_details?.nrc_number}' exist. Please contact IT Support.` };
            }
            else if (nrc_exists?.length === 1)
            {
              user_existance = nrc_exists;
            }
          }
          else
          {
            // Check if Email Exists
            if (email_exists?.length > 0 && phone_number_exists?.length > 0)
            {
              if (email_exists[0]?.account_email !== phone_number_exists[0]?.account_email)
              {
                return_res = { status: false, error: `Email of Member does not match what is in the system.` };
              }
              else if (email_exists[0]?.account_mobile !== phone_number_exists[0]?.account_mobile)
              {
                return_res = { status: false, error: `Phone Number of Member does not match what is in the system.` };
              }
              else if (email_exists[0]?.account_mobile === phone_number_exists[0]?.account_mobile && email_exists[0]?.account_email === phone_number_exists[0]?.account_email)
              {
                user_existance = email_exists;
              }
            }
            else if (email_exists?.length === 0 && phone_number_exists?.length === 0)
            {
              return_res = { status: true };
            }
          }

          if (user_existance?.length > 0)
          {
            // Check for Producer and Cooperative Account Exists
            const user_account_data = user_existance[0];
            const producer_exists = producer_data?.filter((producer) => user_account_data?.account_id === producer?.producer?.account_id);
            if (producer_exists?.length > 0)
            {
              const current_existsing_producer = producer_exists[0];
              // Check if Producer Exists in Cooperative
              const coop_member_exists = coop_data?.filter((coop) => current_existsing_producer?.producer?.account_id === coop?.producer?.account_id);
              if (coop_member_exists?.length > 0)
              {
                return_res = { status: false, error: "Member already Exists in Cooperative." };
              }
              else
              {
                return_res = { status: true };
              }
            }
            else
            {
              return_res = { status: true };
            }
          }
          else 
          {
            return_res = { status: true };
          }
        }
        else
        {
          return_res = { status: false, error: "Invalid NRC. NRC Should be 'NIL' if not Available." };
        }
      }
    }
    return return_res;
  };

  // Listen to State Changes
  const retrieveAllProducersList = useSelector((state) => state.retrieveAllProducersList);

  useEffect(() => {
    if (retrieveAllProducersList.status !== app_consts.IDLE)
    {
      if (!retrieveAllProducersList.isLoading)
      {
        if (retrieveAllProducersList.error)
        {
        }
        else if (retrieveAllProducersList.data)
        {
          setProducerCompleteLoad(true);
          setProducerData(retrieveAllProducersList.data);
        }
        dispatch(retrieveAllProducersListReset());
      }
    }
  }, [retrieveAllProducersList]);

  // Listen to User List Reducer
  const user_list_listner = useSelector((state) => state.getUserAccountList);
  // 
  useEffect(() => {
    if (user_list_listner.status !== app_consts.IDLE)
    {
      if (!user_list_listner.isLoading)
      {
        if (user_list_listner.error)
        {
        }
        else
        {
          setUserCompleteLoad(true);
          setUserData(user_list_listner.data);
        }
        setLoading(false);
        dispatch(retrieveAllUsersReset());
      }
    }
  }, [user_list_listner]);

  return (
    <>
      <button
        data-drawer-target='logo-sidebar'
        data-drawer-toggle='logo-sidebar'
        aria-controls='logo-sidebar'
        type='button'
        className='inline-flex items-center p-2 mt-2 ml-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600'
      >
        <span className='sr-only'>Open sidebar</span>
        <svg
          className='w-6 h-6'
          aria-hidden='true'
          fill='currentColor'
          viewBox='0 0 20 20'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            clip-rule='evenodd'
            fill-rule='evenodd'
            d='M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z'
          ></path>
        </svg>
        <NavLogo />
      </button>

      <nav class="flex items-center justify-between flex-wrap p-6 bg-gray-50">
        <div class="flex items-center flex-shrink-0  mr-6">

          <a href='/home' className='flex items-center pl-2.5'>
            <DynamicNavLogo height='50' width='150' />
          </a>
        </div>
        <div class="block lg:hidden">
          <button class="flex items-center px-3 py-2 border rounded hover:text-white hover:border-white">
            <svg class="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" /></svg>
          </button>
        </div>
        <div class="w-full block flex-grow lg:flex lg:items-center lg:w-auto">
          <div class="text-sm lg:flex-grow">
            <a href={`/exchange/${entity_id}/${entity_mid}/cooperative_management/${member_type_id}`} class="text-lg flex items-center p-2 text-gray-600 hover:text-green-600 rounded-lg dark:text-white font-semibold group capitalize">
              Cooperative Details {page_data && page_data?.entity?.entity_name ? `(${page_data?.entity?.entity_name})` : ""}
            </a>
          </div>
          <div>
            <a href="#" onClick={handleLogout} class="inline-block text-sm px-4 py-2 leading-none border border-white hover:border-transparent  hover:bg-white mt-4 lg:mt-0 font-semibold">Logout</a>
          </div>
        </div>
      </nav>

      <div className="lg-full lg:w-10/12 m-auto">
        <div className="p-4 rounded-lg dark:border-gray-700">

          <div className="relative">
            <div>
              <h5 class="text-3xl mr-3 font-semibold dark:text-white text-gray-500">Import Cooperative Members for <span className='text-gray-800 capitalize'>{page_data?.entity?.entity_name}</span></h5>
              <div>
                <div class="flex flex-col items-start justify-start pt-5 pb-6">
                  <p class="mb-2 text-base text-gray-500 dark:text-gray-400"><span class="font-semibold">Please Attach a CSV Document to Begin the Importation Process.</span></p>
                  <p class="text-xs text-gray-500 dark:text-gray-400">
                    <p class="text-base text-gray-500 dark:text-gray-400">Click <span className='cursor-pointer text-blue-600 underline font-bold' onClick={export_to_csv}>Here</span> to Download a Template of the Document to be Uploaded.</p>
                  </p>
                </div>
                {
                  data_verification === app_consts.COMPLETE && (
                    <>
                      {
                        !completed && (
                          <>
                            <div class="flex flex-row justify-between w-full">
                              <div>
                                <button className="flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-gray-600 hover:bg-green-800 focus:ring-4 focus:ring-gray-300 dark:bg-gray-600 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800" type="button" onClick={handleButtonClick} disabled={loading} >

                                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 mr-2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M9 3.75H6.912a2.25 2.25 0 0 0-2.15 1.588L2.35 13.177a2.25 2.25 0 0 0-.1.661V18a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18v-4.162c0-.224-.034-.447-.1-.661L19.24 5.338a2.25 2.25 0 0 0-2.15-1.588H15M2.25 13.5h3.86a2.25 2.25 0 0 1 2.012 1.244l.256.512a2.25 2.25 0 0 0 2.013 1.244h3.218a2.25 2.25 0 0 0 2.013-1.244l.256-.512a2.25 2.25 0 0 1 2.013-1.244h3.859M12 3v8.25m0 0-3-3m3 3 3-3" />
                                  </svg>


                                  Re-upload CSV
                                </button>
                                <input
                                  id="dropzone-file"
                                  type="file"
                                  accept=".csv"
                                  className="hidden"
                                  ref={fileInputRef}
                                  onChange={(event) => handleFileChange(event)}
                                />
                              </div>
                              <div>
                                <button className="flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-gray-600 hover:bg-green-800 focus:ring-4 focus:ring-gray-300 dark:bg-gray-600 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800" type="button" onClick={handleDataImport} disabled={loading}>
                                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 mr-2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M10.125 2.25h-4.5c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125v-9M10.125 2.25h.375a9 9 0 0 1 9 9v.375M10.125 2.25A3.375 3.375 0 0 1 13.5 5.625v1.5c0 .621.504 1.125 1.125 1.125h1.5a3.375 3.375 0 0 1 3.375 3.375M9 15l2.25 2.25L15 12" />
                                  </svg>

                                  Submit and Save Data
                                </button>
                              </div>
                            </div>
                          </>
                        )
                      }
                    </>
                  )
                }
              </div>

              {
                loading ? (
                  <>
                    <div className="text-center my-2">
                      <div className="text-center my-6">
                        <span colSpan="8" className="text-gray-700 py-4 my-2">
                          {loadingmessage}
                        </span>
                      </div>
                      <div role="status">
                        <svg
                          aria-hidden="true"
                          className="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-green-600"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill"
                          />
                        </svg>
                        <span className="sr-only">Please wait...</span>
                      </div>
                    </div>
                  </>
                )
                  : (
                    <>
                      {error && (
                        <div
                          className='p-4 my-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400'
                          role='alert'
                        >
                          <span className='font-medium'>Error: </span>
                          {error}
                        </div>
                      )
                      }
                      {
                        completed ?
                          <>
                            <div className="text-center my-2 flex flex-col items-center justify-center">
                              <div className="text-center my-6">
                                <span colSpan="8" className="text-gray-700 py-4 my-2">
                                  {completed_success}
                                </span>
                              </div>
                              <div role="status">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 text-green-600 inline w-8 h-8 mr-2  dark:text-gray-600  ">
                                  <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                </svg>

                                <span className="sr-only">Please wait...</span>
                              </div>
                              <button onClick={() => navigate(`/exchange/${entity_id}/${entity_mid}/cooperative_management/${member_type_id}`)} className="mt-4 flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-green-600 hover:bg-green-800 focus:ring-4 focus:ring-green-300 dark:bg-green-600 dark:hover:bg-green-700 focus:outline-none dark:focus:ring-green-800 px-8 py-2" type="button" disabled={loading}>

                                Finish
                              </button>
                            </div>
                            <div className='my-4'>
                              <div>
                                <span className='text-xl font-semibold text-gray-700'>Failed Submissions</span>
                              </div>
                              <div>
                                <TableComp tableData={failed_imports} tableType={app_consts.INVALID_COOPERATIVE_MEMBER_CSV_DATA} tableHeading={"Failed Submissions"} />
                              </div>
                            </div>
                          </>
                          :
                          <>
                            {
                              data_verification === app_consts.COMPLETE ?
                                <>
                                  <div>
                                    {
                                      successImports && (
                                        <>
                                          <div className='my-4'>
                                            <div>
                                              <span className='text-xl font-semibold text-gray-700'>Valid Data (To be Imported)</span>
                                            </div>
                                            <div>
                                              <TableComp tableData={successImports} tableType={app_consts.VALID_COOPERATIVE_MEMBER_CSV_DATA} tableHeading={"Valid Data"} />
                                            </div>
                                          </div>
                                        </>
                                      )
                                    }
                                    {
                                      failed_imports && (
                                        <>
                                          <div className='my-4'>
                                            <div>
                                              <span className='text-xl font-semibold text-gray-700'>Invalid Data (Not to be Imported)</span>
                                            </div>
                                            <div>
                                              <TableComp tableData={failed_imports} tableType={app_consts.INVALID_COOPERATIVE_MEMBER_CSV_DATA} tableHeading={"Invalid Data"} />
                                            </div>
                                          </div>
                                        </>
                                      )
                                    }
                                  </div>
                                </>
                                :
                                <>
                                  <div class="flex items-center justify-center w-full mt-8">
                                    <label for="dropzone-file" class="flex flex-col items-center justify-center w-full h-96 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                                      <div>
                                        <div class="flex flex-col items-center justify-center pt-5 pb-6">
                                          <svg class="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                                          </svg>
                                          <p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold">Click to upload</span></p>
                                          <p class="text-xs text-gray-500 dark:text-gray-400"> CSV File Only</p>
                                        </div>
                                        <input
                                          id="dropzone-file"
                                          type="file"
                                          accept=".csv"
                                          className="hidden"
                                          onChange={handleFileChange}
                                        />
                                      </div>
                                    </label>
                                  </div>
                                </>
                            }
                          </>
                      }
                    </>
                  )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CooperativeMemberCSVImport;