import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as app_consts from "../../../../utils/constants";
import * as yup from 'yup';
import { wheat_grading_scale } from '../../../../utils/commodity_grading_scale';
import { createCommodityGrading } from '../../../../utils/actions';

const grading_criteria = { specific_weight: "", moisture_content: "", protein: "", hagberg_falling_number: "", screenings: "", split_beans: "", other_grains: "", foreign_matter: "", damaged_kernels: "", combined_deviations: "", heavily_frost_damaged_kernels: "", field_fungi_infected_kernels: "", storage_fungi_infected_kernels: "", };

const WheatGradingStep = ({ nextStep, prevStep, prevData, setPrevData }) => {


    const [commodity_grade, setCommodityGrade] = useState("--");
    const [delivery_id, setDeliveryId] = useState("");
    const [formData, setFormData] = useState(prevData);
    const [commod_grad_criteria, setCommodGradeCriteria] = useState(grading_criteria);

    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        setFormData(prevData);
        setDeliveryId(prevData.delivery_id);
    }, [prevData]);

    // Validate Form Params
    const commodityGradingSchema = yup.object().shape({
        specific_weight: yup.string().required('Specific Weight is required'),
        moisture_content: yup.string().required('Moisture Content is required'),
        protein: yup.string().required('Protein Content is required'),
        hagberg_falling_number: yup.string().required('Hagberg Falling Number is required'),
        screenings: yup.string().required('Screenings is required'),
        other_grains: yup.string().required('Other Grains is required'),
        foreign_matter: yup.string().required('Foreign Matter is required'),
        damaged_kernels: yup.string().required('Damged Kernels is required'),
        combined_deviations: yup.string().required('Combined Deviations is required'),
        heavily_frost_damaged_kernels: yup.string().required('Heavily Frost Damaged Kernels is required'),
        field_fungi_infected_kernels: yup.string().required('Field Fungi Infected Kernals is required'),
        storage_fungi_infected_kernels: yup.string().required('Storage Fungi Infected Kernals is required'),
    });

    // Formik Validation
    const formik = useFormik({
        initialValues: {
            specific_weight: '',
            moisture_content: '',
            protein: "",
            hagberg_falling_number: '',
            screenings: '',
            other_grains: '',
            foreign_matter: '',
            damaged_kernels: '',
            combined_deviations: '',
            heavily_frost_damaged_kernels: '',
            field_fungi_infected_kernels: '',
            storage_fungi_infected_kernels: '',
            commodity: app_consts.WHEAT,
            delivery_id: delivery_id,
            grade: commodity_grade
        },

        validationSchema: commodityGradingSchema,
        onSubmit: async (values) => {
            setError("");
            setLoading(true);
            try
            {
                const commodity_params = {
                    specific_weight: parseFloat(values.specific_weight).toFixed(2),
                    moisture_content: parseFloat(values.moisture_content).toFixed(2),
                    protein: parseFloat(values.protein).toFixed(2),
                    hagberg_falling_number: parseFloat(values.hagberg_falling_number).toFixed(2),
                    screenings: parseFloat(values.screenings).toFixed(2),
                    other_grains: parseFloat(values.other_grains).toFixed(2),
                    foreign_matter: parseFloat(values.foreign_matter).toFixed(2),
                    damaged_kernels: parseFloat(values.damaged_kernels).toFixed(2),
                    combined_deviations: parseFloat(values.combined_deviations).toFixed(2),
                    heavily_frost_damaged_kernels: parseFloat(values.heavily_frost_damaged_kernels).toFixed(2),
                    field_fungi_infected_kernels: parseFloat(values.field_fungi_infected_kernels).toFixed(2),
                    storage_fungi_infected_kernels: parseFloat(values.storage_fungi_infected_kernels).toFixed(2),
                    commodity: app_consts.WHEAT,
                    delivery_id: delivery_id,
                    grade: commodity_grade,
                };

                await dispatch(createCommodityGrading(commodity_params));

                nextStep();

            } catch (error)
            {
                if (error.response)
                {
                    if (error.response.status === 400)
                    {
                        setError(error.response.data.message);
                    }
                    else if (error.response.status === 500)
                    {
                        setError("Failed to Grade Commodity.");
                    }
                }
                else
                {
                    setError("Failed to Grade Commodity.");
                }
            } finally
            {
                setLoading(false);
            }
        },
    });

    const handleMeasurementChange = (e) => {
        let result;
        if (e.target.value)
        {
            result = wheat_grading_scale(e.target.name, e.target.value);
        }
        else
        {
            result = "";
        }
        setCommodGradeCriteria({ ...commod_grad_criteria, [e.target.name]: result });
        formik.setFieldValue(e.target.name, e.target.value);
    };

    const handleOnblur = (name, value) => {
        if (value)
        {
            formik.setFieldValue(name, parseFloat(value).toFixed(2));
        }
    };


    // Listen to Formik Values Changes
    useEffect(() => {
        if (commod_grad_criteria === grading_criteria)
        {
            setCommodityGrade("---");
        }
        else
        {
            const gradingArray = [commod_grad_criteria.specific_weight, commod_grad_criteria.moisture_content, commod_grad_criteria.protein, commod_grad_criteria.hagberg_falling_number, commod_grad_criteria.screenings, commod_grad_criteria.other_grains, commod_grad_criteria.foreign_matter, commod_grad_criteria.damaged_kernels, commod_grad_criteria.combined_deviations, commod_grad_criteria.heavily_frost_damaged_kernels, commod_grad_criteria.field_fungi_infected_kernels, commod_grad_criteria.storage_fungi_infected_kernels];

            if (gradingArray?.includes(0) || gradingArray?.includes(""))
            {
                setCommodityGrade("---");
            }
            else if (gradingArray?.includes("B4"))
            {
                setCommodityGrade("B4");
            }
            else if (gradingArray?.includes("B3"))
            {
                setCommodityGrade("B3");
            }
            else if (gradingArray?.includes("B2"))
            {
                setCommodityGrade("B2");
            }
            else
            {
                setCommodityGrade("B1");
            }
        }

    }, [commod_grad_criteria]);

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className='flex flex-row w-full'>
                    <div className="grid gap-6 mb-8 lg:grid-cols-3 p-6 sm:p-16 lg:p-8 lg:w-9/12 m-auto">
                        <div>
                            <label htmlFor="specific_weight" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                Specific Weight
                            </label>
                            <input
                                step="0.01"
                                type="number"
                                min="0.00"
                                name="specific_weight"
                                value={formik.values.specific_weight}
                                onChange={handleMeasurementChange}
                                onBlur={() => handleOnblur("specific_weight", formik.values.specific_weight)}
                                // required
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                            />
                            {formik.touched.specific_weight && formik.errors.specific_weight && (
                                <p className="text-red-500 text-sm mt-1">{formik.errors.specific_weight}</p>
                            )}
                        </div>
                        <div>
                            <label htmlFor="moisture_content" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                Moisture Content
                            </label>
                            <input
                                step="0.01"
                                type="number"
                                min="0.00"
                                default="0.00"
                                name="moisture_content"
                                value={formik.values.moisture_content}
                                onChange={handleMeasurementChange}
                                onBlur={() => handleOnblur("moisture_content", formik.values.moisture_content)}
                                // required
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                            />
                            {formik.touched.moisture_content && formik.errors.moisture_content && (
                                <p className="text-red-500 text-sm mt-1">{formik.errors.moisture_content}</p>
                            )}
                        </div>
                        <div>
                            <label htmlFor="protein" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                Protein
                            </label>
                            <input
                                step="0.01"
                                type="number"
                                min="0.00"
                                default="0.00"
                                name="protein"
                                value={formik.values.protein}
                                onChange={handleMeasurementChange}
                                onBlur={() => handleOnblur("protein", formik.values.protein)}
                                // required
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                            />
                            {formik.touched.protein && formik.errors.protein && (
                                <p className="text-red-500 text-sm mt-1">{formik.errors.protein}</p>
                            )}
                        </div>
                        <div>
                            <label htmlFor="hagberg_falling_number" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                Hagberg Falling Number
                            </label>
                            <input
                                step="0.01"
                                type="number"
                                min="0.00"
                                default="0.00"
                                name="hagberg_falling_number"
                                value={formik.values.hagberg_falling_number}
                                onChange={handleMeasurementChange}
                                onBlur={() => handleOnblur("hagberg_falling_number", formik.values.hagberg_falling_number)}
                                // required
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                            />
                            {formik.touched.hagberg_falling_number && formik.errors.hagberg_falling_number && (
                                <p className="text-red-500 text-sm mt-1">{formik.errors.hagberg_falling_number}</p>
                            )}
                        </div>
                        <div>
                            <label htmlFor="screenings" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                Screenings
                            </label>
                            <input
                                step="0.01"
                                type="number"
                                min="0.00"
                                default="0.00"
                                name="screenings"
                                value={formik.values.screenings}
                                onChange={handleMeasurementChange}
                                onBlur={() => handleOnblur("screenings", formik.values.screenings)}
                                // required
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                            />
                            {formik.touched.screenings && formik.errors.screenings && (
                                <p className="text-red-500 text-sm mt-1">{formik.errors.screenings}</p>
                            )}
                        </div>
                        <div>
                            <label htmlFor="other_grains" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                Other Grains
                            </label>
                            <input
                                step="0.01"
                                type="number"
                                min="0.00"
                                default="0.00"
                                name="other_grains"
                                value={formik.values.other_grains}
                                onChange={handleMeasurementChange}
                                onBlur={() => handleOnblur("other_grains", formik.values.other_grains)}
                                // required
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                            />
                            {formik.touched.other_grains && formik.errors.other_grains && (
                                <p className="text-red-500 text-sm mt-1">{formik.errors.other_grains}</p>
                            )}
                        </div>
                        <div>
                            <label htmlFor="foreign_matter" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                Foreign Matter
                            </label>
                            <input
                                step="0.01"
                                type="number"
                                min="0.00"
                                default="0.00"
                                name="foreign_matter"
                                value={formik.values.foreign_matter}
                                onChange={handleMeasurementChange}
                                onBlur={() => handleOnblur("foreign_matter", formik.values.foreign_matter)}
                                // required
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                            />
                            {formik.touched.foreign_matter && formik.errors.foreign_matter && (
                                <p className="text-red-500 text-sm mt-1">{formik.errors.foreign_matter}</p>
                            )}
                        </div>
                        <div>
                            <label htmlFor="damaged_kernels" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                Damaged Kernels
                            </label>
                            <input
                                step="0.01"
                                type="number"
                                min="0.00"
                                default="0.00"
                                name="damaged_kernels"
                                value={formik.values.damaged_kernels}
                                onChange={handleMeasurementChange}
                                onBlur={() => handleOnblur("damaged_kernels", formik.values.damaged_kernels)}
                                // required
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                            />
                            {formik.touched.damaged_kernels && formik.errors.damaged_kernels && (
                                <p className="text-red-500 text-sm mt-1">{formik.errors.damaged_kernels}</p>
                            )}
                        </div>
                        <div>
                            <label htmlFor="combined_deviations" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                Combined Deviations
                            </label>
                            <input
                                step="0.01"
                                type="number"
                                min="0.00"
                                default="0.00"
                                name="combined_deviations"
                                value={formik.values.combined_deviations}
                                onChange={handleMeasurementChange}
                                onBlur={() => handleOnblur("combined_deviations", formik.values.combined_deviations)}
                                // required
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                            />
                            {formik.touched.combined_deviations && formik.errors.combined_deviations && (
                                <p className="text-red-500 text-sm mt-1">{formik.errors.combined_deviations}</p>
                            )}
                        </div>
                        <div>
                            <label htmlFor="heavily_frost_damaged_kernels" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                Heavily Frost Damaged Kernals
                            </label>
                            <input
                                step="0.01"
                                type="number"
                                min="0.00"
                                default="0.00"
                                name="heavily_frost_damaged_kernels"
                                value={formik.values.heavily_frost_damaged_kernels}
                                onBlur={() => handleOnblur("heavily_frost_damaged_kernels", formik.values.heavily_frost_damaged_kernels)}
                                onChange={handleMeasurementChange}
                                // required
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                            />
                            {formik.touched.heavily_frost_damaged_kernels && formik.errors.heavily_frost_damaged_kernels && (
                                <p className="text-red-500 text-sm mt-1">{formik.errors.heavily_frost_damaged_kernels}</p>
                            )}
                        </div>
                        <div>
                            <label htmlFor="field_fungi_infected_kernels" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                Field Fungi Infected Kernels
                            </label>
                            <input
                                step="0.01"
                                type="number"
                                min="0.00"
                                default="0.00"
                                name="field_fungi_infected_kernels"
                                value={formik.values.field_fungi_infected_kernels}
                                onChange={handleMeasurementChange}
                                onBlur={() => handleOnblur("field_fungi_infected_kernels", formik.values.field_fungi_infected_kernels)}
                                // required
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                            />
                            {formik.touched.field_fungi_infected_kernels && formik.errors.field_fungi_infected_kernels && (
                                <p className="text-red-500 text-sm mt-1">{formik.errors.field_fungi_infected_kernels}</p>
                            )}
                        </div>
                        <div>
                            <label htmlFor="storage_fungi_infected_kernels" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                Storage Fungi Infected Kernels
                            </label>
                            <input
                                step="0.01"
                                type="number"
                                min="0.00"
                                default="0.00"
                                name="storage_fungi_infected_kernels"
                                value={formik.values.storage_fungi_infected_kernels}
                                onChange={handleMeasurementChange}
                                onBlur={() => handleOnblur("storage_fungi_infected_kernels", formik.values.storage_fungi_infected_kernels)}
                                // required
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                            />
                            {formik.touched.storage_fungi_infected_kernels && formik.errors.storage_fungi_infected_kernels && (
                                <p className="text-red-500 text-sm mt-1">{formik.errors.storage_fungi_infected_kernels}</p>
                            )}
                        </div>
                    </div>
                    <div className='w-3/12 flex flex-column justify-center items-center h-auto bg-gray-50 p-2'>
                        <div>
                            <p className='text-center text-3xl mb-2 font-medium text-gray-700'>Commodity Grade</p>
                            <p className='text-5xl text-center font-bold my-6'>{commodity_grade}</p>
                        </div>
                    </div>
                </div>
                {error && error.length > 0 && (
                    <div className="my-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 px-6 py-4 sm:px-16 lg:px-8" role="alert">
                        <span className="font-medium">Error: </span>
                        {error}
                    </div>
                )}
                <div className='my-4 px-6 flex flex-row justify-end items-center'>
                    {/* <button
                    onClick={prevStep}
                    type="submit"
                    className="text-white bg-gray-500 hover:bg-gray-600 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-gray-500 dark:hover:bg-gray-600 dark:focus:ring-gray-700"
                >
                    Previous Step: Truck Information
                </button> */}
                    <button
                        // disabled={!(formData.values.commmodity_id)}
                        // onClick={nextStep}
                        type="submit"
                        className={`text-white bg-green-600 cursor-pointer hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800`}
                    >
                        Next Step: Storage Information
                    </button>
                </div>
            </form>
        </>
    );
};

export default WheatGradingStep;