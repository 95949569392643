import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { addGrnToDeliveryReset, addWarehouse, add_grn_to_delivery } from '../../utils/actions';
import * as app_consts from '../../utils/constants';

const AddGoodsReceivedNoteModal = ({ isOpen, setIsOpen, successFunction, delivery_id }) => {

    const dispatch = useDispatch();

    const [openModal, setOpenModal] = useState(isOpen);
    const [isLoading, setLoading] = useState("");
    const [error, setError] = useState("");

    useEffect(() => {
        setOpenModal(isOpen);
        formik.resetForm();
    }, [isOpen]);

    const addGoodsRecivedNoteModal = yup.object().shape({
        delivery_id: yup.string().required('Delivery ID is required'),
        truck_load_no: yup.string().required('Truck Load No is required'),
        no_of_bags: yup.string().required('No of Bags is required'),
        grn_no: yup.string().required('GRN No is required'),
        date_received: yup.string().required('Date Received is required'),
        quantity: yup.string().required('Quantity is required'),
    });

    const formik = useFormik({
        initialValues: {
            delivery_id: delivery_id,
            truck_load_no: "",
            no_of_bags: "",
            grn_no: "",
            date_received: "",
            quantity: "",
        },
        validationSchema: addGoodsRecivedNoteModal,


        onSubmit: async (values) => {
            setError("");
            try
            {
                const grnData = {
                    delivery_id: delivery_id,
                    grn_params: {
                        truck_load_no: values.truck_load_no,
                        no_of_bags: values.no_of_bags,
                        grn_no: values.grn_no,
                        date_received: values.date_received,
                        quantity: values.quantity
                    }
                };

                await dispatch(add_grn_to_delivery(grnData));

            } catch (error)
            {
                console.log(error.message);
            }

        },
    });

    const closeModal = () => {
        setIsOpen(false);
    };

    // Listen to UseEffect for Adding GRN
    const add_grn_listener = useSelector((state) => state.addGrnToDelivery);
    // 
    useEffect(() => {
        if (add_grn_listener.status !== app_consts.IDLE)
        {
            if (add_grn_listener.isLoading)
            {
                setLoading(true);
            }
            else
            {
                setLoading(false);
                if (add_grn_listener.error)
                {
                    setError(add_grn_listener.message);
                }
                else if (add_grn_listener.data)
                {
                    successFunction(add_grn_listener.data);
                }
                dispatch(addGrnToDeliveryReset());
            }
        }
    }, [add_grn_listener]);

    return (
        <>
            {
                openModal && (
                    <>
                        <div className="fixed inset-0 flex items-center justify-center z-50 overflow-y-auto">
                            <div className="fixed inset-0 bg-black opacity-50"></div>
                            {/* Modal content goes here */}
                            <div className="my-8 relative p-4 bg-white lg:w-5/12 w-5/6 rounded-lg shadow dark:bg-gray-800 md:p-8 max-h-screen overflow-y-auto">
                                {/* Add your modal content here */}
                                <div class="flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5 dark:border-gray-600">
                                    <h3 class="text-lg font-semibold text-gray-900 dark:text-white">
                                        Add Goods Received Note
                                    </h3>
                                    <button type="button" onClick={closeModal} class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="updateProductModal">
                                        <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                        <span class="sr-only">Close</span>
                                    </button>
                                </div>
                                <form className="space-y-4 md:space-y-6" onSubmit={formik.handleSubmit}>
                                    <div className="container grid grid-cols-2 gap-6 sm:grid-cols-2 lg:grid-cols-2">
                                        {/* <div className="grid gap-6 mb-2 lg:grid-cols-3 p-6 sm:p-16 lg:p-8"> */}
                                        <div>
                                            <label htmlFor="truck_load_no" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                                Truck Load No.
                                            </label>
                                            <input
                                                step="0.01"
                                                type="number"
                                                min="0.00"
                                                name="truck_load_no"
                                                value={formik.values.truck_load_no}
                                                onChange={formik.handleChange}
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                                            />
                                            {formik.touched.truck_load_no && formik.errors.truck_load_no && (
                                                <p className="text-red-500 text-sm mt-1">{formik.errors.truck_load_no}</p>
                                            )}
                                        </div>
                                        <div>
                                            <label htmlFor="description" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                                No. Of Bags
                                            </label>
                                            <input
                                                step="0.01"
                                                type="number"
                                                min="0.00"
                                                name="no_of_bags"
                                                value={formik.values.no_of_bags}
                                                onChange={formik.handleChange}
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                                            />
                                            {formik.touched.no_of_bags && formik.errors.no_of_bags && (
                                                <p className="text-red-500 text-sm mt-1">{formik.errors.no_of_bags}</p>
                                            )}
                                        </div>
                                        <div>
                                            <label htmlFor="description" className="flex flex-row items-center block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                                Quantity <span className="block ml-2 text-xs font-medium text-gray-600 dark:text-white">(Metric Tonnes)</span>
                                            </label>
                                            <input
                                                step="0.01"
                                                type="number"
                                                min="0.00"
                                                name="quantity"
                                                value={formik.values.quantity}
                                                onChange={formik.handleChange}
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                                            />
                                            {formik.touched.quantity && formik.errors.quantity && (
                                                <p className="text-red-500 text-sm mt-1">{formik.errors.quantity}</p>
                                            )}
                                        </div>
                                        <div>
                                            <label htmlFor="description" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                                GRN No.
                                            </label>
                                            <input
                                                type="text"
                                                name="grn_no"
                                                value={formik.values.grn_no}
                                                onChange={formik.handleChange}
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                                            />
                                            {formik.touched.grn_no && formik.errors.grn_no && (
                                                <p className="text-red-500 text-sm mt-1">{formik.errors.grn_no}</p>
                                            )}
                                        </div>

                                        <div>
                                            <label htmlFor="date_received" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                                Date Received
                                            </label>
                                            <input
                                                type="datetime-local"
                                                name="date_received"
                                                value={formik.values.date_received}
                                                onChange={formik.handleChange}
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                                            />
                                            {formik.touched.date_received && formik.errors.date_received && (
                                                <p className="text-red-500 text-sm mt-1">{formik.errors.date_received}</p>
                                            )}
                                        </div>
                                    </div>
                                    {error && error.length > 0 && (
                                        <div className="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
                                            <span className="font-medium">Error: </span>
                                            {error}
                                        </div>
                                    )}
                                    {/* Close button */}
                                    <div className="flex space-x-4 justify-end">
                                        <button
                                            type="submit"
                                            disabled={isLoading}
                                            className={`${isLoading ? "cursor-not-allowed bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-gray-300 dark:focus:ring-gray-800" : "cursor-pointer bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-primary-300 dark:focus:ring-primary-800"} mt-4 text-white focus:ring-4 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 mb-2`}
                                        >

                                            {isLoading ? 'Adding GRN...' : 'Add GRN'}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </>
                )
            }
        </>
    );
};

export default AddGoodsReceivedNoteModal;