import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as app_consts from "../../../../utils/constants";
import * as yup from 'yup';
import { createCommodityGrading } from '../../../../utils/actions';
import { useDispatch, useSelector } from 'react-redux';
import { maize_grading_scale } from '../../../../utils/commodity_grading_scale';

const grading_criteria = { test_density: "", moisture_content: "", extraneous_matter: "", broken_grains: "", other_coloured_grains: "", total_other_defective_grains: "", insect_pest_damaged_grains: "", diseased_grain: "", immature_shrivelled_grain: "", fungal_damaged_grains: "", germinated_grains: "", pass_thru_6_35mm_sieve: "", diplodia: "", fusarium: "" };

const MaizeGradingStep = ({ nextStep, prevStep, prevData, setPrevData }) => {

    const [formData, setFormData] = useState(prevData);
    const [delivery_id, setDeliveryId] = useState("");
    const [commodity_grade, setCommodityGrade] = useState("--");
    const [commod_grad_criteria, setCommodGradeCriteria] = useState(grading_criteria);

    useEffect(() => {
        setFormData(prevData);
        setDeliveryId(prevData.delivery_id);
    }, [prevData]);

    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    // Validate Form Params
    const commodityGradingSchema = yup.object().shape({
        test_density: yup.string().required('Tesst Density is Required'),
        moisture_content: yup.string().required('Moisture Content is required'),
        extraneous_matter: yup.string().required('Extraneous Matter is required'),
        broken_grains: yup.string().required('Broken Grains is required'),
        other_coloured_grains: yup.string().required('Other Coloured Grains is Required'),
        total_other_defective_grains: yup.string().required('Total Other Defective Grains is required'),
        insect_pest_damaged_grains: yup.string().required('Insect Pest Damaged Grains is required'),
        diseased_grain: yup.string().required('Diseased Grain is required'),
        immature_shrivelled_grain: yup.string().required('Immature Damaged Grains is required'),
        fungal_damaged_grains: yup.string().required('Fungal Damaged Grains is required'),
        germinated_grains: yup.string().required('Germinated Grains is required'),
        pass_thru_6_35mm_sieve: yup.string().required('Pass Through 6-35mm Sieve is required'),
        diplodia: yup.string().required('Diplodia is required'),
        fusarium: yup.string().required('Fusarium is required'),
        discoloured_grains: yup.string().required('Discoloured Grains is required'),
    });

    // Formik Validation
    const formik = useFormik({
        initialValues: {
            test_density: "",
            moisture_content: '',
            extraneous_matter: '',
            broken_grains: '',
            other_coloured_grains: '',
            total_other_defective_grains: '',
            insect_pest_damaged_grains: '',
            diseased_grain: '',
            immature_shrivelled_grain: '',
            fungal_damaged_grains: '',
            germinated_grains: "",
            pass_thru_6_35mm_sieve: '',
            diplodia: "",
            fusarium: "",
            discoloured_grains: "",
            commodity: app_consts.MAIZE,
            delivery_id: delivery_id,
            grade: commodity_grade
        },

        validationSchema: commodityGradingSchema,
        onSubmit: async (values) => {
            setError("");
            setLoading(true);
            try
            {
                const commodity_params = {
                    test_density: parseFloat(values.test_density).toFixed(2),
                    moisture_content: parseFloat(values.moisture_content).toFixed(2),
                    extraneous_matter: parseFloat(values.extraneous_matter).toFixed(2),
                    broken_grains: parseFloat(values.broken_grains).toFixed(2),
                    other_coloured_grains: parseFloat(values.other_coloured_grains).toFixed(2),
                    total_other_defective_grains: parseFloat(values.total_other_defective_grains).toFixed(2),
                    insect_pest_damaged_grains: parseFloat(values.insect_pest_damaged_grains).toFixed(2),
                    diseased_grain: parseFloat(values.diseased_grain).toFixed(2),
                    immature_shrivelled_grain: parseFloat(values.immature_shrivelled_grain).toFixed(2),
                    fungal_damaged_grains: parseFloat(values.fungal_damaged_grains).toFixed(2),
                    germinated_grains: parseFloat(values.germinated_grains).toFixed(2),
                    pass_thru_6_35mm_sieve: parseFloat(values.pass_thru_6_35mm_sieve).toFixed(2),
                    diplodia: parseFloat(values.diplodia).toFixed(2),
                    fusarium: parseFloat(values.fusarium).toFixed(2),
                    discoloured_grains: parseFloat(values.discoloured_grains).toFixed(2),
                    commodity: app_consts.MAIZE,
                    delivery_id: delivery_id,
                    grade: commodity_grade,
                };

                await dispatch(createCommodityGrading(commodity_params));

                nextStep();

            } catch (error)
            {
                if (error.response)
                {
                    if (error.response.status === 400)
                    {
                        setError(error.response.data.message);
                    }
                    else if (error.response.status === 500)
                    {
                        setError("Failed to Grade Commodity.");
                    }
                }
                else
                {
                    setError("Failed to Grade Commodity.");
                }
            } finally
            {
                setLoading(false);
            }
        },
    });

    const handleMeasurementChange = (e) => {
        let result;
        if (e.target.value)
        {
            result = maize_grading_scale(e.target.name, e.target.value);
        }
        else
        {
            result = "";
        }
        setCommodGradeCriteria({ ...commod_grad_criteria, [e.target.name]: result });
        formik.setFieldValue(e.target.name, e.target.value);
    };

    // Listen to Formik Values Changes
    useEffect(() => {
        if (commod_grad_criteria === grading_criteria)
        {
            setCommodityGrade("---");
        }
        else
        {

            const gradingArray = [commod_grad_criteria.test_density, commod_grad_criteria.moisture_content, commod_grad_criteria.extraneous_matter, commod_grad_criteria.broken_grains, commod_grad_criteria.other_coloured_grains, commod_grad_criteria.insect_pest_damaged_grains, commod_grad_criteria.diseased_grain, commod_grad_criteria.immature_shrivelled_grain, commod_grad_criteria.fungal_damaged_grains, commod_grad_criteria.germinated_grains, commod_grad_criteria.pass_thru_6_35mm_sieve, commod_grad_criteria.diplodia, commod_grad_criteria.fusarium, commod_grad_criteria.discoloured_grains, commod_grad_criteria.total_other_defective_grains];

            // const average = Math.round(sum / 18);
            if (gradingArray?.includes(0) || gradingArray?.includes(""))
            {
                setCommodityGrade("---");
            }
            else if (gradingArray?.includes("C"))
            {
                setCommodityGrade("C");
            }
            else if (gradingArray?.includes("B"))
            {
                setCommodityGrade("B");
            }
            else
            {
                setCommodityGrade("A");
            }
        }

    }, [commod_grad_criteria]);


    const handleOnblur = (name, value) => {
        if (value)
        {
            formik.setFieldValue(name, parseFloat(value).toFixed(2));
        }
    };

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className='flex flex-row w-full'>
                    <div className="grid gap-6 mb-8 lg:grid-cols-3 p-6 sm:p-16 lg:p-8 lg:w-9/12 m-auto">
                        <div>
                            <label htmlFor="test_density" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                Test Density
                            </label>
                            <input
                                step="0.01"
                                type="number"
                                min="0.00"
                                default="0.00"
                                name="test_density"
                                value={formik.values.test_density}
                                onChange={handleMeasurementChange}
                                onBlur={() => handleOnblur("test_density", formik.values.test_density)}
                                // required
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                            />
                            {formik.touched.test_density && formik.errors.test_density && (
                                <p className="text-red-500 text-sm mt-1">{formik.errors.test_density}</p>
                            )}
                        </div>
                        <div>
                            <label htmlFor="moisture_content" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                Mositure Content
                            </label>
                            <input
                                step="0.01"
                                type="number"
                                min="0.00"
                                default="0.00"
                                name="moisture_content"
                                value={formik.values.moisture_content}
                                onChange={handleMeasurementChange}
                                onBlur={() => handleOnblur("moisture_content", formik.values.moisture_content)}
                                // required
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                            />
                            {formik.touched.moisture_content && formik.errors.moisture_content && (
                                <p className="text-red-500 text-sm mt-1">{formik.errors.moisture_content}</p>
                            )}
                        </div>
                        <div>
                            <label htmlFor="broken_grains" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                Broken Grains
                            </label>
                            <input
                                step="0.01"
                                type="number"
                                min="0.00"
                                default="0.00"
                                name="broken_grains"
                                value={formik.values.broken_grains}
                                onChange={handleMeasurementChange}
                                onBlur={() => handleOnblur("broken_grains", formik.values.broken_grains)}
                                // required
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                            />
                            {formik.touched.broken_grains && formik.errors.broken_grains && (
                                <p className="text-red-500 text-sm mt-1">{formik.errors.broken_grains}</p>
                            )}
                        </div>
                        <div>
                            <label htmlFor="extraneous_matter" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                Extraneous Matter
                            </label>
                            <input
                                step="0.01"
                                type="number"
                                min="0.00"
                                default="0.00"
                                name="extraneous_matter"
                                value={formik.values.extraneous_matter}
                                onChange={handleMeasurementChange}
                                onBlur={() => handleOnblur("extraneous_matter", formik.values.extraneous_matter)}
                                // required
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                            />
                            {formik.touched.extraneous_matter && formik.errors.extraneous_matter && (
                                <p className="text-red-500 text-sm mt-1">{formik.errors.extraneous_matter}</p>
                            )}
                        </div>
                        <div>
                            <label htmlFor="other_coloured_grains" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                Other Coloured Grains
                            </label>
                            <input
                                step="0.01"
                                type="number"
                                min="0.00"
                                default="0.00"
                                name="other_coloured_grains"
                                value={formik.values.other_coloured_grains}
                                onChange={handleMeasurementChange}
                                onBlur={() => handleOnblur("other_coloured_grains", formik.values.other_coloured_grains)}
                                // required
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                            />
                            {formik.touched.other_coloured_grains && formik.errors.other_coloured_grains && (
                                <p className="text-red-500 text-sm mt-1">{formik.errors.other_coloured_grains}</p>
                            )}
                        </div>
                        <div>
                            <label htmlFor="total_other_defective_grains" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                Total Other Defective Grains
                            </label>
                            <input
                                step="0.01"
                                type="number"
                                min="0.00"
                                default="0.00"
                                name="total_other_defective_grains"
                                value={formik.values.total_other_defective_grains}
                                onChange={handleMeasurementChange}
                                onBlur={() => handleOnblur("total_other_defective_grains", formik.values.total_other_defective_grains)}
                                // required
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                            />
                            {formik.touched.total_other_defective_grains && formik.errors.total_other_defective_grains && (
                                <p className="text-red-500 text-sm mt-1">{formik.errors.total_other_defective_grains}</p>
                            )}
                        </div>
                        <div>
                            <label htmlFor="discoloured_grains" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                Discolured Grains
                            </label>
                            <input
                                step="0.01"
                                type="number"
                                min="0.00"
                                default="0.00"
                                name="discoloured_grains"
                                value={formik.values.discoloured_grains}
                                onChange={handleMeasurementChange}
                                onBlur={() => handleOnblur("discoloured_grains", formik.values.discoloured_grains)}
                                // required
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                            />
                            {formik.touched.discoloured_grains && formik.errors.discoloured_grains && (
                                <p className="text-red-500 text-sm mt-1">{formik.errors.discoloured_grains}</p>
                            )}
                        </div>
                        <div>
                            <label htmlFor="insect_pest_damaged_grains" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                Insect Pest Damaged Grains
                            </label>
                            <input
                                step="0.01"
                                type="number"
                                min="0.00"
                                default="0.00"
                                name="insect_pest_damaged_grains"
                                value={formik.values.insect_pest_damaged_grains}
                                onChange={handleMeasurementChange}
                                onBlur={() => handleOnblur("insect_pest_damaged_grains", formik.values.insect_pest_damaged_grains)}
                                // required
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                            />
                            {formik.touched.insect_pest_damaged_grains && formik.errors.insect_pest_damaged_grains && (
                                <p className="text-red-500 text-sm mt-1">{formik.errors.insect_pest_damaged_grains}</p>
                            )}
                        </div>
                        <div>
                            <label htmlFor="diseased_grain" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                Diseased Grains
                            </label>
                            <input
                                step="0.01"
                                type="number"
                                min="0.00"
                                default="0.00"
                                name="diseased_grain"
                                value={formik.values.diseased_grain}
                                onChange={handleMeasurementChange}
                                onBlur={() => handleOnblur("diseased_grain", formik.values.diseased_grain)}
                                // required
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                            />
                            {formik.touched.diseased_grain && formik.errors.diseased_grain && (
                                <p className="text-red-500 text-sm mt-1">{formik.errors.diseased_grain}</p>
                            )}
                        </div>
                        <div>
                            <label htmlFor="immature_shrivelled_grain" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                Immature Shrivelled Grain
                            </label>
                            <input
                                step="0.01"
                                type="number"
                                min="0.00"
                                default="0.00"
                                name="immature_shrivelled_grain"
                                value={formik.values.immature_shrivelled_grain}
                                onChange={handleMeasurementChange}
                                onBlur={() => handleOnblur("immature_shrivelled_grain", formik.values.immature_shrivelled_grain)}
                                // required
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                            />
                            {formik.touched.immature_shrivelled_grain && formik.errors.immature_shrivelled_grain && (
                                <p className="text-red-500 text-sm mt-1">{formik.errors.immature_shrivelled_grain}</p>
                            )}
                        </div>
                        <div>
                            <label htmlFor="fungal_damaged_grains" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                Fungal Damaged Grains
                            </label>
                            <input
                                step="0.01"
                                type="number"
                                min="0.00"
                                default="0.00"
                                name="fungal_damaged_grains"
                                value={formik.values.fungal_damaged_grains}
                                onChange={handleMeasurementChange}
                                onBlur={() => handleOnblur("fungal_damaged_grains", formik.values.fungal_damaged_grains)}
                                // required
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                            />
                            {formik.touched.fungal_damaged_grains && formik.errors.fungal_damaged_grains && (
                                <p className="text-red-500 text-sm mt-1">{formik.errors.fungal_damaged_grains}</p>
                            )}
                        </div>
                        <div>
                            <label htmlFor="germinated_grains" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                Germinated Grains
                            </label>
                            <input
                                step="0.01"
                                type="number"
                                min="0.00"
                                default="0.00"
                                name="germinated_grains"
                                value={formik.values.germinated_grains}
                                onChange={handleMeasurementChange}
                                onBlur={() => handleOnblur("germinated_grains", formik.values.germinated_grains)}
                                // required
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                            />
                            {formik.touched.germinated_grains && formik.errors.germinated_grains && (
                                <p className="text-red-500 text-sm mt-1">{formik.errors.germinated_grains}</p>
                            )}
                        </div>
                        <div>
                            <label htmlFor="pass_thru_6_35mm_sieve" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                Pass Trhough 6-35mm Sieve
                            </label>
                            <input
                                step="0.01"
                                type="number"
                                min="0.00"
                                default="0.00"
                                name="pass_thru_6_35mm_sieve"
                                value={formik.values.pass_thru_6_35mm_sieve}
                                onChange={handleMeasurementChange}
                                onBlur={() => handleOnblur("pass_thru_6_35mm_sieve", formik.values.pass_thru_6_35mm_sieve)}
                                // required
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                            />
                            {formik.touched.pass_thru_6_35mm_sieve && formik.errors.pass_thru_6_35mm_sieve && (
                                <p className="text-red-500 text-sm mt-1">{formik.errors.pass_thru_6_35mm_sieve}</p>
                            )}
                        </div>
                        <div>
                            <label htmlFor="diplodia" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                Diplodia
                            </label>
                            <input
                                step="0.01"
                                type="number"
                                name="diplodia"
                                value={formik.values.diplodia}
                                onChange={handleMeasurementChange}
                                onBlur={() => handleOnblur("diplodia", formik.values.diplodia)}
                                // required
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                            />
                            {formik.touched.diplodia && formik.errors.diplodia && (
                                <p className="text-red-500 text-sm mt-1">{formik.errors.diplodia}</p>
                            )}
                        </div>
                        <div>
                            <label htmlFor="fusarium" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                Fusarium
                            </label>
                            <input
                                step="0.01"
                                type="number"
                                name="fusarium"
                                value={formik.values.fusarium}
                                onChange={handleMeasurementChange}
                                onBlur={() => handleOnblur("fusarium", formik.values.fusarium)}
                                // required
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                            />
                            {formik.touched.fusarium && formik.errors.fusarium && (
                                <p className="text-red-500 text-sm mt-1">{formik.errors.fusarium}</p>
                            )}
                        </div>
                    </div>
                    <div className='w-3/12 flex flex-column justify-center items-center h-auto bg-gray-50 p-2'>
                        <div>
                            <p className='text-center text-3xl mb-2 font-medium text-gray-700'>Commodity Grade</p>
                            <p className='text-5xl text-center font-bold my-6'>{commodity_grade}</p>
                        </div>
                    </div>
                </div>
                {error && error.length > 0 && (
                    <div className="my-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 px-6 py-4 sm:px-16 lg:px-8" role="alert">
                        <span className="font-medium">Error: </span>
                        {error}
                    </div>
                )}
                <div className='mb-4 px-6 flex flex-row justify-end items-center'>
                    {/* <button
                    onClick={prevStep}
                    type="submit"
                    className="text-white bg-gray-500 hover:bg-gray-600 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-gray-500 dark:hover:bg-gray-600 dark:focus:ring-gray-700"
                >
                    Previous Step: Truck Information
                </button> */}
                    <button
                        // disabled={!(formData.values.commmodity_id)}
                        // onClick={nextStep}
                        type="submit"
                        className={`text-white bg-green-600 cursor-pointer hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800`}
                    >
                        Next Step: Storage Information
                    </button>
                </div>
            </form>
        </>
    );
};

export default MaizeGradingStep;
