import { combineReducers } from 'redux';
import { ActionTypes } from './actions';
import * as app_consts from './constants';

const initialAuthState = {
  loading: false,
  error: null,
  token: null,
  isAuthenticated: false,
};

const initialVerificationState = {
  loading: false,
  error: null,
  message: null,
};

const initialProfileState = {
  loading: false,
  error: null,
  profile: null,
};

const initialEntityListState = {
  loading: false,
  error: null,
  data: [],
  total: 0,
};

const initialDirectorListState = {
  data: [],
  loading: false,
  error: null,
};

const initialBankState = {
  loading: false,
  error: null,
  bankAccount: null,
  data: [],
  total: 0,
  status: app_consts.IDLE,
};

const initialRegisterNewMemberState = {
  loading: false,
  error: null,
  newMemberRegistration: null,
};

const submitRegistrationState = {
  loading: false,
  error: null,
  submitRegistration: null,
};

const initialEntityState = {
  loading: false,
  error: null,
  entity: null,
  data: [],
  total: 0,
  status: app_consts.IDLE,
};

const initialEntityProfilesListState = {
  data: [],
  loading: false,
  error: null,
};

const initialCheckRegistrationState = {
  isLoading: false,
  message: '',
  status: null,
  error: null,
};

const initialAddWarehouseState = {
  isLoading: false,
  message: '',
  status: app_consts.IDLE,
  error: null,
};

const initialWarehouseListState = {
  isLoading: false,
  message: '',
  status: app_consts.IDLE,
  error: null,
};

const initialAddEntityProfileState = {
  isLoading: false,
  message: '',
  status: null,
  error: null,
};

// Specific Warehouse State
const initialSpecificWarehouseState = {
  isLoading: false,
  message: '',
  error: null,
  status: app_consts.IDLE,
};

// Send Reset Password Otp
const initialsendResetPasswordOtpState = {
  isLoading: false,
  message: '',
  status: null,
  error: null,
};

// Verify Reset Password Otp
const initialverifyResetPasswordOtpState = {
  isLoading: false,
  message: '',
  status: null,
  error: null,
};

//  Reset Password
const initialResetPasswordState = {
  isLoading: false,
  message: '',
  status: null,
  error: null,
};

//  Reset Password
const initialCreateEntityExistingAccountState = {
  isLoading: false,
  message: '',
  status: null,
  error: null,
};

const initialProducerListState = {
  loading: false,
  error: null,
  data: [],
  total: 0,
  status: app_consts.IDLE,
};

const initialCooperativeMemberListState = {
  loading: false,
  error: null,
  data: [],
  total: 0,
};

const initialOfferListState = {
  loading: false,
  error: null,
  data: [],
  total: 0,
};

const initialOfferListStatusFilteredState = {
  loading: false,
  error: null,
  data: [],
  total: 0,
  status: app_consts.IDLE
};

const initialOfferDetailsState = {
  loading: false,
  error: null,
  data: null,
};

const initialProducerState = {
  loading: false,
  error: null,
  entity: null,
};

//Activate Membership
const initialactivateMembershipType = {
  isLoading: false,
  error: null,
  data: [],
  total: 0,
  status: app_consts.IDLE,
};

const initialBrokerOfferListState = {
  loading: false,
  error: null,
  data: [],
  total: 0,
};

const initialBrokerBidListByBrokerIdState = {
  loading: false,
  error: null,
  data: [],
  total: 0,
};

const initialBrokerBidListByCommodityOrderIdState = {
  loading: false,
  error: null,
  data: [],
  total: 0,
};

const initialReceiptNumberListState = {
  loading: false,
  error: null,
  data: [],
  total: 0,
};

const initialBrokerOfferState = {
  loading: false,
  error: null,
  entity: null,
};

//  Retrieve Specific Entity List
const initialgetSPecificEntityProfileListState = {
  isLoading: false,
  message: '',
  status: null,
  error: null,
};

//  Create Delivery Note
const initialCreateDeliveryNoteListState = {
  isLoading: false,
  message: '',
  status: null,
  error: null,
};

//  Create Commodity Grading
const initialCreateCommodityGradingState = {
  isLoading: false,
  message: '',
  status: null,
  error: null,
};

//  Create Storage Inventory
const initialCreateStorageInventoryState = {
  isLoading: false,
  message: '',
  status: null,
  error: null,
};

//  Create Storage Inventory
const initialUpdateWarehouseReceiptSettlementState = {
  isLoading: false,
  message: '',
  status: null,
  error: null,
};

//  Generate Warehouse Receipt
const initialGenerateWarehouseReceiptState = {
  isLoading: false,
  message: '',
  status: null,
  error: null,
};

//  Retrieve Entity Warehouse Recipt List
const initialRetrieveEntityWarehouseReceiptListState = {
  isLoading: false,
  message: '',
  error: null,
  status: app_consts.IDLE,
};

//  Retrieve All Producers List
const initialRetrieveAllProducersListState = {
  isLoading: false,
  message: '',
  status: null,
  error: null,
};

//  Create Pending Entity
const initialCreatePendingEntityState = {
  isLoading: false,
  message: '',
  status: null,
  error: null,
};

//  Retrieve Pending Entity List
const initialRetrievePendingEntityListState = {
  isLoading: false,
  message: '',
  status: null,
  error: null,
};

//  Retrieve Inbound Shipments List
const initialRetrieveInboundShipmentListState = {
  isLoading: false,
  message: '',
  status: null,
  error: null,
};

//  Retrieve Specific Inbound Shipment
const initialRetrieveSpecificInboundShipmentState = {
  isLoading: false,
  message: '',
  status: null,
  error: null,
};

//  Retrieve Warehuose Storage List
const initialRetrieveWarehouseStoragelistState = {
  isLoading: false,
  message: '',
  status: null,
  error: null,
};

//  Retrieve Specific Warehouse Receipt
const initialRetrieveSpecificWarehouseReceiptState = {
  isLoading: false,
  message: '',
  status: null,
  error: null,
};

//  Continue Processing Warehouse Receipt
const initialContinueProcessingWarehouseReceiptState = {
  isLoading: false,
  message: '',
  status: null,
  error: null,
};

//  Update Warehouse Status
const initialUpdateWarehouseState = {
  isLoading: false,
  message: '',
  status: null,
  error: null,
};

//  Update Broker Trading Authority
const initialUpdateBrokerTradingAuthorityState = {
  isLoading: false,
  message: '',
  error: null,
  data: [],
  total: 0,
  status: app_consts.IDLE,
};

// Get Broker list
const initialBrokerListState = {
  loading: false,
  error: null,
  data: [],
  total: 0,
};

// Get producers and warehouse receipts
const initialProducersAndWarehouseReceiptsState = {
  loading: false,
  error: null,
  data: [],
  total: 0,
};

// Retrieve Issued Warehouse Receipts List
const initialRetrieveIssuedWarehouseReceiptsListState = {
  loading: false,
  error: null,
  data: [],
  total: 0,
};

// Amend Warehouse Receipt State
const initialAmendWarehouseReceiptState = {
  loading: false,
  error: null,
  data: [],
  total: 0,
};

// Get User Account List
const initialUserAccountListState = {
  isLoading: false,
  error: null,
  data: [],
  total: 0,
  status: app_consts.IDLE,
};

// Get User Account Details
const initialUserAccountDetailsState = {
  isLoading: false,
  error: null,
  data: [],
  total: 0,
  status: app_consts.IDLE,
};

// Retrieve Specific Broker Offer State
const initialRetrieveSpecificBrokerOfferState = {
  loading: false,
  error: null,
  data: [],
  total: 0,
};

// Accept Bid On Commodity Order State
const initialAccpetBidOnCommodityOrderState = {
  loading: false,
  error: null,
  data: [],
  total: 0,
  status: app_consts.IDLE,
};

// Retrieve Trade Offers Detail State
const initialRetrieveTradeOfferDetailsState = {
  isLoading: false,
  error: null,
  data: [],
  total: 0,
  status: app_consts.IDLE,
};

// Retrieve Broker bids List by Broker ID State
const initialRetrieveBrokerBidsByBrokerIdState = {
  isLoading: false,
  error: null,
  data: [],
  total: 0,
  status: app_consts.IDLE,
};

// Retrieve Specific Broker Bid Details State
const initialRetrieveSpecificBrokerBidDetailsState = {
  isLoading: false,
  error: null,
  data: [],
  total: 0,
  status: app_consts.IDLE,
};

// Upload Trade Proof of Payment State
const initialUploadTradeProofOfPaymentState = {
  isLoading: false,
  error: null,
  data: [],
  total: 0,
  status: app_consts.IDLE,
};

// Retrieve Broker Producer State
const initialRetrieveBrokerProducerState = {
  isLoading: false,
  error: null,
  data: [],
  total: 0,
  status: app_consts.IDLE,
};

// Approve Trade Payment State
const initialApproveTradePaymentState = {
  isLoading: false,
  error: null,
  data: [],
  total: 0,
  status: app_consts.IDLE,
};

// Upload Signed Trade Contract State
const initialUploadSignedTradeContractState = {
  isLoading: false,
  error: null,
  data: [],
  total: 0,
  status: app_consts.IDLE,
};

// Confirm Trade By Contract Number State
const initialConfirmTradeByContractNumberState = {
  isLoading: false,
  error: null,
  data: [],
  total: 0,
  status: app_consts.IDLE,
};

// Retrieve Trade Fulfillment List State
const initialRetrieveTradeFulfillmentListState = {
  isLoading: false,
  error: null,
  data: [],
  total: 0,
  status: app_consts.IDLE,
};

// Retrieve Specific Trade Fulfillment State
const initialRetrieveSpecificTradeFulfillmentState = {
  isLoading: false,
  error: null,
  data: [],
  total: 0,
  status: app_consts.IDLE,
};

// Retrieve Issued Warehouse Receipts List
const initialRetrieveProvisionReceiptsListState = {
  loading: false,
  error: null,
  data: [],
  total: 0,
};

// Upload Warehouse Inspection Certificate State
const initialUploadWarehouseInspectionCertificateState = {
  loading: false,
  error: null,
  data: [],
  total: 0,
  status: app_consts.IDLE,
};

// Retrieve Specific Warehouse Storage Types and Capacities Via Delivery ID
const initialRetrieveSpecificWarehouseStorageTypesAndCapacitiesViaDeliveryIdState = {
  loading: false,
  error: null,
  data: [],
  total: 0,
  status: app_consts.IDLE,
};

// Retrieve Super Bids and Offers
const initialRetrieveValidSuperAndSubOffersBidsState = {
  loading: false,
  error: null,
  data: [],
  total: 0,
  status: app_consts.IDLE,
};


// Retrieve Broker bids List by Broker ID State
const initialRetrieveBrokerSuperBidsByBrokerIdState = {
  isLoading: false,
  error: null,
  data: [],
  total: 0,
  status: app_consts.IDLE,
};

// List All Producers With Entities State
const initialListAllProducerWithEntitiesState = {
  isLoading: false,
  error: null,
  data: [],
  total: 0,
  status: app_consts.IDLE,
};

// Retrieve Entity Profile Infor State
const initialRetrieveEntityProfileInfoState = {
  isLoading: false,
  error: null,
  data: [],
  total: 0,
  status: app_consts.IDLE,
};

// Retrieve Entity User Profile State
const initialRetrieveEntityUserProfilestate = {
  isLoading: false,
  error: null,
  data: [],
  total: 0,
  status: app_consts.IDLE,
};

// Amend Profile State
const initialAmendProfileRolestate = {
  isLoading: false,
  error: null,
  data: [],
  total: 0,
  status: app_consts.IDLE,
};

// Retrieve Specific Warehouse Storage Type State
const initialRetrieveSpecificWarehouseStorageTypestate = {
  isLoading: false,
  error: null,
  data: [],
  total: 0,
  status: app_consts.IDLE,
};

// Process Bulk Delivery State 
const initialProcessBulkDeliverystate = {
  isLoading: false,
  error: null,
  data: [],
  total: 0,
  status: app_consts.IDLE,
};

// Toggle View On Website State 
const initialToggleViewWarehouseOnWebsitestate = {
  isLoading: false,
  error: null,
  data: [],
  total: 0,
  status: app_consts.IDLE,
};

// Update Bank Account Document State 
const initialUpdateBankAccountDocumentsReducerstate = {
  isLoading: false,
  error: null,
  data: [],
  total: 0,
  status: app_consts.IDLE,
};

// Retrieve Specific Entity Profile Reports  State 
const initialretrieveSpecificEntityProfileReportsReducerstate = {
  isLoading: false,
  error: null,
  data: [],
  total: 0,
  status: app_consts.IDLE,
};

// Upload Warehouse Inspection Image
const initialUploadWarehouseInspectionImageReducerstate = {
  isLoading: false,
  error: null,
  data: [],
  total: 0,
  status: app_consts.IDLE,
};

// Upload Warehouse Inspection Image
const initialProcessGoodsReceivedNoteReducerstate = {
  isLoading: false,
  error: null,
  data: [],
  total: 0,
  status: app_consts.IDLE,
};

// Add GRN to Delivery State
const initialAddGrnToDeliveryReducerstate = {
  isLoading: false,
  error: null,
  data: [],
  total: 0,
  status: app_consts.IDLE,
};

// Confirm Goods Received Delivery State
const initialConfirmGoodsReceivedDeliveryReducerstate = {
  isLoading: false,
  error: null,
  data: [],
  total: 0,
  status: app_consts.IDLE,
};
// Book Goods Receieved Delivery Into Storage  State
const initialBookGoodsRecievedIntoStorageReducerstate = {
  isLoading: false,
  error: null,
  data: [],
  total: 0,
  status: app_consts.IDLE,
};

// Retrieve Warehouse Receipt Depositors Reducer
const initialRetrieveWarehouseReceiptDepositorsReducerstate = {
  isLoading: false,
  error: null,
  data: [],
  total: 0,
  status: app_consts.IDLE,
};

// Retrieve Specific Warehouse Receipt Depositor Reducer
const initialRetrieveSpecificWarehouseReceiptDepositorReducerstate = {
  isLoading: false,
  error: null,
  data: [],
  total: 0,
  status: app_consts.IDLE,
};

// Retrieve Entity Profile Audit Trail Reducer State
const initialRetrieveEntityProfileAuditTrailsReducerstate = {
  isLoading: false,
  error: null,
  data: [],
  total: 0,
  status: app_consts.IDLE,
};

// Retrieve Specific Producer Reducer
const initialRetrieveSpecificProducerState = {
  isLoading: false,
  error: null,
  data: [],
  total: 0,
  status: app_consts.IDLE,
};

// Retrieve Specific Producer Reducer
const initialAssignBrokerToProducerState = {
  isLoading: false,
  error: null,
  data: [],
  total: 0,
  status: app_consts.IDLE,
};

// Retrieve Entity List Data Reducer
const initialRetrieveEntityListDataState = {
  isLoading: false,
  error: null,
  data: [],
  total: 0,
  status: app_consts.IDLE,
};

// Retrieve Entity Details Reducer
const initialRetrieveEntityDetailsState = {
  isLoading: false,
  error: null,
  data: [],
  total: 0,
  status: app_consts.IDLE,
};

// Verify Membership Reducer
const initialVerifyMembershipState = {
  isLoading: false,
  error: null,
  data: [],
  total: 0,
  status: app_consts.IDLE,
};

// Update Membership Reducer
const initialUpdateMembershipState = {
  isLoading: false,
  error: null,
  data: [],
  total: 0,
  status: app_consts.IDLE,
};

// Update Document Reducer
const initialUpdateDocumentState = {
  isLoading: false,
  error: null,
  data: [],
  total: 0,
  status: app_consts.IDLE,
};


// Retrieve Data Analytics Reducer
const initialRetriveDataAnalyticsState = {
  isLoading: false,
  error: null,
  data: [],
  total: 0,
  status: app_consts.IDLE,
};


// Retrieve Ats Data Reducer
const initialRetriveAtsListState = {
  isLoading: false,
  error: null,
  data: [],
  total: 0,
  status: app_consts.IDLE,
};

// Retrieve Ats Analytics Reducer
const initialRetriveAtsAnalyticsState = {
  isLoading: false,
  error: null,
  data: [],
  total: 0,
  status: app_consts.IDLE,
};


// Retrieve Producer Analytics Reducer
const retrieveProducerAnalyticsState = {
  isLoading: false,
  error: null,
  data: [],
  total: 0,
  status: app_consts.IDLE,
};

// Retrieve Commodity Order Details Reducer
const bulkEntityCreationState = {
  isLoading: false,
  error: null,
  data: [],
  total: 0,
  status: app_consts.IDLE,
};

const authReducer = (state = initialAuthState, action) => {
  switch (action.type)
  {
    case ActionTypes.LOGIN_REQUEST:
    case ActionTypes.GET_CURRENT_ACCOUNT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        token: action.payload.token,
      };

    case ActionTypes.REGISTER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ActionTypes.REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        token: action.payload.token,
        isAuthenticated: true,
      };
    case ActionTypes.LOGIN_FAILURE:
    case ActionTypes.REGISTER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case ActionTypes.GET_CURRENT_ACCOUNT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isAuthenticated: false,
      };
    case ActionTypes.GET_CURRENT_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        token: action.payload.token,
        account: action.payload,
      };
    case ActionTypes.LOGIN_RESET:
      return {
        ...state,
        loading: false,
        error: null,
        token: null,
        isAuthenticated: false,
      };
    default:
      return state;
  }
};

const verificationReducer = (state = initialVerificationState, action) => {
  switch (action.type)
  {
    case ActionTypes.VERIFY_REGISTRATION_REQUEST:
    case ActionTypes.RESEND_OTP_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ActionTypes.VERIFY_REGISTRATION_SUCCESS:
    case ActionTypes.RESEND_OTP_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload,
      };
    case ActionTypes.VERIFY_REGISTRATION_FAILURE:
    case ActionTypes.RESEND_OTP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

const profileReducer = (state = initialProfileState, action) => {
  switch (action.type)
  {
    case ActionTypes.CREATE_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ActionTypes.CREATE_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        profile: action.payload,
      };
    case ActionTypes.CREATE_PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

const entityReducer = (state = initialEntityState, action) => {
  switch (action.type)
  {
    case ActionTypes.UPDATE_ENTITY_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ActionTypes.UPDATE_ENTITY_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case ActionTypes.UPDATE_ENTITY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ActionTypes.UPLOAD_REGISTRATION_DOCUMENT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ActionTypes.UPLOAD_REGISTRATION_DOCUMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case ActionTypes.UPLOAD_REGISTRATION_DOCUMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ActionTypes.UPLOAD_DIRECTOR_IMAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        status: 200,
        error: null,
      };
    case ActionTypes.UPLOAD_DIRECTOR_IMAGES_FAILURE:
      return {
        ...state,
        loading: false,
        status: 400,
        error: action.payload,
      };
    case ActionTypes.UPLOAD_DIRECTOR_IMAGES_RESET:
      return {
        ...state,
        isLoading: false,
        data: '',
        status: app_consts.IDLE,
        error: null,
      };
    default:
      return state;
  }
};

const directorListReducer = (state = initialDirectorListState, action) => {
  switch (action.type)
  {
    case ActionTypes.GET_DIRECTORS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ActionTypes.GET_DIRECTORS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case ActionTypes.GET_DIRECTORS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

const bankAccountReducer = (state = initialBankState, action) => {
  switch (action.type)
  {
    case ActionTypes.ADD_BANK_ACCOUNT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        message: '',
        status: null,
        error: null,
      };
    case ActionTypes.ADD_BANK_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        profile: action.payload,
        status: 200,
        error: null,
        data: action.payload,
      };
    case ActionTypes.ADD_BANK_ACCOUNT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        status: 400,
      };
    case ActionTypes.ADD_BANK_ACCOUNT_RESET:
      return {
        ...state,
        isLoading: false,
        data: '',
        status: app_consts.IDLE,
        error: null,
      };
    default:
      return state;
  }
};

const submitRegistrationReducer = (state = submitRegistrationState, action) => {
  switch (action.type)
  {
    case ActionTypes.REGISTRATION_COMPLETE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ActionTypes.REGISTRATION_COMPLETE_SUCCESS:
      return {
        ...state,
        loading: false,
        profile: action.payload,
      };
    case ActionTypes.REGISTRATION_COMPLETE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

const entityListReducer = (state = initialEntityListState, action) => {
  switch (action.type)
  {
    case ActionTypes.GET_ENTITIES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ActionTypes.GET_ENTITIES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        total: action.payload.data, // Update to access total property
      };
    case ActionTypes.GET_ENTITIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

const getEntityReducer = (state = initialEntityState, action) => {
  switch (action.type)
  {
    case ActionTypes.GET_ENTITY_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ActionTypes.GET_ENTITY_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case ActionTypes.GET_ENTITY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

const getEntityProfileReducer = (state = initialEntityState, action) => {
  switch (action.type)
  {
    case ActionTypes.GET_ENTITY_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ActionTypes.GET_ENTITY_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case ActionTypes.GET_ENTITY_PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

const entityProfilesListReducer = (
  state = initialEntityProfilesListState,
  action
) => {
  switch (action.type)
  {
    case ActionTypes.GET_ENTITIES_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ActionTypes.GET_ENTITIES_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case ActionTypes.GET_ENTITIES_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

const registerNewMemberReducer = (
  state = initialRegisterNewMemberState,
  action
) => {
  switch (action.type)
  {
    case ActionTypes.ADD_NEW_MEMBER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ActionTypes.ADD_NEW_MEMBER_SUCCESS:
      return {
        ...state,
        loading: false,
        newMemberRegistration: action.payload,
      };
    case ActionTypes.ADD_NEW_MEMBER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

const checkRegistrationReducer = (
  state = initialCheckRegistrationState,
  action
) => {
  switch (action.type)
  {
    case ActionTypes.CHECK_REGISTRATION_REQUEST:
      return {
        ...state,
        isLoading: true,
        message: '',
        status: null,
        error: null,
      };
    case ActionTypes.CHECK_REGISTRATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
        status: 200,
        error: null,
      };
    case ActionTypes.CHECK_REGISTRATION_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
        status: 404,
        error: action.payload,
      };
    default:
      return state;
  }
};

// Add Warehouse
const addWarehouseReducer = (state = initialAddWarehouseState, action) => {
  switch (action.type)
  {
    case ActionTypes.ADD_WAREHOUSE_REQUEST:
      return {
        ...state,
        isLoading: true,
        message: '',
        status: null,
        error: null,
      };
    case ActionTypes.ADD_WAREHOUSE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
        status: 200,
        error: null,
      };
    case ActionTypes.ADD_WAREHOUSE_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
        status: 400,
        error: action.payload,
      };
    case ActionTypes.ADD_WAREHOUSE_RESET:
      return {
        ...state,
        isLoading: false,
        data: '',
        status: app_consts.IDLE,
        error: null,
      };
    default:
      return state;
  }
};

// Create Entity Profile
const createEntityProfileReducer = (
  state = initialAddEntityProfileState,
  action
) => {
  switch (action.type)
  {
    case ActionTypes.CREATE_ENTITY_PROFILE_REQUEST:
      return {
        ...state,
        isLoading: true,
        message: '',
        status: null,
        error: null,
      };
    case ActionTypes.CREATE_ENTITY_PROFILE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
        status: 200,
        error: null,
      };
    case ActionTypes.CREATE_ENTITY_PROFILE_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
        status: 404,
        error: action.payload,
      };
    default:
      return state;
  }
};

// Get Warehouse List
const warehouseListReducer = (state = initialWarehouseListState, action) => {
  switch (action.type)
  {
    case ActionTypes.GET_WAREHOUSE_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
        message: '',
        status: null,
        error: null,
      };
    case ActionTypes.GET_WAREHOUSE_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: 200,
        error: null,
      };
    case ActionTypes.GET_WAREHOUSE_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
        status: 404,
        error: action.payload,
      };
    case ActionTypes.GET_WAREHOUSE_LIST_RESET:
      return {
        ...state,
        isLoading: false,
        data: '',
        status: app_consts.IDLE,
        error: null,
      };
    default:
      return state;
  }
};

// Specific Warehouse
const specificWarehouseReducer = (
  state = initialSpecificWarehouseState,
  action
) => {
  switch (action.type)
  {
    case ActionTypes.GET_SPECIFIC_WAREHOUSE_REQUEST:
      return {
        ...state,
        isLoading: true,
        message: '',
        status: null,
        error: null,
      };
    case ActionTypes.GET_SPECIFIC_WAREHOUSE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: 200,
        error: null,
      };
    case ActionTypes.GET_SPECIFIC_WAREHOUSE_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
        status: 400,
        error: action.payload,
      };
    case ActionTypes.GET_SPECIFIC_WAREHOUSE_RESET:
      return {
        ...state,
        isLoading: false,
        data: '',
        status: app_consts.IDLE,
        error: null,
      };
    default:
      return state;
  }
};

// Send Reset Password Reducer
const sendResetPasswordOtpReducer = (
  state = initialsendResetPasswordOtpState,
  action
) => {
  switch (action.type)
  {
    case ActionTypes.SEND_RESET_PASSWORD_OTP_REQUEST:
      return {
        ...state,
        isLoading: true,
        message: '',
        status: null,
        error: null,
      };
    case ActionTypes.SEND_RESET_PASSWORD_OTP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: 200,
        error: null,
      };
    case ActionTypes.SEND_RESET_PASSWORD_OTP_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
        status: 400,
        error: action.payload,
      };
    case ActionTypes.SEND_RESET_PASSWORD_OTP_RESET:
      return {
        ...state,
        isLoading: false,
        data: '',
        status: app_consts.IDLE,
        error: null,
      };
    default:
      return state;
  }
};

// Verify Reset Password Reducer
const verifyResetPasswordOtpReducer = (
  state = initialverifyResetPasswordOtpState,
  action
) => {
  switch (action.type)
  {
    case ActionTypes.VERIFY_RESET_PASSWORD_OTP_REQUEST:
      return {
        ...state,
        isLoading: true,
        message: '',
        status: null,
        error: null,
      };
    case ActionTypes.VERIFY_RESET_PASSWORD_OTP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: 200,
        error: null,
      };
    case ActionTypes.VERIFY_RESET_PASSWORD_OTP_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
        status: 400,
        error: action.payload,
      };
    case ActionTypes.VERIFY_RESET_PASSWORD_OTP_RESET:
      return {
        ...state,
        isLoading: false,
        data: '',
        status: app_consts.IDLE,
        error: null,
      };
    default:
      return state;
  }
};

// Verify Reset Password Reducer
const resetPasswordReducer = (state = initialResetPasswordState, action) => {
  switch (action.type)
  {
    case ActionTypes.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        isLoading: true,
        message: '',
        status: null,
        error: null,
      };
    case ActionTypes.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: 200,
        error: null,
      };
    case ActionTypes.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
        status: 400,
        error: action.payload,
      };
    case ActionTypes.RESET_PASSWORD_RESET:
      return {
        ...state,
        isLoading: false,
        data: '',
        status: app_consts.IDLE,
        error: null,
      };
    default:
      return state;
  }
};

// Verify Reset Password Reducer
const createEntityExistingUserReducer = (
  state = initialCreateEntityExistingAccountState,
  action
) => {
  switch (action.type)
  {
    case ActionTypes.CREATE_ENTITY_EXISTING_ACCOUNT_REQUEST:
      return {
        ...state,
        isLoading: true,
        message: '',
        status: null,
        error: null,
      };
    case ActionTypes.CREATE_ENTITY_EXISTING_ACCOUNT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: 200,
        error: null,
      };
    case ActionTypes.CREATE_ENTITY_EXISTING_ACCOUNT_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
        status: 400,
        error: action.payload,
      };
    case ActionTypes.CREATE_ENTITY_EXISTING_ACCOUNT_RESET:
      return {
        ...state,
        isLoading: false,
        data: '',
        status: app_consts.IDLE,
        error: null,
      };
    default:
      return state;
  }
};

const cooperativeMemberListReducer = (
  state = initialCooperativeMemberListState,
  action
) => {
  switch (action.type)
  {
    case ActionTypes.GET_COOPERATIVE_MEMBER_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ActionTypes.GET_COOPERATIVE_MEMBER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        total: action.payload.counts, // Update to access total property
      };
    case ActionTypes.GET_COOPERATIVE_MEMBER_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

const producerListReducer = (state = initialProducerListState, action) => {
  switch (action.type)
  {
    case ActionTypes.GET_PRODUCERS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        status: null,
        message: '',
      };
    case ActionTypes.GET_PRODUCERS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        total: action.payload.total, // Update to access total property
      };
    case ActionTypes.GET_PRODUCERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ActionTypes.GET_PRODUCERS_RESET:
      return {
        ...state,
        isLoading: false,
        data: '',
        status: app_consts.IDLE,
        error: null,
      };
    default:
      return state;
  }
};

const getProducerReducer = (state = initialProducerState, action) => {
  switch (action.type)
  {
    case ActionTypes.GET_PRODUCER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ActionTypes.GET_PRODUCER_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case ActionTypes.GET_PRODUCER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

const activateMembershipTypeReducer = (
  state = initialactivateMembershipType,
  action
) => {
  switch (action.type)
  {
    case ActionTypes.ACTIVATE_MEMBERSHIP_TYPE_REQUEST:
      return {
        ...state,
        isLoading: true,
        message: '',
        status: null,
        error: null,
      };
    case ActionTypes.ACTIVATE_MEMBERSHIP_TYPE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: 200,
        error: null,
      };
    case ActionTypes.ACTIVATE_MEMBERSHIP_TYPE_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
        status: 400,
        error: action.payload,
      };
    case ActionTypes.ACTIVATE_MEMBERSHIP_TYPE_RESET:
      return {
        ...state,
        isLoading: false,
        data: '',
        status: app_consts.IDLE,
        error: null,
      };
    default:
      return state;
  }
};

const brokerOfferListReducer = (
  state = initialBrokerOfferListState,
  action
) => {
  switch (action.type)
  {
    case ActionTypes.GET_BROKER_OFFERS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ActionTypes.GET_BROKER_OFFERS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        total: action.payload.total,
      };
    case ActionTypes.GET_BROKER_OFFERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

const brokerBidListByCommodityOrderIdReducer = (
  state = initialBrokerBidListByCommodityOrderIdState,
  action
) => {
  switch (action.type)
  {
    case ActionTypes.GET_BROKER_BIDS_BY_COMMODITY_ORDER_ID_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ActionTypes.GET_BROKER_BIDS_BY_COMMODITY_ORDER_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        total: action.payload.total,
      };
    case ActionTypes.GET_BROKER_BIDS_BY_COMMODITY_ORDER_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

const brokerBidListByBrokerIdReducer = (
  state = initialBrokerBidListByBrokerIdState,
  action
) => {
  switch (action.type)
  {
    case ActionTypes.GET_BROKER_BIDS_BY_BROKER_ID_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ActionTypes.GET_BROKER_BIDS_BY_BROKER_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        total: action.payload.total,
      };
    case ActionTypes.GET_BROKER_BIDS_BY_BROKER_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

const receiptNumberListReducer = (
  state = initialReceiptNumberListState,
  action
) => {
  switch (action.type)
  {
    case ActionTypes.GET_RECEIPT_NUMBERS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ActionTypes.GET_RECEIPT_NUMBERS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        total: action.payload.total,
      };
    case ActionTypes.GET_RECEIPT_NUMBERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

const offerListReducer = (state = initialOfferListState, action) => {
  switch (action.type)
  {
    case ActionTypes.GET_OFFER_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ActionTypes.GET_OFFER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        // total: action.payload.total,
      };
    case ActionTypes.GET_OFFER_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

const offerListStatusFilteredReducer = (
  state = initialOfferListStatusFilteredState,
  action
) => {
  switch (action.type)
  {
    case ActionTypes.GET_OFFER_LIST_FILTER_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        message: '',
        status: null,
      };
    case ActionTypes.GET_OFFER_LIST_FILTER_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        // total: action.payload.total,
      };
    case ActionTypes.GET_OFFER_LIST_FILTER_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ActionTypes.GET_OFFER_LIST_FILTER_STATUS_RESET:
      return {
        ...state,
        isLoading: false,
        data: '',
        status: app_consts.IDLE,
        error: null,
      };
    default:
      return state;
  }
};

const getOfferDetailsReducer = (state = initialOfferDetailsState, action) => {
  switch (action.type)
  {
    case ActionTypes.GET_OFFER_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        message: '',
        status: null,
      };
    case ActionTypes.GET_OFFER_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        status: 200,
        error: null,
      };
    case ActionTypes.GET_OFFER_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        status: 400,
      };
    case ActionTypes.GET_OFFER_DETAILS_RESET:
      return {
        ...state,
        isLoading: false,
        data: '',
        status: app_consts.IDLE,
        error: null,
      };
    default:
      return state;
  }
};

// const receiptNumberListReducer = (state = initialReceiptNumberListState, action) => {
//   switch (action.type)
//   {
//     case ActionTypes.GET_RECEIPT_NUMBERS_REQUEST:
//       return {
//         ...state,
//         loading: true,
//         error: null,
//       };
//     case ActionTypes.GET_RECEIPT_NUMBERS_SUCCESS:
//       return {
//         ...state,
//         loading: false,
//         data: action.payload,
//         total: action.payload.total,
//       };
//     case ActionTypes.GET_RECEIPT_NUMBERS_FAILURE:
//       return {
//         ...state,
//         loading: false,
//         error: action.payload,
//       };
//     default:
//       return state;
//   }
// };

const getBrokerOfferReducer = (state = initialBrokerOfferState, action) => {
  switch (action.type)
  {
    case ActionTypes.GET_BROKER_OFFER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ActionTypes.GET_BROKER_OFFER_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case ActionTypes.GET_BROKER_OFFER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

const getBrokerListReducer = (state = initialBrokerListState, action) => {
  switch (action.type)
  {
    case ActionTypes.GET_BROKER_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ActionTypes.GET_BROKER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        // total: action.payload.total,
      };
    case ActionTypes.GET_BROKER_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

// Retrive Specific Entity TYpe List Reducer
const specificEntityProfileListReducer = (
  state = initialgetSPecificEntityProfileListState,
  action
) => {
  switch (action.type)
  {
    case ActionTypes.GET_SPECIFIC_ENTITY_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
        message: '',
        status: null,
        error: null,
      };
    case ActionTypes.GET_SPECIFIC_ENTITY_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: 200,
        error: null,
      };
    case ActionTypes.GET_SPECIFIC_ENTITY_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
        status: 400,
        error: action.payload,
      };
    case ActionTypes.GET_SPECIFIC_ENTITY_LIST_RESET:
      return {
        ...state,
        isLoading: false,
        data: '',
        status: app_consts.IDLE,
        error: null,
      };
    default:
      return state;
  }
};

// Retrive Specific Entity TYpe List Reducer
const createDeliveryNoteReducer = (
  state = initialCreateDeliveryNoteListState,
  action
) => {
  switch (action.type)
  {
    case ActionTypes.CREATE_DELIVERY_NOTE_REQUEST:
      return {
        ...state,
        isLoading: true,
        message: '',
        status: null,
        error: null,
      };
    case ActionTypes.CREATE_DELIVERY_NOTE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: 200,
        error: null,
      };
    case ActionTypes.CREATE_DELIVERY_NOTE_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
        status: 400,
        error: action.payload,
      };
    case ActionTypes.CREATE_DELIVERY_NOTE_RESET:
      return {
        ...state,
        isLoading: false,
        data: '',
        status: app_consts.IDLE,
        error: null,
      };
    default:
      return state;
  }
};

//Create Commodity Grading
const createCommodityGradingReducer = (
  state = initialCreateCommodityGradingState,
  action
) => {
  switch (action.type)
  {
    case ActionTypes.CREATE_COMMODITY_GRADING_REQUEST:
      return {
        ...state,
        isLoading: true,
        message: '',
        status: null,
        error: null,
      };
    case ActionTypes.CREATE_COMMODITY_GRADING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: 200,
        error: null,
      };
    case ActionTypes.CREATE_COMMODITY_GRADING_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
        status: 400,
        error: action.payload,
      };
    case ActionTypes.CREATE_COMMODITY_GRADING_RESET:
      return {
        ...state,
        isLoading: false,
        data: '',
        status: app_consts.IDLE,
        error: null,
      };
    default:
      return state;
  }
};

//Create Commodity Grading
const createStorageInventoryReducer = (
  state = initialCreateStorageInventoryState,
  action
) => {
  switch (action.type)
  {
    case ActionTypes.CREATE_STORAGE_INVENTORY_REQUEST:
      return {
        ...state,
        isLoading: true,
        message: '',
        status: null,
        error: null,
      };
    case ActionTypes.CREATE_STORAGE_INVENTORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: 200,
        error: null,
      };
    case ActionTypes.CREATE_STORAGE_INVENTORY_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
        status: 400,
        error: action.payload,
      };
    case ActionTypes.CREATE_STORAGE_INVENTORY_RESET:
      return {
        ...state,
        isLoading: false,
        data: '',
        status: app_consts.IDLE,
        error: null,
      };
    default:
      return state;
  }
};

//Update Warehouse Receipt Settlement
const updateWarehouseReceiptSettlementReducer = (
  state = initialUpdateWarehouseReceiptSettlementState,
  action
) => {
  switch (action.type)
  {
    case ActionTypes.UPDATE_WAREHOUSE_RECEIPT_SETTLEMENT_REQUEST:
      return {
        ...state,
        isLoading: true,
        message: '',
        status: null,
        error: null,
      };
    case ActionTypes.UPDATE_WAREHOUSE_RECEIPT_SETTLEMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: 200,
        error: null,
      };
    case ActionTypes.UPDATE_WAREHOUSE_RECEIPT_SETTLEMENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
        status: 400,
        error: action.payload,
      };
    case ActionTypes.UPDATE_WAREHOUSE_RECEIPT_SETTLEMENT_RESET:
      return {
        ...state,
        isLoading: false,
        data: '',
        status: app_consts.IDLE,
        error: null,
      };
    default:
      return state;
  }
};

//Update Warehouse Receipt Settlement
const generateWarehouseReceiptReducer = (
  state = initialGenerateWarehouseReceiptState,
  action
) => {
  switch (action.type)
  {
    case ActionTypes.GENERATE_WAREHOUSE_RECEIPT_REQUEST:
      return {
        ...state,
        isLoading: true,
        message: '',
        status: null,
        error: null,
      };
    case ActionTypes.GENERATE_WAREHOUSE_RECEIPT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: 200,
        error: null,
      };
    case ActionTypes.GENERATE_WAREHOUSE_RECEIPT_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
        status: 400,
        error: action.payload,
      };
    case ActionTypes.GENERATE_WAREHOUSE_RECEIPT_RESET:
      return {
        ...state,
        isLoading: false,
        data: '',
        status: app_consts.IDLE,
        error: null,
      };
    default:
      return state;
  }
};

//Retrieve List of Warehouse Receipts from an Entity
const retrieveEntityWarehouseReceiptListReducer = (
  state = initialRetrieveEntityWarehouseReceiptListState,
  action
) => {
  switch (action.type)
  {
    case ActionTypes.RETRIEVE_ENTITY_WAREHOUSE_RECEIPT_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
        message: '',
        status: null,
        error: null,
      };
    case ActionTypes.RETRIEVE_ENTITY_WAREHOUSE_RECEIPT_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: 200,
        error: null,
      };
    case ActionTypes.RETRIEVE_ENTITY_WAREHOUSE_RECEIPT_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
        status: 400,
        error: action.payload,
      };
    case ActionTypes.RETRIEVE_ENTITY_WAREHOUSE_RECEIPT_LIST_RESET:
      return {
        ...state,
        isLoading: false,
        data: '',
        status: app_consts.IDLE,
        error: null,
      };
    default:
      return state;
  }
};

//Retrieve List of Warehouse Receipts from an Entity
const retrieveAllProducersListReducer = (
  state = initialRetrieveAllProducersListState,
  action
) => {
  switch (action.type)
  {
    case ActionTypes.RETRIEVE_ALL_PRODUCER_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
        message: '',
        status: null,
        error: null,
      };
    case ActionTypes.RETRIEVE_ALL_PRODUCER_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: 200,
        error: null,
      };
    case ActionTypes.RETRIEVE_ALL_PRODUCER_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
        status: 400,
        error: action.payload,
      };
    case ActionTypes.RETRIEVE_ALL_PRODUCER_LIST_RESET:
      return {
        ...state,
        isLoading: false,
        data: '',
        status: app_consts.IDLE,
        error: null,
      };
    default:
      return state;
  }
};

// Create Pending Entity
const createPendingEntityReducer = (
  state = initialCreatePendingEntityState,
  action
) => {
  switch (action.type)
  {
    case ActionTypes.CREATE_PENDING_ENTITY_REQUEST:
      return {
        ...state,
        isLoading: true,
        message: '',
        status: null,
        error: null,
      };
    case ActionTypes.CREATE_PENDING_ENTITY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: 200,
        error: null,
      };
    case ActionTypes.CREATE_PENDING_ENTITY_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
        status: 400,
        error: action.payload,
      };
    case ActionTypes.CREATE_PENDING_ENTITY_RESET:
      return {
        ...state,
        isLoading: false,
        data: '',
        status: app_consts.IDLE,
        error: null,
      };
    default:
      return state;
  }
};

// Retrive Pending Entity List
const retrievePendingEntityListReducer = (
  state = initialRetrievePendingEntityListState,
  action
) => {
  switch (action.type)
  {
    case ActionTypes.RETRIEVE_PENDING_ENTITY_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
        message: '',
        status: null,
        error: null,
      };
    case ActionTypes.RETRIEVE_PENDING_ENTITY_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: 200,
        error: null,
      };
    case ActionTypes.RETRIEVE_PENDING_ENTITY_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
        status: 400,
        error: action.payload,
      };
    case ActionTypes.RETRIEVE_PENDING_ENTITY_LIST_RESET:
      return {
        ...state,
        isLoading: false,
        data: '',
        status: app_consts.IDLE,
        error: null,
      };
    default:
      return state;
  }
};

// Retrive Inbound Shipments List
const retrieveInboundShipmentListReducer = (
  state = initialRetrieveInboundShipmentListState,
  action
) => {
  switch (action.type)
  {
    case ActionTypes.RETRIEVE_INBOUND_SHIPMENT_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
        message: '',
        status: null,
        error: null,
      };
    case ActionTypes.RETRIEVE_INBOUND_SHIPMENT_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: 200,
        error: null,
      };
    case ActionTypes.RETRIEVE_INBOUND_SHIPMENT_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
        status: 400,
        error: action.payload,
      };
    case ActionTypes.RETRIEVE_INBOUND_SHIPMENT_LIST_RESET:
      return {
        ...state,
        isLoading: false,
        data: '',
        status: app_consts.IDLE,
        error: null,
      };
    default:
      return state;
  }
};

// Retrive Specific Inbound Shipments List
const retrieveSpecificInboundShipmentReducer = (
  state = initialRetrieveSpecificInboundShipmentState,
  action
) => {
  switch (action.type)
  {
    case ActionTypes.RETRIEVE_SPECIFIC_INBOUND_SHIPMENT_REQUEST:
      return {
        ...state,
        isLoading: true,
        message: '',
        status: null,
        error: null,
      };
    case ActionTypes.RETRIEVE_SPECIFIC_INBOUND_SHIPMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: 200,
        error: null,
      };
    case ActionTypes.RETRIEVE_SPECIFIC_INBOUND_SHIPMENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
        status: 400,
        error: action.payload,
      };
    case ActionTypes.RETRIEVE_SPECIFIC_INBOUND_SHIPMENT_RESET:
      return {
        ...state,
        isLoading: false,
        data: '',
        status: app_consts.IDLE,
        error: null,
      };
    default:
      return state;
  }
};

// Retrive Specific Inbound Shipments List
const retrieveWarehouseStorageListReducer = (
  state = initialRetrieveWarehouseStoragelistState,
  action
) => {
  switch (action.type)
  {
    case ActionTypes.RETRIEVE_WAREHOUSE_STORAGE_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
        message: '',
        status: null,
        error: null,
      };
    case ActionTypes.RETRIEVE_WAREHOUSE_STORAGE_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: 200,
        error: null,
      };
    case ActionTypes.RETRIEVE_WAREHOUSE_STORAGE_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
        status: 400,
        error: action.payload,
      };
    case ActionTypes.RETRIEVE_WAREHOUSE_STORAGE_LIST_RESET:
      return {
        ...state,
        isLoading: false,
        data: '',
        status: app_consts.IDLE,
        error: null,
      };
    default:
      return state;
  }
};

// Retrive Specific Warehouse Receipt
const retrieveSpecificWarehouseReceiptReducer = (
  state = initialRetrieveSpecificWarehouseReceiptState,
  action
) => {
  switch (action.type)
  {
    case ActionTypes.RETRIEVE_SPECIFIC_WAREHOUSE_RECEIPT_REQUEST:
      return {
        ...state,
        isLoading: true,
        message: '',
        status: null,
        error: null,
      };
    case ActionTypes.RETRIEVE_SPECIFIC_WAREHOUSE_RECEIPT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: 200,
        error: null,
      };
    case ActionTypes.RETRIEVE_SPECIFIC_WAREHOUSE_RECEIPT_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
        status: 400,
        error: action.payload,
      };
    case ActionTypes.RETRIEVE_SPECIFIC_WAREHOUSE_RECEIPT_RESET:
      return {
        ...state,
        isLoading: false,
        data: '',
        status: app_consts.IDLE,
        error: null,
      };
    default:
      return state;
  }
};

// Continue Processing Warehouse Receipt
const continueProcessingWarehouseReceiptReducer = (
  state = initialContinueProcessingWarehouseReceiptState,
  action
) => {
  switch (action.type)
  {
    case ActionTypes.CONTINUE_PROCESSING_WAREHOUSE_RECEIPT_REQUEST:
      return {
        ...state,
        isLoading: true,
        message: '',
        status: null,
        error: null,
      };
    case ActionTypes.CONTINUE_PROCESSING_WAREHOUSE_RECEIPT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: 200,
        error: null,
      };
    case ActionTypes.CONTINUE_PROCESSING_WAREHOUSE_RECEIPT_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
        status: 400,
        error: action.payload,
      };
    case ActionTypes.CONTINUE_PROCESSING_WAREHOUSE_RECEIPT_RESET:
      return {
        ...state,
        isLoading: false,
        data: '',
        status: app_consts.IDLE,
        error: null,
      };
    default:
      return state;
  }
};

// Update Warehouse Status
const updateWarehouseStatusReducer = (
  state = initialUpdateWarehouseState,
  action
) => {
  switch (action.type)
  {
    case ActionTypes.UPDATE_WAREHOUSE_STATUS_REQUEST:
      return {
        ...state,
        isLoading: true,
        message: '',
        status: null,
        error: null,
      };
    case ActionTypes.UPDATE_WAREHOUSE_STATUS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: 200,
        error: null,
      };
    case ActionTypes.UPDATE_WAREHOUSE_STATUS_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
        status: 400,
        error: action.payload,
      };
    case ActionTypes.UPDATE_WAREHOUSE_STATUS_RESET:
      return {
        ...state,
        isLoading: false,
        data: '',
        status: app_consts.IDLE,
        error: null,
      };
    default:
      return state;
  }
};

// Get Producers and their Issued Warehouse Receipts List
const getProducersAndWarehouseReceiptsForBrokerReducer = (
  state = initialProducersAndWarehouseReceiptsState,
  action
) => {
  switch (action.type)
  {
    case ActionTypes.GET_PRODUCERS_AND_WAREHOUSE_RECEIPTS_FOR_BROKER_REQUEST:
      return {
        ...state,
        isLoading: true,
        message: '',
        status: null,
        error: null,
      };
    case ActionTypes.GET_PRODUCERS_AND_WAREHOUSE_RECEIPTS_FOR_BROKER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: 200,
        error: null,
      };
    case ActionTypes.GET_PRODUCERS_AND_WAREHOUSE_RECEIPTS_FOR_BROKER_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
        status: 400,
        error: action.payload,
      };
    case ActionTypes.GET_PRODUCERS_AND_WAREHOUSE_RECEIPTS_FOR_BROKER_RESET:
      return {
        ...state,
        isLoading: false,
        data: '',
        status: app_consts.IDLE,
        error: null,
      };
    default:
      return state;
  }
};

// Update broker Trading Authority
const updateBrokerTradingAuthorityReducer = (
  state = initialUpdateBrokerTradingAuthorityState,
  action
) => {
  switch (action.type)
  {
    case ActionTypes.UPDATE_BROKER_TRADING_AUTHORITY_REQUEST:
      return {
        ...state,
        isLoading: true,
        message: '',
        status: null,
        error: null,
      };
    case ActionTypes.UPDATE_BROKER_TRADING_AUTHORITY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: 200,
        error: null,
      };
    case ActionTypes.UPDATE_BROKER_TRADING_AUTHORITY_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
        status: 400,
        error: action.payload,
      };
    case ActionTypes.UPDATE_BROKER_TRADING_AUTHORITY_RESET:
      return {
        ...state,
        isLoading: false,
        data: '',
        status: app_consts.IDLE,
        error: null,
      };
    default:
      return state;
  }
};

// Retrieve Issued Warehouse Receipts List
const retrieveIssuedWarehouseReceiptsListReducer = (
  state = initialRetrieveIssuedWarehouseReceiptsListState,
  action
) => {
  switch (action.type)
  {
    case ActionTypes.RETIREVE_ISSUED_WAREHOUSE_RECEIPTS_REQUEST:
      return {
        ...state,
        isLoading: true,
        message: '',
        status: null,
        error: null,
      };
    case ActionTypes.RETIREVE_ISSUED_WAREHOUSE_RECEIPTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: 200,
        error: null,
      };
    case ActionTypes.RETIREVE_ISSUED_WAREHOUSE_RECEIPTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
        status: 400,
        error: action.payload,
      };
    case ActionTypes.RETIREVE_ISSUED_WAREHOUSE_RECEIPTS_RESET:
      return {
        ...state,
        isLoading: false,
        data: '',
        status: app_consts.IDLE,
        error: null,
      };
    default:
      return state;
  }
};

// Amend Warehouse Receipt Status
const amendWarehouseReceiptReducer = (
  state = initialAmendWarehouseReceiptState,
  action
) => {
  switch (action.type)
  {
    case ActionTypes.AMEND_WAREHOUSE_RECEIPT_REQUEST:
      return {
        ...state,
        isLoading: true,
        message: '',
        status: null,
        error: null,
      };
    case ActionTypes.AMEND_WAREHOUSE_RECEIPT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: 200,
        error: null,
      };
    case ActionTypes.AMEND_WAREHOUSE_RECEIPT_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
        status: 400,
        error: action.payload,
      };
    case ActionTypes.AMEND_WAREHOUSE_RECEIPT_RESET:
      return {
        ...state,
        isLoading: false,
        data: '',
        status: app_consts.IDLE,
        error: null,
      };
    default:
      return state;
  }
};

const getUserAccountListReducer = (
  state = initialUserAccountListState,
  action
) => {
  switch (action.type)
  {
    case ActionTypes.GET_USER_ACCOUNT_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
        message: '',
        status: null,
        error: null,
      };
    case ActionTypes.GET_USER_ACCOUNT_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: 200,
        error: null,
      };
    case ActionTypes.GET_USER_ACCOUNT_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
        status: 400,
        error: action.payload,
      };
    case ActionTypes.GET_USER_ACCOUNT_LIST_RESET:
      return {
        ...state,
        isLoading: false,
        data: '',
        status: app_consts.IDLE,
        error: null,
      };
    default:
      return state;
  }
};

const getUserAccountDetailsReducer = (
  state = initialUserAccountDetailsState,
  action
) => {
  switch (action.type)
  {
    case ActionTypes.GET_USER_ACCOUNT_DETAILS_REQUEST:
      return {
        ...state,
        isLoading: true,
        message: '',
        status: null,
        error: null,
      };
    case ActionTypes.GET_USER_ACCOUNT_DETAILS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: 200,
        error: null,
      };
    case ActionTypes.GET_USER_ACCOUNT_DETAILS_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
        status: 400,
        error: action.payload,
      };
    case ActionTypes.GET_USER_ACCOUNT_DETAILS_RESET:
      return {
        ...state,
        isLoading: false,
        data: '',
        status: app_consts.IDLE,
        error: null,
      };
    default:
      return state;
  }
};

// Retrieve Specific Broker Offer
const retrieveSpecificBrokerOfferReducer = (
  state = initialRetrieveSpecificBrokerOfferState,
  action
) => {
  switch (action.type)
  {
    case ActionTypes.RECEIVE_SPECIFIC_BROKER_OFFER_REQUEST:
      return {
        ...state,
        loading: true,
        message: '',
        status: null,
        error: null,
      };
    case ActionTypes.RECEIVE_SPECIFIC_BROKER_OFFER_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        status: 200,
        error: null,
      };
    case ActionTypes.RECEIVE_SPECIFIC_BROKER_OFFER_FAILURE:
      return {
        ...state,
        loading: false,
        message: action.payload,
        status: 400,
        error: action.payload,
      };
    case ActionTypes.RECEIVE_SPECIFIC_BROKER_OFFER_RESET:
      return {
        ...state,
        loading: false,
        data: '',
        status: app_consts.IDLE,
        error: null,
      };
    default:
      return state;
  }
};

// Accept Bid On Commodity Order
const acceptBidOnCommodityOrderReducer = (
  state = initialAccpetBidOnCommodityOrderState,
  action
) => {
  switch (action.type)
  {
    case ActionTypes.ACCEPT_BID_ON_COMMODITY_ORDER_REQUEST:
      return {
        ...state,
        isLoading: true,
        message: '',
        status: null,
        error: null,
      };
    case ActionTypes.ACCEPT_BID_ON_COMMODITY_ORDER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: 200,
        error: null,
      };
    case ActionTypes.ACCEPT_BID_ON_COMMODITY_ORDER_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
        status: 400,
        error: action.payload,
      };
    case ActionTypes.ACCEPT_BID_ON_COMMODITY_ORDER_RESET:
      return {
        ...state,
        isLoading: false,
        data: '',
        status: app_consts.IDLE,
        error: null,
      };
    default:
      return state;
  }
};

// Retrieve Trade Offer Details
const retrieveTradeOfferDetailsReducer = (
  state = initialRetrieveTradeOfferDetailsState,
  action
) => {
  switch (action.type)
  {
    case ActionTypes.RETRIEVE_TRADE_OFFER_DETAILS_REQUEST:
      return {
        ...state,
        isLoading: true,
        message: '',
        status: null,
        error: null,
      };
    case ActionTypes.RETRIEVE_TRADE_OFFER_DETAILS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: 200,
        error: null,
      };
    case ActionTypes.RETRIEVE_TRADE_OFFER_DETAILS_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
        status: 400,
        error: action.payload,
      };
    case ActionTypes.RETRIEVE_TRADE_OFFER_DETAILS_RESET:
      return {
        ...state,
        isLoading: false,
        data: '',
        status: app_consts.IDLE,
        error: null,
      };
    default:
      return state;
  }
};

// Retrieve Broker Bids List by Broker ID
const retrieveBrokerBidsByBrokerIdReducer = (
  state = initialRetrieveBrokerBidsByBrokerIdState,
  action
) => {
  switch (action.type)
  {
    case ActionTypes.RETRIEVE_BROKER_BIDS_BY_BROKER_ID_REQUEST:
      return {
        ...state,
        isLoading: true,
        message: '',
        status: null,
        error: null,
      };
    case ActionTypes.RETRIEVE_BROKER_BIDS_BY_BROKER_ID_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: 200,
        error: null,
      };
    case ActionTypes.RETRIEVE_BROKER_BIDS_BY_BROKER_ID_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
        status: 400,
        error: action.payload,
      };
    case ActionTypes.RETRIEVE_BROKER_BIDS_BY_BROKER_ID_RESET:
      return {
        ...state,
        isLoading: false,
        data: '',
        status: app_consts.IDLE,
        error: null,
      };
    default:
      return state;
  }
};

// Retrieve Sepecific Broker Bid Details
const retreiveSpecificBrokerBidDetailsReducer = (
  state = initialRetrieveSpecificBrokerBidDetailsState,
  action
) => {
  switch (action.type)
  {
    case ActionTypes.RETRIEVE_SPECIFIC_BROKER_BID_DETAILS_REQUEST:
      return {
        ...state,
        isLoading: true,
        message: '',
        status: null,
        error: null,
      };
    case ActionTypes.RETRIEVE_SPECIFIC_BROKER_BID_DETAILS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: 200,
        error: null,
      };
    case ActionTypes.RETRIEVE_SPECIFIC_BROKER_BID_DETAILS_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
        status: 400,
        error: action.payload,
      };
    case ActionTypes.RETRIEVE_SPECIFIC_BROKER_BID_DETAILS_RESET:
      return {
        ...state,
        isLoading: false,
        data: '',
        status: app_consts.IDLE,
        error: null,
      };
    default:
      return state;
  }
};

// Update Trade Proof of Payment
const uploadTradeProofOfPaymentReducer = (
  state = initialUploadTradeProofOfPaymentState,
  action
) => {
  switch (action.type)
  {
    case ActionTypes.UPLOAD_TRADE_PROOF_OF_PAYMENT_REQUEST:
      return {
        ...state,
        isLoading: true,
        message: '',
        status: null,
        error: null,
      };
    case ActionTypes.UPLOAD_TRADE_PROOF_OF_PAYMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: 200,
        error: null,
      };
    case ActionTypes.UPLOAD_TRADE_PROOF_OF_PAYMENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
        status: 400,
        error: action.payload,
      };
    case ActionTypes.UPLOAD_TRADE_PROOF_OF_PAYMENT_RESET:
      return {
        ...state,
        isLoading: false,
        data: '',
        status: app_consts.IDLE,
        error: null,
      };
    default:
      return state;
  }
};

// Retrieve Broker Producer
const retrieveBrokerProducerReducer = (
  state = initialRetrieveBrokerProducerState,
  action
) => {
  switch (action.type)
  {
    case ActionTypes.RETRIEVE_BROKER_PRODUCERS_REQUEST:
      return {
        ...state,
        isLoading: true,
        message: '',
        status: null,
        error: null,
      };
    case ActionTypes.RETRIEVE_BROKER_PRODUCERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: 200,
        error: null,
      };
    case ActionTypes.RETRIEVE_BROKER_PRODUCERS_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
        status: 400,
        error: action.payload,
      };
    case ActionTypes.RETRIEVE_BROKER_PRODUCERS_RESET:
      return {
        ...state,
        isLoading: false,
        data: '',
        status: app_consts.IDLE,
        error: null,
      };
    default:
      return state;
  }
};

// Approve Trade Payment
const approveTradePaymentReducer = (
  state = initialApproveTradePaymentState,
  action
) => {
  switch (action.type)
  {
    case ActionTypes.APPROVE_TRADE_PAYMENT_REQUEST:
      return {
        ...state,
        isLoading: true,
        message: '',
        status: null,
        error: null,
      };
    case ActionTypes.APPROVE_TRADE_PAYMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: 200,
        error: null,
      };
    case ActionTypes.APPROVE_TRADE_PAYMENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
        status: 400,
        error: action.payload,
      };
    case ActionTypes.APPROVE_TRADE_PAYMENT_RESET:
      return {
        ...state,
        isLoading: false,
        data: '',
        status: app_consts.IDLE,
        error: null,
      };
    default:
      return state;
  }
};

// Upload Signed Trade Contract
const uploadSignedCTradeContractReducer = (
  state = initialUploadSignedTradeContractState,
  action
) => {
  switch (action.type)
  {
    case ActionTypes.UPLOAD_SIGNED_TRADE_CONTRACT_REQUEST:
      return {
        ...state,
        isLoading: true,
        message: '',
        status: null,
        error: null,
      };
    case ActionTypes.UPLOAD_SIGNED_TRADE_CONTRACT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: 200,
        error: null,
      };
    case ActionTypes.UPLOAD_SIGNED_TRADE_CONTRACT_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
        status: 400,
        error: action.payload,
      };
    case ActionTypes.UPLOAD_SIGNED_TRADE_CONTRACT_RESET:
      return {
        ...state,
        isLoading: false,
        data: '',
        status: app_consts.IDLE,
        error: null,
      };
    default:
      return state;
  }
};

// Confirm Trade By Contract Number
const confirmTradeByContractNumberReducer = (
  state = initialConfirmTradeByContractNumberState,
  action
) => {
  switch (action.type)
  {
    case ActionTypes.CONFIRM_TRADE_BY_CONTRACT_NUMBER_REQUEST:
      return {
        ...state,
        isLoading: true,
        message: '',
        status: null,
        error: null,
      };
    case ActionTypes.CONFIRM_TRADE_BY_CONTRACT_NUMBER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: 200,
        error: null,
      };
    case ActionTypes.CONFIRM_TRADE_BY_CONTRACT_NUMBER_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
        status: 400,
        error: action.payload,
      };
    case ActionTypes.CONFIRM_TRADE_BY_CONTRACT_NUMBER_RESET:
      return {
        ...state,
        isLoading: false,
        data: '',
        status: app_consts.IDLE,
        error: null,
      };
    default:
      return state;
  }
};

// Retrieve Trade Fulfillment List
const retrieveTradeFulfillmentListReducer = (
  state = initialRetrieveTradeFulfillmentListState,
  action
) => {
  switch (action.type)
  {
    case ActionTypes.RETRIEVE_TRADE_FULFILLMENT_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
        message: '',
        status: null,
        error: null,
      };
    case ActionTypes.RETRIEVE_TRADE_FULFILLMENT_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: 200,
        error: null,
      };
    case ActionTypes.RETRIEVE_TRADE_FULFILLMENT_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
        status: 400,
        error: action.payload,
      };
    case ActionTypes.RETRIEVE_TRADE_FULFILLMENT_LIST_RESET:
      return {
        ...state,
        isLoading: false,
        data: '',
        status: app_consts.IDLE,
        error: null,
      };
    default:
      return state;
  }
};

// Retrieve Specific Trade Fulfillment
const retrieveSpecificTradeFulfillmentReducer = (
  state = initialRetrieveSpecificTradeFulfillmentState,
  action
) => {
  switch (action.type)
  {
    case ActionTypes.RETRIEVE_SPECIFIC_TRADE_FULFILLMENT_REQUEST:
      return {
        ...state,
        isLoading: true,
        message: '',
        status: null,
        error: null,
      };
    case ActionTypes.RETRIEVE_SPECIFIC_TRADE_FULFILLMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: 200,
        error: null,
      };
    case ActionTypes.RETRIEVE_SPECIFIC_TRADE_FULFILLMENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
        status: 400,
        error: action.payload,
      };
    case ActionTypes.RETRIEVE_SPECIFIC_TRADE_FULFILLMENT_RESET:
      return {
        ...state,
        isLoading: false,
        data: '',
        status: app_consts.IDLE,
        error: null,
      };
    default:
      return state;
  }
};

// Retrieve Provisional Receipts List
const retrieveProvisionReceiptsListReducer = (
  state = initialRetrieveProvisionReceiptsListState,
  action
) => {
  switch (action.type)
  {
    case ActionTypes.RETIREVE_PROVISION_RECEIPTS_REQUEST:
      return {
        ...state,
        isLoading: true,
        message: '',
        status: null,
        error: null,
      };
    case ActionTypes.RETIREVE_PROVISION_RECEIPTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: 200,
        error: null,
      };
    case ActionTypes.RETIREVE_PROVISION_RECEIPTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
        status: 400,
        error: action.payload,
      };
    case ActionTypes.RETIREVE_PROVISION_RECEIPTS_RESET:
      return {
        ...state,
        isLoading: false,
        data: '',
        status: app_consts.IDLE,
        error: null,
      };
    default:
      return state;
  }
};

// Upload Warehuose Inspectino Certificate
const uploadWarehouseInspectionCertificateReducer = (
  state = initialUploadWarehouseInspectionCertificateState,
  action
) => {
  switch (action.type)
  {
    case ActionTypes.UPLOAD_WAREHOUSE_INSPECTION_CERTIFCATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        message: '',
        status: null,
        error: null,
      };
    case ActionTypes.UPLOAD_WAREHOUSE_INSPECTION_CERTIFCATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: 200,
        error: null,
      };
    case ActionTypes.UPLOAD_WAREHOUSE_INSPECTION_CERTIFCATE_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
        status: 400,
        error: action.payload,
      };
    case ActionTypes.UPLOAD_WAREHOUSE_INSPECTION_CERTIFCATE_RESET:
      return {
        ...state,
        isLoading: false,
        data: '',
        status: app_consts.IDLE,
        error: null,
      };
    default:
      return state;
  }
};

// Retrieve Specific Warehouse Storage Types and Capacities Via Delivery ID
const retrieveSpecificWarehouseStorageTypesAndCapacitiesViaDeliveryIdReducer = (
  state = initialRetrieveSpecificWarehouseStorageTypesAndCapacitiesViaDeliveryIdState,
  action
) => {
  switch (action.type)
  {
    case ActionTypes.RETRIEVE_SPECIFIC_WAREHOUSE_STORAGE_TYPES_AND_CAPACITIES_VIA_DELIVERY_ID_REQUEST:
      return {
        ...state,
        isLoading: true,
        message: '',
        status: null,
        error: null,
      };
    case ActionTypes.RETRIEVE_SPECIFIC_WAREHOUSE_STORAGE_TYPES_AND_CAPACITIES_VIA_DELIVERY_ID_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: 200,
        error: null,
      };
    case ActionTypes.RETRIEVE_SPECIFIC_WAREHOUSE_STORAGE_TYPES_AND_CAPACITIES_VIA_DELIVERY_ID_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
        status: 400,
        error: action.payload,
      };
    case ActionTypes.RETRIEVE_SPECIFIC_WAREHOUSE_STORAGE_TYPES_AND_CAPACITIES_VIA_DELIVERY_ID_RESET:
      return {
        ...state,
        isLoading: false,
        data: '',
        status: app_consts.IDLE,
        error: null,
      };
    default:
      return state;
  }
};

// Retrieve Valid Bids and Offers
const retrieveValidSuperAndSubOffersBidsReducer = (
  state = initialRetrieveValidSuperAndSubOffersBidsState,
  action
) => {
  switch (action.type)
  {
    case ActionTypes.RETRIEVE_VALID_SUPER_AND_SUB_OFFERS_BIDS_REQUEST:
      return {
        ...state,
        isLoading: true,
        message: '',
        status: null,
        error: null,
      };
    case ActionTypes.RETRIEVE_VALID_SUPER_AND_SUB_OFFERS_BIDS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: 200,
        error: null,
      };
    case ActionTypes.RETRIEVE_VALID_SUPER_AND_SUB_OFFERS_BIDS_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
        status: 400,
        error: action.payload,
      };
    case ActionTypes.RETRIEVE_VALID_SUPER_AND_SUB_OFFERS_BIDS_RESET:
      return {
        ...state,
        isLoading: false,
        data: '',
        status: app_consts.IDLE,
        error: null,
      };
    default:
      return state;
  }
};

// Retrieve Broker Bids List by Broker ID
const retrieveBrokerSuperBidsByBrokerIdReducer = (
  state = initialRetrieveBrokerSuperBidsByBrokerIdState,
  action
) => {
  switch (action.type)
  {
    case ActionTypes.RETRIEVE_BROKER_SUPER_BIDS_BY_BROKER_ID_REQUEST:
      return {
        ...state,
        isLoading: true,
        message: '',
        status: null,
        error: null,
      };
    case ActionTypes.RETRIEVE_BROKER_SUPER_BIDS_BY_BROKER_ID_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: 200,
        error: null,
      };
    case ActionTypes.RETRIEVE_BROKER_SUPER_BIDS_BY_BROKER_ID_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
        status: 400,
        error: action.payload,
      };
    case ActionTypes.RETRIEVE_BROKER_SUPER_BIDS_BY_BROKER_ID_RESET:
      return {
        ...state,
        isLoading: false,
        data: '',
        status: app_consts.IDLE,
        error: null,
      };
    default:
      return state;
  }
};

// List All Producers With Entities
const listAllProducersWithEntitiesReducer = (
  state = initialListAllProducerWithEntitiesState,
  action
) => {
  switch (action.type)
  {
    case ActionTypes.LIST_ALL_PRODUCERS_WITH_ENTITIES_REQUEST:
      return {
        ...state,
        isLoading: true,
        message: '',
        status: null,
        error: null,
      };
    case ActionTypes.LIST_ALL_PRODUCERS_WITH_ENTITIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: 200,
        error: null,
      };
    case ActionTypes.LIST_ALL_PRODUCERS_WITH_ENTITIES_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
        status: 400,
        error: action.payload,
      };
    case ActionTypes.LIST_ALL_PRODUCERS_WITH_ENTITIES_RESET:
      return {
        ...state,
        isLoading: false,
        data: '',
        status: app_consts.IDLE,
        error: null,
      };
    default:
      return state;
  }
};

// Retrieve Entity Profile Info 
const retrieveEntityProfileInfoReducer = (
  state = initialRetrieveEntityProfileInfoState,
  action
) => {
  switch (action.type)
  {
    case ActionTypes.RETRIEVE_ENTITY_PROFILE_INFO_REQUEST:
      return {
        ...state,
        isLoading: true,
        message: '',
        status: null,
        error: null,
      };
    case ActionTypes.RETRIEVE_ENTITY_PROFILE_INFO_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: 200,
        error: null,
      };
    case ActionTypes.RETRIEVE_ENTITY_PROFILE_INFO_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
        status: 400,
        error: action.payload,
      };
    case ActionTypes.RETRIEVE_ENTITY_PROFILE_INFO_RESET:
      return {
        ...state,
        isLoading: false,
        data: '',
        status: app_consts.IDLE,
        error: null,
      };
    default:
      return state;
  }
};

// Retrieve Entity Profile Info 
const retrieveEntityUserProfileReducer = (
  state = initialRetrieveEntityUserProfilestate,
  action
) => {
  switch (action.type)
  {
    case ActionTypes.RETRIEVE_ENTITY_USER_PROFILE_REQUEST:
      return {
        ...state,
        isLoading: true,
        message: '',
        status: null,
        error: null,
      };
    case ActionTypes.RETRIEVE_ENTITY_USER_PROFILE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: 200,
        error: null,
      };
    case ActionTypes.RETRIEVE_ENTITY_USER_PROFILE_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
        status: 400,
        error: action.payload,
      };
    case ActionTypes.RETRIEVE_ENTITY_USER_PROFILE_RESET:
      return {
        ...state,
        isLoading: false,
        data: '',
        status: app_consts.IDLE,
        error: null,
      };
    default:
      return state;
  }
};

// Amend Profile Role
const amendProfileRoleReducer = (
  state = initialAmendProfileRolestate,
  action
) => {
  switch (action.type)
  {
    case ActionTypes.AMEND_PROFILE_ROLE_REQUEST:
      return {
        ...state,
        isLoading: true,
        message: '',
        status: null,
        error: null,
      };
    case ActionTypes.AMEND_PROFILE_ROLE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: 200,
        error: null,
      };
    case ActionTypes.AMEND_PROFILE_ROLE_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
        status: 400,
        error: action.payload,
      };
    case ActionTypes.AMEND_PROFILE_ROLE_RESET:
      return {
        ...state,
        isLoading: false,
        data: '',
        status: app_consts.IDLE,
        error: null,
      };
    default:
      return state;
  }
};

// Retrieve Specific Warehouse Storage Types
const retrieveSpecificWarehouseStorageTypeReducer = (
  state = initialRetrieveSpecificWarehouseStorageTypestate,
  action
) => {
  switch (action.type)
  {
    case ActionTypes.RETRIEVE_SPECIFIC_WAREHOUSE_STORAGE_TYPE_REQUEST:
      return {
        ...state,
        isLoading: true,
        message: '',
        status: null,
        error: null,
      };
    case ActionTypes.RETRIEVE_SPECIFIC_WAREHOUSE_STORAGE_TYPE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: 200,
        error: null,
      };
    case ActionTypes.RETRIEVE_SPECIFIC_WAREHOUSE_STORAGE_TYPE_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
        status: 400,
        error: action.payload,
      };
    case ActionTypes.RETRIEVE_SPECIFIC_WAREHOUSE_STORAGE_TYPE_RESET:
      return {
        ...state,
        isLoading: false,
        data: '',
        status: app_consts.IDLE,
        error: null,
      };
    default:
      return state;
  }
};

// Process Bulk Delivery
const processBulkDeliveryReducer = (
  state = initialProcessBulkDeliverystate,
  action
) => {
  switch (action.type)
  {
    case ActionTypes.PROCESS_BULK_DELIVERY_REQUEST:
      return {
        ...state,
        isLoading: true,
        message: '',
        status: null,
        error: null,
      };
    case ActionTypes.PROCESS_BULK_DELIVERY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: 200,
        error: null,
      };
    case ActionTypes.PROCESS_BULK_DELIVERY_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
        status: 400,
        error: action.payload,
      };
    case ActionTypes.PROCESS_BULK_DELIVERY_RESET:
      return {
        ...state,
        isLoading: false,
        data: '',
        status: app_consts.IDLE,
        error: null,
      };
    default:
      return state;
  }
};

// Toggle View On Website Reducer
const toggleViewWarehouseOnWebsiteReducer = (
  state = initialToggleViewWarehouseOnWebsitestate,
  action
) => {
  switch (action.type)
  {
    case ActionTypes.TOGGLE_VIEW_WAREHOUSE_ON_WEBSITE_REQUEST:
      return {
        ...state,
        isLoading: true,
        message: '',
        status: null,
        error: null,
      };
    case ActionTypes.TOGGLE_VIEW_WAREHOUSE_ON_WEBSITE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: 200,
        error: null,
      };
    case ActionTypes.TOGGLE_VIEW_WAREHOUSE_ON_WEBSITE_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
        status: 400,
        error: action.payload,
      };
    case ActionTypes.TOGGLE_VIEW_WAREHOUSE_ON_WEBSITE_RESET:
      return {
        ...state,
        isLoading: false,
        data: '',
        status: app_consts.IDLE,
        error: null,
      };
    default:
      return state;
  }
};

// Update Bank Accounts Reducer
const updateBankAccountDocumentsReducer = (
  state = initialUpdateBankAccountDocumentsReducerstate,
  action
) => {
  switch (action.type)
  {
    case ActionTypes.UPLOAD_BANK_ACCOUNT_DOCUMENTS_REQUEST:
      return {
        ...state,
        isLoading: true,
        message: '',
        status: null,
        error: null,
      };
    case ActionTypes.UPLOAD_BANK_ACCOUNT_DOCUMENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: 200,
        error: null,
      };
    case ActionTypes.UPLOAD_BANK_ACCOUNT_DOCUMENTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
        status: 400,
        error: action.payload,
      };
    case ActionTypes.UPLOAD_BANK_ACCOUNT_DOCUMENTS_RESET:
      return {
        ...state,
        isLoading: false,
        data: '',
        status: app_consts.IDLE,
        error: null,
      };
    default:
      return state;
  }
};

// Retrieve Specific Entity Profile Reports Reducer
const retrieveSpecificEntityProfileReportsReducer = (
  state = initialretrieveSpecificEntityProfileReportsReducerstate,
  action
) => {
  switch (action.type)
  {
    case ActionTypes.RETRIEVE_SPECIFIC_ENTITY_PROFILE_REPORTS_REQUEST:
      return {
        ...state,
        isLoading: true,
        message: '',
        status: null,
        error: null,
      };
    case ActionTypes.RETRIEVE_SPECIFIC_ENTITY_PROFILE_REPORTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: 200,
        error: null,
      };
    case ActionTypes.RETRIEVE_SPECIFIC_ENTITY_PROFILE_REPORTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
        status: 400,
        error: action.payload,
      };
    case ActionTypes.RETRIEVE_SPECIFIC_ENTITY_PROFILE_REPORTS_RESET:
      return {
        ...state,
        isLoading: false,
        data: '',
        status: app_consts.IDLE,
        error: null,
      };
    default:
      return state;
  }
};

// Upload Warehouse Inspection Image Reducer
const uploadWarehouseInspectionImageReducer = (
  state = initialUploadWarehouseInspectionImageReducerstate,
  action
) => {
  switch (action.type)
  {
    case ActionTypes.UPLOAD_WAREHOUSE_INSPECTION_IMAGE_REQUEST:
      return {
        ...state,
        isLoading: true,
        message: '',
        status: null,
        error: null,
      };
    case ActionTypes.UPLOAD_WAREHOUSE_INSPECTION_IMAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: 200,
        error: null,
      };
    case ActionTypes.UPLOAD_WAREHOUSE_INSPECTION_IMAGE_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
        status: 400,
        error: action.payload,
      };
    case ActionTypes.UPLOAD_WAREHOUSE_INSPECTION_IMAGE_RESET:
      return {
        ...state,
        isLoading: false,
        data: '',
        status: app_consts.IDLE,
        error: null,
      };
    default:
      return state;
  }
};

// process Goods Received Note Reducer
const processGoodsReceivedNoteReducer = (
  state = initialProcessGoodsReceivedNoteReducerstate,
  action
) => {
  switch (action.type)
  {
    case ActionTypes.PROCESS_GOODS_RECEIVED_NOTE_REQUEST:
      return {
        ...state,
        isLoading: true,
        message: '',
        status: null,
        error: null,
      };
    case ActionTypes.PROCESS_GOODS_RECEIVED_NOTE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: 200,
        error: null,
      };
    case ActionTypes.PROCESS_GOODS_RECEIVED_NOTE_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
        status: 400,
        error: action.payload,
      };
    case ActionTypes.PROCESS_GOODS_RECEIVED_NOTE_RESET:
      return {
        ...state,
        isLoading: false,
        data: '',
        status: app_consts.IDLE,
        error: null,
      };
    default:
      return state;
  }
};

// Add GRN To Delivery Reducer
const addGrnToDeliveryReducer = (
  state = initialAddGrnToDeliveryReducerstate,
  action
) => {
  switch (action.type)
  {
    case ActionTypes.ADD_GRN_TO_DELIVERY_REQUEST:
      return {
        ...state,
        isLoading: true,
        message: '',
        status: null,
        error: null,
      };
    case ActionTypes.ADD_GRN_TO_DELIVERY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: 200,
        error: null,
      };
    case ActionTypes.ADD_GRN_TO_DELIVERY_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
        status: 400,
        error: action.payload,
      };
    case ActionTypes.ADD_GRN_TO_DELIVERY_RESET:
      return {
        ...state,
        isLoading: false,
        data: '',
        status: app_consts.IDLE,
        error: null,
      };
    default:
      return state;
  }
};

// Confirm Goods Received Delivery Reducer
const confirmGoodsReceivedDeliveryReducer = (
  state = initialConfirmGoodsReceivedDeliveryReducerstate,
  action
) => {
  switch (action.type)
  {
    case ActionTypes.CONFIRM_GOODS_RECEIVED_DELIVERY_REQUEST:
      return {
        ...state,
        isLoading: true,
        message: '',
        status: null,
        error: null,
      };
    case ActionTypes.CONFIRM_GOODS_RECEIVED_DELIVERY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: 200,
        error: null,
      };
    case ActionTypes.CONFIRM_GOODS_RECEIVED_DELIVERY_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
        status: 400,
        error: action.payload,
      };
    case ActionTypes.CONFIRM_GOODS_RECEIVED_DELIVERY_RESET:
      return {
        ...state,
        isLoading: false,
        data: '',
        status: app_consts.IDLE,
        error: null,
      };
    default:
      return state;
  }
};

// Book Goods Receieved Delivery Into Storage Reducer
const bookGoodsRecievedIntoStorageReducer = (
  state = initialBookGoodsRecievedIntoStorageReducerstate,
  action
) => {
  switch (action.type)
  {
    case ActionTypes.BOOK_GOODS_RECEIVED_DELIVERY_INTO_STORAGE_REQUEST:
      return {
        ...state,
        isLoading: true,
        message: '',
        status: null,
        error: null,
      };
    case ActionTypes.BOOK_GOODS_RECEIVED_DELIVERY_INTO_STORAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: 200,
        error: null,
      };
    case ActionTypes.BOOK_GOODS_RECEIVED_DELIVERY_INTO_STORAGE_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
        status: 400,
        error: action.payload,
      };
    case ActionTypes.BOOK_GOODS_RECEIVED_DELIVERY_INTO_STORAGE_RESET:
      return {
        ...state,
        isLoading: false,
        data: '',
        status: app_consts.IDLE,
        error: null,
      };
    default:
      return state;
  }
};

// Retrieve Warehouse Receipt Depositors Reducer
const retrieveWarehouseReceiptDepositorsReducer = (
  state = initialRetrieveWarehouseReceiptDepositorsReducerstate,
  action
) => {
  switch (action.type)
  {
    case ActionTypes.RETRIEVE_WAREHOUSE_RECEIPT_DEPOSITORS_REQUEST:
      return {
        ...state,
        isLoading: true,
        message: '',
        status: null,
        error: null,
      };
    case ActionTypes.RETRIEVE_WAREHOUSE_RECEIPT_DEPOSITORS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: 200,
        error: null,
      };
    case ActionTypes.RETRIEVE_WAREHOUSE_RECEIPT_DEPOSITORS_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
        status: 400,
        error: action.payload,
      };
    case ActionTypes.RETRIEVE_WAREHOUSE_RECEIPT_DEPOSITORS_RESET:
      return {
        ...state,
        isLoading: false,
        data: '',
        status: app_consts.IDLE,
        error: null,
      };
    default:
      return state;
  }
};

// Retrieve Specific Warehouse Receipt Depositor Reducer
const retrieveSpecificWarehouseReceiptDepositorReducer = (
  state = initialRetrieveSpecificWarehouseReceiptDepositorReducerstate,
  action
) => {
  switch (action.type)
  {
    case ActionTypes.RETRIEVE_SPECIFIC_WAREHOUSE_RECEIPT_DEPOSITOR_REQUEST:
      return {
        ...state,
        isLoading: true,
        message: '',
        status: null,
        error: null,
      };
    case ActionTypes.RETRIEVE_SPECIFIC_WAREHOUSE_RECEIPT_DEPOSITOR_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: 200,
        error: null,
      };
    case ActionTypes.RETRIEVE_SPECIFIC_WAREHOUSE_RECEIPT_DEPOSITOR_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
        status: 400,
        error: action.payload,
      };
    case ActionTypes.RETRIEVE_SPECIFIC_WAREHOUSE_RECEIPT_DEPOSITOR_RESET:
      return {
        ...state,
        isLoading: false,
        data: '',
        status: app_consts.IDLE,
        error: null,
      };
    default:
      return state;
  }
};

// Retrieve Entity Profile Audit Trails Reducer
const retrieveEntityProfileAuditTrailsReducer = (
  state = initialRetrieveEntityProfileAuditTrailsReducerstate,
  action
) => {
  switch (action.type)
  {
    case ActionTypes.RETRIEVE_ENTITY_PROFILE_AUDIT_TRAILS_REQUEST:
      return {
        ...state,
        isLoading: true,
        message: '',
        status: null,
        error: null,
      };
    case ActionTypes.RETRIEVE_ENTITY_PROFILE_AUDIT_TRAILS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: 200,
        error: null,
      };
    case ActionTypes.RETRIEVE_ENTITY_PROFILE_AUDIT_TRAILS_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
        status: 400,
        error: action.payload,
      };
    case ActionTypes.RETRIEVE_ENTITY_PROFILE_AUDIT_TRAILS_RESET:
      return {
        ...state,
        isLoading: false,
        data: '',
        status: app_consts.IDLE,
        error: null,
      };
    default:
      return state;
  }
};


// Retrieve Specific Producer Reducer
const retrieveSpecificProducerReducer = (
  state = initialRetrieveSpecificProducerState,
  action
) => {
  switch (action.type)
  {
    case ActionTypes.RETRIEVE_SEPECIFIC_PRODUCER_REQUEST:
      return {
        ...state,
        isLoading: true,
        message: '',
        status: null,
        error: null,
      };
    case ActionTypes.RETRIEVE_SEPECIFIC_PRODUCER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: 200,
        error: null,
      };
    case ActionTypes.RETRIEVE_SEPECIFIC_PRODUCER_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
        status: 400,
        error: action.payload,
      };
    case ActionTypes.RETRIEVE_SEPECIFIC_PRODUCER_RESET:
      return {
        ...state,
        isLoading: false,
        data: '',
        status: app_consts.IDLE,
        error: null,
      };
    default:
      return state;
  }
};


// Assign Broker Producer Reducer
const assignBrokerToProducerReducer = (
  state = initialAssignBrokerToProducerState,
  action
) => {
  switch (action.type)
  {
    case ActionTypes.ASSIGN_BROKER_TO_PRODUCER_REQUEST:
      return {
        ...state,
        isLoading: true,
        message: '',
        status: null,
        error: null,
      };
    case ActionTypes.ASSIGN_BROKER_TO_PRODUCER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: 200,
        error: null,
      };
    case ActionTypes.ASSIGN_BROKER_TO_PRODUCER_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
        status: 400,
        error: action.payload,
      };
    case ActionTypes.ASSIGN_BROKER_TO_PRODUCER_RESET:
      return {
        ...state,
        isLoading: false,
        data: '',
        status: app_consts.IDLE,
        error: null,
      };
    default:
      return state;
  }
};

// Retrieve Entity List Data Reducer
const retrieveEntityListDataRedcuer = (
  state = initialRetrieveEntityListDataState,
  action
) => {
  switch (action.type)
  {
    case ActionTypes.RETRIEVE_ENTITY_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
        message: '',
        status: null,
        error: null,
      };
    case ActionTypes.RETRIEVE_ENTITY_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: 200,
        error: null,
      };
    case ActionTypes.RETRIEVE_ENTITY_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
        status: 400,
        error: action.payload,
      };
    case ActionTypes.RETRIEVE_ENTITY_LIST_RESET:
      return {
        ...state,
        isLoading: false,
        data: '',
        status: app_consts.IDLE,
        error: null,
      };
    default:
      return state;
  }
};

// Retrieve Entity Detailsa Reducer
const retrieveEntityDetailsReducer = (
  state = initialRetrieveEntityDetailsState,
  action
) => {
  switch (action.type)
  {
    case ActionTypes.RETRIEVE_ENTITY_DETAILS_REQUEST:
      return {
        ...state,
        isLoading: true,
        message: '',
        status: null,
        error: null,
      };
    case ActionTypes.RETRIEVE_ENTITY_DETAILS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: 200,
        error: null,
      };
    case ActionTypes.RETRIEVE_ENTITY_DETAILS_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
        status: 400,
        error: action.payload,
      };
    case ActionTypes.RETRIEVE_ENTITY_DETAILS_RESET:
      return {
        ...state,
        isLoading: false,
        data: '',
        status: app_consts.IDLE,
        error: null,
      };
    default:
      return state;
  }
};

// Verify Membership Reducer
const verifyMembershipReducer = (
  state = initialVerifyMembershipState,
  action
) => {
  switch (action.type)
  {
    case ActionTypes.VERIFY_MEMBERSHIP_REQUEST:
      return {
        ...state,
        isLoading: true,
        message: '',
        status: null,
        error: null,
      };
    case ActionTypes.VERIFY_MEMBERSHIP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: 200,
        error: null,
      };
    case ActionTypes.VERIFY_MEMBERSHIP_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
        status: 400,
        error: action.payload,
      };
    case ActionTypes.VERIFY_MEMBERSHIP_RESET:
      return {
        ...state,
        isLoading: false,
        data: '',
        status: app_consts.IDLE,
        error: null,
      };
    default:
      return state;
  }
};

// Update Membership Reducer
const updateMembershipReducer = (
  state = initialUpdateMembershipState,
  action
) => {
  switch (action.type)
  {
    case ActionTypes.UPDATE_MEMBERSHIP_REQUEST:
      return {
        ...state,
        isLoading: true,
        message: '',
        status: null,
        error: null,
      };
    case ActionTypes.UPDATE_MEMBERSHIP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: 200,
        error: null,
      };
    case ActionTypes.UPDATE_MEMBERSHIP_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
        status: 400,
        error: action.payload,
      };
    case ActionTypes.UPDATE_MEMBERSHIP_RESET:
      return {
        ...state,
        isLoading: false,
        data: '',
        status: app_consts.IDLE,
        error: null,
      };
    default:
      return state;
  }
};

// Update Document Reducer
const updateDocumentReducer = (
  state = initialUpdateDocumentState,
  action
) => {
  switch (action.type)
  {
    case ActionTypes.UPLOAD_DOCUMENT_REQUEST:
      return {
        ...state,
        isLoading: true,
        message: '',
        status: null,
        error: null,
      };
    case ActionTypes.UPLOAD_DOCUMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: 200,
        error: null,
      };
    case ActionTypes.UPLOAD_DOCUMENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
        status: 400,
        error: action.payload,
      };
    case ActionTypes.UPLOAD_DOCUMENT_RESET:
      return {
        ...state,
        isLoading: false,
        data: '',
        status: app_consts.IDLE,
        error: null,
      };
    default:
      return state;
  }
};

// Retrieve Data Analytics Reducer
const retriveDataAnalyticsOverviewReducer = (
  state = initialRetriveDataAnalyticsState,
  action
) => {
  switch (action.type)
  {
    case ActionTypes.RETRIEVE_DATA_ANALYTICS_OVERVIEW_REQUEST:
      return {
        ...state,
        isLoading: true,
        message: '',
        status: null,
        error: null,
      };
    case ActionTypes.RETRIEVE_DATA_ANALYTICS_OVERVIEW_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: 200,
        error: null,
      };
    case ActionTypes.RETRIEVE_DATA_ANALYTICS_OVERVIEW_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
        status: 400,
        error: action.payload,
      };
    case ActionTypes.RETRIEVE_DATA_ANALYTICS_OVERVIEW_RESET:
      return {
        ...state,
        isLoading: false,
        data: '',
        status: app_consts.IDLE,
        error: null,
      };
    default:
      return state;
  }
};

// Retrieve Ats Data Reducer
const retrieveAtsListReducer = (
  state = initialRetriveAtsListState,
  action
) => {
  switch (action.type)
  {
    case ActionTypes.RETRIEVE_ATS_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
        message: '',
        status: null,
        error: null,
      };
    case ActionTypes.RETRIEVE_ATS_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: 200,
        error: null,
      };
    case ActionTypes.RETRIEVE_ATS_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
        status: 400,
        error: action.payload,
      };
    case ActionTypes.RETRIEVE_ATS_LIST_RESET:
      return {
        ...state,
        isLoading: false,
        data: '',
        status: app_consts.IDLE,
        error: null,
      };
    default:
      return state;
  }
};

// Retrieve Ats Data Reducer
const retrieveAtsAnalyticsReducer = (
  state = initialRetriveAtsAnalyticsState,
  action
) => {
  switch (action.type)
  {
    case ActionTypes.RETRIEVE_ATS_ANALYTICS_REQUEST:
      return {
        ...state,
        isLoading: true,
        message: '',
        status: null,
        error: null,
      };
    case ActionTypes.RETRIEVE_ATS_ANALYTICS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: 200,
        error: null,
      };
    case ActionTypes.RETRIEVE_ATS_ANALYTICS_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
        status: 400,
        error: action.payload,
      };
    case ActionTypes.RETRIEVE_ATS_ANALYTICS_RESET:
      return {
        ...state,
        isLoading: false,
        data: '',
        status: app_consts.IDLE,
        error: null,
      };
    default:
      return state;
  }
};

// Retrieve Producer Analytics Reducer
const retrieveProducerAnalyticsReducer = (
  state = retrieveProducerAnalyticsState,
  action
) => {
  switch (action.type)
  {
    case ActionTypes.RETRIEVE_PRODUCER_ANALYTICS_REQUEST:
      return {
        ...state,
        isLoading: true,
        message: '',
        status: null,
        error: null,
      };
    case ActionTypes.RETRIEVE_PRODUCER_ANALYTICS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: 200,
        error: null,
      };
    case ActionTypes.RETRIEVE_PRODUCER_ANALYTICS_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
        status: 400,
        error: action.payload,
      };
    case ActionTypes.RETRIEVE_PRODUCER_ANALYTICS_RESET:
      return {
        ...state,
        isLoading: false,
        data: '',
        status: app_consts.IDLE,
        error: null,
      };
    default:
      return state;
  }
};

// Create Bulk Entity Reducer
const bulkEntityCreationReducer = (
  state = bulkEntityCreationState,
  action
) => {
  switch (action.type)
  {
    case ActionTypes.BULK_ENTITY_CREATION_REQUEST:
      return {
        ...state,
        isLoading: true,
        message: '',
        status: null,
        error: null,
      };
    case ActionTypes.BULK_ENTITY_CREATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        status: 200,
        error: null,
      };
    case ActionTypes.BULK_ENTITY_CREATION_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
        status: 400,
        error: action.payload,
      };
    case ActionTypes.BULK_ENTITY_CREATION_RESET:
      return {
        ...state,
        isLoading: false,
        data: '',
        status: app_consts.IDLE,
        error: null,
      };
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  auth: authReducer,
  verification: verificationReducer,
  profile: profileReducer,
  entity: entityReducer,
  entityList: entityListReducer,
  getEntity: getEntityReducer,
  getEntityProfile: getEntityProfileReducer,
  directorList: directorListReducer,
  bankAccount: bankAccountReducer,
  submitRegistration: submitRegistrationReducer,
  entityProfilesList: entityProfilesListReducer,
  registerNewMember: registerNewMemberReducer,
  checkRegistration: checkRegistrationReducer,
  addWarehouse: addWarehouseReducer,
  createEntityProfile: createEntityProfileReducer,
  warehouseList: warehouseListReducer,
  specificWarehouse: specificWarehouseReducer,
  sendResetPasswordOtp: sendResetPasswordOtpReducer,
  verifyResetPasswordOtp: verifyResetPasswordOtpReducer,
  resetPassword: resetPasswordReducer,
  createEntityExistingUser: createEntityExistingUserReducer,
  producerList: producerListReducer,
  cooperativeMemberList: cooperativeMemberListReducer,
  getProducer: getProducerReducer,
  activateMembershipType: activateMembershipTypeReducer,
  brokerOfferList: brokerOfferListReducer,

  // brokerBidList: brokerBidListReducer,

  brokerBidListByBrokerId: brokerBidListByBrokerIdReducer,

  brokerBidListByCommodityOrderId: brokerBidListByCommodityOrderIdReducer,

  getBrokerOffer: getBrokerOfferReducer,
  receiptNumberList: receiptNumberListReducer,
  specificEntityProfileList: specificEntityProfileListReducer,
  createDeliveryNote: createDeliveryNoteReducer,
  createCommodityGrading: createCommodityGradingReducer,
  createStorageInventory: createStorageInventoryReducer,
  updateWarehouseReceiptSettlement: updateWarehouseReceiptSettlementReducer,
  generateWarehouseReceipt: generateWarehouseReceiptReducer,
  retrieveEntityWarehouseReceiptList: retrieveEntityWarehouseReceiptListReducer,
  retrieveAllProducersList: retrieveAllProducersListReducer,
  createPendingEntity: createPendingEntityReducer,
  retrievePendingEntityList: retrievePendingEntityListReducer,
  retrieveInboundShipmentList: retrieveInboundShipmentListReducer,
  retrieveSpecificInboundShipment: retrieveSpecificInboundShipmentReducer,
  offerList: offerListReducer,
  offerListStatusFiltered: offerListStatusFilteredReducer,
  getOfferDetails: getOfferDetailsReducer,
  retrieveWarehouseStorageList: retrieveWarehouseStorageListReducer,
  retrieveSpecificWarehouseReceipt: retrieveSpecificWarehouseReceiptReducer,
  continueProcessingWarehouseReceipt: continueProcessingWarehouseReceiptReducer,
  updateWarehouseStatus: updateWarehouseStatusReducer,
  updateBrokerTradingAuthority: updateBrokerTradingAuthorityReducer,
  getBrokerList: getBrokerListReducer,
  getProducersAndWarehouseReceiptsList:
    getProducersAndWarehouseReceiptsForBrokerReducer,
  retrieveIssuedWarehouseReceiptsList:
    retrieveIssuedWarehouseReceiptsListReducer,
  amendWarehouseReceipt: amendWarehouseReceiptReducer,
  getUserAccountList: getUserAccountListReducer,
  getUserAccountDetails: getUserAccountDetailsReducer,
  retrieveSpecificBrokerOffer: retrieveSpecificBrokerOfferReducer,
  acceptBidOnCommodityOffer: acceptBidOnCommodityOrderReducer,
  retrieveTradeOfferDetails: retrieveTradeOfferDetailsReducer,
  retrieveBrokerBidsByBrokerId: retrieveBrokerBidsByBrokerIdReducer,
  retreiveSpecificBrokerBidDetails: retreiveSpecificBrokerBidDetailsReducer,
  uploadTradeProofOfPayment: uploadTradeProofOfPaymentReducer,
  retrieveBrokerProducer: retrieveBrokerProducerReducer,
  approveTradePayment: approveTradePaymentReducer,
  uploadSignedTradeContract: uploadSignedCTradeContractReducer,
  confirmTradeByContractNumber: confirmTradeByContractNumberReducer,
  retrieveTradeFulfillmentList: retrieveTradeFulfillmentListReducer,
  retrieveSpecificTradeFulfillment: retrieveSpecificTradeFulfillmentReducer,
  retrieveProvisionReceiptsList: retrieveProvisionReceiptsListReducer,
  uploadWarehouseInspectionCertificate: uploadWarehouseInspectionCertificateReducer,
  retrieveSpecificWarehouseStorageTypesAndCapacitiesViaDeliveryId: retrieveSpecificWarehouseStorageTypesAndCapacitiesViaDeliveryIdReducer,
  retrieveValidSuperAndSubOffersBids: retrieveValidSuperAndSubOffersBidsReducer,
  retrieveBrokerSuperBidsByBrokerId: retrieveBrokerSuperBidsByBrokerIdReducer,
  listAllProducersWithEntities: listAllProducersWithEntitiesReducer,
  retrieveEntityProfileInfo: retrieveEntityProfileInfoReducer,
  retrieveEntityUserProfile: retrieveEntityUserProfileReducer,
  amendProfileRole: amendProfileRoleReducer,
  retrieveSpecificWarehouseStorageType: retrieveSpecificWarehouseStorageTypeReducer,
  processBulkDelivery: processBulkDeliveryReducer,
  toggleViewWarehouseOnWebsite: toggleViewWarehouseOnWebsiteReducer,
  updateBankAccountDocuments: updateBankAccountDocumentsReducer,
  retrieveSpecificEntityProfileReports: retrieveSpecificEntityProfileReportsReducer,
  uploadWarehouseInspectionImage: uploadWarehouseInspectionImageReducer,
  processGoodsReceivedNote: processGoodsReceivedNoteReducer,
  addGrnToDelivery: addGrnToDeliveryReducer,
  confirmGoodsReceivedDelivery: confirmGoodsReceivedDeliveryReducer,
  bookGoodsRecievedIntoStorage: bookGoodsRecievedIntoStorageReducer,
  retrieveWarehouseReceiptDepositors: retrieveWarehouseReceiptDepositorsReducer,
  retrieveSpecificWarehouseReceiptDepositor: retrieveSpecificWarehouseReceiptDepositorReducer,
  retrieveEntityProfileAuditTrails: retrieveEntityProfileAuditTrailsReducer,
  retrieveSpecificProducer: retrieveSpecificProducerReducer,
  assignBrokerToProducer: assignBrokerToProducerReducer,
  retrieveEntityListData: retrieveEntityListDataRedcuer,
  retriveEntityDetails: retrieveEntityDetailsReducer,
  verifyMembership: verifyMembershipReducer,
  updateMembership: updateMembershipReducer,
  updateDocument: updateDocumentReducer,
  retriveDataAnalyticsOverview: retriveDataAnalyticsOverviewReducer,
  retrieveAtsList: retrieveAtsListReducer,
  retrieveAtsAnalytics: retrieveAtsAnalyticsReducer,
  retrieveProducerAnalytics: retrieveProducerAnalyticsReducer,
  bulkEntityCreation: bulkEntityCreationReducer
});

export default rootReducer;