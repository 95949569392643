import { Document, Font, PDFViewer, Page, Text, View } from '@react-pdf/renderer';
import React, { useEffect, useState } from 'react';
import PDFSVGLogo from '../../components/PDFSVGLogo';
// Import the font files
import LatoRegular from '../../utils/fonts/Lato-Regular.ttf';
import LatoBold from '../../utils/fonts/Lato-Bold.ttf';
import { format } from 'date-fns';

const WarehouseReceiptTradingContract = ({ data }) => {

    const [contract_data, setContractData] = useState(data);

    //
    useEffect(() => {
        setContractData(data);
    }, [data]);
    // Register the Lato font
    Font.register({ family: 'Lato', fonts: [{ src: LatoRegular }, { src: LatoBold, fontWeight: 'bold' }] });

    return (
        <Document title="Warehouse Receipt Trading Contract">
            <Page size="A4" style={{
                flexDirection: "column",
                backgroundColor: "rgba(0,0,0,0)",
                height: "100%",
                fontFamily: 'Lato',
                paddingLeft: 35,
                paddingRight: 35,
                paddingTop: 10,
                paddingBottom: 15,
            }}>
                <View fixed style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", position: "relative", marginBottom: 15 }}>
                    <PDFSVGLogo height="80px" width="300px" />
                    <Text style={{ fontWeight: 600 }}>Zambian <Text style={{ color: "green" }}>Commodity</Text> Exchange</Text>
                </View>
                <Text style={{ textAlign: "center", fontWeight: 700, fontSize: 18, textDecoration: "underline" }}>ZAMACE LIMITED ("ZAMBIAN COMMODITY EXCHANGE") WAREHOUSE RECEIPT TRADING CONTRACT</Text>
                <View style={{ marginTop: 10 }}>

                    <View style={{ display: "flex", flexDirection: "row", width: "100%", fontSize: 14, alignItems: "center" }}>
                        <View style={{ display: "flex", flexDirection: "row", width: "50%", marginTop: 5 }}>
                            <Text style={{ fontWeight: 500, }}>Contract Date:</Text>
                            <Text style={{ marginLeft: 3, fontWeight: 600, }}>{contract_data?.contract?.contract_date ? format(new Date(contract_data?.contract?.contract_date), 'yyyy-MM-dd') : "---"}</Text>
                        </View>
                        <View style={{ display: "flex", flexDirection: "row", width: "50%", marginTop: 5 }}>
                            <Text style={{ fontWeight: 500, }}>Contract Ref:</Text>
                            <Text style={{ marginLeft: 3, fontWeight: 600, }}>{contract_data?.contract?.contract_reference_no ? contract_data?.contract?.contract_reference_no : "---"}</Text>
                        </View>
                    </View>

                    <View style={{ display: "flex", flexDirection: "column", width: "100%", fontSize: 14, marginTop: 10 }}>
                        <View style={{ display: "flex", flexDirection: "row", marginTop: 5 }}>
                            <Text style={{ fontWeight: 500, }}>Seller:</Text>
                            <Text style={{ marginLeft: 3, fontWeight: 600, }}>{contract_data?.seller?.entity_name ? contract_data?.seller?.entity_name : "---"}</Text>
                        </View>
                    </View>
                    {/* Rules */}
                    <View style={{ marginTop: 5, paddingLeft: 15, paddingRight: 15, paddingTop: 15, }}>
                        <View style={{ display: "flex", flexDirection: "row", fontSize: 14 }}>
                            <Text style={{ marginRight: 2 }}>A.</Text>
                            <Text style={{ marginLeft: 2 }}>
                                These conditions of sale of ZAMACE, read together with the Agricultural Credits Act of 2010, shall be known as the ZAMACE General Conditions of Sale (the "General Conditions") and shall govern all sales / Conveyance of Warehouse Receipts issued by Certified ZAMACE Warehouse Operators.
                            </Text>
                        </View>
                        <View style={{ display: "flex", flexDirection: "row", fontSize: 14, marginTop: 10 }}>
                            <Text style={{ marginRight: 2 }}>B.</Text>
                            <Text style={{ marginLeft: 2 }}>
                                The terms of this contract will always be subject to the legislation from time to time, in force governing conveyance of Warehouse Receipts in Zambia.
                            </Text>
                        </View>
                        <View style={{ display: "flex", flexDirection: "row", fontSize: 14, marginTop: 10 }}>
                            <Text style={{ marginRight: 2 }}>C.</Text>
                            <Text style={{ marginLeft: 2 }}>
                                In this Agreement words and expressions shall have the meanings assigned to them in the INCOTERMS 2000, as published by the International Chamber of Commerce, Paris except where the context requires otherwise and subject to the context, the plural shall include the singular and the singular the plural and the masculine shall include the feminine and vice versa.
                            </Text>
                        </View>
                    </View>
                    <View style={{ marginTop: 10 }}>
                        <View style={{ fontWeight: 600, display: "flex", flexDirection: "row", fontSize: 14 }}>
                            <Text style={{ marginRight: 2 }}>1.</Text>
                            <Text style={{ marginLeft: 2 }}>SELLER</Text>
                        </View>
                        <Text style={{ paddingLeft: 15, paddingRight: 15, paddingTop: 5, fontSize: 14 }}> (In own right or acting for holder of the Warehouse Receipt), and BUYER being the only two parties who have any rights whatsoever under this contract have this day entered into a contract whereby the BUYER agrees to buy and the SELLER agrees to sell commodities not necessarily being the SELLER'S own produce, as stipulated on the Warehouse Receipt as follows:
                        </Text>
                        <View style={{ padding: 15, fontSize: 14 }}>
                            <View style={{ display: "flex", flexDirection: "row" }}>
                                <Text style={{ marginRight: 2 }}>1.1</Text>
                                <View style={{ display: "flex", flexDirection: "row" }}>
                                    <Text style={{ marginLeft: 2, width: "50%", }}>Warehouse Receipt Number	:</Text>
                                    <Text style={{ marginLeft: 2, width: "50%", fontWeight: 600, }}>{contract_data?.warehouse_receipt?.receipt_number ? contract_data?.warehouse_receipt?.receipt_number : "---"}</Text>
                                </View>
                            </View>
                            <View style={{ display: "flex", flexDirection: "row", marginTop: 10 }}>
                                <Text style={{ marginRight: 2 }}>1.2</Text>
                                <View style={{ display: "flex", flexDirection: "row" }}>
                                    <Text style={{ marginLeft: 2, width: "50%", }}>Warehouse Operator	:</Text>
                                    <Text style={{ marginLeft: 2, width: "50%", fontWeight: 600, }}>{contract_data?.warehouse_receipt?.entity_name ? contract_data?.warehouse_receipt?.entity_name : "---"}</Text>
                                </View>
                            </View>
                            <View style={{ display: "flex", flexDirection: "row", marginTop: 10 }}>
                                <Text style={{ marginRight: 2 }}>1.3</Text>
                                <View style={{ display: "flex", flexDirection: "row" }}>
                                    <Text style={{ marginLeft: 2, width: "50%", }}>Warehouse Site	:</Text>
                                    <Text style={{ marginLeft: 2, width: "50%", fontWeight: 600, }}>{contract_data?.warehouse_receipt?.name ? contract_data?.warehouse_receipt?.name : "---"}</Text>
                                </View>
                            </View>
                            <View style={{ display: "flex", flexDirection: "row", marginTop: 10 }}>
                                <Text style={{ marginRight: 2 }}>1.4</Text>
                                <View style={{ display: "flex", flexDirection: "row" }}>
                                    <Text style={{ marginLeft: 2, width: "50%", }}>Commodity	:</Text>
                                    <Text style={{ marginLeft: 2, width: "50%", textTransform: "uppercase", fontWeight: 600, }}>{contract_data?.warehouse_receipt?.commodity ? contract_data?.warehouse_receipt?.commodity : "---"}</Text>
                                </View>
                            </View>
                            <View style={{ display: "flex", flexDirection: "row", marginTop: 10 }}>
                                <Text style={{ marginRight: 2 }}>1.5</Text>
                                <View style={{ display: "flex", flexDirection: "row" }}>
                                    <Text style={{ marginLeft: 2, width: "50%", }}>Quality	:</Text>
                                    <Text style={{ marginLeft: 2, width: "50%", fontWeight: 600, }}>{contract_data?.broker_offer_data?.quality ? contract_data?.broker_offer_data?.quality : "---"}</Text>
                                </View>
                            </View>
                            <View style={{ display: "flex", flexDirection: "row", marginTop: 10 }}>
                                <Text style={{ marginRight: 2 }}>1.6</Text>
                                <View style={{ display: "flex", flexDirection: "row" }}>
                                    <Text style={{ marginLeft: 2, width: "50%", }}>Quantity	:</Text>
                                    <Text style={{ marginLeft: 2, width: "50%", fontWeight: 600, }}>{contract_data?.warehouse_receipt?.quantity ? `${parseFloat(contract_data?.warehouse_receipt?.quantity).toFixed(2)} MT` : "---"}</Text>
                                </View>
                            </View>
                        </View>
                        <View break style={{ marginTop: 10 }}>
                            <View style={{ fontWeight: 600, display: "flex", flexDirection: "row", fontSize: 14 }}>
                                <Text style={{ marginRight: 2 }}>2.</Text>
                                <Text style={{ marginLeft: 2 }}>Charges</Text>
                            </View>
                            <Text style={{ paddingLeft: 15, paddingRight: 15, paddingTop: 15, fontSize: 14 }}>Charges shown upon the face of the Warehouse receipt are to be paid as follows:
                            </Text>
                            <View style={{ padding: 15, fontSize: 14 }}>
                                <View style={{ display: "flex", flexDirection: "row" }}>
                                    <Text style={{ marginRight: 2 }}>2.1</Text>
                                    <View style={{ display: "flex", flexDirection: "row", width: "100%", alignItems: "center", marginLeft: 2 }}>
                                        <View style={{ width: "50%" }}>
                                            <Text>Warehouse Receipt Issuance</Text>
                                        </View>
                                        <View style={{ width: "50%", textAlign: "center" }}>
                                            <Text>Account Seller</Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={{ display: "flex", flexDirection: "row" }}>
                                    <Text style={{ marginRight: 2 }}>2.2</Text>
                                    <View style={{ display: "flex", flexDirection: "row", width: "100%", alignItems: "center", marginLeft: 2 }}>
                                        <View style={{ width: "50%" }}>
                                            <Text>Handling In Charge</Text>
                                        </View>
                                        <View style={{ width: "50%", textAlign: "center" }}>
                                            <Text>Account Seller</Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={{ display: "flex", flexDirection: "row" }}>
                                    <Text style={{ marginRight: 2 }}>2.3</Text>
                                    <View style={{ display: "flex", flexDirection: "row", width: "100%", alignItems: "center", marginLeft: 2 }}>
                                        <View style={{ width: "50%" }}>
                                            <Text>Fumigation and Initial Conditioning costs	  </Text>
                                        </View>
                                        <View style={{ width: "50%", textAlign: "center" }}>
                                            <Text>Account Seller</Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={{ display: "flex", flexDirection: "row" }}>
                                    <Text style={{ marginRight: 2 }}>2.4</Text>
                                    <View style={{ display: "flex", flexDirection: "row", width: "100%", alignItems: "center", marginLeft: 2 }}>
                                        <View style={{ width: "50%" }}>
                                            <Text>Storage Fees</Text>
                                        </View>
                                        <View style={{ width: "50%", textAlign: "center" }}>
                                            <Text>Account Seller</Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={{ display: "flex", flexDirection: "row" }}>
                                    <Text style={{ marginRight: 2 }}>2.5</Text>
                                    <View style={{ display: "flex", flexDirection: "row", width: "100%", alignItems: "center", marginLeft: 2 }}>
                                        <View style={{ width: "50%" }}>
                                            <Text>Handling Out</Text>
                                        </View>
                                        <View style={{ width: "50%", textAlign: "center" }}>
                                            <Text>Account Buyer</Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={{ display: "flex", flexDirection: "row" }}>
                                    <Text style={{ marginRight: 2 }}>2.6</Text>
                                    <View style={{ display: "flex", flexDirection: "row", width: "100%", alignItems: "center", marginLeft: 2 }}>
                                        <View style={{ width: "50%" }}>
                                            <Text>Change of Warehouse Receipt Ownership	</Text>
                                        </View>
                                        <View style={{ width: "50%", textAlign: "center" }}>
                                            <Text>Account Buyer</Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                            <View style={{ marginTop: 10 }}>
                                <View style={{ fontWeight: 600, display: "flex", flexDirection: "row", fontSize: 14 }}>
                                    <Text style={{ marginRight: 2 }}>3.</Text>
                                    <Text style={{ marginLeft: 2 }}>Settlement</Text>
                                </View>
                            </View>
                            <View style={{ padding: 15, fontSize: 14 }}>
                                <Text style={{ marginTop: 5 }}>The buyer and Seller agree to be bound by the ZAMACE Bank Settlement system and undertake to open Bank Accounts in the designated ZAMACE Settlement Bank. They further undertake to settle this transaction through the settlement system. Buyer and Seller acknowledge that transfer of ownership of the Warehouse Receipt will be authorized by ZAMACE upon settlement within the stipulated period i.e. Twenty-Four Hours. It is further agreed that communication between Seller, Buyer and the Exchange is per the Spot trade, Tele-trade phone number supplied by respective Broker/Seller/Buyer.</Text>
                                <Text style={{ marginTop: 5 }}>The Seller understands that the buyer will deposit the contract value into their Settlement Account held in the ZAMACE Settlement Bank and that ZAMACE will settle all outstanding liens, such as fees incidental to storage, finance and finance costs, before transferring the balance to the seller.</Text>
                            </View>
                            <View style={{ marginTop: 10 }}>
                                <View style={{ fontWeight: 600, display: "flex", flexDirection: "row", fontSize: 14 }}>
                                    <Text style={{ marginRight: 2 }}>4.</Text>
                                    <Text style={{ marginLeft: 2 }}>Quality, Quality and Location</Text>
                                </View>
                            </View>
                            <View style={{ padding: 15, fontSize: 14 }}>
                                <Text style={{ marginTop: 5 }}>On the date of transfer of ownership of the Warehouse Receipt, the Buyer immediately takes ownership of the commodity as indicated on the Warehouse Receipt in terms of Quality, Quantity and Location.</Text>
                            </View>
                            <View style={{ marginTop: 10 }} break>
                                <View style={{ fontWeight: 600, display: "flex", flexDirection: "row", fontSize: 14 }}>
                                    <Text style={{ marginRight: 2 }}>5.</Text>
                                    <Text style={{ marginLeft: 2 }}>Delivery</Text>
                                </View>
                            </View>
                            <View style={{ padding: 15, fontSize: 14 }}>
                                <Text style={{ marginTop: 10 }}>If need be, Buyer will take delivery of the commodity following transfer of ownership of the Warehouse Receipt to the Buyer by the Seller. No goods covered by this receipt shall be released or ownership transferred, except upon confirmation of change of ownership of the Receipt or removal of encumbrance in the Silo Certs System.</Text>
                                <Text style={{ marginTop: 10 }}>The ZAMACE Silo Certs system has an “electronic handshake” providing for acceptance of a transferred Warehouse Receipt within 72 hours. Buyer understands that in the event of failure to accept the transfer within the stipulated time, this will result in ownership reverting to the seller.</Text>
                                <Text style={{ marginTop: 10 }}>If need be, Buyer will take delivery of the commodity following transfer of ownership of the Warehouse Receipt to the Buyer by the Seller. No goods covered by this receipt shall be released or ownership transferred, except upon confirmation of change of ownership of the Receipt or removal of encumbrance in the Silo Certs System.</Text>
                                <Text style={{ marginTop: 10 }}>The Quantity or Mass as indicated on the Warehouse Receipt and out-loaded by the Warehouse Operator shall be final and shall be accepted by both Seller and Buyer as binding.</Text>
                            </View>
                            <View style={{ marginTop: 10 }} >
                                <View style={{ fontWeight: 600, display: "flex", flexDirection: "row", fontSize: 14 }}>
                                    <Text style={{ marginRight: 2 }}>6.</Text>
                                    <Text style={{ marginLeft: 2 }}>Price and Payment</Text>
                                </View>
                            </View>
                            <View style={{ padding: 15, fontSize: 14 }}>
                                <View style={{ display: "flex", flexDirection: "row", marginTop: 10 }}>
                                    <Text style={{ marginRight: 2 }}>6.1</Text>
                                    <View style={{ display: "flex", flexDirection: "row" }}>
                                        <Text style={{ marginLeft: 2 }}>The contract Price is <Text style={{ marginLeft: 2, textDecoration: "underline", fontWeight: 600 }}>{contract_data?.broker_bid_data?.bid_volume ? `${parseFloat(contract_data?.broker_bid_data?.bid_volume).toFixed(2)} ZMW` : "---"}</Text> per Tonne in respect of ZAMACE Warehouse Receipt Number : <Text style={{ marginLeft: 2, textDecoration: "underline", fontWeight: 600 }}>{contract_data?.warehouse_receipt?.receipt_number ? contract_data?.warehouse_receipt?.receipt_number : "---"}</Text> </Text>
                                    </View>
                                </View>
                                <View style={{ display: "flex", flexDirection: "row", marginTop: 10 }}>
                                    <Text style={{ marginRight: 2 }}>6.2</Text>
                                    <View style={{ display: "flex", flexDirection: "row" }}>
                                        <Text style={{ marginLeft: 2 }}>Total consideration for this transaction is thus: <Text style={{ marginLeft: 2, textDecoration: "underline", fontWeight: 600 }}>{contract_data?.broker_bid_data?.bid_total ? `${parseFloat(contract_data?.broker_bid_data?.bid_total).toFixed(2)} ZMW` : "---"}</Text></Text>
                                    </View>
                                </View>
                                <View style={{ display: "flex", flexDirection: "row", marginTop: 10 }}>
                                    <Text style={{ marginRight: 2 }}>6.3</Text>
                                    <View style={{ display: "flex", flexDirection: "row" }}>
                                        <Text style={{ marginLeft: 2 }}>Lodgement fee for placement of position on the ZAMACE Platform is K100 per tone transacted on this receipt thus:  <Text style={{ marginLeft: 2, textDecoration: "underline", fontWeight: 600 }}>{contract_data?.broker_bid_data?.bid_volume ? `${parseFloat(parseFloat(contract_data?.broker_bid_data?.bid_volume) * 100).toFixed(2)} ZMW` : "---"}</Text></Text>
                                    </View>
                                </View>
                            </View>
                            <View style={{ marginTop: 10 }} break >
                                <View style={{ fontWeight: 600, display: "flex", flexDirection: "row", fontSize: 14 }}>
                                    <Text style={{ marginRight: 2 }}>7.</Text>
                                    <Text style={{ marginLeft: 2 }}>Force Majeure</Text>
                                </View>
                            </View>
                            <View style={{ padding: 15, fontSize: 14 }}>
                                <Text style={{ marginTop: 5 }}>INeither the BUYER nor the SELLER shall be responsible for delay in despatch or delivery of the underlying commodity or any part thereof occasioned by any Act of God, action by any government, strike, lockout, combination of workmen, break-down of machinery, power failure or fire, provided that the party invoking this clause immediately despatches written notice to the other party of the occurrence and supplies, if so requested by the other, satisfactory evidence justifying the delay or non-fulfilment.</Text>
                            </View>
                            <View style={{ marginTop: 10 }} >
                                <View style={{ fontWeight: 600, display: "flex", flexDirection: "row", fontSize: 14 }}>
                                    <Text style={{ marginRight: 2 }}>8.</Text>
                                    <Text style={{ marginLeft: 2 }}>Notices</Text>
                                </View>
                            </View>
                            <View style={{ padding: 15, fontSize: 14 }}>
                                <View style={{ display: "flex", flexDirection: "row", marginTop: 10 }}>
                                    <Text style={{ marginRight: 2 }}>8.1</Text>
                                    <View style={{ display: "flex", flexDirection: "row" }}>
                                        <Text style={{ marginLeft: 2 }}>Any notice in respect of this contract shall be communicated in the shortest possible time and in a legible form.</Text>
                                    </View>
                                </View>
                                <View style={{ display: "flex", flexDirection: "row", marginTop: 10 }}>
                                    <Text style={{ marginRight: 2 }}>8.2</Text>
                                    <View style={{ display: "flex", flexDirection: "row" }}>
                                        <Text style={{ marginLeft: 2 }}>Notices received after 12:00 on a Business Day shall be deemed to have been received on the Business Day following. All such notices shall be given by letter (to be delivered on the day of writing), fax or e-mail. Proof of sending shall be deemed to be proof of receipt.</Text>
                                    </View>
                                </View>
                            </View>
                            <View style={{ marginTop: 10 }} >
                                <View style={{ fontWeight: 600, display: "flex", flexDirection: "row", fontSize: 14 }}>
                                    <Text style={{ marginRight: 2 }}>9.</Text>
                                    <Text style={{ marginLeft: 2 }}>Business Day/Non-Business Day</Text>
                                </View>
                            </View>
                            <View style={{ padding: 15, fontSize: 14 }}>
                                <Text style={{ marginTop: 5 }}>A business day, for Trading purposes is the period between 09:00 and 12:00 inclusive on any day other than a non- business day. Saturdays, Sundays and officially recognized national holidays applicable throughout the Zambia and any days which may from time to time be declared as non-business days for specific purposes shall be deemed non-business days for the purpose of passing of notices.</Text>
                            </View>

                            <View style={{ marginTop: 10 }} >
                                <View style={{ fontWeight: 600, display: "flex", flexDirection: "row", fontSize: 14 }}>
                                    <Text style={{ marginRight: 2 }}>10.</Text>
                                    <Text style={{ marginLeft: 2 }}>Domicile: SELLER and BUYER agree that, for the purposes of any proceedings</Text>
                                </View>
                            </View>
                            <View style={{ padding: 15, fontSize: 14 }}>
                                <Text style={{ marginTop: 5 }}>This contract shall be deemed to have been made in Zambia and to be performed there, any correspondence in reference to the offer, the acceptance, the place of payment, or otherwise, notwithstanding, and jurisdiction over this transaction is per the law of the Republic of Zambia whatever the domicile, residence or place of business of the parties to this contract may be or become.</Text>
                            </View>
                            <View style={{ marginTop: 10 }} break>
                                <View style={{ fontWeight: 600, display: "flex", flexDirection: "row", fontSize: 14 }}>
                                    <Text style={{ marginRight: 2 }}>11.</Text>
                                    <Text style={{ marginLeft: 2 }}>Special Terms</Text>
                                </View>
                                <Text style={{ padding: 15, fontSize: 14, textDecoration: "underline", fontWeight: 600 }}>{contract_data?.broker_offer_data?.special_terms_and_conditions ? contract_data?.broker_offer_data?.special_terms_and_conditions : "---"}</Text>
                            </View>
                            <View style={{ marginTop: 10, fontSize: 14 }}>
                                <View>
                                    <Text><Text style={{ fontWeight: 600 }}>IN WITNESS WHEREOF</Text> the parties have caused this agreement to be executed by their duly appointed representatives</Text>
                                </View>
                                <View>
                                    <View style={{ marginTop: 5, display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
                                        <View style={{ width: "50%" }}>
                                            <Text>The Seller:</Text>
                                        </View>
                                        <View style={{ width: "50%" }}>
                                            <Text style={{ marginTop: 5, fontWeight: 600 }}>{contract_data?.seller?.entity_name ? contract_data?.seller?.entity_name : "---"}</Text>
                                        </View>
                                    </View>
                                    <View style={{ marginTop: 5, display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
                                        <View style={{ width: "50%" }}>
                                            <Text>Signature:</Text>
                                        </View>
                                        <View style={{ width: "50%" }}>
                                            <Text style={{ marginTop: 5 }}>____________________________________</Text>
                                        </View>
                                    </View>
                                    <View style={{ marginTop: 5, display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
                                        <View style={{ width: "50%" }}>
                                            <Text>As Witness:</Text>
                                        </View>
                                        <View style={{ width: "50%" }}>
                                            <Text style={{ marginTop: 5 }}>____________________________________</Text>
                                        </View>
                                    </View>
                                    <View style={{ marginTop: 5, display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
                                        <View style={{ width: "50%" }}>
                                            <Text>Occupation:</Text>
                                        </View>
                                        <View style={{ width: "50%" }}>
                                            <Text style={{ marginTop: 5 }}>____________________________________</Text>
                                        </View>
                                    </View>
                                    <View style={{ marginTop: 5, display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
                                        <View style={{ width: "50%" }}>
                                            <Text>Signature:</Text>
                                        </View>
                                        <View style={{ width: "50%" }}>
                                            <Text style={{ marginTop: 5 }}>____________________________________</Text>
                                        </View>
                                    </View>
                                    <View style={{ marginTop: 10, marginBottom: 10, display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
                                        <Text>Signed at_________________this_________________day of______________________20___</Text>
                                    </View>
                                    <View style={{ marginTop: 25, display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
                                        <View style={{ width: "50%" }}>
                                            <Text>The Buyer:</Text>
                                        </View>
                                        <View style={{ width: "50%" }}>
                                            <Text style={{ marginTop: 5, fontWeight: 600 }}>{contract_data?.buyer_data?.entity_name ? contract_data?.buyer_data?.entity_name : "---"}</Text>
                                        </View>
                                    </View>
                                    <View style={{ marginTop: 5, display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
                                        <View style={{ width: "50%" }}>
                                            <Text>Signature:</Text>
                                        </View>
                                        <View style={{ width: "50%" }}>
                                            <Text style={{ marginTop: 5 }}>____________________________________</Text>
                                        </View>
                                    </View>
                                    <View style={{ marginTop: 5, display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
                                        <View style={{ width: "50%" }}>
                                            <Text>As Witness:</Text>
                                        </View>
                                        <View style={{ width: "50%" }}>
                                            <Text style={{ marginTop: 5 }}>____________________________________</Text>
                                        </View>
                                    </View>
                                    <View style={{ marginTop: 5, display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
                                        <View style={{ width: "50%" }}>
                                            <Text>Occupation:</Text>
                                        </View>
                                        <View style={{ width: "50%" }}>
                                            <Text style={{ marginTop: 5 }}>____________________________________</Text>
                                        </View>
                                    </View>
                                    <View style={{ marginTop: 5, display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
                                        <View style={{ width: "50%" }}>
                                            <Text>Signature:</Text>
                                        </View>
                                        <View style={{ width: "50%" }}>
                                            <Text style={{ marginTop: 5 }}>____________________________________</Text>
                                        </View>
                                    </View>
                                    <View style={{ marginTop: 10, marginBottom: 10, display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
                                        <Text>Signed at_________________this_________________day of______________________20___</Text>
                                    </View>
                                </View>
                            </View>
                        </View>
                    </View>
                </View>
                <View style={{
                    color: "#8094ae",
                    fontSize: 9,
                    position: "absolute",
                    bottom: 10,
                    left: 30,
                    right: 0,
                }} fixed>
                    <Text>
                        &copy; {new Date().getFullYear()} Zambia Commodity Exchange Limited.
                    </Text>
                    <Text>
                        All Rights Reserved.
                    </Text>
                </View>

                <Text
                    style={{
                        position: "absolute",
                        bottom: 15,
                        left: 0,
                        right: 0,
                        textAlign: "center",
                        color: "#252b32",
                    }}
                    render={({ pageNumber, totalPages }) => `Page ${pageNumber} of ${totalPages}`}
                    fixed
                />
                <Text style={{
                    color: "rgb(75 85 99)",
                    fontSize: 9,
                    position: "absolute",
                    bottom: 20,
                    left: 30,
                    right: 0,
                    width: "90%",
                    padding: 15,
                    borderTop: "1px solid #8094ae",
                    paddingTop: 5,
                }} fixed>This document and all the contents herein are the property of ZAMACE Ltd.  No portion of this document may be copied or distributed in any form whatsoever without the written consent of ZAMACE</Text>
            </Page>
        </Document>
    );
};

export default WarehouseReceiptTradingContract;