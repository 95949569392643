import React, { useEffect, useState } from 'react';
import Icon from '../components/Icon';
import * as app_consts from '../utils/constants';
import { useParams } from 'react-router-dom';

function HeaderBanner ({ title, home = "exchange", type, navInfo, header_addition }) {

    const { view } = useParams();

    const [page_type, setPageType] = useState(type);
    const [page_nav_info, setPageNavInfo] = useState(navInfo);
    const [subHeader, setSubHeader] = useState(header_addition);

    useEffect(() => {
        setPageType(type);
    }, [type]);

    useEffect(() => {
        setPageNavInfo(navInfo);
    }, [navInfo]);

    useEffect(() => {
        setSubHeader(header_addition);
    }, [header_addition]);

    // URL Prefixes
    const warehouseUrlPrefix = () => {
        if (view)
        {
            return 'warehouse/view';
        }
        else
        {
            return 'warehouse';
        }
    };

    return (
        <div className="relative bg-gray-200 dark:bg-gray-600 p-4 sm:p-6 rounded-sm overflow-hidden mb-8">
            {/* Background illustration */}
            <div
                className="absolute right-0 top-0 -mt-4 mr-16 pointer-events-none hidden xl:block"
                aria-hidden="true"
                style={{
                    opacity: 0.4,
                    height: '100%',
                    maxWidth: '100%',
                }}
            >
                <div style={{ height: '100%' }}>
                    <Icon style={{ height: '100%', maxWidth: '100%' }} /> {/* Apply height and maxWidth to the Icon */}
                </div>
            </div>

            <div className="relative">
                <h2 className="text-2xl md:text-3xl text-slate-800 dark:text-slate-100 font-bold mb-1">
                    {title} <span className='text-xl text-slate-600'>{subHeader ? ` - (${subHeader})` : ""}</span>
                </h2>
                <p className="dark:text-gray-200">
                    <ol className="inline-flex items-center space-x-1 md:space-x-3">
                        <li className="inline-flex items-center">
                            <a href={`/${home}`} className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-green-600 dark:text-gray-400 dark:hover:text-white">
                                <svg className="w-3 h-3 mr-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
                                </svg>
                                Home
                            </a>
                        </li>
                        {
                            page_type === app_consts.WAREHOUSE_LOCATIONS ?
                                <>
                                    {
                                        page_nav_info && (
                                            <>
                                                <li>
                                                    <div className="flex items-center">
                                                        <svg className="w-3 h-3 mx-1 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
                                                        </svg>
                                                        <a href={page_nav_info.entity_id && page_nav_info.entity_mid ? `/${warehouseUrlPrefix()}/${page_nav_info.entity_id}/${page_nav_info.entity_mid}` : ""} className="ml-1 text-sm font-medium text-gray-700 hover:text-green-600 md:ml-2 dark:text-gray-400 dark:hover:text-white">Warehouse Dashboard</a>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="flex items-center">
                                                        <svg className="w-3 h-3 mx-1 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
                                                        </svg>
                                                        <a href={page_nav_info.entity_id && page_nav_info.entity_mid ? `/${warehouseUrlPrefix()}/${page_nav_info.entity_id}/${page_nav_info.entity_mid}/locations` : ""} className="ml-1 text-sm font-medium text-gray-700 hover:text-green-600 md:ml-2 dark:text-gray-400 dark:hover:text-white">Warehouse Locations</a>
                                                    </div>
                                                </li>
                                            </>
                                        )
                                    }
                                </>
                                : page_type === app_consts.WHR_DEPOSITORS ?
                                    <>
                                        {
                                            page_nav_info && (
                                                <>
                                                    <li>
                                                        <div className="flex items-center">
                                                            <svg className="w-3 h-3 mx-1 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
                                                            </svg>
                                                            <a href={page_nav_info.entity_id && page_nav_info.entity_mid ? `/${warehouseUrlPrefix()}/${page_nav_info.entity_id}/${page_nav_info.entity_mid}` : ""} className="ml-1 text-sm font-medium text-gray-700 hover:text-green-600 md:ml-2 dark:text-gray-400 dark:hover:text-white">Warehouse Dashboard</a>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="flex items-center">
                                                            <svg className="w-3 h-3 mx-1 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
                                                            </svg>
                                                            <a href={page_nav_info.entity_id && page_nav_info.entity_mid ? `/${warehouseUrlPrefix()}/${page_nav_info.entity_id}/${page_nav_info.entity_mid}/receipt_depositors` : ""} className="ml-1 text-sm font-medium text-gray-700 hover:text-green-600 md:ml-2 dark:text-gray-400 dark:hover:text-white">WHR Depositors</a>
                                                        </div>
                                                    </li>
                                                </>
                                            )
                                        }
                                    </>
                                    : page_type === app_consts.WAREHOUSE_RECEIPTS ?
                                        <>
                                            {
                                                page_nav_info && (
                                                    <>
                                                        <li>
                                                            <div className="flex items-center">
                                                                <svg className="w-3 h-3 mx-1 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
                                                                </svg>
                                                                <a href={page_nav_info.entity_id && page_nav_info.entity_mid ? `/${warehouseUrlPrefix()}/${page_nav_info.entity_id}/${page_nav_info.entity_mid}` : ""} className="ml-1 text-sm font-medium text-gray-700 hover:text-green-600 md:ml-2 dark:text-gray-400 dark:hover:text-white">Warehouse Dashboard</a>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="flex items-center">
                                                                <svg className="w-3 h-3 mx-1 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
                                                                </svg>
                                                                <a href={`${page_nav_info.entity_id && page_nav_info.entity_mid ? `/${warehouseUrlPrefix()}/${page_nav_info.entity_id}/${page_nav_info.entity_mid}/receipts` : ""}`} className="ml-1 text-sm font-medium text-gray-700 hover:text-green-600 md:ml-2 dark:text-gray-400 dark:hover:text-white">Warehouse Receipts</a>
                                                            </div>
                                                        </li>
                                                    </>
                                                )
                                            }
                                        </>
                                        : page_type === app_consts.CUSTOMER_MANAGEMENT ?
                                            <>
                                                <li>
                                                    <div className="flex items-center">
                                                        <svg className="w-3 h-3 mx-1 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
                                                        </svg>
                                                        <a href="/broker/customer_management" className="ml-1 text-sm font-medium text-gray-700 hover:text-green-600 md:ml-2 dark:text-gray-400 dark:hover:text-white">Customer Management</a>
                                                    </div>
                                                </li>
                                            </>
                                            : page_type === app_consts.MEMBER_MANAGEMENT ?
                                                <>
                                                    <li>
                                                        <div className="flex items-center">
                                                            <svg className="w-3 h-3 mx-1 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
                                                            </svg>
                                                            <a href="#" className="ml-1 text-sm font-medium text-gray-700 hover:text-green-600 md:ml-2 dark:text-gray-400 dark:hover:text-white">Member Management</a>
                                                        </div>
                                                    </li>
                                                </>
                                                : page_type === app_consts.MARKET_OFFER_MANAGEMENT ?
                                                    <>
                                                        <li>
                                                            <div className="flex items-center">
                                                                <svg className="w-3 h-3 mx-1 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
                                                                </svg>
                                                                <a href="#" className="ml-1 text-sm font-medium text-gray-700 hover:text-green-600 md:ml-2 dark:text-gray-400 dark:hover:text-white">Market Offer Management</a>
                                                            </div>
                                                        </li>
                                                    </>
                                                    : page_type === app_consts.WAREHOUSE_STORAGES ?
                                                        <>
                                                            {
                                                                page_nav_info && (
                                                                    <>
                                                                        <li>
                                                                            <div className="flex items-center">
                                                                                <svg className="w-3 h-3 mx-1 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                                                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
                                                                                </svg>
                                                                                <a href={page_nav_info.entity_id && page_nav_info.entity_mid ? `/${warehouseUrlPrefix()}/${page_nav_info.entity_id}/${page_nav_info.entity_mid}` : ""} className="ml-1 text-sm font-medium text-gray-700 hover:text-green-600 md:ml-2 dark:text-gray-400 dark:hover:text-white">Warehouse Dashboard</a>
                                                                            </div>
                                                                        </li>
                                                                        {/* <li>
                                                                        <div className="flex items-center">
                                                                            <svg className="w-3 h-3 mx-1 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                                                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
                                                                            </svg>
                                                                            <a href={page_nav_info.entity_id && page_nav_info.entity_mid ? `/${warehouseUrlPrefix()}/${page_nav_info.entity_id}/${page_nav_info.entity_mid}/storage` : ""} className="ml-1 text-sm font-medium text-gray-700 hover:text-green-600 md:ml-2 dark:text-gray-400 dark:hover:text-white">Warehouse Storages</a>
                                                                        </div>
                                                                    </li> */}
                                                                    </>
                                                                )
                                                            }
                                                        </>
                                                        : page_type === app_consts.INBOUND_SHIPMENTS ?
                                                            <>
                                                                {
                                                                    page_nav_info && (
                                                                        <>
                                                                            <li>
                                                                                <div className="flex items-center">
                                                                                    <svg className="w-3 h-3 mx-1 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                                                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
                                                                                    </svg>
                                                                                    <a href={page_nav_info.entity_id && page_nav_info.entity_mid ? `/${warehouseUrlPrefix()}/${page_nav_info.entity_id}/${page_nav_info.entity_mid}` : ""} className="ml-1 text-sm font-medium text-gray-700 hover:text-green-600 md:ml-2 dark:text-gray-400 dark:hover:text-white">Warehouse Dashboard</a>
                                                                                </div>
                                                                            </li>
                                                                            <li>
                                                                                <div className="flex items-center">
                                                                                    <svg className="w-3 h-3 mx-1 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                                                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
                                                                                    </svg>
                                                                                    <a href={page_nav_info.entity_id && page_nav_info.entity_mid ? `/${warehouseUrlPrefix()}/${page_nav_info.entity_id}/${page_nav_info.entity_mid}/inbound_shipments` : ""} className="ml-1 text-sm font-medium text-gray-700 hover:text-green-600 md:ml-2 dark:text-gray-400 dark:hover:text-white">Inbound Shipments</a>
                                                                                </div>
                                                                            </li>
                                                                        </>
                                                                    )
                                                                }
                                                            </>
                                                            : page_type === app_consts.TRADE_FULFILLMENTS ?
                                                                <>
                                                                    {
                                                                        page_nav_info && (
                                                                            <>
                                                                                <li>
                                                                                    <div className="flex items-center">
                                                                                        <svg className="w-3 h-3 mx-1 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                                                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
                                                                                        </svg>
                                                                                        <a href={page_nav_info.entity_id && page_nav_info.entity_mid ? `/${warehouseUrlPrefix()}/${page_nav_info.entity_id}/${page_nav_info.entity_mid}` : ""} className="ml-1 text-sm font-medium text-gray-700 hover:text-green-600 md:ml-2 dark:text-gray-400 dark:hover:text-white">Warehouse Dashboard</a>
                                                                                    </div>
                                                                                </li>
                                                                                <li>
                                                                                    <div className="flex items-center">
                                                                                        <svg className="w-3 h-3 mx-1 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                                                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
                                                                                        </svg>
                                                                                        <a href={page_nav_info.entity_id && page_nav_info.entity_mid ? `/${warehouseUrlPrefix()}/${page_nav_info.entity_id}/${page_nav_info.entity_mid}/trade_fulfillments` : ""} className="ml-1 text-sm font-medium text-gray-700 hover:text-green-600 md:ml-2 dark:text-gray-400 dark:hover:text-white">Trade Fulfillments</a>
                                                                                    </div>
                                                                                </li>
                                                                            </>
                                                                        )
                                                                    }
                                                                </>
                                                                :
                                                                page_type === app_consts.RECEIPTS_REPOSITORY ?
                                                                    <>
                                                                        {
                                                                            page_nav_info && (
                                                                                <>
                                                                                    <li>
                                                                                        <div className="flex items-center">
                                                                                            <svg className="w-3 h-3 mx-1 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                                                                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
                                                                                            </svg>
                                                                                            <a href={page_nav_info.entity_id && page_nav_info.entity_mid ? `/exchange/${page_nav_info.entity_id}/${page_nav_info.entity_mid}` : ""} className="ml-1 text-sm font-medium text-gray-700 hover:text-green-600 md:ml-2 dark:text-gray-400 dark:hover:text-white">Exchange Dashboard</a>
                                                                                        </div>
                                                                                    </li>
                                                                                    <li>
                                                                                        <div className="flex items-center">
                                                                                            <svg className="w-3 h-3 mx-1 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                                                                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
                                                                                            </svg>
                                                                                            <a href={page_nav_info.entity_id && page_nav_info.entity_mid ? `/exchange/${page_nav_info.entity_id}/${page_nav_info.entity_mid}/receipts_repository` : ""} className="ml-1 text-sm font-medium text-gray-700 hover:text-green-600 md:ml-2 dark:text-gray-400 dark:hover:text-white">Receipts Repository</a>
                                                                                        </div>
                                                                                    </li>
                                                                                </>
                                                                            )
                                                                        }
                                                                    </>
                                                                    : page_type === app_consts.BID_MANAGEMENT ?
                                                                        <>
                                                                            {
                                                                                page_nav_info && (
                                                                                    <>
                                                                                        <li>
                                                                                            <div className="flex items-center">
                                                                                                <svg className="w-3 h-3 mx-1 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                                                                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
                                                                                                </svg>
                                                                                                <a href={page_nav_info.entity_id && page_nav_info.entity_mid ? `/broker/${page_nav_info.entity_id}/${page_nav_info.entity_mid}` : ""} className="ml-1 text-sm font-medium text-gray-700 hover:text-green-600 md:ml-2 dark:text-gray-400 dark:hover:text-white">Broker Dashboard</a>
                                                                                            </div>
                                                                                        </li>
                                                                                        <li>
                                                                                            <div className="flex items-center">
                                                                                                <svg className="w-3 h-3 mx-1 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                                                                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
                                                                                                </svg>
                                                                                                <a href={page_nav_info.entity_id && page_nav_info.entity_mid ? `/broker/${page_nav_info.entity_id}/${page_nav_info.entity_mid}/bid_management` : ""} className="ml-1 text-sm font-medium text-gray-700 hover:text-green-600 md:ml-2 dark:text-gray-400 dark:hover:text-white">Bid Management</a>
                                                                                            </div>
                                                                                        </li>
                                                                                    </>
                                                                                )
                                                                            }
                                                                        </>
                                                                        : page_type === app_consts.PROCESS_WAREHOUSE_RECEIPT ?
                                                                            <>
                                                                                {
                                                                                    page_nav_info && (
                                                                                        <>
                                                                                            <li>
                                                                                                <div className="flex items-center">
                                                                                                    <svg className="w-3 h-3 mx-1 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                                                                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
                                                                                                    </svg>
                                                                                                    <a href={page_nav_info.entity_id && page_nav_info.entity_mid ? `/${warehouseUrlPrefix()}/${page_nav_info.entity_id}/${page_nav_info.entity_mid}` : ""} className="ml-1 text-sm font-medium text-gray-700 hover:text-green-600 md:ml-2 dark:text-gray-400 dark:hover:text-white">Warehouse Dashboard</a>
                                                                                                </div>
                                                                                            </li>
                                                                                            <li>
                                                                                                <div className="flex items-center">
                                                                                                    <svg className="w-3 h-3 mx-1 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                                                                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
                                                                                                    </svg>
                                                                                                    <a href={`${page_nav_info.entity_id && page_nav_info.entity_mid ? `/${warehouseUrlPrefix()}/${page_nav_info.entity_id}/${page_nav_info.entity_mid}/receipts` : ""}`} className="ml-1 text-sm font-medium text-gray-700 hover:text-green-600 md:ml-2 dark:text-gray-400 dark:hover:text-white">Warehouse Receipts</a>
                                                                                                </div>
                                                                                            </li>
                                                                                            <li>
                                                                                                <div className="flex items-center">
                                                                                                    <svg className="w-3 h-3 mx-1 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                                                                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
                                                                                                    </svg>
                                                                                                    <a href={`${page_nav_info.entity_id && page_nav_info.entity_mid ? `/${warehouseUrlPrefix()}/${page_nav_info.entity_id}/${page_nav_info.entity_mid}/receipts/process` : ""}`} className="ml-1 text-sm font-medium text-gray-700 hover:text-green-600 md:ml-2 dark:text-gray-400 dark:hover:text-white">Process Warehouse Receipt</a>
                                                                                                </div>
                                                                                            </li>
                                                                                        </>
                                                                                    )
                                                                                }
                                                                            </>
                                                                            : page_type === app_consts.CREATE_BULK_WAREHOUSE_RECEIPT_WIZARD ?
                                                                                <>
                                                                                    {
                                                                                        page_nav_info && (
                                                                                            <>
                                                                                                <li>
                                                                                                    <div className="flex items-center">
                                                                                                        <svg className="w-3 h-3 mx-1 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                                                                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
                                                                                                        </svg>
                                                                                                        <a href={page_nav_info.entity_id && page_nav_info.entity_mid ? `/${warehouseUrlPrefix()}/${page_nav_info.entity_id}/${page_nav_info.entity_mid}` : ""} className="ml-1 text-sm font-medium text-gray-700 hover:text-green-600 md:ml-2 dark:text-gray-400 dark:hover:text-white">Warehouse Dashboard</a>
                                                                                                    </div>
                                                                                                </li>
                                                                                                <li>
                                                                                                    <div className="flex items-center">
                                                                                                        <svg className="w-3 h-3 mx-1 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                                                                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
                                                                                                        </svg>
                                                                                                        <a href={`${page_nav_info.entity_id && page_nav_info.entity_mid ? `/${warehouseUrlPrefix()}/${page_nav_info.entity_id}/${page_nav_info.entity_mid}/receipts` : ""}`} className="ml-1 text-sm font-medium text-gray-700 hover:text-green-600 md:ml-2 dark:text-gray-400 dark:hover:text-white">Warehouse Receipts</a>
                                                                                                    </div>
                                                                                                </li>
                                                                                                <li>
                                                                                                    <div className="flex items-center">
                                                                                                        <svg className="w-3 h-3 mx-1 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                                                                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
                                                                                                        </svg>
                                                                                                        <a href={`${page_nav_info.entity_id && page_nav_info.entity_mid ? `/${warehouseUrlPrefix()}/${page_nav_info.entity_id}/${page_nav_info.entity_mid}/receipts/process_bulk` : ""}`} className="ml-1 text-sm font-medium text-gray-700 hover:text-green-600 md:ml-2 dark:text-gray-400 dark:hover:text-white">Process Bulk Warehouse Receipt</a>
                                                                                                    </div>
                                                                                                </li>
                                                                                            </>
                                                                                        )
                                                                                    }
                                                                                </>
                                                                                :
                                                                                page_type === app_consts.OFFER_MANAGEMENT ?
                                                                                    <>
                                                                                        {
                                                                                            page_nav_info && (
                                                                                                <>
                                                                                                    <li>
                                                                                                        <div className="flex items-center">
                                                                                                            <svg className="w-3 h-3 mx-1 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                                                                                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
                                                                                                            </svg>
                                                                                                            <a href={`${page_nav_info.entity_id && page_nav_info.entity_mid ? `/broker/${page_nav_info.entity_id}/${page_nav_info.entity_mid}` : ""}`} className="ml-1 text-sm font-medium text-gray-700 hover:text-green-600 md:ml-2 dark:text-gray-400 dark:hover:text-white">Broker Dashboard</a>
                                                                                                        </div>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <div className="flex items-center">
                                                                                                            <svg className="w-3 h-3 mx-1 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                                                                                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
                                                                                                            </svg>
                                                                                                            <a href={`${page_nav_info.entity_id && page_nav_info.entity_mid ? `/broker/${page_nav_info.entity_id}/${page_nav_info.entity_mid}/offer_management` : ""}`} className="ml-1 text-sm font-medium text-gray-700 hover:text-green-600 md:ml-2 dark:text-gray-400 dark:hover:text-white">Offer Management</a>
                                                                                                        </div>
                                                                                                    </li>
                                                                                                </>
                                                                                            )
                                                                                        }
                                                                                    </>
                                                                                    :
                                                                                    page_type === app_consts.EXCHANGE_PRODUCERS_LIST ?
                                                                                        <>
                                                                                            <li>
                                                                                                <div className="flex items-center">
                                                                                                    <svg className="w-3 h-3 mx-1 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                                                                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
                                                                                                    </svg>
                                                                                                    <a href={page_nav_info.entity_id && page_nav_info.entity_mid ? `/exchange/${page_nav_info.entity_id}/${page_nav_info.entity_mid}` : ""} className="ml-1 text-sm font-medium text-gray-700 hover:text-green-600 md:ml-2 dark:text-gray-400 dark:hover:text-white">Exchange Dashboard</a>
                                                                                                </div>
                                                                                            </li>
                                                                                            <li>
                                                                                                <div className="flex items-center">
                                                                                                    <svg className="w-3 h-3 mx-1 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                                                                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
                                                                                                    </svg>
                                                                                                    <a href={page_nav_info.entity_id && page_nav_info.entity_mid ? `/exchange/${page_nav_info.entity_id}/${page_nav_info.entity_mid}/producer_management` : ""} className="ml-1 text-sm font-medium text-gray-700 hover:text-green-600 md:ml-2 dark:text-gray-400 dark:hover:text-white">Producer Management</a>
                                                                                                </div>
                                                                                            </li>
                                                                                        </>
                                                                                        :
                                                                                        page_type === app_consts.AUTOMATED_TRADING_SYSTEM ?
                                                                                            <>
                                                                                                {
                                                                                                    page_nav_info && (
                                                                                                        <>
                                                                                                            <li>
                                                                                                                <div className="flex items-center">
                                                                                                                    <svg className="w-3 h-3 mx-1 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                                                                                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
                                                                                                                    </svg>
                                                                                                                    <a href={`${page_nav_info.entity_id && page_nav_info.entity_mid ? `/broker/${page_nav_info.entity_id}/${page_nav_info.entity_mid}` : ""}`} className="ml-1 text-sm font-medium text-gray-700 hover:text-green-600 md:ml-2 dark:text-gray-400 dark:hover:text-white">Broker Dashboard</a>
                                                                                                                </div>
                                                                                                            </li>
                                                                                                            <li>
                                                                                                                <div className="flex items-center">
                                                                                                                    <svg className="w-3 h-3 mx-1 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                                                                                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
                                                                                                                    </svg>
                                                                                                                    <a href={`${page_nav_info.entity_id && page_nav_info.entity_mid ? `/broker/${page_nav_info.entity_id}/${page_nav_info.entity_mid}/live_market` : ""}`} className="ml-1 text-sm font-medium text-gray-700 hover:text-green-600 md:ml-2 dark:text-gray-400 dark:hover:text-white">Automated Trading System</a>
                                                                                                                </div>
                                                                                                            </li>
                                                                                                        </>
                                                                                                    )
                                                                                                }
                                                                                            </>
                                                                                            : page_type === app_consts.PROVISION_RECEIPTS ?
                                                                                                <>
                                                                                                    {
                                                                                                        page_nav_info && (
                                                                                                            <>
                                                                                                                <li>
                                                                                                                    <div className="flex items-center">
                                                                                                                        <svg className="w-3 h-3 mx-1 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                                                                                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
                                                                                                                        </svg>
                                                                                                                        <a href={page_nav_info.entity_id && page_nav_info.entity_mid ? `/broker/${page_nav_info.entity_id}/${page_nav_info.entity_mid}` : ""} className="ml-1 text-sm font-medium text-gray-700 hover:text-green-600 md:ml-2 dark:text-gray-400 dark:hover:text-white">Broker Dashboard</a>
                                                                                                                    </div>
                                                                                                                </li>
                                                                                                                <li>
                                                                                                                    <div className="flex items-center">
                                                                                                                        <svg className="w-3 h-3 mx-1 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                                                                                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
                                                                                                                        </svg>
                                                                                                                        <a href={`${page_nav_info.entity_id && page_nav_info.entity_mid ? `/broker/${page_nav_info.entity_id}/${page_nav_info.entity_mid}/provision_receipts` : ""}`} className="ml-1 text-sm font-medium text-gray-700 hover:text-green-600 md:ml-2 dark:text-gray-400 dark:hover:text-white">Provisional Receipts</a>
                                                                                                                    </div>
                                                                                                                </li>
                                                                                                            </>
                                                                                                        )
                                                                                                    }
                                                                                                </>
                                                                                                : page_type === app_consts.EXCHANGE_PROVISION_RECEIPTS ?
                                                                                                    <>
                                                                                                        {
                                                                                                            page_nav_info && (
                                                                                                                <>
                                                                                                                    <li>
                                                                                                                        <div className="flex items-center">
                                                                                                                            <svg className="w-3 h-3 mx-1 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                                                                                                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
                                                                                                                            </svg>
                                                                                                                            <a href={page_nav_info.entity_id && page_nav_info.entity_mid ? `/markets/${page_nav_info.entity_id}/${page_nav_info.entity_mid}` : ""} className="ml-1 text-sm font-medium text-gray-700 hover:text-green-600 md:ml-2 dark:text-gray-400 dark:hover:text-white">Markets Dashboard</a>
                                                                                                                        </div>
                                                                                                                    </li>
                                                                                                                    <li>
                                                                                                                        <div className="flex items-center">
                                                                                                                            <svg className="w-3 h-3 mx-1 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                                                                                                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
                                                                                                                            </svg>
                                                                                                                            <a href={`${page_nav_info.entity_id && page_nav_info.entity_mid ? `/markets/${page_nav_info.entity_id}/${page_nav_info.entity_mid}/provision_receipt_management` : ""}`} className="ml-1 text-sm font-medium text-gray-700 hover:text-green-600 md:ml-2 dark:text-gray-400 dark:hover:text-white">Provisional Receipts</a>
                                                                                                                        </div>
                                                                                                                    </li>
                                                                                                                </>
                                                                                                            )
                                                                                                        }
                                                                                                    </>
                                                                                                    : page_type === app_consts.SPECIFIC_PROVISION_RECEIPT ?
                                                                                                        <>
                                                                                                            {
                                                                                                                page_nav_info && (
                                                                                                                    <>
                                                                                                                        <li>
                                                                                                                            <div className="flex items-center">
                                                                                                                                <svg className="w-3 h-3 mx-1 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                                                                                                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
                                                                                                                                </svg>
                                                                                                                                <a href={page_nav_info.entity_id && page_nav_info.entity_mid ? `/broker/${page_nav_info.entity_id}/${page_nav_info.entity_mid}` : ""} className="ml-1 text-sm font-medium text-gray-700 hover:text-green-600 md:ml-2 dark:text-gray-400 dark:hover:text-white">Broker Dashboard</a>
                                                                                                                            </div>
                                                                                                                        </li>
                                                                                                                        <li>
                                                                                                                            <div className="flex items-center">
                                                                                                                                <svg className="w-3 h-3 mx-1 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                                                                                                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
                                                                                                                                </svg>
                                                                                                                                <a href={`${page_nav_info.entity_id && page_nav_info.entity_mid ? `/broker/${page_nav_info.entity_id}/${page_nav_info.entity_mid}/provision_receipts` : ""}`} className="ml-1 text-sm font-medium text-gray-700 hover:text-green-600 md:ml-2 dark:text-gray-400 dark:hover:text-white">Provisional Receipts</a>
                                                                                                                            </div>
                                                                                                                        </li>
                                                                                                                        <li>
                                                                                                                            <div className="flex items-center">
                                                                                                                                <svg className="w-3 h-3 mx-1 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                                                                                                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
                                                                                                                                </svg>
                                                                                                                                <a href={`${page_nav_info.entity_id && page_nav_info.entity_mid ? `/broker/${page_nav_info.entity_id}/${page_nav_info.entity_mid}/provisional_receipts/${page_nav_info.receipt_number}` : ""}`} className="ml-1 text-sm font-medium text-gray-700 hover:text-green-600 md:ml-2 dark:text-gray-400 dark:hover:text-white">Provisional Receipt Details</a>
                                                                                                                            </div>
                                                                                                                        </li>
                                                                                                                    </>
                                                                                                                )
                                                                                                            }
                                                                                                        </>
                                                                                                        :
                                                                                                        page_type === app_consts.CREATE_GOODS_RECEIVED_NOTE_WIZARD ?
                                                                                                            <>
                                                                                                                {
                                                                                                                    page_nav_info && (
                                                                                                                        <>
                                                                                                                            <li>
                                                                                                                                <div className="flex items-center">
                                                                                                                                    <svg className="w-3 h-3 mx-1 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                                                                                                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
                                                                                                                                    </svg>
                                                                                                                                    <a href={page_nav_info.entity_id && page_nav_info.entity_mid ? `/${warehouseUrlPrefix()}/${page_nav_info.entity_id}/${page_nav_info.entity_mid}` : ""} className="ml-1 text-sm font-medium text-gray-700 hover:text-green-600 md:ml-2 dark:text-gray-400 dark:hover:text-white">Warehouse Dashboard</a>
                                                                                                                                </div>
                                                                                                                            </li>
                                                                                                                            <li>
                                                                                                                                <div className="flex items-center">
                                                                                                                                    <svg className="w-3 h-3 mx-1 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                                                                                                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
                                                                                                                                    </svg>
                                                                                                                                    <a href={`${page_nav_info.entity_id && page_nav_info.entity_mid ? `/${warehouseUrlPrefix()}/${page_nav_info.entity_id}/${page_nav_info.entity_mid}/receipts` : ""}`} className="ml-1 text-sm font-medium text-gray-700 hover:text-green-600 md:ml-2 dark:text-gray-400 dark:hover:text-white">Warehouse Receipts</a>
                                                                                                                                </div>
                                                                                                                            </li>
                                                                                                                            <li>
                                                                                                                                <div className="flex items-center">
                                                                                                                                    <svg className="w-3 h-3 mx-1 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                                                                                                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
                                                                                                                                    </svg>
                                                                                                                                    <a href={`${page_nav_info.entity_id && page_nav_info.entity_mid ? `/${warehouseUrlPrefix()}/${page_nav_info.entity_id}/${page_nav_info.entity_mid}/receipts/process_grn` : ""}`} className="ml-1 text-sm font-medium text-gray-700 hover:text-green-600 md:ml-2 dark:text-gray-400 dark:hover:text-white">Process Goods Received Note</a>
                                                                                                                                </div>
                                                                                                                            </li>
                                                                                                                        </>
                                                                                                                    )
                                                                                                                }
                                                                                                            </>
                                                                                                            :
                                                                                                            page_type === app_consts.MEMBERSHIP_MANAGEMENT ?
                                                                                                                <>
                                                                                                                    {
                                                                                                                        page_nav_info && (
                                                                                                                            <>
                                                                                                                                <li>
                                                                                                                                    <div className="flex items-center">
                                                                                                                                        <svg className="w-3 h-3 mx-1 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                                                                                                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
                                                                                                                                        </svg>
                                                                                                                                        <a href={page_nav_info.entity_id && page_nav_info.entity_mid ? `/exchange/${page_nav_info.entity_id}/${page_nav_info.entity_mid}` : ""} className="ml-1 text-sm font-medium text-gray-700 hover:text-green-600 md:ml-2 dark:text-gray-400 dark:hover:text-white">Exchange Dashboard</a>
                                                                                                                                    </div>
                                                                                                                                </li>
                                                                                                                                <li>
                                                                                                                                    <div className="flex items-center">
                                                                                                                                        <svg className="w-3 h-3 mx-1 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                                                                                                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
                                                                                                                                        </svg>
                                                                                                                                        <a href={`${page_nav_info.entity_id && page_nav_info.entity_mid ? `/exchange/${page_nav_info.entity_id}/${page_nav_info.entity_mid}/membership` : ""}`} className="ml-1 text-sm font-medium text-gray-700 hover:text-green-600 md:ml-2 dark:text-gray-400 dark:hover:text-white">Membership Management</a>
                                                                                                                                    </div>
                                                                                                                                </li>
                                                                                                                            </>
                                                                                                                        )
                                                                                                                    }
                                                                                                                </>
                                                                                                                :
                                                                                                                page_type === app_consts.WAREHOUSE_MANAGEMENT ?
                                                                                                                    <>
                                                                                                                        {
                                                                                                                            page_nav_info && (
                                                                                                                                <>
                                                                                                                                    <li>
                                                                                                                                        <div className="flex items-center">
                                                                                                                                            <svg className="w-3 h-3 mx-1 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                                                                                                                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
                                                                                                                                            </svg>
                                                                                                                                            <a href={page_nav_info.entity_id && page_nav_info.entity_mid ? `/exchange/${page_nav_info.entity_id}/${page_nav_info.entity_mid}` : ""} className="ml-1 text-sm font-medium text-gray-700 hover:text-green-600 md:ml-2 dark:text-gray-400 dark:hover:text-white">Exchange Dashboard</a>
                                                                                                                                        </div>
                                                                                                                                    </li>
                                                                                                                                    <li>
                                                                                                                                        <div className="flex items-center">
                                                                                                                                            <svg className="w-3 h-3 mx-1 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                                                                                                                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
                                                                                                                                            </svg>
                                                                                                                                            <a href={`${page_nav_info.entity_id && page_nav_info.entity_mid ? `/exchange/${page_nav_info.entity_id}/${page_nav_info.entity_mid}/warehouse_management` : ""}`} className="ml-1 text-sm font-medium text-gray-700 hover:text-green-600 md:ml-2 dark:text-gray-400 dark:hover:text-white">Warehouse Operator Management</a>
                                                                                                                                        </div>
                                                                                                                                    </li>
                                                                                                                                </>
                                                                                                                            )
                                                                                                                        }
                                                                                                                    </>
                                                                                                                    :
                                                                                                                    page_type === app_consts.BROKER_MANAGEMENT ?
                                                                                                                        <>
                                                                                                                            {
                                                                                                                                page_nav_info && (
                                                                                                                                    <>
                                                                                                                                        <li>
                                                                                                                                            <div className="flex items-center">
                                                                                                                                                <svg className="w-3 h-3 mx-1 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                                                                                                                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
                                                                                                                                                </svg>
                                                                                                                                                <a href={page_nav_info.entity_id && page_nav_info.entity_mid ? `/exchange/${page_nav_info.entity_id}/${page_nav_info.entity_mid}` : ""} className="ml-1 text-sm font-medium text-gray-700 hover:text-green-600 md:ml-2 dark:text-gray-400 dark:hover:text-white">Exchange Dashboard</a>
                                                                                                                                            </div>
                                                                                                                                        </li>
                                                                                                                                        <li>
                                                                                                                                            <div className="flex items-center">
                                                                                                                                                <svg className="w-3 h-3 mx-1 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                                                                                                                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
                                                                                                                                                </svg>
                                                                                                                                                <a href={`${page_nav_info.entity_id && page_nav_info.entity_mid ? `/exchange/${page_nav_info.entity_id}/${page_nav_info.entity_mid}/broker_management` : ""}`} className="ml-1 text-sm font-medium text-gray-700 hover:text-green-600 md:ml-2 dark:text-gray-400 dark:hover:text-white">Broker Management</a>
                                                                                                                                            </div>
                                                                                                                                        </li>
                                                                                                                                    </>
                                                                                                                                )
                                                                                                                            }
                                                                                                                        </>
                                                                                                                        :
                                                                                                                        page_type === app_consts.COOPERATIVE_MANAGEMENT ?
                                                                                                                            <>
                                                                                                                                {
                                                                                                                                    page_nav_info && (
                                                                                                                                        <>
                                                                                                                                            <li>
                                                                                                                                                <div className="flex items-center">
                                                                                                                                                    <svg className="w-3 h-3 mx-1 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                                                                                                                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
                                                                                                                                                    </svg>
                                                                                                                                                    <a href={page_nav_info.entity_id && page_nav_info.entity_mid ? `/exchange/${page_nav_info.entity_id}/${page_nav_info.entity_mid}` : ""} className="ml-1 text-sm font-medium text-gray-700 hover:text-green-600 md:ml-2 dark:text-gray-400 dark:hover:text-white">Exchange Dashboard</a>
                                                                                                                                                </div>
                                                                                                                                            </li>
                                                                                                                                            <li>
                                                                                                                                                <div className="flex items-center">
                                                                                                                                                    <svg className="w-3 h-3 mx-1 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                                                                                                                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
                                                                                                                                                    </svg>
                                                                                                                                                    <a href={`${page_nav_info.entity_id && page_nav_info.entity_mid ? `/exchange/${page_nav_info.entity_id}/${page_nav_info.entity_mid}/cooperative_management` : ""}`} className="ml-1 text-sm font-medium text-gray-700 hover:text-green-600 md:ml-2 dark:text-gray-400 dark:hover:text-white">Cooperative Management</a>
                                                                                                                                                </div>
                                                                                                                                            </li>
                                                                                                                                        </>
                                                                                                                                    )
                                                                                                                                }
                                                                                                                            </>
                                                                                                                            :
                                                                                                                            page_type === app_consts.USER_MANAGEMENT ?
                                                                                                                                <>
                                                                                                                                    {
                                                                                                                                        page_nav_info && (
                                                                                                                                            <>
                                                                                                                                                <li>
                                                                                                                                                    <div className="flex items-center">
                                                                                                                                                        <svg className="w-3 h-3 mx-1 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                                                                                                                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
                                                                                                                                                        </svg>
                                                                                                                                                        <a href={page_nav_info.entity_id && page_nav_info.entity_mid ? `/exchange/${page_nav_info.entity_id}/${page_nav_info.entity_mid}` : ""} className="ml-1 text-sm font-medium text-gray-700 hover:text-green-600 md:ml-2 dark:text-gray-400 dark:hover:text-white">Exchange Dashboard</a>
                                                                                                                                                    </div>
                                                                                                                                                </li>
                                                                                                                                                <li>
                                                                                                                                                    <div className="flex items-center">
                                                                                                                                                        <svg className="w-3 h-3 mx-1 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                                                                                                                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
                                                                                                                                                        </svg>
                                                                                                                                                        <a href={`${page_nav_info.entity_id && page_nav_info.entity_mid ? `/exchange/${page_nav_info.entity_id}/${page_nav_info.entity_mid}/user_management` : ""}`} className="ml-1 text-sm font-medium text-gray-700 hover:text-green-600 md:ml-2 dark:text-gray-400 dark:hover:text-white">User Management</a>
                                                                                                                                                    </div>
                                                                                                                                                </li>
                                                                                                                                            </>
                                                                                                                                        )
                                                                                                                                    }
                                                                                                                                </>
                                                                                                                                :
                                                                                                                                <>
                                                                                                                                    <li>
                                                                                                                                        <div className="flex items-center">
                                                                                                                                            <svg className="w-3 h-3 mx-1 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                                                                                                                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
                                                                                                                                            </svg>
                                                                                                                                            <a href="/exchange/membership" className="ml-1 text-sm font-medium text-gray-700 hover:text-green-600 md:ml-2 dark:text-gray-400 dark:hover:text-white">{title}</a>
                                                                                                                                        </div>
                                                                                                                                    </li>
                                                                                                                                </>

                        }
                    </ol>
                </p>
            </div>
        </div>
    );
}

export default HeaderBanner;
