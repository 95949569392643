import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addBankAccount, addBankAccountReset, exchange_add_bank_account, getCurrentAccount } from '../../utils/actions';
import { useNavigate } from 'react-router-dom';
import 'react-phone-input-2/lib/style.css';
import * as app_consts from '../../utils/constants';

const AddBankAccountDetailsModal = ({ main_access_account, closeModal, successFunction }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [error, setError] = useState();

    const [bankAccountData, setbankAccountData] = useState({
        entity_id: "",
        account_id: "",
        profile_id: "",
        bank_account_name: '',
        bank_account_number: '',
        bank_address: '',
        bank_name: '',
        iban_code: '',
        swift_code: '',
        status: "active",
    });

    useEffect(() => {
        if (main_access_account)
        {
            setbankAccountData({ ...bankAccountData, ["account_id"]: main_access_account?.account_id, ["entity_id"]: main_access_account?.entity_id, ["profile_id"]: main_access_account?.profile_id });
        }
    }, [main_access_account]);




    const validateInputs = (data) => {
        const errors = {};

        if (!data.bank_account_name)
        {
            errors.bank_account_name = 'Please enter bank account name';
        }

        if (!data.bank_name)
        {
            errors.bank_name = 'Please enter bank name';
        }

        if (!data.bank_account_number)
        {
            errors.bank_account_number = 'Please enter account number';
        }

        if (!data.bank_address)
        {
            errors.bank_address = 'Please enter bank physical address';
        }

        if (!data.swift_code)
        {
            errors.swift_code = 'Please enter bank swift code';
        }

        return errors;
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setbankAccountData((prevData) => ({ ...prevData, [name]: value }));
        setErrors((prevErrors) => ({ ...prevErrors, [name]: null }));
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate inputs
        const inputErrors = validateInputs(bankAccountData);
        if (Object.keys(inputErrors).length > 0)
        {
            setErrors(inputErrors);
            return;
        }

        try
        {
            await dispatch(exchange_add_bank_account(bankAccountData));
        } catch (error)
        {
            console.error(error);
        }
    };

    // Add Listner
    const add_bank_account_state = useSelector((state) => state.bankAccount);
    // 
    useEffect(() => {
        if (add_bank_account_state.status !== app_consts.IDLE)
        {
            if (add_bank_account_state.loading)
            {
                setLoading(true);
            }
            else
            {
                if (add_bank_account_state.error)
                {
                    setError(add_bank_account_state.error);
                }
                else
                {
                    successFunction();
                    closeModal(false);
                }
                setLoading(false);
                dispatch(addBankAccountReset());
            }
        }
    }, [add_bank_account_state]);

    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className="grid gap-6 mb-8 lg:grid-cols-4 p-6 sm:p-16 lg:p-8">
                    <div>
                        <label htmlFor="bank_account_name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            Bank Account Name
                        </label>
                        <input
                            iban_code="text"
                            name="bank_account_name"
                            value={bankAccountData.bank_account_name}
                            onChange={handleInputChange}
                            required
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                        />
                        {errors.bank_account_name && <p className="text-red-500 text-xs mt-1">{errors.bank_account_name}</p>}
                    </div>
                    <div>
                        <label htmlFor="bank_name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            Full Bank Name
                        </label>
                        <input
                            iban_code="text"
                            name="bank_name"
                            value={bankAccountData.bank_name}
                            onChange={handleInputChange}
                            required
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                        />
                        {errors.bank_name && <p className="text-red-500 text-xs mt-1">{errors.bank_name}</p>}
                    </div>
                    <div>
                        <label htmlFor="bank_account_number" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            Bank Account Number
                        </label>
                        <input
                            iban_code="number"
                            name="bank_account_number"
                            value={bankAccountData.bank_account_number}
                            onChange={handleInputChange}
                            required
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                        />
                        {errors.bank_account_number && <p className="text-red-500 text-xs mt-1">{errors.bank_account_number}</p>}
                    </div>
                    <div>
                        <label htmlFor="bank_account_number" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            Bank Branch Name & Address
                        </label>
                        <input
                            iban_code="text"
                            name="bank_address"
                            value={bankAccountData.bank_address}
                            onChange={handleInputChange}
                            required
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                        />
                        {errors.bank_address && <p className="text-red-500 text-xs mt-1">{errors.bank_address}</p>}
                    </div>
                    <div>
                        <label htmlFor="swift_code" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            Bank SWIFT Code
                        </label>
                        <input
                            iban_code="text"
                            name="swift_code"
                            value={bankAccountData.swift_code}
                            onChange={handleInputChange}
                            required
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                        />
                        {errors.swift_code && <p className="text-red-500 text-xs mt-1">{errors.swift_code}</p>}
                    </div>
                    <div>
                        <label htmlFor="iban_code" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            Bank IBAN (if applicable)
                        </label>
                        <input
                            iban_code="text"
                            name="iban_code"
                            value={bankAccountData.iban_code}
                            onChange={handleInputChange}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                        />
                        {errors.iban_code && <p className="text-red-500 text-xs mt-1">{errors.iban_code}</p>}
                    </div>
                </div>
                {error && (
                    <div
                        className='p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400'
                        role='alert'
                    >
                        <span className='font-medium'>Error: </span>
                        {error}
                    </div>
                )}
                <div className="flex justify-between items-center space-x-4">
                    {/* Close button */}
                    <button
                        type="button"
                        onClick={() => closeModal(false)}
                        className="text-gray-900 bg-white hover:bg-gray-100 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 mr-2 mb-2"
                    >
                        Close Modal
                    </button>
                    <button
                        type="submit"
                        loading={loading}
                        className="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
                    >
                        {loading ? "Adding Bank Account..." : "Add Bank Account"}
                    </button>
                </div>
            </form>
        </>
    );
};

export default AddBankAccountDetailsModal;
