import * as app_consts from "../../utils/constants";

export const table_columns = (type) => {
    let column = [];
    switch (type)
    {
        case app_consts.WAREHOUSE_RECEIPT_TABLE:
            column = [
                { key: 0, label: "#" },
                { key: 1, label: "Message" },
                { key: 2, label: "Timestamp" },
            ];
            break;
        case app_consts.OFFER_HISTORY_TABLE:
            column = [
                { key: 0, label: "#" },
                { key: 1, label: "Message" },
            ];
            break;
        case app_consts.BROKER_OFFER_TRADE_TABLE:
            column = [
                { key: 0, label: "#" },
                { key: 1, label: "Unit Price" },
                { key: 2, label: "Quantity" },
                { key: 3, label: "Status" },
                { key: 4, label: "Trade Date" },
                { key: 5, label: "Action" },
            ];
            break;
        case app_consts.BID_MANAGEMENT_TABLE:
            column = [
                { key: 0, label: "" },
                { key: 1, label: "Bid Reference No." },
                { key: 2, label: "Offer Reference No." },
                { key: 3, label: "Commodity" },
                { key: 4, label: "Bid Volume" },
                { key: 5, label: "Bid Date" },
                { key: 6, label: "Bid Status" },
                { key: 7, label: "Action" },
            ];
            break;
        case app_consts.WAREHOUSE_RECEIPT_LIST_TABLE:
            column = [
                // { key: 0, label: "" },
                { key: 0, label: "RECEIPT SERIAL NO." },
                { key: 1, label: "COMMODITY" },
                { key: 2, label: "OWNER" },
                { key: 3, label: "QUANTITY (METRIC TONNES)" },
                { key: 4, label: "RECEIPT ORIGIN" },
                { key: 5, label: "STATUS" },
                { key: 6, label: "Action" },
            ];
            break;
        case app_consts.TRADE_FULFILLMENTS_LIST_TABLE:
            column = [
                { key: 0, label: "" },
                { key: 1, label: "CONTRACT REF NO." },
                { key: 2, label: "COMMODITY" },
                { key: 3, label: "QUANTITY" },
                { key: 4, label: "TRADE DATE" },
                { key: 5, label: "Contract Status" },
                { key: 6, label: "Fulfilment Status" },
                { key: 7, label: "Trade Status" },
                { key: 8, label: "Action" },
            ];
            break;
        case app_consts.SUPER_BID_MANAGEMENT_TABLE:
            column = [
                { key: 0, label: "" },
                { key: 1, label: "DATE TO BE PLACED ON PLATFORM." },
                { key: 2, label: "Commodity" },
                { key: 3, label: "Bid Volume" },
                { key: 4, label: "Requested Unit Price" },
                { key: 5, label: "Quality" },
                { key: 6, label: "Order Type" },
                { key: 7, label: "Special terms and Conditions" },
                { key: 8, label: "Status" },
                { key: 9, label: "Action" },
            ];
            break;
        case app_consts.ENTITY_USER_PROFILES_TABLE:
            column = [
                { key: 0, label: "" },
                { key: 1, label: "First Name" },
                { key: 2, label: "Last Name" },
                { key: 3, label: "Mobile" },
                { key: 4, label: "Email Address" },
                { key: 5, label: "Date Created" },
                { key: 6, label: "Status" },
                { key: 7, label: "Action" },
            ];
            break;
        case app_consts.PROVISION_RECEIPT_LIST_TABLE:
            column = [
                { key: 0, label: "" },
                { key: 1, label: "RECEIPT SERIAL NO." },
                { key: 2, label: "COMMODITY" },
                { key: 3, label: "OWNER" },
                { key: 4, label: "QUANTITY (METRIC TONNES)" },
                { key: 5, label: "RECEIPT ORIGIN" },
                { key: 6, label: "STATUS" },
                { key: 7, label: "Action" },
            ];
            break;
        case app_consts.WAREHOUSE_REPORTS:
            column = [
                { key: 0, label: "" },
                { key: 1, label: "Warehouse ID" },
                { key: 2, label: "Name" },
                { key: 3, label: "Total Capacity (MT)" },
                { key: 4, label: "Used Capacity (MT)" },
                { key: 5, label: "Available Capacity(MT)" },
                { key: 6, label: "Used Percentage" },
                { key: 7, label: "Total Issued Warehouse Receipts" },
                { key: 8, label: "Status" },
            ];
            break;
        case app_consts.WAREHOUSE_LOCATIONS_TABLE:
            column = [
                // { key: 0, label: "" },
                { key: 0, label: "Warehouse ID" },
                { key: 1, label: "Site Name" },
                { key: 2, label: "Site Location" },
                { key: 3, label: "Status" },
                { key: 4, label: "Action" },
            ];
            break;
        case app_consts.INBOUND_SHIPMENTS_TABLE:
            column = [
                // { key: 0, label: "" },
                { key: 0, label: "Warehouse ID" },
                { key: 1, label: "Commodity" },
                { key: 2, label: "Owner" },
                { key: 3, label: "Arrival Time" },
                { key: 4, label: "Status" },
                { key: 5, label: "Action" },
            ];
            break;
        case app_consts.GOODS_RECEIVED_NOTE_TABLE:
            column = [
                { key: 0, label: "GRN No" },
                { key: 1, label: "Truck Load No" },
                { key: 2, label: "No of Bags" },
                { key: 3, label: "Quantity (Metric Tonnes)" },
                { key: 4, label: "Date Received" },
            ];
            break;
        case app_consts.WHR_DEPOSITORS:
            column = [
                { key: 0, label: "ID" },
                { key: 1, label: "Name" },
                { key: 2, label: "Email" },
                { key: 3, label: "Mobile Number" },
                { key: 4, label: "No. Warehouse Receipts" },
                { key: 5, label: "Last Deposit" },
                { key: 6, label: "Action" },
            ];
            break;
        case app_consts.SPECIFIC_PRODUCER_WHR_TABLE:
            column = [
                // { key: 0, label: "" },
                { key: 0, label: "RECEIPT SERIAL NO." },
                { key: 1, label: "COMMODITY" },
                { key: 2, label: "QUANTITY (METRIC TONNES)" },
                { key: 3, label: "RECEIPT ORIGIN" },
                { key: 4, label: "STATUS" },
                { key: 5, label: "Action" },
            ];
            break;
        case app_consts.REPORTS_AUDIT_TRAILS_TABLE:
            column = [
                // { key: 0, label: "" },
                { key: 0, label: "Timestamp" },
                { key: 1, label: "Message" },
                { key: 2, label: "User" },
            ];
            break;
        case app_consts.EXCHANGE_PRODUCERS_LIST:
            column = [
                // { key: 0, label: "" },
                { key: 0, label: "ID" },
                { key: 1, label: "Name" },
                { key: 2, label: "Type" },
                { key: 2, label: "Contact Number" },
                { key: 3, label: "Contact Email" },
                { key: 4, label: "No of Brokers" },
                { key: 5, label: "Action" },
            ];
            break;
        case app_consts.EXCHANGE_PRODUCER_BROKERS:
            column = [
                // { key: 0, label: "" },
                { key: 0, label: "ZAMACE ID" },
                { key: 1, label: "Name" },
                { key: 2, label: "Contact Number" },
                { key: 3, label: "Contact Email" },
                { key: 4, label: "Status" },
                { key: 5, label: "Action" },
            ];
            break;
        case app_consts.BROKER_PRODUCERS:
            column = [
                // { key: 0, label: "" },
                { key: 0, label: "ID" },
                { key: 1, label: "Type" },
                { key: 2, label: "Name" },
                { key: 3, label: "Email" },
                { key: 4, label: "Phone" },
                { key: 5, label: "Receipts" },
                { key: 6, label: "Province" },
                { key: 7, label: "Country" },
                { key: 8, label: "Action" },
            ];
            break;
        case app_consts.ATS_MANAGEMENT:
            column = [
                // { key: 0, label: "" },
                { key: 0, label: "Reference No." },
                { key: 1, label: "Date" },
                { key: 2, label: "Commodity" },
                { key: 3, label: "Type" },
                { key: 4, label: "Unit Price" },
                { key: 5, label: "Quantity (MT)" },
                { key: 6, label: "Delivery" },
                { key: 7, label: "Status" },
                { key: 8, label: "Action" },
            ];
            break;
        case app_consts.MEMBERSHIP_MANAGEMENT:
            column = [
                { key: 0, label: "" },
                { key: 1, label: "ID" },
                { key: 2, label: "Name" },
                { key: 3, label: "Type" },
                { key: 4, label: "Email" },
                { key: 5, label: "Contact Number" },
                { key: 6, label: "Created on" },
                { key: 6, label: "Status" },
                { key: 7, label: "Action" },
            ];
            break;
        case app_consts.ENTITY_PROFILES_LIST_EXCHANGE_VIEW:
            column = [
                { key: 0, label: "Member ID" },
                { key: 1, label: "Type" },
                { key: 2, label: "Status" },
                { key: 3, label: "Created Date" },
                { key: 4, label: "Action" }
            ];
            break;
        case app_consts.BANK_ACCOUNTS:
            column = [
                { key: 0, label: "Account Name" },
                { key: 1, label: "Bank Name" },
                { key: 2, label: "Bank Number" },
                { key: 3, label: "Branch Code" },
                { key: 4, label: "Swift Code" },
                { key: 5, label: "Status" },
                { key: 6, label: "Actions" }
            ];
            break;
        case app_consts.USER_PROFILES_TABLE:
            column = [
                { key: 0, label: "First Name" },
                { key: 1, label: "Last Name" },
                { key: 2, label: "Profile Type" },
                { key: 3, label: "Mobile" },
                { key: 4, label: "Email Address" },
                { key: 5, label: "Date Created" },
                { key: 6, label: "Status" },
                { key: 7, label: "Action" },
            ];
            break;
        case app_consts.WAREHOUSE_OPERATOR_LIST:
            column = [
                { key: 0, label: "ID" },
                { key: 1, label: "Name" },
                { key: 2, label: "Warehouse Locations" },
                { key: 3, label: "Capacity (MT)" },
                { key: 4, label: "Warehouse Receipts" },
                { key: 5, label: "Date Created" },
                { key: 6, label: "Status" },
                { key: 7, label: "Action" },
            ];
            break;
        case app_consts.EXCHANGE_WAREHOUSE_LIST:
            column = [
                // { key: 0, label: "" },
                { key: 0, label: "Warehouse ID" },
                { key: 1, label: "Warehouse Operator" },
                { key: 2, label: "Site Name" },
                { key: 3, label: "Site Location" },
                { key: 4, label: "Viewable On Website" },
                { key: 5, label: "Status" },
                { key: 6, label: "Action" },
            ];
            break;
        case app_consts.BROKER_LIST:
            column = [
                // { key: 0, label: "" },
                { key: 0, label: "ID" },
                { key: 1, label: "Name" },
                { key: 2, label: "Broker Type" },
                { key: 3, label: "Clients" },
                { key: 4, label: "Trading Authority" },
                { key: 5, label: "Viewable On Website" },
                { key: 6, label: "Brokership Status" },
                { key: 7, label: "Action" },
            ];
            break;
        case app_consts.EXCHANGE_BROKER_DETAILS_SUPER_OFFERS:
            column = [
                // { key: 0, label: "" },
                { key: 0, label: "Date Created" },
                { key: 1, label: "Commodity" },
                { key: 2, label: "Volume on Offer" },
                { key: 3, label: "Requested Unit Price (ZMW)" },
                { key: 4, label: "Quality" },
                { key: 5, label: "Offer Status" },
                { key: 6, label: "Action" },
            ];
            break;
        case app_consts.SPECIFIC_USER_PROFILES_TABLE:
            column = [
                { key: 0, label: "First Name" },
                { key: 1, label: "Last Name" },
                { key: 2, label: "Mobile" },
                { key: 3, label: "Email Address" },
                { key: 4, label: "Date Created" },
                { key: 5, label: "Status" },
            ];
            break;
        case app_consts.EXCHANGE_BROKER_DETAILS_SUPER_BIDS:
            column = [
                { key: 0, label: "Date Created" },
                { key: 1, label: "Commodity" },
                { key: 2, label: "Bid Volume" },
                { key: 3, label: "Requested Unit Price (ZMW)" },
                { key: 4, label: "Quality" },
                { key: 5, label: "Bid Status" },
                { key: 6, label: "Action" },
            ];
            break;
        case app_consts.COOPERATIVE_LIST:
            column = [
                { key: 0, label: "ID" },
                { key: 1, label: "Name" },
                { key: 2, label: "Sector" },
                { key: 3, label: "Province" },
                { key: 4, label: "Members" },
                { key: 5, label: "Viewable On Website" },
                { key: 6, label: "Status" },
                { key: 7, label: "Action" },
            ];
            break;
        case app_consts.EXCHANGE_ENTITY_PRODUCERS_LIST:
            column = [
                // { key: 0, label: "" },
                { key: 0, label: "ID" },
                { key: 1, label: "Type" },
                { key: 2, label: "Name" },
                { key: 3, label: "Email" },
                { key: 4, label: "Phone" },
                { key: 5, label: "Receipts" },
                { key: 6, label: "Province" },
                { key: 7, label: "Country" },
                { key: 8, label: "Action" },
            ];
        case app_consts.USER_LIST:
            column = [
                // { key: 0, label: "" },
                { key: 0, label: "First Name" },
                { key: 1, label: "Last Name" },
                { key: 2, label: "Mobile" },
                { key: 3, label: "Email" },
                { key: 4, label: "Registered Date" },
                { key: 5, label: "Verified" },
                { key: 6, label: "Active" },
                { key: 7, label: "Action" },
            ];
            break;
        case app_consts.USER_ACCOUNT_PROFILES:
            column = [
                { key: 0, label: "" },
                { key: 1, label: "ID" },
                { key: 2, label: "Name" },
                { key: 3, label: "Email" },
                { key: 4, label: "Contact Number" },
                { key: 5, label: "No. of Profiles" },
                { key: 6, label: "Status" },
                { key: 7, label: "Action" },
            ];
            break;
        case app_consts.USER_ACCOUNT_BROKERS_COOPERATIVES:
            column = [
                { key: 0, label: "Member Type ID" },
                { key: 1, label: "Name" },
                { key: 2, label: "Type" },
                { key: 3, label: "Email" },
                { key: 4, label: "Contact Number" },
                { key: 5, label: "Status" },
                { key: 6, label: "Action" },
            ];
            break;
        case app_consts.SPECIFIC_PRODUCER_WHR_GRN_TABLE:
            column = [
                { key: 0, label: "" },
                { key: 1, label: "Receipt Number" },
                { key: 2, label: "Commodity" },
                { key: 3, label: "Quantity (MT)" },
                { key: 4, label: "Date Created" },
                { key: 5, label: "Status" },
            ];
            break;
        case app_consts.INVALID_COOPERATIVE_ENTITY_CSV_DATA:
            column = [
                { key: 0, label: "" },
                { key: 1, label: "Contact Name" },
                { key: 2, label: "Contact Number" },
                { key: 3, label: "Contact Email" },
                { key: 4, label: "Entity Name" },
                { key: 5, label: "Entity Email" },
                { key: 6, label: "Entity Mobile" },
                { key: 7, label: "Area" },
                { key: 8, label: "District" },
            ];
            break;
        case app_consts.VALID_COOPERATIVE_ENTITY_CSV_DATA:
            column = [
                { key: 0, label: "C. First Name" },
                { key: 1, label: "C. Middle Name" },
                { key: 2, label: "C. Last Name" },
                { key: 3, label: "C. Mobile" },
                { key: 4, label: "C. Email" },
                { key: 5, label: "E. Name" },
                { key: 6, label: "E. Mobile" },
                { key: 7, label: "E. Email" },
                { key: 8, label: "Area" },
                { key: 9, label: "District" },
                { key: 10, label: "Province" },
            ];
            break;
        case app_consts.VALID_COOPERATIVE_MEMBER_CSV_DATA:
            column = [
                { key: 0, label: "First Name" },
                { key: 1, label: "Middle Name" },
                { key: 2, label: "Last Name" },
                { key: 3, label: "Gender" },
                { key: 4, label: "Phone Number" },
                { key: 5, label: "NRC Number" },
                { key: 6, label: "Email" },
                { key: 7, label: "Member Type ID" },
                { key: 8, label: "Commodities" }
            ];
            break;
        case app_consts.INVALID_COOPERATIVE_MEMBER_CSV_DATA:
            column = [
                { key: 0, label: "" },
                { key: 1, label: "Name" },
                { key: 2, label: "Gender" },
                { key: 3, label: "Phone Number" },
                { key: 4, label: "NRC Number" },
                { key: 5, label: "Email" },
                { key: 6, label: "Member Type ID" },
                { key: 7, label: "Commodities" }
            ];
    }
    return column;
};