import React, { useEffect, useState } from 'react';
import { updateBrokerTradingAuthority, updateBrokerTradingAuthorityReset } from '../../utils/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { format } from 'date-fns';
import * as app_consts from '../../utils/constants';

const TradingAuthorityModal = ({ showModal, closeModal, successFunction, entityprofile, main_access_account }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { organization_id, member_type_id } = useParams();
  const [brokerProfile, setBrokerProfile] = useState([]);
  const [main_profile, setmainProfile] = useState([]);

  useEffect(() => {
    if (organization_id)
    {
      const broker = entityprofile?.find(profile => profile.type === app_consts.BROKER.toLowerCase() && profile.entity_mid === organization_id);
      setBrokerProfile(broker);
      setmainProfile(main_access_account);
    }
    else if (member_type_id)
    {
      setBrokerProfile(entityprofile);
      setmainProfile(main_access_account);
    }
  }, [entityprofile, main_access_account]);


  const initialValues = {
    bidding_limit: '',
    bidding_status: '',
    offering_and_bidding_authority: '',
    brokership_type: '',
  };

  const validationSchema = yup.object().shape({
    bidding_limit: yup.string().required('Bidding Limit is required'),
    bidding_status: yup.string().required('Bidding Status is required'),
    offering_and_bidding_authority: yup.string().required('Offering and Bidding Authority is required'),
    brokership_type: yup.string().required('Brokership Type is required'),
  });

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const checkboxText = () => {
    switch (formik.values.brokership_type)
    {
      case 'clearing_member':
        return (
          <>
            I confirm that<span className='font-bold text-green-600'> {brokerProfile?.entity_name} </span>has deposited <span className='font-bold text-green-600'>1,300,000.00 ZMW</span>{` `}into ZAMACE Accounts.
          </>
        );
      case 'trading_broker_member':
        return (
          <>
            I confirm that<span className='font-bold text-green-600'> {brokerProfile?.entity_name} </span>has deposited <span className='font-bold text-green-600'>300,000 ZMW</span>{` `}into ZAMACE Accounts.
          </>
        );
      case 'aggregating_broker_member':
        return (
          <>
            I confirm that<span className='font-bold text-green-600'> {brokerProfile?.entity_name} </span>has deposited <span className='font-bold text-green-600'>30,000 ZMW</span>{` `}into ZAMACE Accounts.
          </>
        );
      default:
        return '';
    }
  };

  // Create a useFormik instance with the form configuration
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      try
      {
        const brokerId = main_access_account?.profile_id;
        const entityId = brokerProfile?.entity_id;
        const accountId = main_access_account?.account_id;

        const brokerData = {
          account_id: accountId,
          entity_id: entityId,
          broker_id: brokerId,
          bidding_limit: values.bidding_limit,
          bidding_status: values.bidding_status,
          offering_and_bidding_authority: values.offering_and_bidding_authority,
          brokership_type: values.brokership_type,
        };

        setLoading(true);
        setError('');

        await dispatch(updateBrokerTradingAuthority(brokerData));
        setLoading(false);
      } catch (error)
      {
        console.log(error);
        setError(error.message);
      } finally
      {
        setLoading(false);
      }
    },
  });

  // successFunction
  const trading_authority_listener = useSelector((state) => state.updateBrokerTradingAuthority);
  // 
  useEffect(() => {
    if (trading_authority_listener.status !== app_consts.IDLE)
    {
      if (trading_authority_listener.isLoading)
      {
        setLoading(true);
      }
      else
      {
        setLoading(false);
        if (trading_authority_listener.error)
        {
          setError(trading_authority_listener.error);
        }
        else
        {
          formik.resetForm();
          closeModal();
          successFunction();
        }
        dispatch(updateBrokerTradingAuthorityReset());
      }
    }
  }, [trading_authority_listener]);

  return (
    <>
      {showModal ? (
        <div className='fixed inset-0 flex items-center justify-center z-50 overflow-y-auto'>
          <div className='fixed inset-0 bg-black opacity-50'></div>
          <div className='relative p-4 bg-white rounded-lg shadow dark:bg-gray-800 md:p-8 max-h-screen overflow-y-auto'>
            <div className='flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5 dark:border-gray-600'>
              <h3 className='text-lg font-semibold text-gray-900 dark:text-white'>
                Trading Authority
              </h3>
              <button
                type='button'
                onClick={() => closeModal()}
                className='text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white'
                data-modal-toggle='updateProductModal'
              >
                <svg
                  aria-hidden='true'
                  className='w-5 h-5'
                  fill='currentColor'
                  viewBox='0 0 20 20'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    fillRule='evenodd'
                    d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
                    clipRule='evenodd'
                  ></path>
                </svg>
                <span className='sr-only'>Close</span>
              </button>
            </div>

            <form
              className='space-y-4 md:space-y-6'
              onSubmit={formik.handleSubmit}
            >
              <ol className='items-center w-full sm:w-auto space-y-4 sm:flex sm:space-x-8 sm:space-y-0'>
                <li className='flex items-center text-green-600 dark:text-green-500 space-x-2.5'>
                  <span className='flex items-center justify-center w-8 h-8 border border-green-600 rounded-full shrink-0 dark:border-green-500'>
                    1
                  </span>
                  <span>
                    <h4 className='font-medium leading-tight'>
                      Trading Authority
                    </h4>
                    <p className='text-sm'>Set Trading Authority For Broker</p>
                  </span>
                </li>
              </ol>

              <div className='container grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3'>
                <div>
                  <label
                    htmlFor='bidding_limit'
                    className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                  >
                    Bidding limit (Tier)
                  </label>
                  <select
                    name='bidding_limit'
                    value={formik.values.bidding_limit}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    required
                    className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500'
                  >
                    <option value=''>Set Bidding Limit</option>
                    <option value='tier1'>T + 1 (0 - 5,000,000)</option>
                    <option value='tier2'>
                      T + 2 (5,000,001 - 15,000,000)
                    </option>
                    <option value='tier3'>T + 3 (15,000,000 and above)</option>
                  </select>
                  {formik.touched.bidding_limit &&
                    formik.errors.bidding_limit && (
                      <p className='text-red-500 text-sm mt-1'>
                        {formik.errors.bidding_limit}
                      </p>
                    )}
                </div>

                <div>
                  <label
                    htmlFor='bidding_status'
                    className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                  >
                    Bidding Status
                  </label>
                  <select
                    name='bidding_status'
                    value={formik.values.bidding_status}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    required
                    className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500'
                  >
                    <option value=''>Set Bidding Status</option>
                    <option value='active'>active</option>
                    <option value='suspended'>suspended</option>
                  </select>
                  {formik.touched.bidding_status &&
                    formik.errors.bidding_status && (
                      <p className='text-red-500 text-sm mt-1'>
                        {formik.errors.bidding_status}
                      </p>
                    )}
                </div>
                <div>
                  <label
                    htmlFor='offering_and_bidding_authority'
                    className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                  >
                    Offering and Bidding Authority
                  </label>
                  <select
                    name='offering_and_bidding_authority'
                    value={formik.values.offering_and_bidding_authority}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    required
                    className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500'
                  >
                    <option value=''>Set Offer Authority</option>
                    <option value='client_only'>Client Only</option>
                    <option value='client_and_own'>Client And Own</option>
                  </select>
                  {formik.touched.offering_and_bidding_authority &&
                    formik.errors.offering_and_bidding_authority && (
                      <p className='text-red-500 text-sm mt-1'>
                        {formik.errors.offering_and_bidding_authority}
                      </p>
                    )}
                </div>
                <div>
                  <label
                    htmlFor='brokership_type'
                    className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                  >
                    Brokership Type
                  </label>
                  <select
                    name='brokership_type'
                    value={formik.values.brokership_type}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    required
                    className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500'
                  >
                    <option value=''>Set Brokership Type</option>
                    <option value='clearing_member'>Clearing Member</option>
                    <option value='trading_broker_member'>Trading Broker Member</option>
                    <option value='aggregating_broker_member'>Aggregating Broker Member</option>
                  </select>
                  {formik.touched.brokership_type &&
                    formik.errors.brokership_type && (
                      <p className='text-red-500 text-sm mt-1'>
                        {formik.errors.brokership_type}
                      </p>
                    )}
                </div>
              </div>
              <br />
              {formik.values.brokership_type && (
                <div className='flex items-center'>
                  <input
                    id='link-checkbox'
                    // checked={isChecked}
                    onChange={handleCheckboxChange}
                    type='checkbox'
                    value=''
                    className='w-4 h-4 text-green-600 bg-gray-100 border-gray-300 rounded focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600'
                  />
                  <label
                    htmlFor='link-checkbox'
                    className='ml-2 text-sm font-medium text-gray-900 dark:text-gray-300'
                  >
                    {checkboxText()}
                  </label>
                </div>
              )}

              <div className='flex justify-end space-x-4'>
                <button
                  type='button'
                  onClick={closeModal}
                  className='text-gray-900 bg-white hover:bg-gray-100 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 mr-2 mb-2'
                >
                  Close
                </button>
                <button
                  type='submit'
                  disabled={(!formik.isValid || !isChecked)}
                  className={`text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-primary-800 mr-2 mb-2 ${!formik.isValid || !isChecked ? 'opacity-50 cursor-not-allowed' : ''}`}
                >
                  {loading
                    ? 'Setting Authority...'
                    : 'Set Authority'}
                </button>
              </div>
              {error && (
                <div
                  className='p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400'
                  role='alert'
                >
                  <span className='font-medium'>Error: </span>
                  {error}
                </div>
              )}
              {loading && (
                <div className='text-center'>
                  <div role='status'>
                    <svg
                      aria-hidden='true'
                      className='inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-green-600'
                      viewBox='0 0 100 101'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z'
                        fill='currentColor'
                      />
                      <path
                        d='M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z'
                        fill='currentFill'
                      />
                    </svg>
                    <span className='sr-only'>Please wait...</span>
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default TradingAuthorityModal;