import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { createProfile, getCurrentAccount } from '../../utils/actions';
import NavHeader from '../../layouts/NavHeader';
import Footer from '../../layouts/Footer';
import { useState } from 'react';
import { useNavigate } from 'react-router';

const validationSchema = yup.object().shape({
    orgName: yup.string().required('Organization name is required.'),
    pacraRegNo: yup.string().required('PACRA Registration No. is required.'),
    zraTPIN: yup.string().required('ZRA TPIN Number is required.'),
    profileType: yup.string().required('Please select a membership type before proceeding'),
});

const MembershipType = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [openRegistrationUnavailableModal, setOpenRegistrationUnavailableModal] = useState(false);

    const formik = useFormik({
        initialValues: {
            orgName: '',
            pacraRegNo: '',
            zraTPIN: '',
            profileType: '',
            cooperativeSector: '',
        },
        validationSchema,
        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(true);

            // Check if account_id exists in localStorage
            const account = JSON.parse(localStorage.getItem('account'));
            if (!account || !account.id)
            {
                setError('Account not found. Please make sure you are logged in.');
                setSubmitting(false);
                return;
            }
            const rolesMap = {
                broker: ["administrator", "trader", "clearing", "settlement"],
                warehouse_operator: ["administrator", "manager", "inventory_control", "quality_assurance", "operator", "logistics", "billing", "customer_service"],
                cooperative: ["administrator", "manager", "inventory_control", "quality_assurance", "operator", "logistics", "billing", "customer_service"],
                service_provider: ["administrator", "manager", "inventory_control", "quality_assurance", "operator", "logistics", "billing", "customer_service"],
                investor: ["administrator", "manager", "inventory_control", "quality_assurance", "operator", "logistics", "billing", "customer_service"]
            };

            const roles = rolesMap[values.profileType];

            const profileData = {
                account_id: account.id,
                profile: {
                    name: values.orgName,
                    roles,
                    status: "pending",
                    type: values.profileType
                },
                entity: {
                    entity_name: values.orgName,
                    entity_reg_no: values.pacraRegNo,
                    entity_tax_no: values.zraTPIN,
                    entity_type: values.profileType,
                    entity_sector: values.cooperativeSector,
                    status: "pending",
                    verified: 0,
                    active: 0
                }
            };

            try
            {
                await dispatch(createProfile(profileData.entity, profileData.profile));
                await dispatch(getCurrentAccount());
                setSubmitting(false);
                navigate('/complete_entity_kyc');
            } catch (error)
            {
                if (error.message === 'Organization with given registration number or tax number already exists')
                {
                    navigate('/entity_exists', { state: { entityId: error.entityId } });
                } else
                {
                    setSubmitting(false);
                    console.error(error);
                }
            }

        }

    });

    const {
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isSubmitting
    } = formik;

    const getType = (selectedType) =>
        values.profileType === selectedType
            ? "border rounded-md py-3 px-3 flex items-center justify-center text-sm font-medium uppercase sm:flex-1 cursor-pointer focus:outline-none bg-green-600 border-transparent text-white hover:bg-green-700"
            : "border rounded-md py-3 px-3 flex items-center justify-center text-sm font-medium uppercase sm:flex-1 cursor-pointer focus:outline-none bg-white border-gray-200 text-gray-900 hover:bg-gray-50";


    return (
        <>
            <NavHeader />
            <section class="flex h-screen ">
                <div class="w-full lg:px-9">
                    <div class="relative overflow-hidden bg-white dark:bg-gray-800 sm:rounded-lg">
                        <div className="grid w-full place-items-center bg-white p-6 sm:p-16 lg:p-8">
                            <form className="space-y-6" onSubmit={handleSubmit}>
                                <ol className="items-center w-full space-y-4 sm:flex sm:space-x-8 sm:space-y-0">

                                    <li className="flex items-center text-gray-600 dark:text-gray-500 space-x-2.5">
                                        <span className="flex items-center justify-center w-8 h-8 border border-gray-600 rounded-full shrink-0 dark:border-gray-500">
                                            2
                                        </span>
                                        <span>
                                            <h3 className="font-medium leading-tight">Verify your registration</h3>
                                            <p className="text-sm">Use OTP for verification</p>
                                        </span>
                                    </li>
                                    <li className="flex items-center text-green-600 dark:text-green-500 space-x-2.5">
                                        <span className="flex items-center justify-center w-8 h-8 border border-green-600 rounded-full shrink-0 dark:border-green-500">
                                            3
                                        </span>
                                        <span>
                                            <h3 className="font-medium leading-tight">Membership Type</h3>
                                            <p className="text-sm">Select membership type</p>
                                        </span>
                                    </li>
                                    <li className="flex items-center text-gray-600 dark:text-gray-500 space-x-2.5">
                                        <span className="flex items-center justify-center w-8 h-8 border border-gray-600 rounded-full shrink-0 dark:border-gray-500">
                                            4
                                        </span>
                                        <span>
                                            <h3 className="font-medium leading-tight">Organization Information</h3>
                                            <p className="text-sm">Provide organization details</p>
                                        </span>
                                    </li>
                                    <li className="flex items-center text-gray-600 dark:text-gray-500 space-x-2.5">
                                        <span className="flex items-center justify-center w-8 h-8 border border-gray-600 rounded-full shrink-0 dark:border-gray-500">
                                            5
                                        </span>
                                        <span>
                                            <h3 className="font-medium leading-tight">Directors & Management</h3>
                                            <p className="text-sm">Provide KYC information</p>
                                        </span>
                                    </li>
                                    <li className="flex items-center text-gray-600 dark:text-gray-500 space-x-2.5">
                                        <span className="flex items-center justify-center w-8 h-8 border border-gray-600 rounded-full shrink-0 dark:border-gray-500">
                                            6
                                        </span>
                                        <span>
                                            <h3 className="font-medium leading-tight">Add Financial Details</h3>
                                            <p className="text-sm">Provide Financial Details</p>
                                        </span>
                                    </li>
                                    <li className="flex items-center text-gray-600 dark:text-gray-500 space-x-2.5">
                                        <span className="flex items-center justify-center w-8 h-8 border border-gray-600 rounded-full shrink-0 dark:border-gray-500">
                                            7
                                        </span>
                                        <span>
                                            <h3 className="font-medium leading-tight">Submit Registration</h3>
                                            <p className="text-sm">Submit registration for verification</p>
                                        </span>
                                    </li>

                                </ol>

                                <div className="container grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
                                    <div className="mb-6">
                                        <label htmlFor="orgName-input" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Registered Organization Name</label>
                                        <input
                                            type="text"
                                            name="orgName"
                                            value={values.orgName}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                                        />
                                        {touched.orgName && errors.orgName && <p className="text-red-500 text-sm mt-1">{errors.orgName}</p>}
                                    </div>
                                    <div className="mb-6">
                                        <label htmlFor="pacraRegNo-input" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">PACRA Registration No.</label>
                                        <input
                                            type="text"
                                            name="pacraRegNo"
                                            value={values.pacraRegNo}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                                        />
                                        {touched.pacraRegNo && errors.pacraRegNo && <p className="text-red-500 text-sm mt-1">{errors.pacraRegNo}</p>}
                                    </div>
                                    <div className="mb-6">
                                        <label htmlFor="zraTPIN-input" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">ZRA TPIN Number</label>
                                        <input
                                            type="text"
                                            name="zraTPIN"
                                            value={values.zraTPIN}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                                        />
                                        {touched.zraTPIN && errors.zraTPIN && <p className="text-red-500 text-sm mt-1">{errors.zraTPIN}</p>}
                                    </div>
                                    {values.profileType === 'cooperative' && (
                                        <div className="mb-6">
                                            <label htmlFor="cooperativeSector" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                                Cooperative Sector
                                            </label>
                                            <select
                                                name="cooperativeSector"
                                                value={values.cooperativeSector}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                                            >
                                                <option value="" disabled>Select Sector</option>
                                                <option value="agriculture">Agriculture</option>
                                                <option value="timber">Timber</option>
                                                <option value="mining">Mining</option>
                                                <option value="carbon_credit">Carbon Credit</option>
                                                <option value="savings_group">Savings Group</option>
                                            </select>
                                            {touched.cooperativeSector && errors.cooperativeSector && <p className="text-red-500 text-sm mt-1">{errors.cooperativeSector}</p>}
                                        </div>
                                    )}
                                </div>
                                <p className="mb-5 text-base text-gray-900 sm:text-lg font-thin dark:text-gray-400">Select the type of membership you intend to register for as outlined below:</p>
                                <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
                                    <div className="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                                        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Broker</h5>
                                        <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">A Broker member engages in the business of soliciting or accepting orders for the purchase or sale of commodities. They possess the necessary qualifications to execute customer transactions on ZAMACE.</p>
                                        <label
                                            htmlFor="broker"
                                            className={getType('broker')}
                                        >

                                            <input
                                                onChange={() => setFieldValue('profileType', 'broker')}
                                                id="broker"
                                                type="radio"
                                                name="profileType"
                                                value="broker"
                                                className="sr-only"
                                                aria-labelledby="type-choice-0-label"
                                            />
                                            <p id="type-choice-0-label">Register as Broker</p>
                                        </label>
                                    </div>
                                    <div className="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                                        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Warehouse Operator</h5>
                                        <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">A ZAMACE Certified Warehouse Operator is an authorized storage operator responsible for storing commodities traded on the Exchange and issuing ZAMACE Certified Warehouse Receipts.</p>
                                        <label
                                            htmlFor="warehouse_operator"
                                            className={getType('warehouse_operator')}
                                        >
                                            <input
                                                onChange={() => setFieldValue('profileType', 'warehouse_operator')}
                                                id="warehouse_operator"
                                                type="radio"
                                                name="profileType"
                                                value="warehouse_operator"
                                                className="sr-only"
                                                aria-labelledby="type-choice-0-label"
                                            />

                                            <p id="type-choice-1-label">Register as Warehouse Operator</p>
                                        </label>
                                    </div>
                                    <div className="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                                        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Cooperative</h5>
                                        <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">A cooperative enables its producer-members to participate collectively on ZAMACE and benefit from favorable trading terms by aggregating their produce and selling it as a single unit on ZAMACE.</p>
                                        <label
                                            htmlFor="cooperative"
                                            className={getType('cooperative')}
                                        >
                                            <input
                                                // onChange={() => setFieldValue('profileType', 'cooperative')}
                                                onClick={() => setOpenRegistrationUnavailableModal(true)}
                                                id="cooperative"
                                                type="radio"
                                                name="profileType"
                                                value="cooperative"
                                                className="sr-only"
                                                aria-labelledby="type-choice-0-label"
                                            />

                                            <p id="type-choice-2-label">Register as Cooperative</p>
                                        </label>
                                    </div>
                                </div>
                                <br />
                                <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
                                    <div className="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                                        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Service Provider</h5>
                                        <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">A Service Provider provides services such as transport and logistic services, input and implement suppliers, extension services, collateral managers, and other related professional services on the Exchange.</p>
                                        <label
                                            htmlFor="service_provider"
                                            className={getType('service_provider')}
                                        >
                                            <input
                                                onChange={() => setFieldValue('profileType', 'service_provider')}
                                                id="service_provider"
                                                type="radio"
                                                name="profileType"
                                                value="service_provider"
                                                className="sr-only"
                                                aria-labelledby="type-choice-0-label"
                                            />

                                            <p id="type-choice-3-label">Register as Service Provider</p>
                                        </label>
                                    </div>
                                    <div className="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                                        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Investor</h5>
                                        <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">To participate as an investor on ZAMACE, begin the registration process by creating an investment account and completing the required registration and verification procedures.</p>
                                        <label
                                            htmlFor="investor"
                                            className={getType('investor')}
                                        >
                                            <input
                                                onChange={() => setFieldValue('profileType', 'investor')}
                                                id="investor"
                                                type="radio"
                                                name="profileType"
                                                value="investor"
                                                className="sr-only"
                                                aria-labelledby="type-choice-0-label"
                                            />

                                            <p id="type-choice-4-label">Register as Investor</p>
                                        </label>
                                    </div>
                                    <div className="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                                        <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">For the next process, please ensure you have your organization's contact details, profile, company registration certificate, tax registration certificate, details of company directors, physical IDs, and audited statements of accounts (if applicable).</p>
                                        <br />
                                        {loading && <div className="text-center">
                                            <div role="status">
                                                <svg aria-hidden="true" className="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-green-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                                </svg>
                                                <span className="sr-only">Please wait...</span>
                                            </div>
                                        </div>}
                                        {error && <div className="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
                                            <span className="font-medium">Error: </span>{error}</div>}
                                        <button type="submit" disabled={isSubmitting} className="inline-flex items-center px-3 py-2 text-md font-medium text-center text-white bg-green-700 rounded-lg hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">
                                            Continue Registration
                                            <svg aria-hidden="true" className="w-4 h-4 ml-2 -mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                        </button>


                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    {openRegistrationUnavailableModal && (
                        <div className="fixed inset-0 flex items-center justify-center z-50 overflow-y-auto">
                            <div className="fixed inset-0 bg-black opacity-50"></div>
                            {/* Modal content goes here */}
                            <div className="relative p-4 bg-white lg:w-4/12 w-5/6 rounded-lg shadow dark:bg-gray-800 md:p-8 max-h-screen overflow-y-auto">
                                <div class="flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5 dark:border-gray-600">
                                    <h3 class="text-lg font-semibold text-gray-900 dark:text-white">
                                        Registration unavailable
                                    </h3>
                                    <button type="button" onClick={() => setOpenRegistrationUnavailableModal(false)} class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="updateProductModal">
                                        <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                        <span class="sr-only">Close modal</span>
                                    </button>
                                </div>
                                <div className='flex flex-row items-center justify-center font-meduim text-gray-800 p-4'>
                                    <span className='leading-6 tracking-wider'>Please contact Zamace for Cooperative Registration</span>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </section>
            <Footer />
        </>
    );
};

export default MembershipType;

