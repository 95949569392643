import React, { useEffect, useState } from 'react';
// import { div, Document, img, div, StyleSheet, div, Font } from "@react-pdf/renderer";
// Import the font files
import LatoRegular from '../../utils/fonts/Lato-Regular.ttf';
import LatoBold from '../../utils/fonts/Lato-Bold.ttf';
import { format } from 'date-fns';
import { convertUnderscoreToTitleCase } from '../../utils/functionalUtils';
// import QRCode from 'qrcode';
import PDFSVGLogo from "../../components/PDFSVGLogo";
import DynamicNavLogo from '../../components/DynamicNavLogo';
import QRCode from 'qrcode';
import { useRef } from 'react';
import generatePDF from 'react-to-pdf';
import * as app_consts from '../../utils/constants';
import { Page, Text, View, Document, StyleSheet, Font, Image } from '@react-pdf/renderer';

const WarehouseReceiptPDF = ({ data, downloadWarehouseReceipt, downloadable, setDownloadWarehouseReceipt }) => {

    const targetRef = useRef();
    // Register the Lato font
    Font.register({ family: 'Lato', fonts: [{ src: LatoRegular }, { src: LatoBold, fontWeight: 'bold' }] });

    const [receiptData, setReceiptData] = useState(data);
    const [genQRCode, setGenQRCode] = useState("");

    useEffect(() => {
        if (data)
        {
            setReceiptData(data);
            setGenQRCode(QRCode.toDataURL(`https://www.zamace.co.zm/${receiptData?.receipt_number}/${receiptData?.generation_id}`));
            // generateQRCodeimg(`https://www.zamace.co.zm/${receiptData?.receipt_number}/${receiptData?.generation_id}`);
        }
    }, [data]);

    useEffect(() => {
        if (downloadable === true && downloadWarehouseReceipt === true)
        {
            generatePDF(targetRef, { filename: `${receiptData?.receipt_number} Warehouse Receipt` || "Warehouse Receipt" });
            setDownloadWarehouseReceipt(false);
        }
    }, [downloadWarehouseReceipt]);

    // Generated QR Code
    // const generateQRCodeimg = async (data) => {
    //     try
    //     {
    //         const data_qr_code = QRCode.toCanvas(data);
    //         // Generate QR code as an img
    //         const qrDataURL = await QRCode.toDataURL(data);

    //         const blob = await qrDataURL.blob();
    //         return URL.createObjectURL(blob);
    //     } catch (error)
    //     {
    //         console.error('Error generating QR code:', error);
    //         return null;
    //     }
    // };

    // Font Size for Address and Name
    const getFontSize = (receiptData) => {
        if (receiptData?.depositor_type)
        {
            if (receiptData?.depositor_type !== app_consts.INDIVIDUAL.toLowerCase())
            {
                return receiptData?.depositor_entity_name?.length > 33 ? 12 : 14;
            } else
            {
                return receiptData?.depositor_name?.length > 33 ? 12 : 14;
            }
        }
        return 14;
    };


    return (
        <>
            {
                receiptData && (
                    <Document title={`${receiptData?.receipt_number}Warehouse Receipt`} ref={targetRef}>
                        <Page size="A4" style={{
                            flexDirection: "column",
                            backgroundColor: "rgba(0,0,0,0)",
                            height: "100%",
                            fontFamily: "Lato"
                        }}>
                            <View fixed>
                                <View style={{
                                    width: "100%",
                                    paddingRight: 10,
                                    paddingTop: 10,
                                    paddingBottom: 10,
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    alignItems: "start",
                                    marginBottom: 5,
                                }}>
                                    <View style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        textTransform: "uppercase",
                                        width: "50%",
                                        paddingLeft: 5,
                                    }}>
                                        <View style={{ display: "flex", flexDirection: "column", alignItems: "end", justifyContent: "end", position: 'relative' }}>
                                            <PDFSVGLogo height='100px' width='100%' />
                                            <Text style={{ letterSpacing: "-0.29px", fontWeight: 400, paddingLeft: 6, fontSize: 21, color: "#000", wordSpacing: "0.09px", lineHeight: 0.2, }}>WAREHOUSE RECEIPT</Text>
                                        </View>
                                        <View style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            marginTop: 25,
                                            paddingLeft: 5
                                        }}>
                                            <Text style={{ fontWeight: 700, fontSize: 14, color: "#000" }}>CURRENT OWNER</Text>
                                            <Text style={{ fontWeight: 400, fontSize: getFontSize(receiptData) }}>{receiptData.depositor_type ? <>{receiptData.depositor_type !== app_consts.INDIVIDUAL.toLowerCase() ? receiptData?.depositor_entity_name.toUpperCase() : receiptData?.depositor_name.toUpperCase()}</> : ""}</Text>
                                        </View>
                                        <View style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            marginTop: 5,
                                            paddingLeft: 6
                                        }}>
                                            <Text style={{ fontWeight: 700, fontSize: 14, color: "#000" }}>CURRENT OWNER IDENTIFICATION NO.</Text>
                                            <Text style={{ fontWeight: 400, fontSize: 14 }}>{receiptData.depositor_id && (receiptData.depositor_id.toUpperCase())}</Text>
                                        </View>
                                        <View style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            marginTop: 8,
                                            paddingLeft: 6
                                        }}>
                                            <Text style={{ fontWeight: 700, fontSize: 14, color: "#000" }}>CURRENT ADDRESS</Text>
                                            <Text style={{ fontWeight: 400, fontSize: getFontSize(receiptData) }}>{receiptData.depositor_type ? <>{receiptData.depositor_type !== app_consts.INDIVIDUAL.toLowerCase() ? <>{receiptData.depositor_entity_address ? receiptData?.depositor_entity_address.toUpperCase() : receiptData?.depositor_address.toUpperCase()}</> : receiptData?.depositor_address.toUpperCase()}</> : ""}</Text>
                                        </View>
                                        <View style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            marginTop: 8,
                                            paddingLeft: 6
                                        }}>
                                            <Text style={{ fontWeight: 700, fontSize: 14, color: "#000" }}>ABSOLUTE OWNER ID</Text>
                                            <Text style={{ fontWeight: 400, fontSize: getFontSize(receiptData) }}>{receiptData.absolute_owner_id && (receiptData.absolute_owner_id.toUpperCase())}</Text>
                                        </View>
                                        <View style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            marginTop: 8,
                                            paddingLeft: 6
                                        }}>
                                            <Text style={{ fontWeight: 700, fontSize: 14, color: "#000" }}>ABSOLUTE OWNER NAME</Text>
                                            <Text style={{ fontWeight: 400, fontSize: 14 }}>{receiptData.absolute_owner_name && (receiptData.absolute_owner_name.toUpperCase())}</Text>
                                        </View>
                                    </View>
                                    <View style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "end",
                                        justifyContent: "end",
                                        textTransform: "uppercase",
                                        width: "50%"
                                    }}>
                                        <View style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "flex-end",
                                        }}>
                                            <Text style={{ letterSpacing: 3, fontWeight: 600, fontSize: 21 }}>RECEIPT SERIAL NO.</Text>
                                            <Text style={{ fontWeight: 400, fontSize: 12, color: "#252629", lineHeight: 1.5 }}>{receiptData.receipt_number && (receiptData.receipt_number)}</Text>
                                        </View>
                                        <View style={{
                                            marginTop: 10, display: "flex",
                                            flexDirection: "column",
                                            alignItems: "flex-end",
                                        }}>
                                            <View style={{
                                                marginTop: 2,
                                                paddingLeft: 6,
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "flex-end",
                                                marginBottom: 5
                                            }}>
                                                <Text style={{ fontWeight: 700, marginRight: 2, fontSize: 14, color: "#000", }}>NEGOTIABLE STATUS</Text>
                                                <Text style={{ letterSpacing: 1, fontWeight: 400, fontSize: 14 }}>{receiptData.negotiable ? "NEGOTIABLE" : "NON-NEGOTIABLE"}</Text>
                                            </View>
                                            <View style={{
                                                marginTop: 2, display: "flex",
                                                flexDirection: "row",
                                                alignItems: "flex-end",
                                            }}>
                                                <Text style={{ fontWeight: 700, marginRight: 2, fontSize: 14, color: "#000" }}>DATE ISSUED:</Text>
                                                <Text style={{ fontWeight: 400, fontSize: 14 }}>{receiptData.deposit_date && (format(new Date(receiptData.deposit_date), 'yyyy-MM-dd'))}</Text>
                                            </View>
                                            <View style={{
                                                marginTop: 2, display: "flex",
                                                flexDirection: "row",
                                                alignItems: "flex-end",
                                            }}>
                                                <Text style={{ fontWeight: 700, marginRight: 2, fontSize: 14, color: "#000" }}>MATURITY DATE:</Text>
                                                <Text style={{ fontWeight: 400, fontSize: 14 }}>{receiptData.maturity_date && (format(new Date(receiptData.maturity_date), 'yyyy-MM-dd'))}</Text>
                                            </View>
                                        </View>
                                        <View style={{
                                            marginTop: 10, display: "flex",
                                            flexDirection: "column",
                                            alignItems: "flex-end"
                                        }}>
                                            <View style={{
                                                marginTop: 3, display: "flex",
                                                flexDirection: "column",
                                                alignItems: "flex-end",
                                                paddingLeft: 6
                                            }}>
                                                <Text style={{ letterSpacing: 1, fontWeight: 700, fontSize: 14, color: "#000" }}>WAREHOUSE OPERATOR</Text>
                                                <Text style={{ letterSpacing: 1, fontWeight: 400, fontSize: 14 }}>{receiptData.warehouse_operator_name && (receiptData.warehouse_operator_name.toUpperCase())}</Text>
                                            </View>
                                            <View style={{
                                                marginTop: 10,
                                                paddingLeft: 6,
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "flex-end",
                                            }}>
                                                <Text style={{
                                                    marginRight: 2, letterSpacing: 1, fontWeight: 700, fontSize: 14, color: "#000"
                                                }}>Storage Site:</Text>
                                                <Text style={{ letterSpacing: 1, fontWeight: 400, fontSize: 14 }}>{receiptData.storage_site && (receiptData.storage_site.toUpperCase())}</Text>
                                            </View>
                                            <View style={{
                                                marginTop: 10,
                                                paddingLeft: 6,
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "flex-end",
                                            }}>
                                                <Text style={{ letterSpacing: 1, fontWeight: 700, fontSize: 14, color: "#000" }}>District:</Text>
                                                <Text style={{ letterSpacing: 1, fontWeight: 400, fontSize: 14 }}>{receiptData.district
                                                    && (receiptData.district.toUpperCase())}</Text>
                                            </View>
                                            <View style={{
                                                marginTop: 10, display: "flex",
                                                flexDirection: "column",
                                                alignItems: "flex-end"
                                            }}>
                                                <Text style={{ letterSpacing: 1, fontWeight: 700, paddingLeft: 6, fontSize: 14, color: "#000" }}>OPERATOR ZAMACE ID</Text>
                                                <Text style={{ letterSpacing: 1, fontWeight: 400, paddingLeft: 6, fontSize: 14 }}>{receiptData.operator_id && (receiptData.operator_id)}</Text>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                            </View>
                            <View style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                                width: "100%",
                                paddingLeft: 10,
                                paddingRight: 30,
                                paddingTop: 10,
                                paddingBottom: 5,
                                alignItems: "start",
                                marginBottom: 5,
                                position: "relative"
                            }}>
                                <View style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "flex-end",
                                }}>

                                    <Text style={{
                                        letterSpacing: 1, marginRight: 2, fontWeight: 700, fontSize: 14, color: "#000",
                                        textTransform: "uppercase"
                                    }}>Product:</Text>
                                    <Text style={{ letterSpacing: 1, fontWeight: 400, fontSize: 14 }}>{receiptData.commodity_name && (convertUnderscoreToTitleCase(receiptData.commodity_name.toUpperCase()))}</Text>
                                </View>
                                <View style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "flex-end",
                                }}>
                                    <Text style={{
                                        letterSpacing: 1, fontWeight: 700, marginRight: 2, fontSize: 14, color: "#000",
                                        textTransform: "uppercase"
                                    }}>Grade:</Text>
                                    <Text style={{ letterSpacing: 1, fontWeight: 400, fontSize: 14 }}>{receiptData.grade && (receiptData.grade.toUpperCase())}</Text>
                                </View>
                                <View style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "flex-end",
                                }}>
                                    <Text style={{
                                        letterSpacing: 1, marginRight: 2, fontWeight: 700, fontSize: 14, color: "#000",

                                    }}>QUANTITY (in metric tonnes):</Text>
                                    <Text style={{ letterSpacing: 1, fontWeight: 400, fontSize: 14 }}>{receiptData.commodity_quantity && parseFloat(receiptData.commodity_quantity).toFixed(2)}</Text>
                                </View>
                            </View>
                            <View style={{ marginTop: 5 }}>
                                <View style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center "
                                }}>
                                    <Text style={{ letterSpacing: 1, fontWeight: 700, paddingLeft: 6, fontSize: 21, textAlign: "center" }}>STORAGE OPERATOR STORES</Text>
                                    <Text style={{ marginTop: 3, fontSize: "24px", textAlign: "center" }}>(metric tonnes in words)</Text>
                                </View>
                                <View style={{
                                    marginTop: 10, display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center ",
                                    justifyContent: "space-between",
                                    width: "90%",
                                    alignItems: "start",
                                    marginBottom: 5,
                                    margin: "auto"
                                }}>
                                    <View style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center ",
                                        justifyContent: "center"
                                    }}>
                                        <Text style={{
                                            fontSize: 12,
                                            textAlign: "center"
                                        }}>TEN THOUSANDS</Text>
                                        <View style={{
                                            border: '1px solid #E5E4E2', backgroundColor: "#E5E4E2", height: 20, width: 70, display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center ",
                                            justifyContent: "center",
                                            marginTop: 5
                                        }} >
                                            <Text style={{
                                                fontSize: 12,
                                                textTransform: "uppercase", textAlign: "center"
                                            }}>{receiptData.quantity_words && (receiptData.quantity_words.split(" ")[0])}</Text>
                                        </View>
                                    </View>
                                    <View style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center ",
                                        justifyContent: "center",
                                    }}>
                                        <Text style={{
                                            fontSize: 12,
                                            textAlign: "center"
                                        }}>THOUSANDS</Text>
                                        <View style={{
                                            border: '1px solid #E5E4E2', backgroundColor: "#E5E4E2", height: 20, width: 70, display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center ",
                                            justifyContent: "center",
                                            marginTop: 5
                                        }} >
                                            <Text style={{
                                                fontSize: 12,
                                                textTransform: "uppercase", textAlign: "center"
                                            }}>{receiptData.quantity_words && (receiptData.quantity_words.split(" ")[1])}</Text>
                                        </View>
                                    </View>
                                    <View style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center ",
                                        justifyContent: "center",
                                    }}>
                                        <Text style={{
                                            fontSize: 12,
                                            textAlign: "center"
                                        }}>HUNDREDS</Text>
                                        <View style={{
                                            border: '1px solid #E5E4E2', backgroundColor: "#E5E4E2", height: 20, width: 70, display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center ",
                                            justifyContent: "center",
                                            marginTop: 5
                                        }} >
                                            <Text style={{
                                                fontSize: 12,
                                                textTransform: "uppercase", textAlign: "center"
                                            }}>{receiptData.quantity_words && (receiptData.quantity_words.split(" ")[2])}</Text>
                                        </View>
                                    </View>
                                    <View style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center ",
                                        justifyContent: "center",
                                    }}>
                                        <Text style={{
                                            fontSize: 12,
                                            textAlign: "center"
                                        }}>TENS</Text>
                                        <View style={{
                                            border: '1px solid #E5E4E2', backgroundColor: "#E5E4E2", height: 20, width: 70, display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center ",
                                            justifyContent: "center",
                                            marginTop: 5
                                        }} >
                                            <Text style={{
                                                fontSize: 12,
                                                textTransform: "uppercase", textAlign: "center"
                                            }}>{receiptData.quantity_words && (receiptData.quantity_words.split(" ")[3])}</Text>
                                        </View>
                                    </View>
                                    <View style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center ",
                                        justifyContent: "center",
                                    }}>
                                        <Text style={{
                                            fontSize: 12,
                                            textAlign: "center"
                                        }}>ONES</Text>
                                        <View style={{
                                            border: '1px solid #E5E4E2', backgroundColor: "#E5E4E2", height: 20, width: 70, display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center ",
                                            justifyContent: "center",
                                            marginTop: 5
                                        }} >
                                            <Text style={{
                                                fontSize: 12,
                                                textTransform: "uppercase", textAlign: "center"
                                            }}>{receiptData.quantity_words && (receiptData.quantity_words.split(" ")[4])}</Text>
                                        </View>
                                    </View>
                                    <View style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center ",
                                        justifyContent: "center",
                                    }}>
                                        <Text style={{
                                            fontSize: 12,
                                            textAlign: "center"
                                        }}>DECIMALS</Text>
                                        <View style={{
                                            border: '1px solid #E5E4E2', backgroundColor: "#E5E4E2", height: 20, width: 70, display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center ",
                                            justifyContent: "center",
                                            marginTop: 5
                                        }} >
                                            <Text style={{
                                                fontSize: 12,
                                                textTransform: "uppercase", textAlign: "center"
                                            }}>{receiptData.quantity_words && (receiptData.quantity_words.split(" ")[6]?.substring(0, 2))}</Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                            <View style={{
                                marginTop: 5, display: "flex", flexDirection: "row",
                                display: "flex",
                                width: "100%",
                                padding: 10,
                                alignItems: "start",
                                marginBottom: 5,
                            }}>
                                <View style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center ", width: "55%",
                                    padding: 5
                                }}>
                                    <Text style={{ marginTop: 25, fontSize: 14, textAlign: "justify", lineHeight: 1.2 }}>
                                        The following costs are due upon issuance of the warehouse receipt: insurance, handling, receipt issuance fees, storage, and any other expenses incurred in the process of storage and obtaining the receipt. These charges to be paid by:{` `}
                                        <Text style={{ fontWeight: 700 }}>{(receiptData?.settlement_payer || receiptData?.depositor_entity_name)?.toUpperCase()}.</Text>
                                    </Text>
                                </View>
                                <View style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center ",
                                    justifyContent: "space-between",
                                    width: "45%",
                                    padding: 5
                                }} >
                                    {
                                        receiptData.generation_id && (
                                            <>
                                                <View style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                                                    <Image style={{ width: "200px", height: "200px" }} src={genQRCode} />
                                                    {/* <QRCode
                                                        size={256}
                                                        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                                        value={`https://www.zamace.co.zm/${receiptData?.receipt_number}/${receiptData?.generation_id}`}
                                                        viewBox={`0 0 256 256`}
                                                    /> */}

                                                    <Text style={{
                                                        color: "#8094ae",
                                                        fontSize: 10,
                                                        width: "100%"
                                                    }}>
                                                        {receiptData.receipt_number && (receiptData.receipt_number)}
                                                    </Text>
                                                </View>
                                            </>
                                        )
                                    }
                                </View>
                            </View>
                            <View style={{
                                position: "relative",
                                paddingLeft: 20,
                                paddingRight: 20,
                                paddingTop: 10,
                                paddingBottom: 10,
                                width: "100%",
                                bottom: 5,
                                display: "flex",
                                flexDirection: "row",
                            }}>
                                <Text style={{
                                    color: "#8094ae",
                                    fontSize: 12,
                                    bottom: 10,
                                    left: 0,
                                    right: 0,
                                    width: "100%", display: "flex", flexDirection: "row",
                                }}>
                                    Generation ID: <Text style={{ color: "#717274", fontWeight: 500 }} >{receiptData.generation_id && (receiptData.generation_id)}</Text>
                                </Text>
                            </View>
                        </Page>
                    </Document >
                )
            }
        </>
    );
};

export default WarehouseReceiptPDF;