import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import NavHeader from '../layouts/NavHeader';
import WelcomeBanner from '../layouts/WelcomeBanner';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { createEntityExistingAccount, createEntityExistingAccountReset, getCurrentAccount, getEntity, } from '../utils/actions';
import * as app_consts from '../utils/constants';
import { format } from 'date-fns';
import { retrieveEntityProfileSuffix } from '../utils/functionalUtils';

const Home = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [profiles, setProfiles] = useState([]);
  const [organization_id, setOrganizationId] = useState("");
  const [new_organization_id, setNewOrganizationId] = useState("");
  const [openAccessDeniedModal, setOpenAccessDeniedModal] = useState(false);
  const [entityData, setEntityData] = useState([]);


  const account = JSON.parse(localStorage.getItem('account'));
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  // Registration Modal state and functions
  const [isModalOpen, setIsModalOpen] = useState(false);

  const RegisterSchema = yup.object().shape({
    entity_category: yup.string().required('Please Select Membership Type'),
  });


  const formik = useFormik({
    initialValues: {
      entity_id: '',
      roles: "",
      account_id: "",
      member_type_id: "",
      entity_mid: "",
      entity_category: "",
      history: [],
    },
    validationSchema: RegisterSchema,


    onSubmit: async (values) => {

      setLoading(true);

      try
      {
        const timestamp = new Date();
        const formattedTimestamp = format(timestamp, 'MMMM dd, yyyy hh:mm a');
        const historyStatement = `Created Membership Account ${account.profiles[0].name || ""} on ${formattedTimestamp}`;

        const memberData = {
          entity_id: values.entity_id,
          member_type_id: values.member_type_id,
          entity_mid: values.entity_mid,
          type: values.entity_category,
          account_id: account?.id,
          roles: account?.profiles[0].roles,
          history: [historyStatement],
        };

        await dispatch(createEntityExistingAccount(memberData));

      } catch (error)
      {
        if (error.response.status === 400)
        {
          setError(error.response.data.message);
        }
        else if (error.response.status === 500)
        {
          setError("Could Not Create Member Access Account.");
        }
      } finally
      {
        setLoading(false);
      }
    },
  });

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openModal = () => {
    setError("");
    setIsModalOpen(true);
  };

  useEffect(() => {
    fetchAccountsList();

    // Fetch Entity list
    const fetchEntity = async () => {

      try
      {
        dispatch(getEntity(account.profiles[0].entity_id));
      } catch (error)
      {
        console.error(error);
      }
    };
    fetchEntity();
  }, []);

  // Fetch Accounts List
  const fetchAccountsList = () => {
    try
    {
      dispatch(getCurrentAccount());
    } catch (error)
    {
      console.error(error);
    }
  };

  // Listen to Entity ID Changes
  const organization_entity = useSelector((state) => state.getEntity);

  // UseEffect to Listen to Organization Reducer State Changes
  useEffect(() => {
    if (organization_entity.data) 
    {
      setEntityData(organization_entity.data);
      setOrganizationId(organization_entity.data.organization_id);
      formik.setFieldValue('entity_id', organization_entity.data.id);
      formik.setFieldValue('entity_mid', organization_entity.data.organization_id);
    }
  }, [organization_entity]);

  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    if (auth.account && auth.account.profiles)
    {
      setProfiles(auth.account.profiles);
    }
  }, [auth]);

  //Listen to Create Membership Account State
  const create_member_account_listner = useSelector((state) => state.createEntityExistingUser);
  useEffect(() => {
    if (create_member_account_listner.status !== "idle")
    {
      if (create_member_account_listner.isLoading)
      {
        setLoading(true);
      }
      else
      {
        setLoading(false);
        if (create_member_account_listner.data === "Member Account Successfully Created.")
        {
          fetchAccountsList();
          closeModal();
        }
        dispatch(createEntityExistingAccountReset());
      }
    }
  }, [create_member_account_listner]);

  const handle_category_selection = (e) => {
    const value = e.target.value;
    let new_value;
    if (value === "warehouse_operator")
    {
      new_value = `${organization_id}${app_consts.WAREHOUSE_OPERATOR_SUFFIX}`;
    }
    else if (value === "broker")
    {
      new_value = `${organization_id}${app_consts.BROKER_SUFFIX}`;
    }
    else if (value === "cooperative")
    {
      new_value = `${organization_id}${app_consts.COOPERATIVE_SUFFIX}`;
    }
    else if (value === "service_provider")
    {
      new_value = `${organization_id}${app_consts.SERVICE_PROVIDER_SUFFIX}`;
    }
    else if (value === "investor")
    {
      new_value = `${organization_id}${app_consts.INVESTOR_SUFFIX}`;
    }
    else if (value === "exchange")
    {
      new_value = `${organization_id}${app_consts.EXCHANGE_SUFFIX}`;
    }
    else if (value === "")
    {
      new_value = "";
    }
    setNewOrganizationId(new_value);
    formik.setFieldValue('member_type_id', new_value);
  };

  const checkType = (type) => {
    if (type === "warehouse_operator" || type === "broker" || type === "exchange" || type === "cooperative")
    {
      return true;
    }
    else
    {
      return false;
    }
  };

  const checkPending = (type) => {
    if (type === "pending")
    {
      return true; //ASSA
    }
    else
    {
      return false;
    }
  };

  const handleCardNav = (disabledCheck, url) => {
    if (disabledCheck === true) 
    {
      navigate(url);
    }
    else
    {
      setOpenAccessDeniedModal(true);
    }
  };

  return (
    <>
      <div className="p-4 sm:w-12/12 md:w-11/12 m-auto">
        <NavHeader />
        <div className="p-4 rounded-lg dark:border-gray-700">


          <WelcomeBanner bannerTitle="Dashboard Home" />
          <div className="header flex justify-between items-center">
            <h5 className="text-xl font-bold dark:text-white mb-3 text-gray-600">Membership Accounts - <span className="text-gray-800">{account?.profiles[0].name || ""}</span></h5>
            {
              account.profiles[0].roles.includes("administrator") && entityData && entityData.active && entityData.verified && entityData.status === "active" ?
                <>
                  <button
                    type="button"
                    onClick={openModal}
                    className="flex items-center justify-center px-4 py-2 text-sm bg-gray-100 font-medium text-gray-90 border-rouned-md border-gray-200 border-2 rounded-lg bg-sate-100 hover:border-primary-700 hover:text-green-800 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="w-5 h-5 mr-2"
                    >
                      <path d="M5.433 13.917l1.262-3.155A4 4 0 017.58 9.42l6.92-6.918a2.121 2.121 0 013 3l-6.92 6.918c-.383.383-.84.685-1.343.886l-3.154 1.262a.5.5 0 01-.65-.65z" />
                      <path d="M3.5 5.75c0-.69.56-1.25 1.25-1.25H10A.75.75 0 0010 3H4.75A2.75 2.75 0 002 5.75v9.5A2.75 2.75 0 004.75 18h9.5A2.75 2.75 0 0017 15.25V10a.75.75 0 00-1.5 0v5.25c0 .69-.56 1.25-1.25 1.25h-9.5c-.69 0-1.25-.56-1.25-1.25v-9.5z" />
                    </svg>
                    Register Membership Account
                  </button>
                </>
                :
                <>
                </>
            }
          </div>
          <div className="grid md:grid-cols-2 gap-4 mb-4 mt-3">
            {profiles?.map((profile) => {

              let disabledCheck = checkType(profile.type);
              let pendingState = checkPending(profile.status);


              return (

                <span onClick={() => handleCardNav((disabledCheck && !pendingState), `/${profile.type.split('_')[0]}/${profile?.entity_id}/${profile?.member_type_id}`)} rel="noopener noreferrer">
                  <div className={`each flex hover:shadow-lg select-none p-6 rounded-md border-gray-300 border mb-3 ${disabledCheck && !pendingState ? "hover:border-green-500 " : "hover:border-gray-500"} cursor-pointer`}>
                    <div className="left">
                      <div className={`header ${disabledCheck && !pendingState ? "text-green-600" : "text-gray-600"}  font-semibold text-2xl mb-2`}>
                        {profile.type.replace(/_/g, ' ').toUpperCase()}
                      </div>
                      <div className="text-sm font-bold text-gray-900 dark:text-white">Organization Name: <span class="bg-gray-100 text-gray-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300">{profile.name.toUpperCase() || ""}</span></div>
                      <div className="text-sm font-bold text-gray-900 dark:text-white my-2">Status: <span class="bg-gray-100 text-gray-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300">{profile.status.toUpperCase()}</span>
                      </div>
                    </div>
                    <div className="right m-auto mr-0">
                      <div className="icon">
                        <svg xmlns="http://www.w3.org/2000/svg" className={`h-8 w-8 ${disabledCheck && !pendingState ? "text-green-600" : "text-gray-600"}`} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                        </svg>
                      </div>
                    </div>
                  </div>
                </span>
              );
            })}
          </div>
        </div>
      </div>

      {/* Registration Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 overflow-y-auto">
          <div className="fixed inset-0 bg-black opacity-50"></div>
          {/* Modal content goes here */}
          <div className="relative p-4 bg-white lg:w-4/12 sm:w-5/6 rounded-lg shadow dark:bg-gray-800 md:p-8 max-h-screen overflow-y-auto">
            {/* Add your modal content here */}
            <div class="flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5 dark:border-gray-600">
              <h3 class="text-lg font-semibold text-gray-900 dark:text-white">
                Register New Membership Account
              </h3>
              <button type="button" onClick={closeModal} class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="updateProductModal">
                <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                <span class="sr-only">Close modal</span>
              </button>
            </div>
            <form className="space-y-4 md:space-y-6" onSubmit={formik.handleSubmit}>
              <ol className="items-center w-full sm:w-auto space-y-4 sm:flex sm:space-x-8 sm:space-y-0">

                <li className="flex items-center text-green-600 dark:text-green-500 space-x-2.5">
                  <span className="flex items-center justify-center w-8 h-8 border border-green-600 rounded-full shrink-0 dark:border-green-500">
                    1
                  </span>
                  <span>
                    <h4 className="font-medium leading-tight">Membership Type</h4>
                    <p className="text-sm">Set membership type</p>
                  </span>
                </li>
              </ol>
              <div className="container grid grid-cols-1 gap-6 sm:grid-cols-1 lg:grid-cols-1">
                <div className="mb-6">
                  <label htmlFor="entity_category" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    Select Membership Type
                  </label>
                  <select
                    name="entity_category"
                    value={formik.values.entity_category}
                    onChange={(e) => { formik.handleChange(e); handle_category_selection(e); }}
                    required
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                  >
                    <option value="">Choose membership type</option>
                    <option value="broker">Broker</option>
                    <option value="warehouse_operator">Warehouse Operator</option>
                    <option value="cooperative">Cooperative</option>
                    <option value="service_provider">Service Provider</option>
                    <option value="investor">Investor</option>
                  </select>
                  {formik.touched.entity_category && formik.errors.entity_category && <p className="text-red-500 text-sm mt-1">{formik.errors.entity_category}</p>}
                </div>
                <div className="mb-6">
                  <label htmlFor="comp_name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Registered Organization Name</label>
                  <input
                    type="text"
                    name="comp_name"
                    value={account.profiles[0].name || ""}
                    disabled
                    className="bg-gray-50 border border-gray-100 text-gray-700 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                  />
                </div>
                {
                  new_organization_id && (
                    <>
                      <div className="mb-6">
                        <label htmlFor="orgName" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Membership ID</label>
                        <input
                          type="text"
                          name="orgName"
                          value={new_organization_id}
                          disabled
                          className="bg-gray-50 border border-gray-100 text-gray-700 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                        />
                      </div>
                    </>
                  )
                }
              </div>
              {/* Close button */}
              <div className="flex space-x-4">
                <button
                  type="button"
                  onClick={closeModal}
                  className="text-gray-900 bg-white hover:bg-gray-100 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 mr-2 mb-2"
                >
                  Close Modal
                </button>
                <button
                  type="submit"
                  disabled={loading}
                  className={`text-white ${loading ? "bg-gray-400 " : "bg-primary-600 hover:bg-primary-700 dark:focus:ring-primary-800"} focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 mb-2`}
                >

                  {formik.isSubmitting ? 'Registering Member...' : 'Register Member'}
                </button>
              </div>
              {error && <div className="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
                <span className="font-medium">Error: </span>{error}</div>}
            </form>
          </div>
        </div>
      )}
      {openAccessDeniedModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50 overflow-y-auto">
          <div className="fixed inset-0 bg-black opacity-50"></div>
          {/* Modal content goes here */}
          <div className="relative p-4 bg-white lg:w-4/12 w-5/6 rounded-lg shadow dark:bg-gray-800 md:p-8 max-h-screen overflow-y-auto">
            <div class="flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5 dark:border-gray-600">
              <h3 class="text-lg font-semibold text-gray-900 dark:text-white">
                Access Restricted
              </h3>
              <button type="button" onClick={() => setOpenAccessDeniedModal(false)} class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="updateProductModal">
                <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                <span class="sr-only">Close modal</span>
              </button>
            </div>
            <div className='flex flex-row items-center justify-center font-meduim text-gray-800 p-4'>
              <span className='leading-6 tracking-wider'>Access to this Module has Been Restricted Until Member Account has been Verified.</span>
            </div>
          </div>
        </div>
      )
      }
    </>
  );
};

export default Home;
