import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import RSelect from 'react-select';
import { bookGoodsRecievedIntoStorageReset, book_goods_received_delivery_into_storage, getFilteredEntityTypeActiveStatusVerified, getWarehouseListByEntityStatus, list_all_producers_with_entities, processBulkDeliveryReset, process_bulk_delivery, retreive_pending_entity_list_by_type, retrieveSpecificWarehouseStorageTypeReset, retrieve_specific_warehouse_storage_types } from '../../../utils/actions';
import { useDispatch, useSelector } from 'react-redux';
import { convertToTitleCase, convertUnderscoreToTitleCase, rSelectMapFun, retreive_rSelectVariables } from '../../../utils/functionalUtils';
import * as app_consts from '../../../utils/constants';
import { useParams } from 'react-router-dom';
import { acceptable_grades } from '../../../utils/commodity_grading_scale';
import { FileInput } from 'flowbite-react';
import * as yup from 'yup';

const CommodityGradingInfo = ({ nextStep, setPrevData, prevData }) => {

    const dispatch = useDispatch();
    const { entity_id } = useParams();

    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [previewData, setPreviewData] = useState(prevData);
    const [grades, setGrades] = useState();
    const [errors, setErrors] = useState({});
    const [storage_list_loading, setStorageListLoading] = useState(false);
    const [storage_list, setStorageList] = useState([]);
    const [goods_received_data, setGoodsReceivedData] = useState([]);

    const [selectedFiles, setSelectedFiles] = useState({
        grade_cert_img: null,
    });

    useEffect(() => {
        if (prevData)
        {
            setPreviewData(prevData);
            setGoodsReceivedData(prevData?.goods_received?.goods_received);
            setGrades(acceptable_grades(prevData?.commodity?.replace(/ /g, '_').toLowerCase()));
            dispatch(retrieve_specific_warehouse_storage_types(prevData?.goods_received?.warehouse_id));
        }
    }, [prevData]);

    // Yup Validation
    const deliveriesSchema = yup.object().shape({
        grade: yup.string().required('Commodity Grade is required'),
        description: yup.string().required('Description is required'),
        storage_type: yup.string().required('Storage Type is Required'),
        detailed_storage_description: yup.string().required('Detailed Storage Description is required'),
    });

    const formik = useFormik({
        initialValues: {
            delivery_id: "",
            grade: "",
            description: "",
            storage_type: '',
            detailed_storage_description: '',
            grade_cert_img: ""
        },
        validationSchema: deliveriesSchema,
        onSubmit: async (values) => {
            setError("");
            setLoading(true);
            try
            {
                const storageInforParams = {
                    delivery_id: prevData?.goods_received?.id,
                    grade: values.grade,
                    description: values.description,
                    storage_type: values.storage_type,
                    detailed_storage_description: values.detailed_storage_description,
                    grade_cert_img: values.grade_cert_img
                };

                await dispatch(book_goods_received_delivery_into_storage(storageInforParams));

            } catch (error)
            {
                console.log(error);
            }
        },
    });

    // Listen to Create Bulk Delivery Reducer
    const booking_data = useSelector((state) => state.bookGoodsRecievedIntoStorage);
    // 
    useEffect(() => {
        if (booking_data.status !== app_consts.IDLE)
        {
            if (booking_data.isLoading)
            {
                setLoading(true);
            }
            else
            {
                setLoading(false);
                if (booking_data.error)
                {
                    setError(booking_data.message);
                }
                else if (booking_data.data)
                {
                    setPrevData({ ...prevData, ["warehouse_receipt_data"]: booking_data.data });
                    nextStep();
                }
                dispatch(bookGoodsRecievedIntoStorageReset());
            }
        }
    }, [booking_data]);

    const MAX_FILE_SIZE_MB = 20;

    const handleFileChange = (e, fieldName) => {
        const selectedFile = e.target.files[0];

        if (selectedFile)
        {
            const fileSizeInMB = selectedFile.size / (1024 * 1024);
            if (fileSizeInMB > MAX_FILE_SIZE_MB)
            {
                setSelectedFiles((prevFiles) => ({ ...prevFiles, [fieldName]: null }));
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [fieldName]: `File size exceeds the ${MAX_FILE_SIZE_MB}MB limit.`,
                }));
                return;
            }

            const allowedFileTypes = [
                'image/jpeg',
                'image/jpg',
                'image/png',
                'application/pdf',
            ];

            if (allowedFileTypes.includes(selectedFile.type))
            {
                formik.setFieldValue("grade_cert_img", selectedFile);
                setSelectedFiles((prevFiles) => ({ ...prevFiles, [fieldName]: selectedFile }));
                setErrors((prevErrors) => ({ ...prevErrors, [fieldName]: null, file: null }));
            } else
            {
                formik.setFieldValue("grade_cert_img", "");
                setSelectedFiles((prevFiles) => ({ ...prevFiles, [fieldName]: null }));
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [fieldName]: "Please select a valid image or PDF file (PNG, JPEG, or PDF).",
                }));
            }
        }
    };

    // Listen to Changes on Warehouse Storages List
    const storageListListener = useSelector((state) => state.retrieveSpecificWarehouseStorageType);
    // 
    useEffect(() => {

        if (storageListListener.status !== app_consts.IDLE)
        {
            if (storageListListener.isLoading)
            {
                setStorageListLoading(true);
            }
            else
            {
                if (storageListListener.error)
                {
                    // setError(warehouseReceiptListListener.error);
                }
                else if (storageListListener.data)
                {
                    const fetchData = async () => {
                        if (storageListListener.data)
                        {
                            let mappedData = [];

                            await Promise.all(
                                storageListListener?.data?.map(async (data_to_map) => {
                                    const { label, value } = retreive_rSelectVariables(app_consts.AVAILABLE_STORAGE_LIST_MAP, data_to_map);
                                    const result = await rSelectMapFun(label, value);
                                    mappedData.push(result);
                                })
                            );

                            setStorageList(mappedData);
                        }
                    };

                    fetchData();
                }
                dispatch(retrieveSpecificWarehouseStorageTypeReset());
                setStorageListLoading(false);
            }
        }

    }, [storageListListener]);

    return (
        <>

            <form className='justify-center items-center' onSubmit={formik.handleSubmit}>
                <div className="px-4 flex flex-col items-stretch justify-start border-gray-100">
                    <h3 className="text-2xl font-bold leading-7 text-green-800 sm:truncate sm:text-2xl sm:tracking-tight">
                        Storage Information {previewData.commodity ? <span className='font-medium text-xl'> - {convertUnderscoreToTitleCase(previewData.commodity)}</span> : ""}
                    </h3>
                </div>
                <div className="grid gap-6 mb-2 lg:grid-cols-3 p-6 sm:p-16 lg:p-8">
                    <div>
                        <label htmlFor="quantity" className="flex flex-row items-center block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            Quantity <span className="block ml-2 text-xs font-medium text-gray-600 dark:text-white">(Metric Tonnes)</span>
                        </label>
                        <input
                            step="0.01"
                            type="number"
                            min="0.00"
                            default="0.00"
                            name="quantity"
                            disabled
                            value={parseFloat(previewData?.goods_received?.quantity).toFixed(2)}
                            className="bg-gray-200 border cursor-not-allowed border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                        />
                        {/* {formik.touched.quantity && formik.errors.quantity && (
                            <p className="text-red-500 text-sm mt-1">{formik.errors.quantity}</p>
                        )} */}
                    </div>
                    <div>
                        <label
                            htmlFor='grade'
                            className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                        >
                            Quality
                        </label>
                        <select
                            name='grade'
                            value={formik.values.grade}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            required
                            className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500'
                        >
                            <option value=''>Select Quality</option>
                            {
                                grades?.map((grade) => {
                                    return (
                                        <>
                                            <option value={grade}>{convertToTitleCase(grade)}</option>
                                        </>
                                    );
                                })
                            }
                        </select>
                    </div>

                    <div>
                        <label htmlFor="grade_cert_img" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            Upload Grading Ceritficate
                        </label>
                        <FileInput
                            label="Upload Grading Ceritficate"
                            name="grade_cert_img"
                            onChange={(e) => handleFileChange(e, 'grade_cert_img')}
                        />
                        {errors.grade_cert_img && <p className="text-red-500 text-xs mt-1">{errors.grade_cert_img}</p>}
                    </div>

                    <div>
                        <label htmlFor="description" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            Commodity Description
                        </label>
                        <input
                            type="text"
                            name="description"
                            value={formik.values.description}
                            onChange={formik.handleChange}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                        />
                        {formik.touched.description && formik.errors.description && (
                            <p className="text-red-500 text-sm mt-1">{formik.errors.description}</p>
                        )}
                    </div>

                    <div>
                        <label htmlFor="storage_type" className="flex flex-row justify-between block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            <span>Storage Type</span>
                            {
                                storage_list_loading && (
                                    <>
                                        <div className="text-center">
                                            <div role="status">
                                                <svg
                                                    aria-hidden="true"
                                                    className="inline w-4 h-4 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-green-600"
                                                    viewBox="0 0 100 101"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                        fill="currentColor"
                                                    />
                                                    <path
                                                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                        fill="currentFill"
                                                    />
                                                </svg>
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                        </label>
                        <select
                            name="storage_type"
                            value={formik.values.storage_type}
                            onChange={formik.handleChange}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                        >
                            <option value="">Choose Storage Type</option>
                            {
                                storage_list?.map((storage) => {
                                    return (
                                        <>
                                            <option value={storage.value}>{storage.label}</option>
                                        </>
                                    );
                                })
                            }
                        </select>
                        {formik.touched.storage_type && formik.errors.storage_type && (
                            <p className="text-red-500 text-sm mt-1">{formik.errors.storage_type}</p>
                        )}
                    </div>

                    <div>
                        <label htmlFor="description" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            Detailed Commodity Storage Description
                        </label>
                        <input
                            type="text"
                            name="detailed_storage_description"
                            value={formik.values.detailed_storage_description}
                            onChange={formik.handleChange}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                        />
                        {formik.touched.detailed_storage_description && formik.errors.detailed_storage_description && (
                            <p className="text-red-500 text-sm mt-1">{formik.errors.detailed_storage_description}</p>
                        )}
                    </div>

                </div>

                {error && error.length > 0 && (
                    <div className="my-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 px-6 py-4 sm:px-16 lg:px-8" role="alert">
                        <span className="font-medium">Error: </span>
                        {error}
                    </div>
                )}
                <div className='mb-4 px-6 flex flex-row justify-end items-center'>
                    {/* <button
                        onClick={prevStep}
                        type="submit"
                        className="text-white bg-gray-500 hover:bg-gray-600 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-gray-500 dark:hover:bg-gray-600 dark:focus:ring-gray-700"
                    >
                        Previous Step: Commodity Grading Information
                    </button> */}
                    <button
                        // onClick={nextStep}
                        type="submit"
                        className={`${loading ? "bg-gray-400" : "bg-green-700 hover:bg-green-800 dark:bg-green-600 dark:hover:bg-green-700"} text-white  focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:focus:ring-green-800`}
                    >
                        {loading ? "Processing..." : "Next Step: Inventory Information"}
                    </button>
                </div>
            </form>
        </>
    );
};

export default CommodityGradingInfo;