import React, { useEffect, useState } from 'react';
import * as app_consts from '../../utils/constants';
import { Link, useNavigate } from 'react-router-dom';
import { addThreeDots } from '../../utils/functionalUtils';

const TableRowsData = ({ data, type, nav_link = "#", textClassname, textDarkClassName, toggleClick, toggleClickFunc, parentClassname, check_value }) => {

    const navigate = useNavigate();
    const [isChecked, setIsChecked] = useState(false);
    const [toggle_loading, setToggleLoading] = useState(false);

    useEffect(() => {
        setIsChecked(check_value);
    }, [check_value]);

    return (
        <>
            {
                type === app_consts.TEXT_ROW ?
                    <>
                        <td className={`px-6 py-4 ${textClassname}`}>
                            <span className="text-normal font-medium text-gray-600 whitespace-nowrap dark:text-white">{data}
                            </span>
                        </td>
                    </>
                    :
                    ""
            }
            {
                type === app_consts.TEXT_ROW_CUSTOM_CLASS ?
                    <>
                        <td className={textClassname}>
                            {data}
                        </td>
                    </>
                    :
                    ""
            }
            {
                type === app_consts.TEXT_LINK ?
                    <>
                        <td scope="row" className={`px-6 py-4 font-semibold text-green-900 whitespace-nowrap dark:text-white ${textClassname}`}>
                            <Link to={nav_link}>
                                {data}
                            </Link>
                        </td>
                    </>
                    :
                    ""
            }
            {
                type === app_consts.TEXT_LINK_NEW_PAGE ?
                    <>
                        <td scope="row" className={`px-6 py-4 font-semibold text-green-900 whitespace-nowrap dark:text-white ${textClassname}`}>
                            <Link target="_blank" to={nav_link}>
                                {data}
                            </Link>
                        </td>
                    </>
                    :
                    ""
            }
            {
                type === app_consts.TEXT_LINK_CUSTOM_CLASS ?
                    <>
                        <td scope="row" className={textClassname}>
                            <Link to={nav_link}>
                                {data}
                            </Link>
                        </td>
                    </>
                    :
                    ""
            }
            {
                type === app_consts.TEXT_STATUS ?
                    <>
                        <td className={`px-6 py-4 text-normal font-medium`}>
                            <span class={`${textClassname || "bg-yellow-100 text-yellow-800"} text-xs uppercase font-medium px-2 py-0.5 rounded d${textDarkClassName || "dark:bg-yellow-900"} dark:text-yellow-300`}>{addThreeDots(data?.toUpperCase(), 20)}</span>
                        </td>
                    </>
                    :
                    ""
            }
            {
                type === app_consts.TEXT_STATUS_CUSTOM_CLASS ?
                    <>
                        <td className={parentClassname}>
                            <span className={textClassname}>
                                {data}
                            </span>
                        </td>
                    </>
                    :
                    ""
            }
            {
                type === app_consts.TEXT_VIEW_DETAILS ?
                    <>
                        <td className="px-6 py-4">
                            <button
                                onClick={() => navigate(nav_link)}
                                type="button"
                                class="px-3 py-2 text-xs font-medium text-center inline-flex items-center text-white bg-green-700 rounded-lg hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800 min-w-[110px]"
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5 mr-2">
                                    <path d="M10 12.5a2.5 2.5 0 100-5 2.5 2.5 0 000 5z" />
                                    <path fill-rule="evenodd" d="M.664 10.59a1.651 1.651 0 010-1.186A10.004 10.004 0 0110 3c4.257 0 7.893 2.66 9.336 6.41.147.381.146.804 0 1.186A10.004 10.004 0 0110 17c-4.257 0-7.893-2.66-9.336-6.41zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clip-rule="evenodd" />
                                </svg>
                                View Details
                            </button>
                        </td>
                    </>
                    :
                    ""
            }
            {
                type === app_consts.TEXT_VIEW_DETAILS_NEW_PAGE ?
                    <>
                        <td className="px-6 py-4">
                            <button
                                type="button"
                                class="px-3 py-2 text-xs font-medium text-center inline-flex items-center text-white bg-green-700 rounded-lg hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800 min-w-[110px]"
                            >

                                <a href={nav_link} target="_blank">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5 mr-2">
                                        <path d="M10 12.5a2.5 2.5 0 100-5 2.5 2.5 0 000 5z" />
                                        <path fill-rule="evenodd" d="M.664 10.59a1.651 1.651 0 010-1.186A10.004 10.004 0 0110 3c4.257 0 7.893 2.66 9.336 6.41.147.381.146.804 0 1.186A10.004 10.004 0 0110 17c-4.257 0-7.893-2.66-9.336-6.41zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clip-rule="evenodd" />
                                    </svg>
                                    View Details
                                </a>
                            </button>
                        </td>
                    </>
                    :
                    ""
            }
            {
                type === app_consts.TEXT_VIEW_DETAILS_CUSTOM_CLASS ?
                    <>
                        <td className="px-6 py-4">
                            <button
                                onClick={() => navigate(nav_link)}
                                type="button"
                                class={textClassname}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5 mr-2">
                                    <path d="M10 12.5a2.5 2.5 0 100-5 2.5 2.5 0 000 5z" />
                                    <path fill-rule="evenodd" d="M.664 10.59a1.651 1.651 0 010-1.186A10.004 10.004 0 0110 3c4.257 0 7.893 2.66 9.336 6.41.147.381.146.804 0 1.186A10.004 10.004 0 0110 17c-4.257 0-7.893-2.66-9.336-6.41zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clip-rule="evenodd" />
                                </svg>
                                {data}
                            </button>
                        </td>
                    </>
                    :
                    ""
            }
            {
                type === app_consts.TEXT_VIEW_DETAILS_CUSTOM_CLASS_NEW_PAGE ?
                    <>
                        <td className="px-6 py-4">
                            <button
                                onClick={() => navigate(nav_link)}
                                type="button"
                                class={textClassname}
                            >
                                <a href={nav_link} className="flex items-center" target="_blank" >
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5 mr-2">
                                        <path d="M10 12.5a2.5 2.5 0 100-5 2.5 2.5 0 000 5z" />
                                        <path fill-rule="evenodd" d="M.664 10.59a1.651 1.651 0 010-1.186A10.004 10.004 0 0110 3c4.257 0 7.893 2.66 9.336 6.41.147.381.146.804 0 1.186A10.004 10.004 0 0110 17c-4.257 0-7.893-2.66-9.336-6.41zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clip-rule="evenodd" />
                                    </svg>
                                    {data}
                                </a>
                            </button>
                        </td>
                    </>
                    :
                    ""
            }
            {
                type === app_consts.TEXT_CHECKBOX ?
                    <>
                        {/* Pass the ID */}
                        <td className="w-4 p-4">
                            <div className="flex items-center">
                                <input id={`checkbox-table-search-${data}`} type="checkbox" className="w-4 h-4 text-green-600 text-lg bg-gray-100 border-gray-300 rounded focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                <label htmlFor={`checkbox-table-search-${data}`} className="sr-only">checkbox</label>
                            </div>
                        </td>
                    </>
                    :
                    ""
            }
            {
                type === app_consts.TEXT_MINUS ?
                    <>
                        <td className="w-4 p-4">
                            <div className="flex items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 12h14" />
                                </svg>
                            </div>
                        </td>
                    </>
                    :
                    ""
            }
            {
                type === app_consts.TABLE_TOGGLE ?
                    <>
                        <td className="p-4 flex items-center justify-center cursor-pointer" onClick={() => toggleClickFunc(data)}>
                            {
                                toggleClick?.toggleClick && toggleClick.selectedIndex === data ?
                                    <>
                                        <div className="text-center">
                                            <div role="status">
                                                <svg
                                                    aria-hidden="true"
                                                    className="inline w-6 h-6 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-green-600"
                                                    viewBox="0 0 100 101"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                        fill="currentColor"
                                                    />
                                                    <path
                                                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                        fill="currentFill"
                                                    />
                                                </svg>
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <span
                                            className={`slider mr-3 flex h-[26px] w-[50px] items-center rounded-full p-1 duration-200 ${isChecked && !toggle_loading ? 'bg-green-500' : 'bg-[#CCCCCE]'
                                                }`}
                                        >
                                            <span
                                                className={`dot h-[18px] w-[18px] rounded-full bg-white duration-200 ${isChecked ? 'translate-x-6' : ''
                                                    }`}
                                            ></span>
                                        </span>
                                    </>
                            }
                        </td>
                    </>
                    :
                    ""
            }
            {
                type === app_consts.TEXT_CHEVRON_DOWN ?
                    <>
                        {/* Pass the ID */}
                        <td className="w-4 p-4" onClick={() => toggleClickFunc(data)}>
                            <div className="flex items-center cursor-pointer">
                                {
                                    toggleClick?.toggleClick && toggleClick.selectedIndex === data ?
                                        <>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 20 20"
                                                fill="currentColor"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M10.293 14.293a1 1 0 0 1-1.414 0l-7-7a1 1 0 0 1 1.414-1.414L10 11.586l6.293-6.293a1 1 0 1 1 1.414 1.414l-7 7z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                        </>
                                        :
                                        <>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                className='transition ease-in-out delay-150'
                                            >
                                                <path d="M9 18l6-6-6-6" />
                                            </svg>
                                        </>
                                }
                            </div>
                        </td>
                    </>
                    :
                    ""
            }
        </>
    );
};

<th scope="col" className="p-4">
    <div className="flex items-center">
        <input id="checkbox-all-search" type="checkbox" className="w-4 h-4 text-green-600 bg-gray-100 border-gray-300 rounded focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
        <label for="checkbox-all-search" className="sr-only">checkbox</label>
    </div>
</th>;
export default TableRowsData;