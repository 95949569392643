import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Logo from '../../../components/Logo';
import NavLogo from '../../../components/NavLogo';
import ChildHeaderBanner from '../../../layouts/ChildHeaderBanner';
import CustomTabs from '../../../layouts/CustomTabs';
import { useDispatch, useSelector } from 'react-redux';
import {
  getProducer,
  getEntity,
  getEntityProfileList,
  retrieve_specific_broker_offer,
  retrieveSpecificBrokerOfferReset,
  getBrokerBidsByCommodityOrderId,
  accept_bid_on_commodity_order,
  updateOfferStatus,
} from '../../../utils/actions';
import DocumentViewer from '../../../layouts/DocumentViewer';
import * as app_consts from '../../../utils/constants';
import TableComp from '../../../components/table/TableComp';
import { Button, Card, Timeline } from 'flowbite-react';
import { HiArrowNarrowRight } from 'react-icons/hi';
import { formatNumber } from '../../../utils/functionalUtils';
import YesNoModal from '../../../components/modals/YesNoModal';
import UpdateBrokerSuperOffer from '../../../components/modals/UpdateBrokerSuperOffer';

const OfferDetails = () => {
  const { entity_id, entity_mid, view, broker_offer_id } = useParams();
  const [activeTab, setActiveTab] = useState('Offer Information');

  // const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [profilesList, setProfilesList] = useState([]);
  const [broker_offer_data, setBrokerOfferData] = useState([]);
  const [producer_data, setProducerData] = useState([]);
  const [warehouse_receipt_data, setWarehouseReceiptData] = useState([]);
  const [commodity_order_data, setCommodityOrderData] = useState([]);
  const [bids_on_offer_data, setBidOnOfferData] = useState([]);
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [openYesNoModal, setOpenYesNoModal] = useState(false);
  const [openYesNoModalAction, setOpenYesNoModalAction] = useState("");
  const [openYesNoModalLoading, setOpenYesNoModalLoading] = useState("");
  const [openYesNoModalError, setOpenYesNoModalError] = useState("");
  const [accept_and_match_id, setAcceptAndMatchId] = useState("");
  const [selectBidQuantity, SetSelectedBidQuantity] = useState(0.00);
  const [trade_data, setTradeData] = useState([]);
  const [errors, setErrors] = useState(false);

  const [dropdownVisible, setDropdownVisible] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [actionStatus, setActionStatus] = useState(null);

  const handleOfferActions = async (status) => {
    // Open the modal to confirm the action
    setActionStatus(status);
    setModalOpen(true);
  };

  const closeModal = () => {
    // Close the modal without performing the action
    setModalOpen(false);
  };

  const confirmAction = async () => {
    setModalOpen(false);
    // Proceed with the action
    if (actionStatus)
    {
      try
      {
        await dispatch(
          updateOfferStatus({
            commodity_order_id: commodity_order_data?.id,
            commodity_order_status: actionStatus,
          })
        );
        setLoading(false);
        window.location.reload();
      } catch (error)
      {
        // Handle error
        setErrors('An unexpected error occurred.');
      }
    }
  };
  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const [modal, setModal] = useState({
    tradingAuthorityModal: false,
  });

  const closeTradingAuthorityModal = () => {
    setModal({ ...modal, tradingAuthorityModal: false });
  };

  const openSelectedModal = (modalToBeOpened) => {
    setModal({ ...modal, [modalToBeOpened]: true });
  };

  // Initial UseEffect to Run When Page Opens
  useEffect(() => {
    fetchProfileList();
    dispatch(retrieve_specific_broker_offer(broker_offer_id));
  }, []);

  // Fetech Profile List
  const fetchProfileList = async () => {
    try
    {
      dispatch(getEntityProfileList(entity_id));
    } catch (error)
    {
      console.error(error);
    }
  };

  // Retrieve Profile List from State
  const profile_list = useSelector((state) => state.entityProfilesList);

  const brokerBids = useSelector(
    (state) => state.brokerBidListByCommodityOrderId.data
  );

  useEffect(() => {
    if (profile_list.data)
    {
      const selectedlist = profile_list.data?.find(
        (profile) => profile.member_type_id === entity_mid
      );
      setProfilesList(selectedlist);
    }
  }, [profile_list]);

  // Listen to Specific Broker Offer Reducer
  const specific_broker_offer = useSelector(
    (state) => state.retrieveSpecificBrokerOffer
  );

  useEffect(() => {
    if (specific_broker_offer.status !== app_consts.IDLE)
    {
      if (specific_broker_offer.loading)
      {
        setLoading(true);
      } else
      {
        setLoading(false);
        if (specific_broker_offer.error)
        {
          setError(specific_broker_offer.error);
        } else
        {
          setTradeData(specific_broker_offer?.data?.trades);
          setIsDataFetched(true);
          setBrokerOfferData(specific_broker_offer?.data?.broker_offer);
          setProducerData(specific_broker_offer?.data?.producer);
          setWarehouseReceiptData(
            specific_broker_offer?.data?.warehouse_receipt
          );
          setCommodityOrderData(specific_broker_offer?.data?.commodity_order);
          setBidOnOfferData(specific_broker_offer?.data?.bids);
        }
        dispatch(retrieveSpecificBrokerOfferReset());
      }
    }
  }, [specific_broker_offer]);

  useEffect(() => {
    if (commodity_order_data && commodity_order_data.id)
    {
      let currentPage = 1;
      const pageSize = 1;

      dispatch(
        getBrokerBidsByCommodityOrderId(
          commodity_order_data.id,
          currentPage,
          pageSize
        )
      );
    }
  }, [commodity_order_data]);

  const tabs = [
    { title: 'Offer Information', disabled: !isDataFetched },
    { title: 'Bids On Offer', disabled: !isDataFetched },
    { title: 'Trades', disabled: !isDataFetched },
  ];

  // Handle Logout
  const handleLogout = () => {
    localStorage.clear();
    navigate('/');
  };

  // Open Yes No Modal Function
  const openYesNoModalOpenFunction = (action, transaction_action, bid_quantity) => {
    setOpenYesNoModalAction(action);
    setOpenYesNoModal(true);
    setOpenYesNoModalError("");
    setAcceptAndMatchId(transaction_action);
    SetSelectedBidQuantity(bid_quantity);
  };

  const openYesNoModalCloseFunction = () => {
    setOpenYesNoModalAction("");
    setOpenYesNoModal(false);
    setOpenYesNoModalError("");
    setAcceptAndMatchId("");
    SetSelectedBidQuantity("");
  };

  // Accept Bid and Match 
  const acceptBidAndMatch = () => {
    setOpenYesNoModalError("");
    dispatch(accept_bid_on_commodity_order({ broker_bid_id: accept_and_match_id }));
  };

  // Listen to Specific Broker Offer Reducer
  const acceptBidOnCommodityOffer = useSelector((state) => state.acceptBidOnCommodityOffer);

  useEffect(() => {
    if (acceptBidOnCommodityOffer.status !== app_consts.IDLE)
    {
      if (acceptBidOnCommodityOffer.isLoading)
      {
        setOpenYesNoModalLoading(true);
      } else
      {
        setOpenYesNoModalLoading(false);
        if (acceptBidOnCommodityOffer.error)
        {
          setOpenYesNoModalError(acceptBidOnCommodityOffer.error);
        } else if (acceptBidOnCommodityOffer.data)
        {
          dispatch(retrieve_specific_broker_offer(broker_offer_id));
          openYesNoModalCloseFunction();
        }
      }
    }
  }, [acceptBidOnCommodityOffer]);

  // URL Prefixes
  const brokerUrlPrefix = () => {
    if (view)
    {
      return 'broker/view';
    }
    else
    {
      return 'broker';
    }
  };

  return (
    <div>
      <button
        data-drawer-target='logo-sidebar'
        data-drawer-toggle='logo-sidebar'
        aria-controls='logo-sidebar'
        type='button'
        className='inline-flex items-center p-2 mt-2 ml-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600'
      >
        <span className='sr-only'>Open sidebar</span>
        <svg
          className='w-6 h-6'
          aria-hidden='true'
          fill='currentColor'
          viewBox='0 0 20 20'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            clip-rule='evenodd'
            fill-rule='evenodd'
            d='M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z'
          ></path>
        </svg>
        <NavLogo />
      </button>


      <aside
        id='logo-sidebar'
        className='fixed top-0 left-0 z-40 w-64 h-screen transition-transform -translate-x-full sm:translate-x-0'
        aria-label='Sidebar'
      >
        <div className='h-full px-3 py-4 overflow-y-auto bg-gray-50 dark:bg-gray-800'>
          <a href='/' className='flex items-center pl-2.5 mb-5'>
            <Logo />
          </a>

          <ul className='space-y-2 font-medium'>
            <li>
              <a href="/home" className="flex items-center p-2 text-gray-600 hover:text-green-600 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                <svg class="w-6 h-6  dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6.143 1H1.857A.857.857 0 0 0 1 1.857v4.286c0 .473.384.857.857.857h4.286A.857.857 0 0 0 7 6.143V1.857A.857.857 0 0 0 6.143 1Zm10 0h-4.286a.857.857 0 0 0-.857.857v4.286c0 .473.384.857.857.857h4.286A.857.857 0 0 0 17 6.143V1.857A.857.857 0 0 0 16.143 1Zm-10 10H1.857a.857.857 0 0 0-.857.857v4.286c0 .473.384.857.857.857h4.286A.857.857 0 0 0 7 16.143v-4.286A.857.857 0 0 0 6.143 11Zm10 0h-4.286a.857.857 0 0 0-.857.857v4.286c0 .473.384.857.857.857h4.286a.857.857 0 0 0 .857-.857v-4.286a.857.857 0 0 0-.857-.857Z" />
                </svg>
                <span className="ml-3">My Dashboards</span>
              </a>
            </li>
            <li>
              <a
                href={`/${brokerUrlPrefix()}/${entity_id}/${entity_mid}`}
                className="flex items-center p-2 text-gray-600 hover:text-green-600 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
              >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 3v11.25A2.25 2.25 0 006 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0118 16.5h-2.25m-7.5 0h7.5m-7.5 0l-1 3m8.5-3l1 3m0 0l.5 1.5m-.5-1.5h-9.5m0 0l-.5 1.5M9 11.25v1.5M12 9v3.75m3-6v6" />
                </svg>
                <span className='ml-3'>Broker Dashboard</span>
              </a>
            </li>
            <li>
              <a href={`/${brokerUrlPrefix()}/${entity_id}/${entity_mid}/customer_management`} className="flex items-center p-2 text-gray-600 hover:text-green-600 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z" />
                </svg>
                <span className="ml-3">Clients</span>
              </a>
            </li>
            <li>
              <a href={`/${brokerUrlPrefix()}/${entity_id}/${entity_mid}/bid_management`} className="flex items-center p-2 text-gray-600 hover:text-green-600 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m6.75 12l-3-3m0 0l-3 3m3-3v6m-1.5-15H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                </svg>
                <span className="ml-3">Bids</span>
              </a>
            </li>
            <li>
              <a href={`/${brokerUrlPrefix()}/${entity_id}/${entity_mid}/offer_management`} className="flex items-center p-2 text-green-600 hover:text-green-600 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m.75 12l3 3m0 0l3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                </svg>
                <span className="ml-3">Offers</span>
              </a>
            </li>
            <li>
              <a
                href={`/${brokerUrlPrefix()}/${entity_id}/${entity_mid}/live_market`} className='flex items-center p-2 text-gray-600 hover:text-green-600 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke-width='1.5'
                  stroke='currentColor'
                  class='w-6 h-6'
                >
                  <path
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    d='M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z'
                  />
                </svg>
                <span className='ml-3'>ATS</span>
              </a>
            </li>
            <li>
              <a
                href={`/${brokerUrlPrefix()}/${entity_id}/${entity_mid}/provision_receipts`} className='flex items-center p-2 text-gray-600 hover:text-green-600 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke-width='1.5'
                  stroke='currentColor'
                  class='w-6 h-6'
                >
                  <path
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    d='M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z'
                  />
                </svg>
                <span className='ml-3'>Provisional Receipts</span>
              </a>
            </li>
            <li className="my-2 fixed bottom-12 w-11/12">
              <a onClick={handleLogout} className="cursor-pointer flex items-center p-2 text-gray-600 hover:text-green-600 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                <svg class="w-6 h-6 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 8h11m0 0-4-4m4 4-4 4m-5 3H3a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h3" />
                </svg>
                <span className="flex-1 ml-3 whitespace-nowrap">Sign Out</span>
              </a>
            </li>
          </ul>
        </div>
      </aside>

      <div className='p-4 sm:ml-64'>
        <div className='p-4 rounded-lg dark:border-gray-700'>
          <ChildHeaderBanner
            title='Offer Details'
            /* producer={contact_name}  */ header_addition={
              profilesList && profilesList.entity_name
                ? profilesList.entity_name
                : ''
            }
            type={app_consts.OFFER_DETAILS}
            navInfo={{ entity_id, entity_mid, broker_offer_id }}
          />

          <div className='relative'>
            <CustomTabs
              tabs={tabs}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
            <br />
            {loading ? (
              <>
                <div className="text-center mt-4 h-10">
                  <div role="status">
                    <svg
                      aria-hidden="true"
                      className="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-green-600"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                    <span className="sr-only">Please wait...</span>
                  </div>
                </div>
              </>
            ) : (
              <>
                {error && error?.length > 0 ? (
                  <>
                    <div className='text-center mt-8 h-10'>
                      <div
                        role='status'
                        className='text-red-600 flex flex-col items-center justify-center mb-4 leading-4'
                      >
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          fill='none'
                          viewBox='0 0 24 24'
                          strokeWidth={1.5}
                          stroke='currentColor'
                          className='w-8 h-8'
                        >
                          <path
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            d='M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
                          />
                        </svg>
                      </div>
                      <span
                        colSpan='8'
                        className='text-gray-700 py-4 my-6 tracking-wider'
                      >
                        Could Not Retrieve Offer Details.
                      </span>
                    </div>
                  </>
                ) : (
                  <>
                    {activeTab === 'Offer Information' && (
                      <>
                        <div>
                          <div class='flex flex-col items-stretch justify-end flex-shrink-0 w-full space-y-2 md:w-auto md:flex-row md:space-y-0 md:items-center md:space-x-3'>
                            {
                              !view && (
                                <>
                                  <button
                                    type='button'
                                    onClick={() =>
                                      setModal({ tradingAuthorityModal: true })
                                    }
                                    className='flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800'
                                  >
                                    <svg
                                      xmlns='http://www.w3.org/2000/svg'
                                      viewBox='0 0 20 20'
                                      fill='currentColor'
                                      className='w-5 h-5 mr-2'
                                    >
                                      <path d='M5.433 13.917l1.262-3.155A4 4 0 017.58 9.42l6.92-6.918a2.121 2.121 0 013 3l-6.92 6.918c-.383.383-.84.685-1.343.886l-3.154 1.262a.5.5 0 01-.65-.65z' />
                                      <path d='M3.5 5.75c0-.69.56-1.25 1.25-1.25H10A.75.75 0 0010 3H4.75A2.75 2.75 0 002 5.75v9.5A2.75 2.75 0 004.75 18h9.5A2.75 2.75 0 0017 15.25V10a.75.75 0 00-1.5 0v5.25c0 .69-.56 1.25-1.25 1.25h-9.5c-.69 0-1.25-.56-1.25-1.25v-9.5z' />
                                    </svg>
                                    Update Offer
                                  </button>
                                </>
                              )
                            }
                            <div className='flex items-center w-full space-x-3 md:w-auto relative'>
                              {/* {broker_offer_data && (
                                <button
                                  id='actionsDropdownButton'
                                  onClick={toggleDropdown}
                                  className='flex items-center justify-center w-full px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg md:w-auto focus:outline-none hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700'
                                  type='button'
                                >
                                  <svg
                                    className='-ml-1 mr-1.5 w-5 h-5'
                                    fill='currentColor'
                                    viewBox='0 0 20 20'
                                    xmlns='http://www.w3.org/2000/svg'
                                    aria-hidden='true'
                                  >
                                    <path
                                      clipRule='evenodd'
                                      fillRule='evenodd'
                                      d='M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z'
                                    />
                                  </svg>
                                  Actions
                                </button>
                              )} */}
                              {dropdownVisible && (
                                <div className='absolute top-full right-0 mt-1 z-10 bg-white divide-y divide-gray-100 rounded shadow w-44 dark:bg-gray-700 dark:divide-gray-600'>
                                  <ul className='py-1 text-sm text-gray-700 dark:text-gray-200'>
                                    <li>
                                      <a
                                        href='#'
                                        // onClick={handleExportCSV}
                                        onClick={() => handleOfferActions("withdrawn")}
                                        className='block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white'
                                      >
                                        Withdraw Offer
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              )}
                            </div>
                          </div>
                          <div class='relative overflow-hidden bg-white dark:bg-gray-800 sm:rounded-lg'>
                            {broker_offer_data && (
                              <>
                                <div class='flex-row items-center justify-between p-4 space-y-3 sm:flex sm:space-y-0 sm:space-x-4'>
                                  <div>
                                    <h5 class='mr-3 font-semibold dark:text-white'>
                                      Offer Information
                                    </h5>
                                    {/* <p class='text-gray-600 font-medium dark:text-gray-400'>
                                      Commodity Offer
                                    </p> */}
                                  </div>
                                </div>
                                <dl className='grid grid-cols-2 gap-4 mb-4'>
                                  <div className='col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600'>
                                    <dt className='mb-2 font-semibold leading-none text-gray-900 dark:text-white'>
                                      Warehouse Receipt Number
                                    </dt>
                                    <dd className='flex items-center text-gray-500 dark:text-gray-400'>
                                      {warehouse_receipt_data?.receipt_number}
                                    </dd>
                                  </div>
                                  <div className='col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600'>
                                    <dt className='mb-2 font-semibold leading-none text-gray-900 dark:text-white'>
                                      Commodity
                                    </dt>
                                    <dd className='flex items-center text-gray-500 dark:text-gray-400'>
                                      {broker_offer_data?.commodity}
                                    </dd>
                                  </div>
                                  <div className='col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600'>
                                    <dt className='mb-2 font-semibold leading-none text-gray-900 dark:text-white'>
                                      Quality
                                    </dt>
                                    <dd className='flex items-center text-gray-500 dark:text-gray-400'>
                                      {broker_offer_data?.quality}
                                    </dd>
                                  </div>
                                  <div className='col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600'>
                                    <dt className='mb-2 font-semibold leading-none text-gray-900 dark:text-white'>
                                      Total Volume
                                    </dt>
                                    <dd className='flex items-center text-gray-500 dark:text-gray-400'>
                                      {broker_offer_data?.volume}
                                    </dd>
                                  </div>
                                  <div className='col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600'>
                                    <dt className='mb-2 font-semibold leading-none text-gray-900 dark:text-white'>
                                      Amount to Be Sold
                                    </dt>
                                    <dd className='flex items-center text-gray-500 dark:text-gray-400'>
                                      {broker_offer_data?.volume_on_offer
                                        ? broker_offer_data?.volume_on_offer
                                        : '---'}
                                    </dd>
                                  </div>
                                  <div className='col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600'>
                                    <dt className='mb-2 font-semibold leading-none text-gray-900 dark:text-white'>
                                      Unit of Measure
                                    </dt>
                                    <dd className='flex items-center text-gray-500 dark:text-gray-400'>
                                      {broker_offer_data?.unit_of_measure}
                                    </dd>
                                  </div>
                                  {/* <div className='col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600'>
                                    <dt className='mb-2 font-semibold leading-none text-gray-900 dark:text-white'>
                                      Status
                                    </dt>
                                    <dd className='flex items-center text-gray-500 dark:text-gray-400'>
                                      <span className='bg-yellow-100 text-yellow-800'>
                                        {broker_offer_data.status
                                          ? broker_offer_data?.status.toUpperCase()
                                          : ''}
                                      </span>
                                    </dd>
                                  </div>
                                  <div className='col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600'>
                                    <dt className='mb-2 font-semibold leading-none text-gray-900 dark:text-white'>
                                      Status
                                    </dt>
                                    <dd className='flex items-center text-gray-500 dark:text-gray-400'>
                                      {broker_offer_data?.status
                                        ? broker_offer_data?.status.toUpperCase()
                                        : ''}
                                    </dd>
                                  </div> */}
                                  <div className='col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600'>
                                    <dt className='mb-2 font-semibold leading-none text-gray-900 dark:text-white'>
                                      Requested Unit Price
                                    </dt>
                                    <dd className='flex items-center text-gray-500 dark:text-gray-400'>
                                      {broker_offer_data?.requested_unit_price}
                                    </dd>
                                  </div>
                                  <div className='col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600'>
                                    <dt className='mb-2 font-semibold leading-none text-gray-900 dark:text-white'>
                                      Order Type
                                    </dt>
                                    <dd className='flex items-center text-gray-500 dark:text-gray-400'>
                                      {broker_offer_data?.order_type
                                        ? broker_offer_data?.order_type.toUpperCase()
                                        : ''}
                                    </dd>
                                  </div>
                                  <div className='col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600'>
                                    <dt className='mb-2 font-semibold leading-none text-gray-900 dark:text-white'>
                                      Date
                                    </dt>
                                    <dd className='flex items-center text-gray-500 dark:text-gray-400'>
                                      {broker_offer_data?.date_to_be_placed_on_platform
                                        ? broker_offer_data?.date_to_be_placed_on_platform.toUpperCase()
                                        : ''}
                                    </dd>
                                  </div>
                                  <div className='col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600'>
                                    <dt className='mb-2 font-semibold leading-none text-gray-900 dark:text-white'>
                                      Special Terms and Conditions
                                    </dt>
                                    <dd className='flex items-center text-gray-500 dark:text-gray-400'>
                                      {broker_offer_data?.special_terms_and_conditions
                                        ? broker_offer_data?.special_terms_and_conditions
                                        : ''}
                                    </dd>
                                  </div>
                                  <div className='col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600'>
                                    <dt className='mb-2 font-semibold leading-none text-gray-900 dark:text-white'>
                                      Other Documentation Required
                                    </dt>
                                    <dd className='flex items-center text-gray-500 dark:text-gray-400'>
                                      {broker_offer_data?.other_documentation_required
                                        ? broker_offer_data?.other_documentation_required
                                        : ''}
                                    </dd>
                                  </div>
                                  <div className='col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600'>
                                    <dt className='mb-2 font-semibold leading-none text-gray-900 dark:text-white'>
                                      Order Number
                                    </dt>
                                    <dd className='flex items-center text-gray-500 dark:text-gray-400'>
                                      {commodity_order_data?.order_number
                                        ? commodity_order_data?.order_number
                                        : ''}
                                    </dd>
                                  </div>
                                  <div className='col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600'>
                                    <dt className='mb-2 font-semibold leading-none text-gray-900 dark:text-white'>
                                      Status
                                    </dt>
                                    <dd className='flex items-center text-gray-500 dark:text-gray-400'>
                                      <span className='bg-yellow-100 text-yellow-800'>
                                        {commodity_order_data?.status
                                          ? commodity_order_data?.status.toUpperCase()
                                          : ''}
                                      </span>
                                    </dd>
                                  </div>
                                  <div className='col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600'>
                                    <dt className='mb-2 font-semibold leading-none text-gray-900 dark:text-white'>
                                      Reference Number
                                    </dt>
                                    <dd className='flex items-center text-gray-500 dark:text-gray-400'>
                                      {commodity_order_data?.commodity_order_reference_no
                                        ? commodity_order_data?.commodity_order_reference_no
                                        : ''}
                                    </dd>
                                  </div>
                                </dl>

                                <div class='flex-row items-center justify-between p-4 space-y-3 sm:flex sm:space-y-0 sm:space-x-4'>
                                  <div>
                                    <h5 class='mr-3 font-semibold dark:text-white'>
                                      Producer Information
                                    </h5>
                                    {/* <p class='text-gray-600 font-medium dark:text-gray-400'>
                                      Commodity Producer
                                    </p> */}
                                  </div>
                                </div>
                                <dl className='grid grid-cols-2 gap-4 mb-4'>
                                  <div className='col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600'>
                                    <dt className='mb-2 font-semibold leading-none text-gray-900 dark:text-white'>
                                      Producer Id
                                    </dt>
                                    <dd className='flex items-center text-gray-500 dark:text-gray-400'>
                                      {producer_data?.producer_id}
                                    </dd>
                                  </div>
                                  <div className='col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600'>
                                    <dt className='mb-2 font-semibold leading-none text-gray-900 dark:text-white'>
                                      Name
                                    </dt>
                                    <dd className='flex items-center text-gray-500 dark:text-gray-400'>
                                      {producer_data?.contact_name}
                                    </dd>
                                  </div>
                                  <div className='col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600'>
                                    <dt className='mb-2 font-semibold leading-none text-gray-900 dark:text-white'>
                                      Contact Email
                                    </dt>
                                    <dd className='flex items-center text-gray-500 dark:text-gray-400'>
                                      {producer_data?.contact_email}
                                    </dd>
                                  </div>
                                  <div className='col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600'>
                                    <dt className='mb-2 font-semibold leading-none text-gray-900 dark:text-white'>
                                      Contact Phone
                                    </dt>
                                    <dd className='flex items-center text-gray-500 dark:text-gray-400'>
                                      {producer_data?.contact_phone}
                                    </dd>
                                  </div>
                                  <div className='col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600'>
                                    <dt className='mb-2 font-semibold leading-none text-gray-900 dark:text-white'>
                                      Type
                                    </dt>
                                    <dd className='flex items-center text-gray-500 dark:text-gray-400'>
                                      {producer_data?.type
                                        ? producer_data?.type.toUpperCase()
                                        : ''}
                                    </dd>
                                  </div>
                                  <div className='col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600'>
                                    <dt className='mb-2 font-semibold leading-none text-gray-900 dark:text-white'>
                                      Country
                                    </dt>
                                    <dd className='flex items-center text-gray-500 dark:text-gray-400'>
                                      {producer_data?.country}
                                    </dd>
                                  </div>
                                  <div className='col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600'>
                                    <dt className='mb-2 font-semibold leading-none text-gray-900 dark:text-white'>
                                      Address
                                    </dt>
                                    <dd className='flex items-center text-gray-500 dark:text-gray-400'>
                                      {producer_data?.contact_address}
                                    </dd>
                                  </div>
                                </dl>

                                <div class='flex-row items-center justify-between p-4 space-y-3 sm:flex sm:space-y-0 sm:space-x-4'>
                                  <div>
                                    <h5 class='mr-3 font-semibold dark:text-white'>
                                      Offer History
                                    </h5>
                                  </div>
                                </div>
                                <TableComp
                                  tableData={broker_offer_data?.history}
                                  tableType={app_consts.OFFER_HISTORY_TABLE}
                                />
                              </>
                            )}
                          </div>
                          <YesNoModal isOpen={openYesNoModal} setIsOpen={setOpenYesNoModal} closeFunction={openYesNoModalCloseFunction} modalTitle={`${openYesNoModalAction} Bid`} modalMessage={`Are you sure that you want to ${openYesNoModalAction} this Bid?`} cancelButtonLabel="NO" agreeButtonLabel={openYesNoModalLoading ? "Processing..." : "Accept and Match"} agreeClickFunction={acceptBidAndMatch} modalError={openYesNoModalError} modalLoading={openYesNoModalLoading} messageHint={`${parseFloat(selectBidQuantity).toFixed(2)} MT will be Reserved for this Bid and your placed Offer Will be Updated to ${parseFloat(broker_offer_data?.volume_on_offer - selectBidQuantity).toFixed(2)} MT.`} />

                          <YesNoModal
                            isOpen={modalOpen}
                            closeFunction={closeModal}
                            modalTitle='Confirmation'
                            modalMessage='Are you sure you want to withdraw this offer?'
                            cancelButtonLabel='Cancel'
                            agreeButtonLabel='Yes'
                            agreeClickFunction={confirmAction}
                          />
                          {broker_offer_data && (
                            <UpdateBrokerSuperOffer
                              showModal={modal.tradingAuthorityModal}
                              closeModal={closeTradingAuthorityModal}
                              openSelectedModal={openSelectedModal}
                              offerData={broker_offer_data}
                            />
                          )}
                        </div>
                      </>
                    )}
                    {activeTab === 'Bids On Offer' && (
                      <>
                        <div class='flex-row items-center justify-between p-4 space-y-3 sm:flex sm:space-y-0 sm:space-x-4'>
                          <div>
                            <h5 class='mr-3 font-semibold dark:text-white'>
                              Bids On Offer
                            </h5>
                            {/* <p class='text-gray-600 font-medium dark:text-gray-400'>
                            Commodity Offer
                          </p> */}
                          </div>
                        </div>
                        <Card className='w-6/12' href='#'>
                          {brokerBids && brokerBids.length > 0 ? (
                            brokerBids.map((bid) => (

                              < Timeline >
                                <Timeline.Item>
                                  <Timeline.Point />
                                  <Timeline.Content>
                                    <Timeline.Time>
                                      {bid?.date_to_be_placed_on_platform}
                                    </Timeline.Time>
                                    <Timeline.Title>
                                      Bid:{' '}
                                      {bid?.bid
                                        ? 'K' +
                                        formatNumber(parseFloat(bid?.bid))
                                        : 'offer'}
                                    </Timeline.Title>
                                    <Timeline.Body>
                                      <p>
                                        Quantity:{' '}
                                        {bid?.bid_volume
                                          ? formatNumber(
                                            parseFloat(bid?.bid_volume)
                                          ) + ' MT'
                                          : 'N/A'}
                                      </p>

                                      {bid.status === 'open' ? (
                                        <p>
                                          Status:{' '}
                                          <span className='font-bold text-green-400'>
                                            {bid?.status}
                                          </span>
                                        </p>
                                      ) : bid.status === 'closed' ? (
                                        <p>
                                          Status:{' '}
                                          <span className='font-bold text-yellow-400'>
                                            {bid?.status}
                                          </span>
                                        </p>
                                      ) : bid.status === 'sold' ? (
                                        <p>
                                          Status:{' '}
                                          <span className='font-bold text-red-500'>
                                            {bid?.status}
                                          </span>
                                        </p>
                                      ) : (
                                        'N/A'
                                      )}
                                    </Timeline.Body>
                                    {
                                      !view && (
                                        <>
                                          {
                                            bid?.status === app_consts.CLOSED ?
                                              ""
                                              :
                                              parseFloat(bid.bid_volume) > parseFloat(broker_offer_data?.volume_on_offer) ?
                                                <span className='text-red-700 font-medium text-sm'>Cannot Accept Bid. <span className="text-xs">(Bid Quantity is More than Remaining Volume on Offer.)</span></span>
                                                :
                                                <Button color='gray' onClick={() => openYesNoModalOpenFunction("Accept and Match", bid.id, bid.bid_volume)}>
                                                  Accept & Match
                                                  <HiArrowNarrowRight className='ml-2 h-3 w-3' />
                                                </Button>
                                          }
                                        </>
                                      )
                                    }
                                  </Timeline.Content>
                                </Timeline.Item>
                              </Timeline>
                            ))
                          ) : (
                            <tr>
                              <td colSpan='8' className='text-center py-4'>
                                No Bids found.
                              </td>
                            </tr>
                          )}
                        </Card>
                        <YesNoModal isOpen={openYesNoModal} setIsOpen={setOpenYesNoModal} closeFunction={openYesNoModalCloseFunction} modalTitle={`${openYesNoModalAction} Bid`} modalMessage={`Are you sure that you want to ${openYesNoModalAction} this Bid?`} cancelButtonLabel="NO" agreeButtonLabel={openYesNoModalLoading ? "Processing..." : "Accept and Match"} agreeClickFunction={acceptBidAndMatch} modalError={openYesNoModalError} modalLoading={openYesNoModalLoading} messageHint={`${parseFloat(selectBidQuantity).toFixed(2)} MT will be Reserved for this Bid and your placed Offer Will be Updated to ${parseFloat(broker_offer_data?.volume_on_offer - selectBidQuantity).toFixed(2)} MT.`} />
                      </>
                    )}
                  </>
                )}
                {activeTab === 'Trades' && (
                  <>
                    <div class='flex-row items-center justify-between p-4 space-y-3 sm:flex sm:space-y-0 sm:space-x-4'>
                      <div>
                        <h5 class='mr-3 font-semibold dark:text-white'>
                          Trades
                        </h5>
                        {/* <p class='text-gray-600 font-medium dark:text-gray-400'>
                        Commodity Offer
                      </p> */}
                      </div>
                    </div>
                    <TableComp
                      tableData={trade_data}
                      tableType={app_consts.BROKER_OFFER_TRADE_TABLE}
                    />
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OfferDetails;
