import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as app_consts from "../../../../utils/constants";
import * as yup from 'yup';
import { sunflower_grading_scale } from '../../../../utils/commodity_grading_scale';
import { createCommodityGrading } from '../../../../utils/actions';

const grading_criteria = { moisture_content: "", extraneous_matter: "", total_defective_grains: "", heat_damaged: "", pest_damaged: "", head_rot_other_fungal_damaged: "", immature_shrivelled_beans: "", germinated_seed: "", weather_damaged_seed: "", oil_content: "", protein_content: "" };

const SunflowerGradingStep = ({ nextStep, prevStep, prevData, setPrevData }) => {

    const [commodity_grade, setCommodityGrade] = useState("--");
    const [delivery_id, setDeliveryId] = useState("");
    const [formData, setFormData] = useState(prevData);
    const [commod_grad_criteria, setCommodGradeCriteria] = useState(grading_criteria);

    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        setFormData(prevData);
        setDeliveryId(prevData.delivery_id);
    }, [prevData]);

    // Validate Form Params
    const commodityGradingSchema = yup.object().shape({
        extraneous_matter: yup.string().required('Extraneous Mattter is required'),
        moisture_content: yup.string().required('Moisture Content is required'),
        total_defective_grains: yup.string().required('Total Defective Grains is required'),
        heat_damaged: yup.string().required('Head Damaged is required'),
        pest_damaged: yup.string().required('Pest Damaged is required'),
        head_rot_other_fungal_damaged: yup.string().required('Hot Rot Other Fungal Damaged is Required'),
        immature_shrivelled_beans: yup.string().required('Immature Shriveled Beans is required'),
        germinated_seed: yup.string().required('Germinated Seed is required'),
        weather_damaged_seed: yup.string().required('Weather Damaged Seed is required'),
        oil_content: yup.string().required('Oil Content is required'),
        protein_content: yup.string().required('Protein Content is required'),
    });

    // Formik Validation
    const formik = useFormik({
        initialValues: {
            moisture_content: '',
            extraneous_matter: "",
            total_defective_grains: '',
            heat_damaged: '',
            pest_damaged: '',
            head_rot_other_fungal_damaged: '',
            immature_shrivelled_beans: '',
            germinated_seed: '',
            weather_damaged_seed: '',
            oil_content: '',
            protein_content: '',
            commodity: app_consts.SUNFLOWER_SEED,
            delivery_id: delivery_id,
            grade: commodity_grade
        },

        validationSchema: commodityGradingSchema,
        onSubmit: async (values) => {
            setError("");
            setLoading(true);
            try
            {
                const commodity_params = {
                    moisture_content: values.moisture_content,
                    extraneous_matter: values.extraneous_matter,
                    total_defective_grains: values.total_defective_grains,
                    heat_damaged: values.heat_damaged,
                    pest_damaged: values.pest_damaged,
                    head_rot_other_fungal_damaged: values.head_rot_other_fungal_damaged,
                    immature_shrivelled_beans: values.immature_shrivelled_beans,
                    germinated_seed: values.germinated_seed,
                    weather_damaged_seed: values.weather_damaged_seed,
                    oil_content: values.oil_content,
                    protein_content: values.protein_content,
                    commodity: app_consts.SUNFLOWER_SEED,
                    delivery_id: delivery_id,
                    grade: commodity_grade,
                };

                await dispatch(createCommodityGrading(commodity_params));

                nextStep();

            } catch (error)
            {
                if (error.response)
                {
                    if (error.response.status === 500)
                    {
                        setError("Failed to Grade Commodity");
                    }
                    else
                    {
                        setError(error.response.data.message);
                    }
                }
                else
                {
                    setError("Failed to Grade Commodity");
                }
            } finally
            {
                setLoading(false);
            }
        },
    });

    const handleMeasurementChange = (e) => {
        let result;
        if (e.target.value)
        {
            result = sunflower_grading_scale(e.target.name, e.target.value);
        }
        else
        {
            result = "";
        }
        setCommodGradeCriteria({ ...commod_grad_criteria, [e.target.name]: result });
        formik.setFieldValue(e.target.name, e.target.value);
    };

    const handleOnblur = (name, value) => {
        if (value)
        {
            formik.setFieldValue(name, parseFloat(value).toFixed(2));
        }
    };

    // Listen to Formik Values Changes
    useEffect(() => {
        if (commod_grad_criteria === grading_criteria)
        {
            setCommodityGrade("---");
        }
        else
        {
            const gradingArray = [commod_grad_criteria.moisture_content, commod_grad_criteria.extraneous_matter, commod_grad_criteria.total_defective_grains, commod_grad_criteria.heat_damaged, commod_grad_criteria.pest_damaged, commod_grad_criteria.head_rot_other_fungal_damaged, commod_grad_criteria.immature_shrivelled_beans, commod_grad_criteria.germinated_seed, commod_grad_criteria.weather_damaged_seed, commod_grad_criteria.oil_content, commod_grad_criteria.protein_content];

            if (gradingArray?.includes(0) || gradingArray?.includes(""))
            {
                setCommodityGrade("---");
            }
            else if (gradingArray?.includes("C"))
            {
                setCommodityGrade("C");
            }
            else if (gradingArray?.includes("B"))
            {
                setCommodityGrade("B");
            }
            else
            {
                setCommodityGrade("A");
            }
        }

    }, [commod_grad_criteria]);

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className='flex flex-row w-full'>
                    <div className="grid gap-6 mb-8 lg:grid-cols-3 p-6 sm:p-16 lg:p-8 lg:w-9/12 m-auto">
                        <div>
                            <label htmlFor="moisture_content" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                Moisture Content
                            </label>
                            <input
                                step="0.01"
                                type="number"
                                min="0.00"
                                name="moisture_content"
                                value={formik.values.moisture_content}
                                onChange={handleMeasurementChange}
                                onBlur={() => handleOnblur("moisture_content", formik.values.moisture_content)}
                                // required
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                            />
                            {formik.touched.moisture_content && formik.errors.moisture_content && (
                                <p className="text-red-500 text-sm mt-1">{formik.errors.moisture_content}</p>
                            )}
                        </div>
                        <div>
                            <label htmlFor="extraneous_matter" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                Extraneous Matter
                            </label>
                            <input
                                step="0.01"
                                type="number"
                                min="0.00"
                                name="extraneous_matter"
                                value={formik.values.extraneous_matter}
                                onChange={handleMeasurementChange}
                                onBlur={() => handleOnblur("extraneous_matter", formik.values.extraneous_matter)}
                                // required
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                            />
                            {formik.touched.extraneous_matter && formik.errors.extraneous_matter && (
                                <p className="text-red-500 text-sm mt-1">{formik.errors.extraneous_matter}</p>
                            )}
                        </div>
                        <div>
                            <label htmlFor="total_defective_grains" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                Total Defective Grains
                            </label>
                            <input
                                step="0.01"
                                type="number"
                                min="0.00"
                                name="total_defective_grains"
                                value={formik.values.total_defective_grains}
                                onChange={handleMeasurementChange}
                                onBlur={() => handleOnblur("total_defective_grains", formik.values.total_defective_grains)}
                                // required
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                            />
                            {formik.touched.total_defective_grains && formik.errors.total_defective_grains && (
                                <p className="text-red-500 text-sm mt-1">{formik.errors.total_defective_grains}</p>
                            )}
                        </div>
                        <div>
                            <label htmlFor="heat_damaged" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                Heat Damaged
                            </label>
                            <input
                                step="0.01"
                                type="number"
                                min="0.00"
                                name="heat_damaged"
                                value={formik.values.heat_damaged}
                                onChange={handleMeasurementChange}
                                onBlur={() => handleOnblur("heat_damaged", formik.values.heat_damaged)}
                                // required
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                            />
                            {formik.touched.heat_damaged && formik.errors.heat_damaged && (
                                <p className="text-red-500 text-sm mt-1">{formik.errors.heat_damaged}</p>
                            )}
                        </div>
                        <div>
                            <label htmlFor="pest_damaged" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                Pest Damaged
                            </label>
                            <input
                                step="0.01"
                                type="number"
                                min="0.00"
                                name="pest_damaged"
                                value={formik.values.pest_damaged}
                                onChange={handleMeasurementChange}
                                onBlur={() => handleOnblur("pest_damaged", formik.values.pest_damaged)}
                                // required
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                            />
                            {formik.touched.pest_damaged && formik.errors.pest_damaged && (
                                <p className="text-red-500 text-sm mt-1">{formik.errors.pest_damaged}</p>
                            )}
                        </div>
                        <div>
                            <label htmlFor="head_rot_other_fungal_damaged" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                Head Rot Other Fungal Damaged
                            </label>
                            <input
                                step="0.01"
                                type="number"
                                min="0.00"
                                name="head_rot_other_fungal_damaged"
                                value={formik.values.head_rot_other_fungal_damaged}
                                onChange={handleMeasurementChange}
                                onBlur={() => handleOnblur("head_rot_other_fungal_damaged", formik.values.head_rot_other_fungal_damaged)}
                                // required
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                            />
                            {formik.touched.head_rot_other_fungal_damaged && formik.errors.head_rot_other_fungal_damaged && (
                                <p className="text-red-500 text-sm mt-1">{formik.errors.head_rot_other_fungal_damaged}</p>
                            )}
                        </div>
                        <div>
                            <label htmlFor="immature_shrivelled_beans" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                Immature Shrivelled Beans
                            </label>
                            <input
                                step="0.01"
                                type="number"
                                min="0.00"
                                name="immature_shrivelled_beans"
                                value={formik.values.immature_shrivelled_beans}
                                onChange={handleMeasurementChange}
                                onBlur={() => handleOnblur("immature_shrivelled_beans", formik.values.immature_shrivelled_beans)}
                                // required
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                            />
                            {formik.touched.immature_shrivelled_beans && formik.errors.immature_shrivelled_beans && (
                                <p className="text-red-500 text-sm mt-1">{formik.errors.immature_shrivelled_beans}</p>
                            )}
                        </div>
                        <div>
                            <label htmlFor="germinated_seed" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                Germinated Seed
                            </label>
                            <input
                                step="0.01"
                                type="number"
                                min="0.00"
                                name="germinated_seed"
                                value={formik.values.germinated_seed}
                                onChange={handleMeasurementChange}
                                onBlur={() => handleOnblur("germinated_seed", formik.values.germinated_seed)}
                                // required
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                            />
                            {formik.touched.germinated_seed && formik.errors.germinated_seed && (
                                <p className="text-red-500 text-sm mt-1">{formik.errors.germinated_seed}</p>
                            )}
                        </div>
                        <div>
                            <label htmlFor="weather_damaged_seed" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                Weather Damaged Seed
                            </label>
                            <input
                                step="0.01"
                                type="number"
                                min="0.00"
                                name="weather_damaged_seed"
                                value={formik.values.weather_damaged_seed}
                                onChange={handleMeasurementChange}
                                onBlur={() => handleOnblur("weather_damaged_seed", formik.values.weather_damaged_seed)}
                                // required
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                            />
                            {formik.touched.weather_damaged_seed && formik.errors.weather_damaged_seed && (
                                <p className="text-red-500 text-sm mt-1">{formik.errors.weather_damaged_seed}</p>
                            )}
                        </div>
                        <div>
                            <label htmlFor="oil_content" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                Oil Content
                            </label>
                            <input
                                step="0.01"
                                type="number"
                                min="0.00"
                                name="oil_content"
                                value={formik.values.oil_content}
                                onChange={handleMeasurementChange}
                                onBlur={() => handleOnblur("oil_content", formik.values.oil_content)}
                                // required
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                            />
                            {formik.touched.oil_content && formik.errors.oil_content && (
                                <p className="text-red-500 text-sm mt-1">{formik.errors.oil_content}</p>
                            )}
                        </div>
                        <div>
                            <label htmlFor="protein_content" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                Protein Content
                            </label>
                            <input
                                step="0.01"
                                type="number"
                                min="0.00"
                                name="protein_content"
                                value={formik.values.protein_content}
                                onChange={handleMeasurementChange}
                                onBlur={() => handleOnblur("protein_content", formik.values.protein_content)}
                                // required
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                            />
                            {formik.touched.protein_content && formik.errors.protein_content && (
                                <p className="text-red-500 text-sm mt-1">{formik.errors.protein_content}</p>
                            )}
                        </div>
                    </div>
                    <div className='w-3/12 flex flex-column justify-center items-center h-auto bg-gray-50 p-2'>
                        <div>
                            <p className='text-center text-3xl mb-2 font-medium text-gray-700'>Commodity Grade</p>
                            <p className='text-5xl text-center font-bold my-6'>{commodity_grade}</p>
                        </div>
                    </div>
                </div>
                {error && error.length > 0 && (
                    <div className="my-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 px-6 py-4 sm:px-16 lg:px-8" role="alert">
                        <span className="font-medium">Error: </span>
                        {error}
                    </div>
                )}
                <div className='mb-4 px-6 flex flex-row justify-end items-center'>
                    {/* <button
    onClick={prevStep}
    type="submit"
    className="text-white bg-gray-500 hover:bg-gray-600 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-gray-500 dark:hover:bg-gray-600 dark:focus:ring-gray-700"
>
    Previous Step: Truck Information
</button> */}
                    <button
                        // disabled={!(formData.values.commmodity_id)}
                        // onClick={nextStep}
                        type="submit"
                        className={`text-white bg-green-600 cursor-pointer hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800`}
                    >
                        Next Step: Storage Information
                    </button>
                </div>
            </form>
        </>
    );
};

export default SunflowerGradingStep;