import { Document, Font, Page, Text, View } from '@react-pdf/renderer';
import React, { useEffect, useState } from 'react';
// Import the font files
import LatoRegular from '../../utils/fonts/Lato-Regular.ttf';
import LatoBold from '../../utils/fonts/Lato-Bold.ttf';
import PDFSVGLogo from '../../components/PDFSVGLogo';
import { format } from 'date-fns';

const WarehouseReceiptTradingInvoicePDF = ({ data }) => {

    const [invoice_data, setInvoiceData] = useState(data);

    //
    useEffect(() => {
        setInvoiceData(data);
    }, [data]);


    // Register the Lato font
    Font.register({ family: 'Lato', fonts: [{ src: LatoRegular }, { src: LatoBold, fontWeight: 'bold' }] });

    return (
        <>
            {
                invoice_data && (
                    <>
                        <Document title="Warehouse Receipt Trading Invoice">
                            <Page size="A4" style={{
                                flexDirection: "column",
                                backgroundColor: "rgba(0,0,0,0)",
                                height: "100%",
                                fontFamily: 'Lato'
                            }}>
                                <View fixed>
                                    <View style={{
                                        width: "100%",
                                        paddingRight: 10,
                                        paddingTop: 10,
                                        paddingBottom: 10,
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        alignItems: "start",
                                        marginBottom: 5,
                                    }}>
                                        <View style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            textTransform: "uppercase",
                                            width: "50%",
                                            paddingLeft: 30,
                                        }}>
                                            <View>
                                                <PDFSVGLogo height='80px' width='150px' />
                                            </View>
                                            <View style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                marginTop: 5,
                                                paddingLeft: 5,
                                                fontWeight: 500,
                                                fontSize: 14,
                                                color: "#000"
                                            }}>
                                                <Text >Plot Number. 26592</Text>
                                                <Text style={{ marginTop: 10 }}>Kafue Road, Makeni Area.</Text>
                                                <Text style={{ marginTop: 10 }}>Lusaka, Zambia</Text>
                                            </View>
                                        </View>
                                        <View style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "end",
                                            justifyContent: "end",
                                            textTransform: "uppercase",
                                            width: "50%"
                                        }}>
                                            <View style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                                marginTop: 25
                                            }}>
                                                <Text style={{ letterSpacing: 1, fontWeight: 400, fontSize: 24, }}>TRADE INVOICE</Text>
                                            </View>
                                            <View style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                marginTop: 28,
                                                paddingLeft: 5
                                            }}>invoice_data
                                                <Text style={{ fontWeight: 500, fontSize: 14, color: "#000" }}>Date:</Text>
                                                <Text style={{ fontWeight: 600, fontSize: 14, color: "#000", marginLeft: 2 }}>{invoice_data?.trade_data?.trade_date ? format(new Date(invoice_data?.trade_data?.trade_date), 'yyyy-MM-dd') : "---"}</Text>
                                            </View>
                                            <View style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                marginTop: 10,
                                                paddingLeft: 5
                                            }}>
                                                <Text style={{ fontWeight: 500, fontSize: 14, color: "#000" }}>Invoice #:</Text>
                                                <Text style={{ fontWeight: 600, fontSize: 14, color: "#000", marginLeft: 2 }}>{invoice_data?.trade_data?.trade_invoice_number ? invoice_data?.trade_data?.trade_invoice_number : "---"}</Text>
                                            </View>
                                            <View style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                marginTop: 10,
                                                paddingLeft: 5
                                            }}>
                                                <Text style={{ fontWeight: 500, fontSize: 14, color: "#000" }}>Offer Ref No.: </Text>
                                                <Text style={{ fontWeight: 600, fontSize: 14, color: "#000", marginTop: 2 }}>{invoice_data?.broker_offer_data?.commodity_order_reference_no ? invoice_data?.broker_offer_data?.commodity_order_reference_no : "---"}</Text>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                                <View style={{ paddingLeft: 30, marginTop: 20 }}>
                                    <View style={{ fontWeight: 700, fontSize: 18, color: "#000", textDecoration: "underline" }}>
                                        <Text>Trade Buyer Details</Text>
                                    </View>
                                    <View style={{ width: "100%", marginTop: 20, display: "flex", flexDirection: "row" }}>
                                        <View style={{ width: "50%", display: "flex", flexDirection: "column" }}>
                                            <Text style={{ fontSize: 14, fontWeight: 500 }}>Bid Ref No.:</Text>
                                            <Text style={{ fontSize: 14, fontWeight: 600 }}>{invoice_data?.broker_bid_data?.broker_bid_reference_no ? invoice_data?.broker_bid_data?.broker_bid_reference_no : "---"}</Text>
                                        </View>
                                        <View style={{ width: "50%", display: "flex", flexDirection: "column" }}>
                                            <Text style={{ fontSize: 14, fontWeight: 600 }}>Zamace No.:</Text>
                                            <Text style={{ fontSize: 14, fontWeight: 600 }}>{invoice_data?.buyer?.organization_id ? invoice_data?.buyer?.organization_id : "---"}</Text>
                                        </View>
                                    </View>
                                    <View style={{ width: "100%", marginTop: 20, display: "flex", flexDirection: "row" }}>
                                        <View style={{ width: "50%", display: "flex", flexDirection: "column" }}>
                                            <Text style={{ fontSize: 14, fontWeight: 500 }}>Name:</Text>
                                            <Text style={{ fontSize: 14, fontWeight: 600 }}>{invoice_data?.buyer?.entity_name ? invoice_data?.buyer?.entity_name : "---"}</Text>
                                        </View>
                                        <View style={{ width: "50%", display: "flex", flexDirection: "column" }}>
                                            <Text style={{ fontSize: 14, fontWeight: 500 }}>Email Address:</Text>
                                            <Text style={{ fontSize: 14, fontWeight: 600 }}>{invoice_data?.buyer?.entity_email ? invoice_data?.buyer?.entity_email : "---"}</Text>
                                        </View>
                                    </View>
                                    <View style={{ width: "100%", marginTop: 20, display: "flex", flexDirection: "row" }}>
                                        <View style={{ width: "50%", display: "flex", flexDirection: "column" }}>
                                            <Text style={{ fontSize: 14, fontWeight: 500 }}>Phone:</Text>
                                            <Text style={{ fontSize: 14, fontWeight: 600 }}>{invoice_data?.buyer?.entity_phone ? invoice_data?.buyer?.entity_phone : "---"}</Text>
                                        </View>
                                    </View>
                                    <View style={{ paddingRight: "30px" }}>
                                        <View style={{
                                            color: "rgba(0, 0, 0, 0.87)",
                                            marginTop: 24,
                                            borderRadius: 4,
                                            borderStyle: "solid",
                                            borderColor: "#e5e9f2",
                                        }}>
                                            <View style={{
                                                width: "100%",
                                                flexDirection: "row",
                                                borderBottomWidth: 2,
                                                borderTopWidth: 2,
                                                borderStyle: "solid",
                                                borderColor: "#302f2f",
                                            }}>
                                                <View style={{
                                                    width: "23%",
                                                    padding: 3,
                                                }}>
                                                    <Text style={{
                                                        margin: "auto",
                                                        marginTop: 5,
                                                        color: "#302f2f",
                                                        fontSize: 12,
                                                        lineHeight: 1.6,
                                                        fontWeight: 700,
                                                        lineHeight: "normal",
                                                        padding: 3
                                                    }}>Description</Text>
                                                </View>
                                                <View style={{
                                                    width: "23%",
                                                    padding: 3,
                                                }}>
                                                    <Text style={{
                                                        margin: "auto",
                                                        marginTop: 5,
                                                        color: "#302f2f",
                                                        fontSize: 12,
                                                        lineHeight: 1.6,
                                                        fontWeight: 700,
                                                        lineHeight: "normal",
                                                        padding: 3
                                                    }}>Unit price (ZMW)</Text>
                                                </View>
                                                <View style={{
                                                    width: "23%",
                                                    padding: 3,
                                                }}>
                                                    <Text style={{
                                                        margin: "auto",
                                                        marginTop: 5,
                                                        color: "#302f2f",
                                                        fontSize: 12,
                                                        lineHeight: 1.6,
                                                        fontWeight: 700,
                                                        lineHeight: "normal",
                                                        padding: 3
                                                    }}>Quantity (MT)</Text>
                                                </View>
                                                <View style={{
                                                    width: "23%",
                                                    padding: 3,
                                                }}>
                                                    <Text style={{
                                                        margin: "auto",
                                                        marginTop: 5,
                                                        color: "#302f2f",
                                                        fontSize: 12,
                                                        lineHeight: 1.6,
                                                        fontWeight: 700,
                                                        lineHeight: "normal",
                                                        padding: 3
                                                    }}>Amount (ZMW)</Text>
                                                </View>
                                            </View>
                                            <View style={{
                                                flexDirection: "row",
                                                borderColor: "#e5e9f2",
                                                borderStyle: "solid",
                                                borderBottomWidth: 1,
                                            }}>
                                                <View style={{
                                                    width: "23%",
                                                    padding: 3,
                                                }}>
                                                    <Text style={{
                                                        marginTop: 5,
                                                        color: "#252b32",
                                                        fontSize: 12,
                                                        fontWeight: 400,
                                                        textAlign: "center",
                                                        padding: 3
                                                    }}>{invoice_data?.broker_bid_data?.commodity ? invoice_data?.broker_bid_data?.commodity : "---"}</Text>
                                                </View>
                                                <View style={{
                                                    width: "23%",
                                                    padding: 3,
                                                }}>
                                                    <Text style={{
                                                        marginTop: 5,
                                                        color: "#252b32",
                                                        fontSize: 12,
                                                        fontWeight: 400,
                                                        textAlign: "center",
                                                        padding: 3
                                                    }}>{invoice_data?.broker_bid_data?.requested_unit_price ? invoice_data?.broker_bid_data?.requested_unit_price : "---"}</Text>
                                                </View>
                                                <View style={{
                                                    width: "23%",
                                                    padding: 3,
                                                }}>
                                                    <Text style={{
                                                        marginTop: 5,
                                                        color: "#252b32",
                                                        fontSize: 12,
                                                        fontWeight: 400,
                                                        textAlign: "center",
                                                        padding: 3
                                                    }}>{invoice_data?.broker_bid_data?.bid_volume ? parseFloat(invoice_data?.broker_bid_data?.bid_volume).toFixed(2) : "---"}</Text>
                                                </View>
                                                <View style={{
                                                    width: "23%",
                                                    padding: 3,
                                                }}>
                                                    <Text style={{
                                                        marginTop: 5,
                                                        color: "#252b32",
                                                        fontSize: 12,
                                                        fontWeight: 400,
                                                        textAlign: "center",
                                                        padding: 3
                                                    }}>{invoice_data?.broker_bid_data?.bid_total ? parseFloat(invoice_data?.broker_bid_data?.bid_total).toFixed(2) : "---"}</Text>
                                                </View>
                                            </View>
                                            <View style={{
                                                flexDirection: "row",
                                                borderColor: "#e5e9f2",
                                                borderStyle: "solid",
                                                borderBottomWidth: 1,
                                                marginTop: 20
                                            }}>
                                                <View style={{
                                                    width: "23%",
                                                    padding: 3,
                                                }}>
                                                    <Text style={{
                                                        marginTop: 5,
                                                        color: "#252b32",
                                                        fontSize: 12,
                                                        fontWeight: 400,
                                                        textAlign: "center",
                                                        padding: 3
                                                    }}></Text>
                                                </View>
                                                <View style={{
                                                    width: "23%",
                                                    padding: 3,
                                                }}>
                                                    <Text style={{
                                                        marginTop: 5,
                                                        color: "#252b32",
                                                        fontSize: 12,
                                                        fontWeight: 400,
                                                        textAlign: "center",
                                                        padding: 3
                                                    }}></Text>
                                                </View>
                                                <View style={{
                                                    width: "23%",
                                                    padding: 3,
                                                }}>
                                                    <Text style={{
                                                        marginTop: 5,
                                                        color: "#252b32",
                                                        fontSize: 12,
                                                        fontWeight: 600,
                                                        textAlign: "center",
                                                        padding: 3
                                                    }}>Total</Text>
                                                </View>
                                                <View style={{
                                                    width: "23%",
                                                    padding: 3,
                                                }}>
                                                    <Text style={{
                                                        marginTop: 5,
                                                        color: "#252b32",
                                                        fontSize: 12,
                                                        fontWeight: 600,
                                                        textAlign: "center",
                                                        borderColor: "#e5e9f2",
                                                        borderStyle: "solid",
                                                        borderWidth: 2,
                                                        padding: 3
                                                    }}>{invoice_data?.broker_bid_data?.bid_total ? parseFloat(invoice_data?.broker_bid_data?.bid_total).toFixed(2) : "---"}</Text>
                                                </View>
                                            </View>
                                        </View>
                                    </View>

                                </View>
                                <View style={{
                                    position: "absolute",
                                    bottom: 45,
                                    left: 0,
                                    right: 0,
                                    color: "#252b32",
                                    paddingLeft: 30,
                                    paddingRight: 20,
                                    paddingTop: 10,
                                    paddingBottom: 10,
                                    marginTop: 20
                                }}>
                                    <View style={{ border: "1px solid #e5e9f2", padding: 5 }}>
                                        {/* <Text style={{ fontWeight: 700, fontSize: 12, color: "#000", }}>All Payments to ZAMACE</Text> */}

                                        <View style={{ marginTop: 5 }}>
                                            <View style={{ fontWeight: 700, fontSize: 18, color: "#000", textDecoration: "underline" }}>
                                                <Text>Bank Account Details</Text>
                                            </View>
                                            <View style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                                                <View style={{ width: "50%", display: "flex", flexDirection: "column", fontSize: 11, padding: 10 }}>
                                                    <View style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                        <View style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                            <View style={{ width: "50%" }}>
                                                                <Text style={{ fontWeight: 500 }}>Bank Name:</Text>
                                                            </View>
                                                            <View style={{ width: "50%", display: "flex", flexDirection: "row", alignItems: "center", }}>
                                                                <Text style={{ fontWeight: 600 }}>Atlas Mara Bank Zambia</Text>
                                                            </View>
                                                        </View>
                                                    </View>
                                                    <View style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center", margniTop: 5 }}>
                                                        <View style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                            <View style={{ width: "50%" }}>
                                                                <Text style={{ fontWeight: 500 }}>Account Name:</Text>
                                                            </View>
                                                            <View style={{ width: "50%", display: "flex", flexDirection: "row", alignItems: "center", }}>
                                                                <Text style={{ fontWeight: 600 }}>Zamace Limited</Text>
                                                            </View>
                                                        </View>
                                                    </View>
                                                    <View style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center", margniTop: 5 }}>
                                                        <View style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                            <View style={{ width: "50%" }}>
                                                                <Text style={{ fontWeight: 500 }}>Account Number:</Text>
                                                            </View>
                                                            <View style={{ width: "50%", display: "flex", flexDirection: "row", alignItems: "center", }}>
                                                                <Text style={{ fontWeight: 600 }}>3256064357010 Settlement (ZMW)</Text>
                                                            </View>
                                                        </View>
                                                    </View>
                                                    <View style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center", margniTop: 5 }}>
                                                        <View style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                            <View style={{ width: "50%" }}>
                                                                <Text style={{ fontWeight: 500 }}>TPIN:</Text>
                                                            </View>
                                                            <View style={{ width: "50%", display: "flex", flexDirection: "row", alignItems: "center", }}>
                                                                <Text style={{ fontWeight: 600 }}>1001859467</Text>
                                                            </View>
                                                        </View>
                                                    </View>
                                                </View>
                                                <View style={{ width: "50%", marginTop: 5, display: "flex", flexDirection: "column", fontSize: 11, padding: 10 }}>
                                                    <View style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                        <View style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center", margniTop: 5 }}>
                                                            <View style={{ width: "50%" }}>
                                                                <Text style={{ fontWeight: 500 }}>Branch Name:</Text>
                                                            </View>
                                                            <View style={{ width: "50%", display: "flex", flexDirection: "row", alignItems: "center", }}>
                                                                <Text style={{ fontWeight: 600 }}>East Park Mall</Text>
                                                            </View>
                                                        </View>
                                                    </View>
                                                    <View style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center", margniTop: 5 }}>
                                                        <View style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                            <View style={{ width: "50%" }}>
                                                                <Text style={{ fontWeight: 500 }}>Branch Code: 325</Text>
                                                            </View>
                                                            <View style={{ width: "50%", display: "flex", flexDirection: "row", alignItems: "center", }}>
                                                                <Text style={{ fontWeight: 600 }}>Zamace Limited</Text>
                                                            </View>
                                                        </View>
                                                    </View>
                                                    <View style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center", margniTop: 5 }}>
                                                        <View style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                            <View style={{ width: "50%" }}>
                                                                <Text style={{ fontWeight: 500 }}>Sort Code:</Text>
                                                            </View>
                                                            <View style={{ width: "50%", display: "flex", flexDirection: "row", alignItems: "center", }}>
                                                                <Text style={{ fontWeight: 600 }}>200050</Text>
                                                            </View>
                                                        </View>
                                                    </View>
                                                    <View style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center", margniTop: 5 }}>
                                                        <View style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                            <View style={{ width: "50%" }}>
                                                                <Text style={{ fontWeight: 500 }}>Swift Code:</Text>
                                                            </View>
                                                            <View style={{ width: "50%", display: "flex", flexDirection: "row", alignItems: "center", }}>
                                                                <Text style={{ fontWeight: 600 }}>FMBZZMLX</Text>
                                                            </View>
                                                        </View>
                                                    </View>
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                                <Text
                                    style={{
                                        position: "absolute",
                                        bottom: 15,
                                        left: 0,
                                        right: 0,
                                        textAlign: "center",
                                        color: "#252b32",
                                    }}
                                    render={({ pageNumber, totalPages }) => `Page ${pageNumber} of ${totalPages}`}
                                    fixed
                                />
                            </Page>
                        </Document>
                    </>
                )
            }
        </>
    );
};

export default WarehouseReceiptTradingInvoicePDF;